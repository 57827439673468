import React from 'react';
import { Typography } from '@material-ui/core';

interface ApTabsPanelProps {
    children?: React.ReactNode;
    current: number;
    index: number;
}

export const ApTabsPanel = ({ children, current, index }: ApTabsPanelProps) => {
    return (
        <Typography component="div" role="tabpanel" hidden={current !== index}>
            {children}
        </Typography>
    );
};
