import { Deliveroo } from './deliveroo';
import * as faker from 'faker';

export const fakeDeliverooData = (): Deliveroo => ({
    enabled: faker.random.boolean(),
    locationId: faker.random.uuid(),
    brandId: faker.random.uuid(),
    coverImage: faker.random.image(),
});

export const fakeDeliveroo = (): Deliveroo => new Deliveroo(fakeDeliverooData());

export const fakeDeliverooArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Deliveroo[] => {
    const deliveroo: Deliveroo[] = [];

    for (let i = 0; i < nbMax; i++) {
        deliveroo.push(fakeDeliveroo());
    }

    return deliveroo;
};
