import { ApCol, ApInput, ApRow } from '@aphilia/shared-ui-core-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeliveryParametersContext } from '../../stores';
import { ApForm } from '@aphilia/shared/react';
import { Address } from '@aphilia/shared/utils';
import { updateDeliveryParametersBySiteId } from '@aphilia/deliveries/data-access';

export const DeliveryParametersAddress = () => {
    const { t } = useTranslation();
    const { deliveryParameters, deliveriesServiceUrl } = useDeliveryParametersContext();
    const [localValue, setLocalValue] = useState<{ name: string } & Address>();

    useEffect(() => {
        if (deliveryParameters?.address) {
            setLocalValue({
                name: deliveryParameters?.name,
                ...deliveryParameters?.address,
            });
        }
    }, [deliveryParameters?.address, deliveryParameters?.name]);

    /**
     * Handle the update of an address entry
     *
     * @param value
     * @param property
     */
    const handleValueChange = (value: string, property: string) => {
        setLocalValue({
            ...localValue,
            [property]: value,
        });
    };

    return (
        <ApForm
            cancelText={'Annuler'}
            confirmText={'Confirmer'}
            onApSubmit={async () => await updateDeliveryParametersBySiteId(deliveriesServiceUrl, deliveryParameters?.siteId, { address: localValue })}
            errorMessage={'Une erreur est survenue'}
            successMessage={'Les paramètres sont bien à jour'}
        >
            <ApRow>
                <ApCol>
                    <ApInput
                        readonly
                        label={t('delivery.parameters.siteName')}
                        value={localValue?.name || ''}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'name')}
                    />
                    <ApInput
                        maxLength={50}
                        label={t('delivery.parameters.siteAddress')}
                        value={localValue?.mainAddress || ''}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'mainAddress')}
                    />
                    <ApInput
                        maxLength={50}
                        label={t('delivery.parameters.complementaryAddress')}
                        value={localValue?.complementaryInformation || ''}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'complementaryInformation')}
                    />
                    <ApInput
                        label={t('delivery.parameters.zipCode')}
                        value={localValue?.zipCode || ''}
                        maxLength={5}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'zipCode')}
                    />
                    <ApInput
                        maxLength={50}
                        label={t('delivery.parameters.city')}
                        value={localValue?.city || ''}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'city')}
                    />
                    <ApInput
                        maxLength={50}
                        label={t('delivery.parameters.country')}
                        value={localValue?.country || ''}
                        onInputChange={(e) => handleValueChange(e.detail.target.value, 'country')}
                    />
                </ApCol>
            </ApRow>
        </ApForm>
    );
};
