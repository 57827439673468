import { CreateAtTable } from './create-at-table';
import * as faker from 'faker';

export const fakeCreateAtTableData = (): CreateAtTable => ({
    name: faker.name.findName(),
});

export const fakeCreateAtTable = (): CreateAtTable => new CreateAtTable(fakeCreateAtTableData());

export const fakeCreateAtTableArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateAtTable[] => {
    const atTable: CreateAtTable[] = [];

    for (let i = 0; i < nbMax; i++) {
        atTable.push(fakeCreateAtTable());
    }

    return atTable;
};
