import { BaseQueryModelPaginate } from '@aphilia/shared/utils';

export enum BillPaymentEventEventName {
    Processing = 'processing',
    Received = 'received',
    SentToPos = 'sent_to_pos',
    SendToPosFailed = 'sent_to_pos_failed',
    PaymentFailed = 'payment_failed',
    Canceled = 'canceled',
    RefundSent = 'refund_sent',
    RefundReceived = 'refund_received',
}

export enum BillPaymentEventStatus {
    Processing = 'processing',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Canceled = 'canceled',
    Refunded = 'refunded',
}

export interface BillItem {
    /**
     * The code of the article in the pos system
     */
    sku: number;

    /**
     * The title of the article
     */
    title: string;

    /**
     * The quantity of the article
     */
    quantity: number;

    /**
     * The price of the article in cents
     */
    unit_price: number;

    /**
     * If a discount is applied the amount of the discount in cents
     */
    discount?: number;

    /**
     * The label of the discount
     */
    discount_label?: string;

    /**
     * The tax rate applied on the article
     */
    tax_rate: number;

    /**
     * The tax amount applied on the article
     */
    tax_amount: number;
}

export enum BillStatus {
    InProgress = 'in_progress',
    InBill = 'in_bill',
    Completed = 'completed',
}

export interface Bill {
    /**
     * The site id to which the bill is associated
     */
    site_id: string;

    /**
     * The table number of the bill
     */
    table_number?: number;

    /**
     * All the items that are in the bill
     */
    items: BillItem[];

    /**
     * The payment information of the bill
     */
    payment: BillPayment;

    /**
     * The number of cutlery
     */
    cutlery_number: number;

    /**
     * The status of the bill
     */
    status: BillStatus;
}

export interface BillPaymentEvent {
    /**
     * The id of the site. This id is the relation between the site at Pi Electronique and with the provider.
     * Therefore, the provider must integrate this id and associate it to a site to complete the relationship
     */
    _id?: string;

    /**
     * Transaction id for pos system
     */
    transaction_id: string;

    /**
     * The id of the site. This id is the relation between the site at Pi Electronique and with the provider.
     * Therefore, the provider must integrate this id and associate it to a site to complete the relationship
     */
    site_id: string;

    /**
     * The number of the table where the order has been placed
     */
    table_number: number;

    /**
     * The id of the bill in the pos system
     */
    bill_pos_id: string;

    /**
     * The status of the payment.
     */
    status?: BillPaymentEventStatus;

    /**
     * The amount of the payment in cents. This amount must be in cents
     */
    amount: number;

    /**
     * The amount of tips in cents
     */
    tips_amount?: number;

    /**
     * The tick sys needed for request ERS img
     */
    tick_sys?: string;

    /**
     * The label of the payment type use for payment
     */
    label?: string;

    /**
     * The client email
     */
    email?: string;

    /**
     * The payment platform
     */
    pipayId?: string;

    /**
     * The payment platform
     */
    platform?: string;

    /**
     * The events from payment flow
     */
    events?: BillPaymentEventEvent[];

    /**
     * The refund Url from pipay
     */
    refundUrl?: string;

    /**
     * The ticket content encoded in base64
     */
    ticket?: string;
}

export interface CreateBillPaymentEvent {
    /**
     * Amount of client bill
     */
    amount: number;

    /**
     * Amount of client tips
     */
    tips: number;
}

export interface BillPayment {
    /**
     * The total of the bill
     */
    total: number;

    /**
     * The id of the bill in the pos system
     */
    bill_pos_id: string;

    /**
     * Indicates if the bill is balanced or not
     */
    is_bill_balanced: boolean;

    /**
     * All the payment events that happened on the bill
     */
    payment_events?: BillPaymentEvent[];

    /**
     * There can be a discount provided depending on the total
     */
    discount?: number;

    /**
     * The client email
     */
    email?: string;

    /**
     * The client email
     */
    events?: BillPaymentEventStatus;
}

export interface BillPaymentEventEvent {
    /**
     * Status of payment
     */
    name: BillPaymentEventEventName;

    /**
     * Pipay data event
     */
    data?: any;

    /**
     * Date of event creation
     */
    createdAt?: string;
}

export interface BillNoteData {
    /**
     * The site name
     */
    siteName: string;

    /**
     * The site's address
     */
    siteAddress: string;

    /**
     * The site's address complement
     */
    siteAddressComp: string;

    /**
     * The site siret
     */
    siteSiret: string;

    /**
     * The bill related table number
     */
    tableNumber: number;

    /**
     * The bill related cutlery number
     */
    cutleryNumber: number;

    /**
     * The name related to the bill/order
     */
    orderedBy: string;

    /**
     * The bill date
     */
    billDate: string;

    /**
     * The order tva
     */
    tva: number;

    /**
     * The tva HT amout
     */
    totalHt: number;

    /**
     * The tva amount
     */
    tvaAmount: number;

    /**
     * Firmware informations
     */
    firmware: string;
}

export interface BankReceiptData {
    /**
     * The receipt amount
     */
    amount: number;

    /**
     * The site name
     */
    siteName: string;

    /**
     * The site city
     */
    city: string;

    /**
     * The site postal code
     */
    postalCode: string;

    /**
     * The site country
     */
    country: string;

    /**
     * Receipt creation date
     */
    receiptDate: string;

    /**
     * The id of the payment transaction. It must be a unique identifier.
     */
    transaction_id: string;
}

export interface QueryPaginatePaymentEvent extends BaseQueryModelPaginate {
    /**
     * The site id to who the payments are related to
     */
    siteId: string;

    /**
     * The search value
     */
    search?: string;
}
