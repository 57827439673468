import { Theme } from '@material-ui/core/styles';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) => ({
    root: {
        '& header': {
            boxShadow: 'none',
            position: 'relative',
            '&:before': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                borderTop: '1px solid ' + theme.palette.secondary.light,
            },
        },
        '& .MuiTabs-root': {
            minHeight: 38,
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            minWidth: 0,
            minHeight: 38,
            '& i': {
                fontSize: 22,
                width: 24,
                height: 24,
                lineHeight: '24px',
            },
        },
        '& .MuiTab-textColorPrimary': {
            '&:not(.Mui-selected)': {
                color: theme.palette.secondary.main,
                '&:hover': {
                    background: designTokens.colors.statesDefaultHover,
                },
            },
        },
        '& .MuiTab-labelIcon': {
            '& .MuiTab-wrapper': {
                flexDirection: 'row',
                '& > *:first-child': {
                    marginBottom: 0,
                    marginRight: 8,
                },
            },
        },
        '& *[role="tabpanel"]': {
            padding: 0,
        },
        '& .MuiTabs-indicator': {
            height: 1,
        },
    },
    label: {
        padding: '12px 16px 10px 16px',
    },
    icon: {
        padding: '8px 16px 6px 16px',
    },
    iconLabel: {
        padding: '8px 16px 6px 16px',
    },
});
