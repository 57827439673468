import moment, { Moment } from 'moment';
import { DeliveryPlatform, OrderType, OrderState } from '../order/order';
import { CreateCustomer, OrderDelivery, Payment } from '../..';
import { CreateItem } from '../item';
import { Promotion } from '../promotion';

export class CreateOrder {
    /**
     * Id of the site the order belong to
     */
    siteId: string;

    /**
     * Id of the order in the delivery platform database.
     */
    platformId: string;

    /**
     * Id to be displayed to the end user.
     */
    shortId: string;

    /**
     * The state of the order.
     */
    state?: OrderState;

    /**
     * Items of the order
     */
    items: CreateItem[];

    /**
     * Payment of the order
     */
    payment: Payment;

    /**
     * Date time tje order is estimated ready to be delivered
     */
    estimatedReadyForPickupAt: Moment;

    /**
     * The platform where the order comes from
     */
    readonly deliveryPlatform?: DeliveryPlatform;

    /**
     * The type of the order.
     */
    type: OrderType;

    /**
     * Original json object of an order
     */
    originalPayload?: any;

    /**
     * Indicates if an order is for right now or later
     */
    asap?: boolean;

    /**
     * Customer of the order
     */
    customer: CreateCustomer;

    /**
     * Information about the delivery for a user
     */
    delivery?: OrderDelivery;

    /**
     * Additional information about order from client to restaurant
     */
    additionalInformation?: string;

    /**
     * The date time the order has been created
     */
    createdAt?: Moment = moment();

    /**
     * The date time the order has been created
     */
    updatedAt?: Moment = moment();

    /**
     * Contains relative informations about promotion, related promotion items and values
     */
    promotions?: Promotion[];

    /**
     * The number of the table if the order is at table
     */
    tableNumber?: number;

    constructor(props: Partial<CreateOrder>) {
        Object.assign(this, props);
        this.estimatedReadyForPickupAt = this.estimatedReadyForPickupAt && moment(this.estimatedReadyForPickupAt);
        this.payment = new Payment(
            this.payment || {
                total: 0,
            }
        );

        this.customer = new CreateCustomer(this.customer || {});
        this.createdAt = this.createdAt && moment(this.createdAt);
        this.updatedAt = this.updatedAt && moment(this.updatedAt);
    }
}
