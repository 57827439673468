import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import style from './ApSnackbar.style';

const useStyles = makeStyles(style);

interface Props {
    className?: string;
    message?: React.ReactNode;
    action?: React.ReactNode;
    open: boolean;
    anchor?: SnackbarOrigin;
}

export const ApSnackbar = ({ className, message, action, open, anchor }: Props) => {
    const classes = useStyles();

    return <Snackbar anchorOrigin={anchor} className={`${className} ${classes.root}`} open={open} message={message} action={action} />;
};
