import { UpdateJob } from './update-job';
import * as faker from 'faker';
import mongoose from 'mongoose';
import moment from 'moment';

export function fakeUpdateJobData<T>(data: T): UpdateJob<T> {
    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        name: faker.name.findName(),
        status: 'completed',
        progression: faker.random.number(100),
        finishedAt: moment(),
        startedAt: moment(),
        jobId: faker.random.number().toString(),
        data,
    };
}

export function fakeUpdateJob<T>(data: T): UpdateJob<T> {
    return new UpdateJob(fakeUpdateJobData(data));
}

export function fakeUpdateJobArray<T>(data: T, nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateJob<T>[] {
    const job: UpdateJob<T>[] = [];

    for (let i = 0; i < nbMax; i++) {
        job.push(fakeUpdateJob(data));
    }

    return job;
}
