import { UberStore } from '../store/store';
import { UberEater } from '../eater/eater';
import { UberPayment } from '../payment/payment';
import { UberPackaging } from '../packaging/packaging';
import { UberCart } from '../item/cart';

export enum UberOrderState {
    Created = 'CREATED',
    Accepted = 'ACCEPTED',
    Denied = 'DENIED',
    Finished = 'FINISHED',
    Canceled = 'CANCELED',
}

export enum UberOrderType {
    PickUp = 'PICK_UP',
    DineIn = 'DINE_IN',
    DeliverByUber = 'DELIVERY_BY_UBER',
    DeliverByRestaurant = 'DELIVERY_BY_RESTAURANT',
}

export enum UberDenyOrCancelOrderReason {
    OutOfItems = 'OUT_OF_ITEMS',
    KitchenClosed = 'KITCHEN_CLOSED',
    CustomerCalledToCancel = 'CUSTOMER_CALLED_TO_CANCEL',
    RestaurantTooBusy = 'RESTAURANT_TOO_BUSY',
    CannotCompleteCustomerNote = 'CANNOT_COMPLETE_CUSTOMER_NOTE',
    Other = 'OTHER',
}

export class UberOrder {
    /**
     * Unique identifier for the order, provided by Uber Eats.
     */
    readonly id: string;

    /**
     * 5-character displayable friendly ID, used by stores and couriers to identify the order -
     * these 5 digits represent the last 5 characters of the Order Workflow UUID.
     */
    readonly display_id: string;

    /**
     * If the order was placed via the API, an optional reference ID can be supplied and will be returned here
     */
    readonly external_reference_id: string;

    /**
     * The current state of the order.
     */
    current_state: UberOrderState;

    /**
     * The dining type of the order.
     */
    type: UberOrderType;

    /**
     * Details of the Store for which the order was placed.
     */
    store: UberStore;

    /**
     * Details of the Eater that placed the order.
     */
    eater: UberEater;

    /**
     * The shopping cart associated with an order.
     */
    cart: UberCart;

    /**
     * Payment related to an order.
     */
    payment: UberPayment;

    /**
     * Packaging related to an order.
     */
    packaging: UberPackaging;

    /**
     * The time at which the order was placed, represented in ISO 8601 format.
     * EXAMPLE "2019-05-14T15:16:54.723Z"
     */
    placed_at: string;

    /**
     * The time Uber predicts food will be ready for pick-up, represented in ISO 8601 format.
     * EXAMPLE "2019-05-14T15:16:54.723Z"
     */
    estimated_ready_for_pickup_at: string;

    /**
     * The reason why the order was denied or cancelled
     */
    denyOrCancelOrderReason?: UberDenyOrCancelOrderReason;

    /**
     * The reason why the order was denied or cancelled if it was other that was selected in the list of reason to deny or cancelled
     */
    otherDenyOrCancelReason?: string;

    constructor(props: UberOrder) {
        Object.assign(this, props);
    }
}
