import { Theme, createStyles } from '@material-ui/core/styles';
import { palette, useRem } from '../../theme';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) =>
    createStyles({
        root: {
            '& .MuiFilledInput-root': {
                padding: '8px',
                backgroundColor: palette.common.white,
                '& i': {
                    fontSize: '16px',
                    lineHeight: '24px',
                },
                '&.MuiFilledInput-marginDense i': {
                    fontSize: '16px',
                    lineHeight: '18px',
                },
            },
            '& .MuiFilledInput-root:not(.Mui-focused):hover': {
                borderColor: theme.palette.secondary.dark,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.secondary.main,
                transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(8px, 16px) scale(1)',
                lineHeight: '20px',
            },
            '& .MuiInputLabel-shrink': {
                transform: 'translate(8px, 8px) scale(1)',
                lineHeight: '12px',
                fontSize: theme.typography.pxToRem(10),
                '& + .MuiFilledInput-root .MuiFilledInput-input': {
                    padding: '12px 0 0',
                },
            },
            '& .MuiFilledInput-input': {
                padding: '6px 0',
                lineHeight: '20px',
                fontSize: theme.typography.pxToRem(14),
            },
            '& input.MuiFilledInput-input': {
                height: '2rem',
            },
            '& .MuiFormHelperText-root': {
                marginLeft: '4px',
                color: theme.palette.secondary.main,
            },
            '& .MuiFilledInput-root .MuiInputAdornment-root': {
                marginTop: 0,
            },
            '& .MuiFilledInput-marginDense': {
                padding: '6px',
                '& input.MuiFilledInput-input': {
                    padding: '0',
                    height: '18px',
                },
            },
            '& i, & svg': {
                color: palette.primary.main,
            },
            '&.disabled': {
                color: theme.palette.secondary.light,
                '& .MuiFilledInput-root': {
                    border: `1px solid ${theme.palette.secondary.light}`,
                    '&:not(.Mui-focused):hover': {
                        border: `1px solid ${theme.palette.secondary.light}`,
                    },
                },
                '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root, .MuiSelect-filled.MuiSelect-filled': {
                    color: theme.palette.secondary.light,
                },
            },
            '&.ap-success': {
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.success.main}`,
                },
                '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                    color: palette.success.main,
                },
                '&.disabled': {
                    '& .MuiFilledInput-root': {
                        border: `1px solid ${palette.success.light}`,
                    },
                    '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                        color: palette.success.light,
                    },
                },
            },
            '&.ap-warning': {
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.warning.main}`,
                },
                '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                    color: palette.warning.main,
                },
                '&.disabled': {
                    '& .MuiFilledInput-root': {
                        border: `1px solid ${palette.warning.light}`,
                    },
                    '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                        color: palette.warning.light,
                    },
                },
            },
            '&.ap-error': {
                '& .MuiFormHelperText-root': {
                    color: palette.error.main,
                },
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.error.main}`,
                },
                '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                    color: palette.error.main,
                },
                '&.disabled': {
                    '& .MuiFilledInput-root': {
                        border: `1px solid ${palette.error.light}`,
                    },
                    '& .MuiInputLabel-shrink, & i, & svg, & .MuiFormHelperText-root, & .MuiInputLabel-root.Mui-focused': {
                        color: palette.error.light,
                    },
                },
            },
        },
        prefixed: {
            '& .input-grid': {
                padding: '0 8px',
                borderRadius: 3.1,
                border: `1px solid ${theme.palette.secondary.main}`,
                transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                display: 'flex',
                '&:hover': {
                    borderColor: theme.palette.secondary.dark,
                },
                '& .start-icon': {
                    width: 24,
                    height: '24px',
                    lineHeight: '24px',
                    margin: '12px -4px 10px 0',
                    textAlign: 'center',
                    '& i, & svg': {
                        lineHeight: '24px',
                    },
                    '& + div': {
                        flexGrow: 1,
                    },
                },
                '& .MuiFilledInput-root': {
                    border: 'none',
                },
            },
            '& .MuiFormHelperText-root': {
                fontSize: theme.typography.pxToRem(10),
                lineHeight: '12px',
                marginTop: 4,
            },
        },
        readOnly: {
            pointerEvents: 'none',
            '& .MuiFilledInput-root': {
                cursor: 'default',
                border: 'none !important',
                '& input': {
                    cursor: 'default',
                },
            },
        },
        borderLess: {
            '& .MuiFilledInput-root, &.input-grid': {
                backgroundColor: 'transparent',
            },
            '&:hover': {
                '& .MuiFilledInput-root, &.input-grid': {
                    backgroundColor: designTokens.colors.statesDefaultHover,
                },
                '& .input-grid .MuiFilledInput-root': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiFilledInput-root:not(.Mui-error), & .input-grid': {
                border: 'none !important',
            },
        },
        multiline: {
            '&:hover': {
                '& .MuiFilledInput-root:not(.Mui-focused)': {
                    borderColor: theme.palette.secondary.dark,
                },
            },
            '& .MuiInputLabel-shrink': Object.assign(useRem(designTokens.typeStyles.captionBlack), {
                color: theme.palette.secondary.main,
                lineHeight: '18px',
                '& + .MuiFilledInput-root .MuiFilledInput-input': {
                    padding: 0,
                    marginTop: 22,
                },
            }),
            '& .MuiFormHelperText-root': {
                color: theme.palette.secondary.dark,
                margin: 0,
                padding: '4px 0 0 16px',
                fontSize: theme.typography.pxToRem(10),
                lineHeight: '20px',
            },
        },
    });
