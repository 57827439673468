import * as React from 'react';

function SvgOldConfig(props) {
    return (
        <svg width={24} height={24} {...props}>
            <path
                fill={props?.fill || '#232323'}
                // tslint:disable-next-line: max-line-length
                d="M16.1 13.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 3.8c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3z"
            />
            <path
                fill={props?.fill || '#232323'}
                // tslint:disable-next-line: max-line-length
                d="M22.1 14.6l-1.4-.3.8-1.2c.1-.2.1-.5-.1-.7L20 11.1c-.2-.2-.5-.2-.7-.1l-.7.4c.1-.2.2-.4.2-.7V7.8c0-.4-.1-.7-.4-1 .2-.3.4-.6.4-1.1V3.2c0-.9-.7-1.6-1.7-1.6h-14c-.9 0-1.6.7-1.6 1.6v2.6c0 .4.1.8.4 1.1-.2.3-.4.6-.4 1v2.7c0 .4.1.7.4 1-.2.3-.4.6-.4 1v2.7c0 .9.7 1.6 1.6 1.6h6.6v.2c0 .3.2.5.4.6l1.4.3-.8 1.2c-.1.2-.1.5.1.7l1.4 1.3c.2.2.5.2.7.1l1.2-.8.3 1.4c.1.3.3.4.6.4h1.9c.3 0 .5-.2.6-.4l.3-1.4 1.2.8c.2.1.5.1.7-.1l1.4-1.3c.2-.2.2-.5.1-.7l-.6-1.2 1.4-.3c.3-.1.4-.3.4-.6v-1.9c.1-.3-.1-.6-.3-.6zM2.6 3.2c0-.3.2-.5.5-.5H17c.3 0 .5.2.5.5v2.6c0 .3-.2.5-.5.5H3.1c-.2 0-.5-.3-.5-.5V3.2zm0 4.6c0-.2.2-.4.4-.4h14.1c.2 0 .4.2.4.4v2.1c-.1-.1-.3-.2-.5-.2h-1.9c-.3 0-.5.2-.6.4l-.3 1.4-1.2-.7c-.2-.1-.5-.1-.7.1l-.1.1H3.1c-.2 0-.4-.2-.4-.4V7.8zm7.5 6.8c-.3.1-.4.3-.4.6v.6H3.1c-.2 0-.4-.2-.4-.4v-2.7c0-.2.2-.4.4-.4H11l-.1.1c-.2.2-.2.5-.1.7l.8 1.2-1.5.3zm11.2 2l-1.3.3c-.2 0-.4.2-.4.4-.1.2-.1.3-.2.5s-.1.4 0 .6l.7 1.1-.7.7-1.1-.7c-.2-.1-.4-.1-.6 0-.2.1-.3.1-.5.2s-.3.2-.4.4l-.3 1.3h-1l-.3-1.3c0-.2-.2-.4-.4-.4-.2-.1-.3-.1-.5-.2s-.4-.1-.6 0l-1.1.7-.7-.7.7-1.1c.1-.2.1-.4 0-.6-.1-.2-.2-.3-.2-.5-.1-.2-.2-.3-.4-.4l-1.3-.3v-1l1.3-.3c.2 0 .4-.2.4-.4.1-.2.1-.3.2-.5s.1-.4 0-.6l-.7-1.1.7-.7 1.1.7c.2.1.4.1.6 0 .2-.1.3-.1.5-.2s.3-.2.4-.4l.3-1.3h1l.3 1.3c0 .2.2.4.4.4.2.1.3.1.5.2s.4.1.6 0l1.1-.7.7.7-.7 1.1c-.1.2-.1.4 0 .6.1.2.2.3.2.5.1.2.2.3.4.4l1.3.3v1z"
            />
            <path
                fill={props?.fill || '#232323'}
                // tslint:disable-next-line: max-line-length
                d="M4.3 5h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.3 0-.6.3-.6.6 0 .4.2.6.6.6zM6.6 5h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.4.1-.6.3-.6.7 0 .3.2.5.6.5zM8.9 5h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.3 0-.6.3-.6.6 0 .4.2.6.6.6zM4.3 9.8h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.3 0-.6.3-.6.6s.2.6.6.6zM6.6 9.8h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.4 0-.6.3-.6.6s.2.6.6.6zM8.9 9.8h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-.3c-.3 0-.6.3-.6.6s.2.6.6.6zM4.6 13.4h-.3c-.3 0-.6.3-.6.6s.3.6.6.6h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM6.9 13.4h-.3c-.3 0-.6.3-.6.6s.3.6.6.6h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zM9.2 13.4h-.3c-.3 0-.6.3-.6.6s.3.6.6.6h.3c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z"
            />
        </svg>
    );
}

export default SvgOldConfig;
