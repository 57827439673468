import moment, { Moment } from 'moment';

export class BaseModel {
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt?: Moment;

    constructor(props: BaseModel) {
        this.createdAt = moment(props.createdAt);
        this.updatedAt = moment(props.updatedAt);
        this.deletedAt = props.deletedAt && moment(props.deletedAt);
    }
}
