import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApCard.style';
import { Paper, CardProps } from '@material-ui/core';

const useStyles = makeStyles(style);

interface Props extends CardProps {
    padding?: string | number;
}

export const ApCard = ({ children, padding = 32, className, ...others }: Props) => {
    const classes = useStyles();

    return (
        <Paper className={`${className} ${classes.root}`} elevation={1} style={{ padding }} {...others}>
            {children}
        </Paper>
    );
};
