import * as React from 'react';

function SvgDoDisturb(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path
                    // tslint:disable-next-line: max-line-length
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31A7.902 7.902 0 0112 20zm6.31-3.1L7.1 5.69A7.902 7.902 0 0112 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"
                    id="do_disturb_svg__a"
                />
            </defs>
            <use fill="currentColor" xlinkHref="#do_disturb_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgDoDisturb;
