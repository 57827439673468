import * as faker from 'faker';
import { QueryWebArticle } from './query-web-article';

export const fakeQueryWebArticleData = (): QueryWebArticle => ({
    siteId: faker.random.number().toString(),
    search: faker.random.word(),
});

export const fakeQueryWebArticle = (): QueryWebArticle => new QueryWebArticle(fakeQueryWebArticleData());

export const fakeQueryWebArticleArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): QueryWebArticle[] => {
    const webArticle: QueryWebArticle[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticle.push(fakeQueryWebArticle());
    }

    return webArticle;
};
