import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stripe from 'stripe';
import { useStripeContext } from '../../store/stripe-context';
import { ApTag, ApCol, ApGrid, ApRow, ApSpinner, ApTypography } from '@aphilia/shared-ui-core-react';
import { getPayouts } from '@aphilia/payments/data-access';
import i18next from 'i18next';
import moment from 'moment';
import './payouts-list.scss';

export const PayoutsList = () => {
    const { t } = useTranslation();
    const [payouts, setPayouts] = useState<Stripe.Payout[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const { account, paymentsUrl } = useStripeContext();
    const [isLoadingPayouts, setIsLoadingPayouts] = useState<boolean>();

    const loadPayout = useCallback(async () => {
        try {
            setIsLoadingPayouts(true);
            const res = await getPayouts(paymentsUrl, account?.site_id, payouts[payouts?.length - 1]?.id);
            setPayouts([...payouts, ...res.payouts]);
            setHasMore(res.hasMore);
            setIsLoadingPayouts(false);
        } catch (e) {
            console.error(e);
            setIsLoadingPayouts(false);
            throw e;
        }
    }, [payouts, payouts[payouts?.length - 1]?.id, account?.site_id, paymentsUrl]);

    useEffect(() => {
        loadPayout();
    }, [account?.site_id]);

    const getBadgeColor = (status: string): 'success' | 'error' | 'warning' | 'neutral' | 'primary' => {
        switch (status) {
            case 'paid':
                return 'success';
            case 'in_transit':
            case 'pending':
                return 'warning';
            case 'canceled':
            case 'failed':
                return 'error';
        }
    };

    return (
        <div className="payouts-container">
            <ApGrid className="payouts-list">
                {isLoadingPayouts ? (
                    <ApSpinner color="secondary" />
                ) : payouts?.length > 0 ? (
                    <>
                        <ApRow className="payouts-lists-header">
                            <ApCol size={3}>
                                <p>{t('payments.payouts.amount')}</p>
                            </ApCol>
                            <ApCol size={2}>{t('payments.payouts.status.title')}</ApCol>
                            <ApCol size={3}>
                                <p>{t('payments.payouts.createdAt')}</p>
                            </ApCol>
                            <ApCol>
                                <p>{t('payments.payouts.arrivalAt')}</p>
                            </ApCol>
                        </ApRow>
                        {payouts?.map((payout, payoutIndex) => (
                            <ApRow className="payout-item" key={`payout-${payoutIndex}`}>
                                <ApCol size={3}>
                                    <ApTypography type="paragraph-text" weight="bold">
                                        {Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(payout?.amount / 100)}
                                    </ApTypography>
                                </ApCol>
                                <ApCol size={2}>
                                    <ApTag type="ghost" color={getBadgeColor(payout?.status)} label={t(`payments.payouts.status.${payout?.status}`)} />
                                </ApCol>
                                <ApCol size={3}>
                                    <p>{moment.unix(payout?.created).format('LL')}</p>
                                </ApCol>
                                <ApCol>
                                    <p>{moment.unix(payout?.arrival_date).format('LL')}</p>
                                </ApCol>
                            </ApRow>
                        ))}
                        <ApRow>
                            <ApCol>
                                {hasMore ? (
                                    <p className="load-more" onClick={loadPayout}>
                                        {t('payments.payouts.list.loadMore')}
                                    </p>
                                ) : undefined}
                            </ApCol>
                        </ApRow>
                    </>
                ) : (
                    <p>{t('payments.payouts.none')}</p>
                )}
            </ApGrid>
        </div>
    );
};
