function sum(...args) {
    return args.reduce((acc, term) => {
        if (typeof term === 'number') {
            acc += term;
        }

        return acc;
    }, 0);
}

type numberOrString = number | string;

function valueToNumber(numberValue: numberOrString): number {
    if (typeof numberValue === 'string') {
        return parseFloat(numberValue);
    }
    return numberValue;
}

export { sum, valueToNumber };
