import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Payment, PaymentEvent, PaymentEventName, PaymentStatus, RefundReason } from '@aphilia/payments/types';
import { ApTag, ApCol, ApGrid, ApIcon, ApRow, ApSpinner } from '@aphilia/shared-ui-core-react';
import { useStripeContext } from '../../store/stripe-context';
import { getPaymentById } from '@aphilia/payments/data-access';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './payments-details-modal.scss';
import { useOrganizationContext } from '@aphilia/shared/organizations/feature-main';
import { getUserById } from '@aphilia/shared/organizations/data-access';
import { RefundButton } from '../refund-button';
import { ApPortalModal, Can } from '@aphilia/shared/react';

interface Props {
    /**
     * The id of the payment.
     * When the id is set, the payment will be loaded from the backend service directly.
     *
     * If there is no paymentId, the modal will be close. Once a payment id is set the modal will be open
     */
    paymentId: string;

    /**
     * Event triggered when the modal has been hidden from the view.
     */
    onHide: () => void;

    /**
     * Event triggered when the user click on the refund
     *
     * @param _id
     * @param amount
     */
    onClickOnRefund: (_id, amount) => void;
}

export const PaymentsDetailsModal = ({ paymentId, onHide, onClickOnRefund }: Props) => {
    const [payment, setPayment] = useState<Payment>();
    const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
    const [isLoadingEventData, setIsLoadingEventData] = useState<boolean>(false);
    const { paymentsUrl, paymentEvent } = useStripeContext();
    const { organizationsUrl } = useOrganizationContext();
    const { t } = useTranslation();

    useEffect(() => {
        if (paymentId) loadPayment();
    }, [paymentId]);

    useEffect(() => {
        if (payment?.events?.length > 0) loadEventData();
    }, [payment?.events?.length]);

    useEffect(() => {
        if (paymentEvent?.event) {
            setPayment(paymentEvent?.payment);
        }
    }, [paymentEvent?.event]);

    /**
     * Load the payment from the back end system
     */
    const loadPayment = useCallback(async () => {
        setIsLoadingPayment(true);
        try {
            setPayment(await getPaymentById(paymentsUrl, paymentId));
            setIsLoadingPayment(false);
        } catch (e) {
            console.error(e);
            setIsLoadingPayment(false);
            throw e;
        }
    }, [paymentId, paymentsUrl]);

    /**
     * Load all the data required to display the events information about the payment
     */
    const loadEventData = useCallback(async () => {
        try {
            setIsLoadingEventData(true);
            const events = await Promise.all(
                payment?.events.map(
                    async (event) =>
                        event && {
                            ...event,
                            triggered_by:
                                event?.triggered_by.indexOf('@clients') < 0 && event?.triggered_by !== 'stripe' && event?.triggered_by !== 'customer'
                                    ? ` par ${(await getUserById(organizationsUrl, event.triggered_by)).first_name}`
                                    : '',
                        }
                )
            );
            setPayment({
                ...payment,
                events: events.sort((a, b) => (moment(a?.occurred_at).isAfter(moment(b?.occurred_at)) ? 1 : -1)),
            });
            setIsLoadingEventData(false);
        } catch (e) {
            console.error(e);
            setIsLoadingEventData(false);
            throw e;
        }
    }, [payment, organizationsUrl]);

    const getIconByEvent = (event: PaymentEventName) => {
        switch (event) {
            case PaymentEventName.InitiatedRefund:
            case PaymentEventName.Created:
                return <ApIcon icon="create" size="small" color="neutral" />;
            case PaymentEventName.Canceled:
                return <ApIcon icon="cancel" size="small" color="error" />;
            case PaymentEventName.FailedRefund:
            case PaymentEventName.Failed:
                return <ApIcon icon="cancel" size="small" color="error" />;
            case PaymentEventName.Initialized:
                return <ApIcon icon="credit_card" size="small" color="neutral" />;
            case PaymentEventName.Refunded:
                return <ApIcon icon="undo" size="small" color="warning" />;
            case PaymentEventName.Succeeded:
                return <ApIcon icon="check_circle" size="small" color="success" />;
            case PaymentEventName.DisputeCreated:
                return <ApIcon icon="warning" size="small" color="warning" />;
            case PaymentEventName.DisputeLost:
                return <ApIcon icon="error" size="small" color="error" />;
            case PaymentEventName.DisputeWon:
                return <ApIcon icon="check_circle" size="small" color="success" />;
        }
    };

    const paymentHasReason = useMemo(() => {
        return (payment?.refund_reason === RefundReason.Other && !!payment?.other_refund_reason) || payment?.refund_reason !== RefundReason.Other;
    }, [payment?.other_refund_reason, payment?.refund_reason]);

    const getTranslateKeyByEvent = useCallback(
        (event: PaymentEvent) => {
            switch (event.name) {
                case PaymentEventName.Created:
                    return t('payments.event.created', { amount: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(payment?.amount / 100) });
                case PaymentEventName.Canceled:
                    return payment?.cancel_reason ? t('payments.event.canceledWithReason', { reason: payment?.cancel_reason }) : t('payments.event.canceledWithNoReason');
                case PaymentEventName.Failed:
                    return t(`payments.failed.reason.${event.data.object.last_payment_error.code}`);
                case PaymentEventName.Initialized:
                    return t('payments.event.initialized');
                case PaymentEventName.Refunded:
                    return t('payments.event.refunded');
                case PaymentEventName.Succeeded:
                    return t('payments.event.succeeded');
                case PaymentEventName.InitiatedRefund:
                    return paymentHasReason
                        ? t('payments.event.initiateRefundWithReason', {
                              reason:
                                  payment?.refund_reason === RefundReason.Other
                                      ? payment?.other_refund_reason
                                      : t(t(`payments.refundReason.form.reason.${payment?.refund_reason}`)),
                          })
                        : t('payments.event.initiateRefundWithNoReason');
                case PaymentEventName.FailedRefund:
                    return t('payments.event.failedRefund', {
                        reason: t(`payments.refund.failedReason.${payment?.refund_failed_reason}`),
                    });
                case PaymentEventName.DisputeCreated:
                    return t('payments.event.dispute.created', {
                        reason: t(`payments.dispute.reason.${payment.dispute_reason}`),
                    });
                case PaymentEventName.DisputeLost:
                    return t('payments.event.dispute.lost');
                case PaymentEventName.DisputeWon:
                    return t('payments.event.dispute.won');
            }
        },
        [payment?.amount, payment?.refund_reason, payment?.other_refund_reason, payment?.refund_reason, payment?.cancel_reason, paymentHasReason]
    );

    const getContent = useMemo(() => {
        if (isLoadingPayment) {
            return <ApSpinner />;
        } else if (!payment) {
            return <p>{t('payments.detailModal.noPayment')}</p>;
        } else if (payment) {
            return (
                <ApGrid>
                    <ApRow class="information">
                        <ApCol>
                            <h2>
                                {Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(payment?.amount / 100)}
                                <ApTag
                                    label={t(`payments.charges.status.${payment?.status}`)}
                                    type="ghost"
                                    color={
                                        payment?.status === 'succeeded'
                                            ? 'success'
                                            : [PaymentStatus.Failed, PaymentStatus.FailedRefund].indexOf(payment?.status) !== -1
                                            ? 'error'
                                            : [PaymentStatus.Disputed].indexOf(payment?.status) !== -1
                                            ? 'warning'
                                            : 'neutral'
                                    }
                                />
                            </h2>
                            <small>
                                {t(`payments.detailModal.applicationFee`, {
                                    amount: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(payment?.application_fee / 100),
                                })}
                            </small>
                        </ApCol>
                        <ApCol size={6}>
                            <p>
                                <small>ID: {payment?._id}</small>
                            </p>
                            <p>
                                <small>Stripe ID: {payment?.platform_id}</small>
                            </p>
                            <RefundButton status={payment?.status} amount={payment?.amount} onClickOnRefund={() => onClickOnRefund(payment?._id, payment?.amount)} />
                        </ApCol>
                    </ApRow>
                    <div className="information">
                        <h4>{t('payments.detailModal.information')}</h4>
                        <ApRow>
                            <ApCol>
                                <h5>
                                    <ApIcon icon="event" size="small" color="neutral" /> Date
                                </h5>
                                <p>{moment(payment?.createdAt).format('LLL')}</p>
                            </ApCol>
                            {payment?.customer_email && (
                                <ApCol>
                                    <h5>
                                        <ApIcon icon="person" size="small" color="neutral" /> Client
                                    </h5>
                                    <p>{payment?.customer_email}</p>
                                </ApCol>
                            )}
                            {payment?.payment_method?.card_last_4 && (
                                <ApCol>
                                    <h5>
                                        <ApIcon icon="credit_card" size="small" color="neutral" /> Moyen de paiement
                                    </h5>
                                    <p>
                                        {payment?.payment_method?.card_brand} **** {payment?.payment_method?.card_last_4} {payment?.payment_method?.card_country}{' '}
                                        {payment?.payment_method?.three_d_secure && <ApIcon color="neutral" icon="shield" />}{' '}
                                        {payment?.payment_method?.currency_exchange && <ApIcon icon="price_change" />}
                                    </p>
                                </ApCol>
                            )}
                        </ApRow>
                    </div>
                    {payment?.events?.length > 0 && (
                        <div className="information">
                            <h4>{t('payments.detailModal.chronology')}</h4>
                            {isLoadingEventData ? (
                                <ApSpinner />
                            ) : (
                                payment.events?.reverse()?.map(
                                    (event) =>
                                        event && (
                                            <div className="event-block">
                                                <p>
                                                    {getIconByEvent(event?.name)} - {getTranslateKeyByEvent(event)}
                                                </p>
                                                <small>
                                                    {moment(event?.occurred_at).format('LLLL')} {event?.triggered_by}
                                                </small>
                                            </div>
                                        )
                                )
                            )}
                        </div>
                    )}
                </ApGrid>
            );
        }
    }, [isLoadingPayment, payment, isLoadingEventData]);

    return (
        <ApPortalModal
            headerTitle={t('payments.detailModal.title')}
            isOpen={!!paymentId}
            onHide={() => {
                setPayment(undefined);
                onHide();
            }}
        >
            <div className="payment-details-modal">{getContent}</div>
        </ApPortalModal>
    );
};
