import * as faker from 'faker';
import { OrderDelivery } from './order-delivery';
import { fakeOrderDeliveryPrice } from '../order-delivery-price/order-delivery-price.data';

/**
 * Return order-delivery data generated by faker.
 * @return OrderDelivery
 */
export const fakeOrderDeliveryData = (): OrderDelivery => ({
    jobId: faker.random.alphaNumeric(10),
    assignment_code: faker.random.alphaNumeric(4),
    duration: faker.random.number(30),
    trackingUrl: faker.internet.url(),
    payment: fakeOrderDeliveryPrice(),
});

/**
 * Return an instance of OrderDelivery class with data generated by faker
 *
 * @return OrderDelivery
 */
export const fakeOrderDelivery = (): OrderDelivery => new OrderDelivery(fakeOrderDeliveryData());
