import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { PiclickV2 } from '../piclick-v2';

export class UpdatePiclick {
    /**
     * An url that is use to redirect the user when the user just pass an order
     */
    redirectionUrl?: string;

    /**
     * The url for the cgu of the site
     */
    cguUrl?: string;

    /**
     * Url on the gdpr
     */
    gdprUrl?: string;

    /**
     * The style of the application PiClick
     */
    style?: string;

    /**
     * All the time periods when a user can go on the piclick site
     */
    timePeriods?: WeekdayTimePeriods[];

    /**
     * The url of the logo for piclick
     */
    logoUrl?: string;

    /**
     * Use default loader
     */
    useDefaultLoader?: boolean;

    /**
     * The url for the loader
     */
    loaderUrl?: string;

    /**
     * Configuration specific to using configurateur and piclick in their second version
     */
    v2?: PiclickV2;

    /**
     * Indicates if piclick is available or not for this site
     */
    enabled?: boolean;

    constructor(props: UpdatePiclick) {
        Object.assign(this, props);
    }
}
