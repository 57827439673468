import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        '& .MuiAlert-action': {
            color: palette.grey[500],
        },
        '&.success': {
            backgroundColor: '#FFF',
            '& .MuiAlert-filledSuccess': {
                backgroundColor: '#FFF',
                color: palette.success.main,
            },
        },
        '&.warning': {
            backgroundColor: '#FFF',
            '& .MuiAlert-filledWarning': {
                backgroundColor: '#FFF',
                color: palette.warning.main,
            },
        },
        '&.error': {
            backgroundColor: '#FFF',
            '& .MuiAlert-filledError': {
                backgroundColor: '#FFF',
                color: palette.error.main,
            },
        },
    },
});
