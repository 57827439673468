export class CreateWebArticleCategory {
    /**
     * Name of the category depending on the language.
     */
    title: Record<string, string>;

    /**
     * The parent category's ID.
     */
    parentCategoryId: string;

    /**
     * Order of the category
     */
    order?: number;

    /**
     * Subtitle of the category
     */
    subtitle?: Record<string, string>;

    /**
     * List of all the ids of the articles that are in this category
     */
    articles?: string[];

    /**
     * Image urls of the category
     */
    images?: {
        data: string;
        contentType: string;
        contentLength: number;
    }[];

    constructor(props: CreateWebArticleCategory) {
        Object.assign(this, props);
    }
}
