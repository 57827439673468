import { Ability } from '@casl/ability';

export enum PaymentActions {
    Read = 'read',
    Create = 'create',
    Refund = 'refund',
    Cancel = 'cancel',
}

export enum PaymentSubjects {
    Payment = 'payment',
    StripeAccount = 'stripe-account',
}

export type PaymentAbility = Ability<[PaymentActions, PaymentSubjects]>;
