export class PromotionItem {
    /**
     * Article id
     */
    id?: string;

    /**
     * This is the quantity of the item with a promo applied
     */
    discountedQuantity?: number;

    constructor(props: PromotionItem) {
        Object.assign(this, props);
    }
}
