export class CreateWeekdayTimePeriods {
    /**
     * Example property of the entity
     */
    name?: string;

    constructor(props: CreateWeekdayTimePeriods) {
        Object.assign(this, props);
    }
}
