import { FSAction } from '@aphilia/shared/react';
import * as Actions from './constant';
import { DeliveryParameters } from '@aphilia/deliveries/data';
import { Address } from '@aphilia/shared/utils';

///////////////////////////////////
///////// Delivery Parameters /////
///////////////////////////////////
export function updateDeliveryParameters(parameters: DeliveryParameters): FSAction<keyof typeof Actions, DeliveryParameters> {
    return {
        type: Actions.UPDATE_DELIVERY_PARAMETERS,
        payload: parameters,
    };
}

export function updateLoadingDeliveryParameters(loading: boolean): FSAction<keyof typeof Actions, boolean> {
    return {
        type: Actions.UPDATE_LOADING_DELIVERY_PARAMETERS,
        payload: loading,
    };
}

export function updateDeliveryParametersAddress(address: Address): FSAction<keyof typeof Actions, Address> {
    return {
        type: Actions.UPDATE_LOADING_DELIVERY_PARAMETERS,
        payload: address,
    };
}

///////////////////////////////////
/////////// Initializing //////////
///////////////////////////////////
export function updateIsInitializing(isInit: boolean): FSAction<keyof typeof Actions, boolean> {
    return {
        type: Actions.UPDATE_IS_INITIALIZING,
        payload: isInit,
    };
}
