import { CreateDayPeriod } from './create-day-period';
import * as faker from 'faker';

export const fakeCreateDayPeriodData = (): CreateDayPeriod => ({
    name: faker.name.findName(),
});

export const fakeCreateDayPeriod = (): CreateDayPeriod => new CreateDayPeriod(fakeCreateDayPeriodData());

export const fakeCreateDayPeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateDayPeriod[] => {
    const dayPeriod: CreateDayPeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        dayPeriod.push(fakeCreateDayPeriod());
    }

    return dayPeriod;
};
