import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
    size?: number;
    className?: string;
    color?: 'primary' | 'secondary' | 'white';
}

export const ApSpinner = ({ size, className, color = 'primary' }: IProps) => (
    <div
        className={`spinner ${className}`}
        style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: color === 'white' ? '#fff' : undefined,
        }}
    >
        <CircularProgress color={((color === 'primary' || color === 'secondary') && color) || 'inherit'} size={size} />
    </div>
);
