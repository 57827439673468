import { UberQuantityConstraint } from '../quantity-constraint/quantity-constraint';
import { UberContextType } from '../../enum';

export class UberQuantityConstraintOverride {
    /**
     * Type of the context in which to override.
     */
    context_type: UberContextType;

    /**
     * Identifying string (id) for the specified context.
     */
    context_value: string;

    /**
     * Quantity constraints for the item in this context.
     */
    quantity: UberQuantityConstraint;

    constructor(props: Partial<UberQuantityConstraintOverride>) {
        Object.assign(this, props);
    }
}
