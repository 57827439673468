import { TimeSlot } from '../../time-slot';

export class CreatePeriod {
    /**
     * The site id of period
     */
    readonly siteId: string;

    /**
     * Label of the period
     */
    label: Record<string, string>;

    /**
     * Days in which the period is active
     */
    days: boolean[];

    /**
     * The timeslots that make up this period
     */
    timeSlots: TimeSlot[];

    constructor(props: CreatePeriod) {
        Object.assign(this, props);
    }
}
