type ConditionnalString = [any, string] | string;
type ConditionnalClass = [any, string] | string;

function createString(conditionnalItems: ConditionnalString[], partsJoin = ' ') {
    const parts = [];

    conditionnalItems.forEach((conditionnalItem) => {
        if (Array.isArray(conditionnalItem) && conditionnalItem.length >= 2) {
            let test: boolean;
            if (typeof conditionnalItem[0] === 'boolean') {
                test = conditionnalItem[0];
            } else {
                test = !!conditionnalItem[0];
            }
            if (test) {
                parts.push(conditionnalItem[1]);
            }
        } else if (typeof conditionnalItem === 'string') {
            parts.push(conditionnalItem);
        }
    });

    if (!parts.length) {
        return null;
    }

    return parts.join(partsJoin);
}

function createClassString(conditionnalClasses: ConditionnalString[]) {
    return createString(conditionnalClasses);
}

function createPartString(conditionnalParts: ConditionnalString[]) {
    return createString(conditionnalParts);
}

function capitalize(text: string) {
    return `${text[0].toUpperCase()}${text.substring(1)}`;
}

function stripTags(htmlText: string): string {
    const textContainer = document.createElement('div');
    textContainer.innerHTML = htmlText;
    return textContainer.innerText;
}

function replaceSpaces(text: string, separator = '-') {
    return text.replace(/\s+/g, separator);
}

export { createClassString, ConditionnalClass, ConditionnalString, createPartString, capitalize, stripTags, replaceSpaces };
