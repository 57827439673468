import { CreatePeriod } from './create-period';
import * as faker from 'faker';
import { fakeTimeSlotArray } from '../../time-slot';
import mongoose from 'mongoose';

export const fakeCreatePeriodData = (): CreatePeriod => {
    let days = Array(7).fill(false);
    days = days.map(() => faker.random.boolean());
    return {
        siteId: mongoose.Types.ObjectId().toHexString(),
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        days,
        timeSlots: fakeTimeSlotArray(),
    };
};

export const fakeCreatePeriod = (): CreatePeriod => new CreatePeriod(fakeCreatePeriodData());

export const fakeCreatePeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreatePeriod[] => {
    const period: CreatePeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        period.push(fakeCreatePeriod());
    }

    return period;
};
