export class PiclickV2StyleParameters {
    /**
     * Represents the neutral colors
     */
    neutralColor?: string;

    /**
     * Primary base color
     */
    primaryColor?: string;

    /**
     * Indicates if dark mode is enabled
     */
    darkMode?: boolean;

    constructor(props: PiclickV2StyleParameters) {
        Object.assign(this, props);
    }
}
