import React, { createContext, ReactNode, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { StripeState } from './types';
import * as Actions from './constant';
import { UpdateStripeAccount } from '@aphilia/payments/types';
import { updateIsLoadingAccount, updateStripeAccount, updateStripeBalance } from './action';
import { useOrganizationContext } from '@aphilia/shared/organizations/feature-main';
import { getBalance, getStripeAccount, getStripeAccountLink, updateStripeAccountBySiteId } from '@aphilia/payments/data-access';
import i18next from 'i18next';
import { usePaymentsSockets } from '../../hooks/payments-socket.hooks';

const StripeContext = createContext<StripeState>({
    paymentsUrl: '',
    pusherCluster: '',
    pusherClientKey: '',
    paymentEvent: undefined,
});

interface Props {
    children: ReactNode;
    initial: StripeState;
}

function StripeContextProvider({ initial, children }: Props) {
    const { siteSelected, isLoadingSelectedSite } = useOrganizationContext();
    const { setSiteId, paymentEvent } = usePaymentsSockets({ pusherClientKey: initial.pusherClientKey, pusherCluster: initial.pusherCluster, paymentsUrl: initial.paymentsUrl });
    const [stripeState, stripeDispatch] = useReducer<Reducer<StripeState, any>>((currentState: StripeState, action: { type: keyof typeof Actions; payload?: any }) => {
        switch (action.type) {
            case Actions.UPDATE_STATE:
                return Object.assign({}, currentState, action.payload);
            case Actions.UPDATE_STRIPE_ACCOUNT:
                return Object.assign({}, currentState, {
                    account: action.payload,
                });
            case Actions.UPDATE_STRIPE_BALANCE:
                return Object.assign({}, currentState, {
                    balance: action.payload,
                });
            case Actions.UPDATE_IS_LOADING_ACCOUNT:
                return Object.assign({}, currentState, {
                    isLoadingAccount: action.payload,
                });
            default:
                throw new Error();
        }
    }, initial);

    /**
     * Update the account.
     * It will handle the request to the back end and update the store accordingly
     */
    const updateAccount = useCallback(
        async (accountData: UpdateStripeAccount) => {
            try {
                const updatedAccount = await updateStripeAccountBySiteId(stripeState.paymentsUrl, siteSelected?._id, accountData);
                stripeDispatch(updateStripeAccount(updatedAccount));
                return updatedAccount;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        [siteSelected?._id]
    );

    /**
     * Fetch the account from the back end and load it into the store.
     * It will also return the account loaded
     */
    const setAccount = useCallback(async () => {
        try {
            stripeDispatch(updateIsLoadingAccount(true));
            const [loadedAccount, loadedBalance] = await Promise.all([
                getStripeAccount(stripeState.paymentsUrl, siteSelected?._id),
                getBalance(stripeState.paymentsUrl, siteSelected?._id),
            ]);
            stripeDispatch(updateStripeAccount(loadedAccount));
            stripeDispatch(updateStripeBalance(loadedBalance));
            return loadedAccount;
        } catch (e) {
            console.error(e);
            stripeDispatch(updateStripeAccount(undefined));
        } finally {
            stripeDispatch(updateIsLoadingAccount(false));
        }
    }, [stripeState.paymentsUrl, siteSelected?._id]);

    /**
     * Fetch the balance and set it in the balance value.
     */
    const setBalance = useCallback(async () => {
        try {
            const loadedBalance = await getBalance(stripeState.paymentsUrl, siteSelected?._id);
            stripeDispatch(updateStripeBalance(loadedBalance));
            return loadedBalance;
        } catch (e) {
            console.error(e);
        }
    }, [stripeState.paymentsUrl, siteSelected?._id]);

    /**
     * Return a link to access the account on boarding
     */
    const accountLink = useCallback(async () => {
        try {
            return await getStripeAccountLink(stripeState.paymentsUrl, siteSelected?._id);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }, [siteSelected?._id]);

    useEffect(() => {
        if (paymentEvent?.event) {
            setBalance();
        }
    }, [paymentEvent?.event]);

    /**
     * When the site id selected change then we need to change the account loaded
     */
    useEffect(() => {
        if (siteSelected?._id) {
            setAccount();
            setSiteId(siteSelected?._id);
        }
    }, [siteSelected?._id]);

    useEffect(() => {
        // If the selected site is loading, then we set the loading account at true
        // as the account will be loaded once the site is updated.
        // It allows to not wait for the async call of the siteSelected
        if (isLoadingSelectedSite) {
            stripeDispatch(updateIsLoadingAccount(true));
        }
    }, [isLoadingSelectedSite]);

    /**
     * Return the current balance of the connected account
     */
    const totalBalance = useMemo(() => stripeState.balance, [stripeState.balance]);

    /**
     * Return the formatted current balance
     */
    const formattedTotalBalance = useMemo(() => {
        return Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(totalBalance / 100);
    }, [totalBalance]);

    return (
        <StripeContext.Provider
            value={{
                account: stripeState.account,
                accountLink,
                setAccount,
                updateAccount,
                isLoadingAccount: stripeState.isLoadingAccount,
                totalBalance,
                formattedTotalBalance,
                balance: stripeState.balance,
                paymentsUrl: stripeState.paymentsUrl,
                setBalance,
                paymentEvent,
                pusherCluster: initial.pusherCluster,
                pusherClientKey: initial.pusherClientKey,
            }}
        >
            {children}
        </StripeContext.Provider>
    );
}

const useStripeContext = () => useContext(StripeContext);
export { useStripeContext, StripeContext, StripeContextProvider };
