export class UberQuantityConstraint {
    /**
     * Minimum quantity allowed (inclusive).
     */
    min_permitted?: number;

    /**
     * Maximum quantity allowed (inclusive).
     */
    max_permitted?: number;

    /**
     * Default quantity that will be pre-selected.
     */
    default_quantity?: number;

    /**
     * When provided, the item price will only be charged per quantity unit in excess of this amount.
     * Can either be applied to an individual item or an entire modifier group.
     * charge_above and refund_under must either both be null or both be non-null.
     *
     * Cannot be negative.
     * Cannot be greater than max_permitted.
     * Cannot be less than default_quantity.
     */
    charge_above?: number;

    /**
     * When provided, the item price will be refunded per quantity unit chosen below this amount.
     * Can either be applied to an individual item or an entire modifier group.
     * charge_above and refund_under must either both be null or both be non-null.
     *
     * Cannot be negative.
     * Cannot be less than max_permitted.
     * Cannot be greater than default_quantity.
     */
    refund_under?: number;

    constructor(props: Partial<UberQuantityConstraint>) {
        Object.assign(this, props);
    }
}
