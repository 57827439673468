import { Theme as MuiTheme } from '@material-ui/core/styles';
import { Color, lighten } from '@material-ui/core';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import { designTokens } from '@aphilia/shared/ui/styles';
import { Typography } from '@material-ui/core/styles/createTypography';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type overridesNameToClassKey = {
    [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
    // tslint:disable-next-line: no-empty-interface
    export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

import 'typeface-roboto/index.css';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const palette: Partial<Palette> = {
    background: {
        paper: '#fff',
        default: designTokens.colors.greyA200,
    },
    common: {
        black: designTokens.colors.generalBlack,
        white: designTokens.colors.generalWhite,
    },
    grey: {
        50: designTokens.colors.grey050,
        100: designTokens.colors.grey100,
        200: designTokens.colors.greyLight,
        300: designTokens.colors.grey300,
        400: designTokens.colors.grey400,
        500: designTokens.colors.secondaryDefault,
        600: designTokens.colors.grey600,
        700: designTokens.colors.secondaryDark,
        800: designTokens.colors.grey800,
        900: designTokens.colors.grey900,
        A100: designTokens.colors.greyA100,
        A200: designTokens.colors.greyA200,
        A400: '#BFBFBF',
        A700: '#636363',
    } as Color,
    primary: {
        light: designTokens.colors.primaryLight,
        main: designTokens.colors.primaryDefault,
        dark: designTokens.colors.primaryDark,
    } as PaletteColor,
    secondary: {
        light: designTokens.colors.greyLight,
        main: designTokens.colors.secondaryDefault,
        dark: designTokens.colors.secondaryDark,
    } as PaletteColor,
    info: {
        main: '#3165EC',
    } as PaletteColor,
    error: {
        light: designTokens.colors.errorLight,
        main: designTokens.colors.errorDefault,
        dark: designTokens.colors.errorDark,
    } as PaletteColor,
    warning: {
        light: designTokens.colors.warningLight,
        main: designTokens.colors.warningDefault,
        dark: designTokens.colors.warningDark,
        contrastText: '#FFF',
    } as PaletteColor,
    success: {
        light: designTokens.colors.successLight,
        main: designTokens.colors.successDefault,
        dark: designTokens.colors.successDark,
    } as PaletteColor,
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
};

const pxToRem = (px: number) => `${px / 16}rem`;

export const useRem = (typography: CSSProperties) => {
    if (!typography) return typography;
    const remFontSize = (typography['font-size'] && pxToRem(typography['font-size'] as number)) || undefined;
    const remLineHeight = (typography.lineHeight && pxToRem(typography.lineHeight as number)) || undefined;
    return Object.assign({}, typography, {
        'font-size': remFontSize,
        lineHeight: remLineHeight,
    });
};

export const ApTheme: Partial<MuiTheme> = {
    palette: palette as Palette,
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        pxToRem,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: useRem(designTokens.typeStyles.headlinesH1Black),
        h2: useRem(designTokens.typeStyles.headlinesH2Black),
        h3: useRem(designTokens.typeStyles.headlinesH3Black),
        h4: useRem(designTokens.typeStyles.headlinesH4Black),
        h5: useRem(designTokens.typeStyles.headlinesH5Black),
        h6: Object.assign(useRem(designTokens.typeStyles.headlinesH6Black), {
            lineHeight: '1.45rem',
        }),
        subtitle1: useRem(designTokens.typeStyles.subtitle1LeftAlignBlack),
        subtitle2: useRem(designTokens.typeStyles.subtitle2LeftAlignBlack),
        body1: useRem(designTokens.typeStyles.body1LeftAlignBlack),
        body2: useRem(designTokens.typeStyles.body1LeftAlignGrey),
        caption: Object.assign(
            {
                display: 'block',
            },
            useRem(designTokens.typeStyles.captionBlack)
        ),
        button: Object.assign(
            {
                textTransform: 'none',
                paddingTop: 8,
                paddingBottom: 8,
            },
            useRem(designTokens.typeStyles.buttonsLeftAlignBlack)
        ),
        overline: useRem(designTokens.typeStyles.subtitleLeftAlignGrey),
    } as Typography,
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiCheckbox: {
            root: {
                '&:hover, &.MuiCheckbox-colorPrimary.Mui-checked:hover': {
                    backgroundColor: 'rgba(0,0,0,0)',
                },
            },
        },
        MuiInputLabel: {
            shrink: { color: palette.primary.main },
        },
        MuiFilledInput: {
            root: {
                borderRadius: '3.14px',
                backgroundColor: 'transparent',
                border: `1px solid ${palette.grey[400]}`,
                '&.MuiFilledInput-underline:before, &.MuiFilledInput-underline:after': {
                    content: 'none',
                },
                '&.Mui-error': {
                    borderColor: palette.error.main,
                },
                '&.Mui-focused:not(.Mui-error)': {
                    borderColor: palette.primary.main,
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiOutlinedInput: {
            root: { color: palette.grey[800] },
        },
        MuiInputAdornment: {
            root: {
                '&.hidden': {
                    visibility: 'hidden',
                },
            },
            positionEnd: {
                position: 'absolute',
                right: 11,
                '& .MuiIconButton-root': {
                    fontSize: pxToRem(14),
                    width: 14,
                    height: 14,
                    boxSizing: 'content-box',
                    padding: 6,
                    '& .MuiSvgIcon-root': {
                        width: 14,
                        height: 14,
                    },
                },
            },
        },
        MuiListItem: {
            root: {
                fontSize: pxToRem(16),
                width: 'auto',
                borderRadius: 0,
                padding: '10px 16px',
                '&:hover': {
                    background: lighten(palette.primary.main, 0.9),
                },
                '& .MuiTypography-root': {
                    color: palette.primary.main,
                },
                '& i[class*="ap-"]': {
                    color: palette.common.black,
                    fontSize: pxToRem(24),
                },
                '& svg': {
                    marginRight: '8px',
                    verticalAlign: 'middle',
                    color: 'inherit',
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: '40px',
                color: palette.common.black,
            },
        },
        MuiListItemText: {
            root: {
                flex: 'inherit',
                marginTop: 0,
                marginBottom: 0,
            },
        },
        MuiDrawer: {
            paper: {
                width: 630,
                padding: '40px 12px 40px 32px',
                boxSizing: 'border-box',
            },
        },
        MuiFormControl: {
            root: { width: '100%' },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: '0!important',
                marginRight: '0!important',
            },
        },
        MuiPaper: {
            root: {
                '& .MuiPickersBasePicker-container': {
                    width: '240px',
                    padding: '8px',
                    boxSizing: 'border-box',
                },
                '& .MuiPickersBasePicker-pickerView': {
                    minWidth: '224px',
                    minHeight: 'auto',
                },
            },
        },
        MuiPickersDay: {
            day: {
                width: '32px',
                height: '32px',
                margin: 0,
                borderRadius: '3.1px',
                '& p': {
                    fontSize: pxToRem(12),
                    lineHeight: '1.33',
                    letterSpacing: '0.48px',
                },
            },
        },
        MuiPickersMonthSelection: {
            container: {
                marginTop: '8px',
                width: '224px',
            },
        },
        MuiPickersMonth: {
            root: {
                width: '45px',
                height: '32px',
                flex: '1 0 20%',
                flexGrow: 'inherit',
                borderRadius: '3.1px',
            },
            monthSelected: {
                color: palette.common.white,
                background: palette.primary.main,
                fontSize: pxToRem(12),
                lineHeight: '16px',
            },
        },
        MuiPickersYear: {
            root: {
                height: '32px',
                color: palette.secondary.main,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                margin: '0 0 8px 0!important',
                padding: '0 32px',
                '& button': {
                    padding: '7px',
                    borderRadius: '3.1px',
                },
                '& *': { color: palette.secondary.main },
            },
            daysHeader: {
                marginBottom: '8px',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                height: '20px',
                minHeight: '20px',
                padding: 0,
                backgroundColor: palette.common.white,
                '& .MuiPickersToolbarText-toolbarTxt': {
                    color: palette.secondary.main,
                },
            },
        },
        MuiPickersSlideTransition: {
            transitionContainer: {
                margin: '0!important',
                '&.MuiPickersCalendar-transitionContainer': {
                    minHeight: 'fit-content',
                    display: 'flex',
                },
                '& > *': {
                    position: 'relative',
                },
            },
        },
    },
};
