export class QueryWebArticleCategory {
    /**
     * Site id of the web categories they belong to
     */
    siteId: string;

    constructor(props: QueryWebArticleCategory) {
        Object.assign(this, props);
    }
}
