import { CreateOrderDelivery, PlatformType, PublicDeliveryParameters, OrderType, getPlatformType } from '@aphilia/deliveries/data';
import axios from 'axios';
import moment from 'moment';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';

const blur = 2;

const fusePeriods = (weekday: WeekdayTimePeriods) => {
    const finalPeriods = [...weekday.timePeriods];

    for (let i = finalPeriods.length - 1; i > 0; i--) {
        const previousPeriod = finalPeriods[i - 1];
        const currentPeriod = finalPeriods[i];

        const momentEndTime = moment(previousPeriod.endTime, 'HH:mm');
        const momentStartTime = moment(currentPeriod.startTime, 'HH:mm');

        if (momentStartTime.diff(momentEndTime, 'minutes') <= blur) {
            previousPeriod.endTime = currentPeriod.endTime;
            finalPeriods.splice(i, 1);
        }
    }
    weekday.timePeriods = finalPeriods;
};

/**
 * Return all the sites parameters by brandId
 *
 * @param brandId
 * @param type
 */
export const getAllSitesByBrandId = async (deliveriesService: string, brandId: string, type?: PlatformType): Promise<PublicDeliveryParameters[]> => {
    try {
        const response = await axios.get(`${deliveriesService}/parameters/public/${brandId}${type ? `?type=${type}` : ''}`);

        if (type) {
            response.data.forEach((currentSite: PublicDeliveryParameters) => {
                currentSite.orderingPeriods.forEach(fusePeriods);
                currentSite.timePeriods.forEach(fusePeriods);
            });
        }

        return response.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return on site parameters by its siteId
 *
 * @param siteId
 * @param type
 */
export const getPublicDeliveryParametersBySiteId = async (deliveriesService: string, siteId: string, type?: OrderType): Promise<PublicDeliveryParameters> => {
    try {
        return (await axios.get(`${deliveriesService}/parameters/public/site/${siteId}?type=${getPlatformType(type)}`)).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return the information if a delivery can happen for a site
 *
 * @param siteId
 * @param job
 */
export const validateDelivery = async (deliveriesService: string, siteId: string, job: CreateOrderDelivery): Promise<boolean> => {
    try {
        return (await axios.post(`${deliveriesService}/orders/delivery/validate/${siteId}`, job)).data;
    } catch (e) {
        console.error(e);
        return false;
    }
};
