import { WebArticleUnavailabilityPeriod } from '../../web-article';

export class CreateWebArticleAdditionalInformation {
    /**
     * The article id the information is related to
     */
    articleId: string;

    /**
     * The site id of the article
     */
    siteId: string;

    /**
     * Is available for at table
     */
    atTableAvailability?: boolean;

    /**
     * Is Available for the click and collect
     */
    clickAndCollectAvailability?: boolean;

    /**
     * Is available for the delivery
     */
    deliveryAvailability?: boolean;

    /**
     * Is a favorite article
     */
    isFavorite?: boolean;

    /**
     * Indicates if the article is of type success when it's not available for sale
     */
    isSuccess?: boolean;

    /**
     * Indicates if the article is available for sale
     */
    isAvailableForSale?: boolean;

    /**
     * If an article is unavailable for sell, it indicates the period of time it is unavailable for.
     */
    periodOfUnavailability?: WebArticleUnavailabilityPeriod;

    /**
     * Indicates if the article is suggested or not
     */
    suggestion?: boolean;

    constructor(props: CreateWebArticleAdditionalInformation) {
        Object.assign(this, props);
    }
}
