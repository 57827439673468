export enum AllergenType {
    Dairy = 'DAIRY',
    Eggs = 'EGGS',
    Fish = 'FISH',
    Shellfish = 'SHELLFISH',
    Treenuts = 'TREENUTS',
    Peanuts = 'PEANUTS',
    Gluten = 'GLUTEN',
    Soy = 'SOY',
    Other = 'OTHER',
}

export class Item {
    /**
     * Id of the item in the database if needed.
     */
    _id: string;

    /**
     * Id in the pos system => code_ventilation
     */
    posNumber: number;

    /**
     * Display title of the item.
     */
    title: string;

    /**
     * Price of the article. This price is the unit price for one article only.
     * Therefore the total price of the article is the unitPrice * quantity
     */
    unitPrice: number;

    /**
     * Special instructions made by the consumer if needed.
     */
    specialInstructions?: string;

    /**
     * Quantity asked for this item
     */
    quantity: number;

    /**
     * All the modifiers that are applied to this item
     */
    selectedItems?: Item[];

    /**
     * List of all the allergen that should be taken in account by the user.
     */
    allergen?: AllergenType[];

    /**
     * Instructions for an allergen that is OTHER type.
     */
    allergenInstructions?: string;

    /**
     * List of all the diets of the article
     */
    diets?: string[];

    constructor(props: Item) {
        Object.assign(this, props);

        this.selectedItems = this.selectedItems?.map((selectedItem) => new Item(selectedItem));
    }
}
