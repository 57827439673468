import * as React from 'react';

function SvgArrowDownward(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="arrow_downward_svg__a" d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z" />
            </defs>
            <use fill="currentColor" xlinkHref="#arrow_downward_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgArrowDownward;
