import React from 'react';
import { Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApTabs.style';

interface Props {
    label?: string;
    icon?: React.ReactElement;
    disabled?: boolean;
}

const useStyles = makeStyles(style);

export const ApTab = (props: Props) => {
    const classes = useStyles();

    const getClassName = () => {
        if (!props.icon) return classes.label;
        if (!props.label) return classes.icon;
        return classes.iconLabel;
    };

    return <Tab className={getClassName()} {...props} />;
};
