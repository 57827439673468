import React from 'react';

import { ApCheckbox } from '@aphilia/shared-ui-core-react';
import { ConditionnalString, createClassString } from '@aphilia/shared/utils';
import { CHECKBOX_CELL_RATIO, ROW_SLOT_PREFIX } from '../../config';
import { ApTableCell } from './ApTableCell';
import { ApCellData, ApCellPosition, ApColumnData } from '../../types';

interface ApTableRowProps {
    rowId: string;
    rowIndex: number;
    rows: Map<string, ApCellData>;

    contentColumns?: ApColumnData[];
    hoveredRow?: string;
    isSelectable?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    hasCheck?: boolean;

    onCheck?: (key: string) => (evt) => void;
    onRow?: (key, value) => (evt) => void;
    onHoverRow?: (key: string, disabledRow) => () => void;
    cellsAreEditable?: boolean;
    onCellDoubleClick?: (rowId: string, column: string) => void;
    onCellEdition?: (rowId: string, key: string, value: any, valueType?: string) => void;
    onSelectClose?: () => void;
    editableCell?: ApCellPosition;
    selectedRows?: string[];
    dndProps?: any;
}

export function ApTableRow({
    rowId,
    rows,
    contentColumns,
    hoveredRow,
    isSelectable,
    isSelected,
    isDisabled,
    rowIndex,
    hasCheck,
    onCheck,
    onRow,
    onHoverRow,
    cellsAreEditable,
    onCellDoubleClick,
    onCellEdition,
    onSelectClose,
    editableCell,
    dndProps = null,
}: ApTableRowProps) {
    const rowClassesAr: ConditionnalString[] = [
        'ap-table-row',
        [hoveredRow === rowId, 'ap-hovered-row'],
        [isSelected, 'ap-selected-row'],
        [isDisabled, 'ap-disabled-row'],
        [rowIndex % 2 === 0, 'ap-even-row'],
        [editableCell?.row === rowId, 'ap-editable-row'],
    ];

    const rowStyles = {
        gridRow: `${ROW_SLOT_PREFIX}${rowId}`,
    };

    let isDragging = false;
    if (dndProps) {
        isDragging = dndProps.isDragging;
    }

    const rowClasses = createClassString(rowClassesAr);

    // TODO : prop pour message ligne sélectionnées

    return (
        <div
            id={dndProps ? dndProps['data-rbd-draggable-id'] : null}
            {...dndProps}
            key={`row-${rowIndex}`}
            className={rowClasses}
            style={rowStyles}
            onPointerEnter={onHoverRow(rowId, isDisabled)}
            onPointerLeave={onHoverRow(null, isDisabled)}
            onPointerUp={!isSelectable || isDisabled ? null : onRow(rowId, isSelected)}
        >
            {isSelectable && hasCheck && (
                <ApTableCell
                    rowId={rowId}
                    className="ap-checkbox-cell"
                    cellRatio={CHECKBOX_CELL_RATIO}
                    component={<ApCheckbox className="no-selection" checked={!!isSelected} disabled={isDisabled} size="medium" onCheck={onCheck(rowId)} />}
                    cellsAreEditable={false}
                />
            )}
            {contentColumns?.map((column, columnIndex) => {
                const cellData = rows.get(rowId)[column.dataKey];

                const cellEditionProps = {};
                if (!isDragging) {
                    cellEditionProps['onCellDoubleClick'] = onCellDoubleClick;
                    cellEditionProps['editableCell'] = editableCell;
                    cellEditionProps['onCellEdition'] = onCellEdition;
                    cellEditionProps['onSelectClose'] = onSelectClose;
                }

                return (
                    <ApTableCell
                        {...cellEditionProps}
                        rowId={rowId}
                        key={`body-cell-${columnIndex}`}
                        column={column}
                        cellRatio={column.cellRatio}
                        cellData={cellData}
                        cellType={column.cellType}
                        cellsAreEditable={cellsAreEditable}
                    />
                );
            })}
        </div>
    );
}
