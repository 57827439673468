export class Stuart {
    /**
     * Indicates if stuart is enabled
     */
    enabled: boolean;

    /**
     * The client id to connect to stuart
     */
    clientId: string;

    /**
     * The client secret to access stuart
     */
    clientSecret: string;

    /**
     * The token to call stuart
     */
    token?: string;

    /**
     * The token expiration (timestamp unix)
     */
    tokenExpiration?: number;

    constructor(props: Stuart) {
        Object.assign(this, props);
    }
}
