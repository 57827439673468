import React, { useRef } from 'react';
import { isEmpty } from 'lodash';

import { createClassString } from '@aphilia/shared/utils';
import { useCellActions, useContent, useLinkActions } from './hooks';
import { ApCellData, ApCellPosition, ApCellType, ApColumnData } from '../../types';
import { getCellWidth, getClassFromCellType } from '../../utils';
import { BASE_CELL_WIDTH, noEditionCellTypes } from '../../config';

interface ApTableCellProps extends React.ComponentPropsWithoutRef<'td'> {
    // Required
    rowId: string;

    // Optional
    disabled?: boolean;
    cellData?: ApCellData;
    column?: ApColumnData;
    cellRatio?: number;
    cellType?: ApCellType;
    component?: React.ReactNode;
    editableCell?: ApCellPosition;
    cellsAreEditable?: boolean;

    onCellDoubleClick?: (row: string, column: string) => void;
    onCellEdition?: (rowId: string, key: string, value: any, valueType?: string) => void;
    onSelectClose?: () => void;
}

export function ApTableCell({
    rowId,
    cellData,
    column,
    cellType,
    component,
    cellRatio,
    className,
    editableCell,
    onCellDoubleClick,
    onCellEdition,
    onSelectClose,
    cellsAreEditable,
}: ApTableCellProps) {
    const { isEditable, handleCellDoubleClick } = useCellActions(rowId, column, editableCell, onCellDoubleClick);
    const { renderContent } = useContent(rowId, cellData, column, component, isEditable, onCellEdition, onSelectClose);
    const { linkProps } = useLinkActions(rowId, column);

    const hasActions = !isEmpty(column?.actions);

    const cellContentClasses = createClassString(['ap-table-cell-content', 'no-selection', [!!hasActions, 'ap-actions'], [column?.isTruncatable, 'truncate-content']]);
    const cellClassType = getClassFromCellType(cellType);
    const cellClasses = createClassString(['ap-table-cell', [cellClassType, cellClassType], [className, className], [isEditable, 'ap-editable-cell']]);

    const cellStyles = {
        width: getCellWidth(cellRatio, BASE_CELL_WIDTH),
    };

    const cellRef = useRef(null);

    const cellProps = {};
    if (noEditionCellTypes.includes(column?.valueType)) {
        cellProps['tabIndex'] = -1;

        if (isEditable) {
            cellRef?.current?.focus();
        }
    }

    return (
        <div
            {...cellProps}
            ref={cellRef}
            className={cellClasses}
            style={cellStyles}
            onKeyUp={null}
            onDoubleClick={cellsAreEditable ? handleCellDoubleClick : null}
            data-row-id={rowId}
            data-column-key={column?.dataKey}
            data-column-valuetype={column?.valueType}
        >
            {!!hasActions && !isEditable ? (
                <span {...linkProps} className={cellContentClasses}>
                    {renderContent(cellsAreEditable)}
                </span>
            ) : (
                renderContent(cellsAreEditable)
            )}
        </div>
    );
}
