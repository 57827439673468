import { UpdateWebArticleAdditionalInformation } from './update-web-article-additional-information';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleUnavailabilityPeriod } from '@aphilia/deliveries/data';

export const fakeUpdateWebArticleAdditionalInformationData = (): UpdateWebArticleAdditionalInformation => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    articleId: mongoose.Types.ObjectId().toHexString(),
    atTableAvailability: faker.random.boolean(),
    clickAndCollectAvailability: faker.random.boolean(),
    deliveryAvailability: faker.random.boolean(),
    isFavorite: faker.random.boolean(),
    isAvailableForSale: faker.random.boolean(),
    periodOfUnavailability: faker.random.objectElement<WebArticleUnavailabilityPeriod>(WebArticleUnavailabilityPeriod),
    isSuccess: faker.random.boolean(),
    siteId: mongoose.Types.ObjectId().toHexString(),
    suggestion: faker.random.boolean(),
});

export const fakeUpdateWebArticleAdditionalInformation = (): UpdateWebArticleAdditionalInformation =>
    new UpdateWebArticleAdditionalInformation(fakeUpdateWebArticleAdditionalInformationData());

export const fakeUpdateWebArticleAdditionalInformationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticleAdditionalInformation[] => {
    const webArticleAdditionalInformation: UpdateWebArticleAdditionalInformation[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleAdditionalInformation.push(fakeUpdateWebArticleAdditionalInformation());
    }

    return webArticleAdditionalInformation;
};
