import * as faker from 'faker';
import { PromotionItem } from './promotion-item';

export const fakePromotionItemData = (): PromotionItem => ({
    id: faker.random.uuid(),
    discountedQuantity: faker.random.number(10),
});

export const fakePromotionItem = (): PromotionItem => new PromotionItem(fakePromotionItemData());

export const fakePromotionItemArray = (nbMax: number = faker.random.number({ min: 1, max: 10 })): PromotionItem[] => {
    return new Array(nbMax).fill(fakePromotionItem());
};
