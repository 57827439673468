import React from 'react';
import { makeStyles } from '@material-ui/core';
import style from './SelectedCounter.style';

interface Props {
    amount: number;
    text: string;
}

const useStyles = makeStyles(style);

export const ApSelectedCounter = ({ amount, text }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className="selected-counter">{amount}</span>
            <span className="text">{text}</span>
        </div>
    );
};

export default ApSelectedCounter;
