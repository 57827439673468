import { CreateWebArticleDescriptiveSheet } from './create-web-article-descriptive-sheet';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleType } from '../../web-article';

export const fakeCreateWebArticleDescriptiveSheetData = (): CreateWebArticleDescriptiveSheet => ({
    brandId: mongoose.Types.ObjectId().toHexString(),
    genericArticleId: mongoose.Types.ObjectId().toHexString(),
    type: faker.random.objectElement<WebArticleType>(WebArticleType),
    menuNumber: faker.random.number(),
    siteId: faker.random.number().toString(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    shortDescription: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    longDescription: {
        fr: faker.lorem.paragraph(),
        en: faker.lorem.paragraph(),
    },
    images: [
        {
            data: faker.image.image(),
            contentType: 'jpg',
            contentLength: faker.random.number(),
        },
    ],
    calories: faker.random.number(),
    nutriScore: faker.random.arrayElement(['A', 'B', 'C', 'D', 'E']),
    allergens: [faker.commerce.productAdjective()],
    tag: faker.commerce.productAdjective(),
    specialDiets: [faker.commerce.productAdjective()],
    oldPrice: parseInt(faker.commerce.price(), 10),
    labels: [faker.commerce.productAdjective()],
});

export const fakeCreateWebArticleDescriptiveSheet = (): CreateWebArticleDescriptiveSheet => new CreateWebArticleDescriptiveSheet(fakeCreateWebArticleDescriptiveSheetData());

export const fakeCreateWebArticleDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWebArticleDescriptiveSheet[] => {
    const webArticleDescriptiveSheet: CreateWebArticleDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleDescriptiveSheet.push(fakeCreateWebArticleDescriptiveSheet());
    }

    return webArticleDescriptiveSheet;
};
