export class CreateWebArticle {
    /**
     * Id of the article in the pos system
     */
    posId: number;

    /**
     * Id of the generic article that is associated with it
     */
    genericArticleId?: number;

    /**
     * Title of the article on the web.
     */
    title: Record<string, string>;

    /**
     * Short description of the article.
     */
    shortDescription?: Record<string, string>;

    /**
     * Long description of the article
     */
    longDescription?: Record<string, string>;

    /**
     * URL of the images to be used on the web
     */
    images?: string[];

    /**
     * Price of the article
     */
    price: number;

    /**
     * The vat rate for the article
     */
    vatRate?: number;

    /**
     * List of all the modifiers' ids that are associated with the article
     */
    modifiers?: string[];

    /**
     * The minimum number of item that can be selected.
     */
    quantityMin: number;

    /**
     * The maximum number of item that can be selected.
     */
    quantityMax: number;

    /**
     * Nutri score information about the ingredient
     */
    nutriScore?: 'A' | 'B' | 'C' | 'D' | 'E';

    constructor(props: CreateWebArticle) {
        Object.assign(this, props);
    }
}
