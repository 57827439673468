import React, { ReactElement } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import style from './ApTooltip.style';

interface Props extends Partial<TooltipProps> {
    text: string;
    children?: ReactElement;
}

const useStyles = makeStyles(style);

function StyledTooltip(props: TooltipProps) {
    return <Tooltip {...props} TransitionComponent={Zoom} />;
}

export const ApTooltip = ({ text, children, className, ...props }: Props) => {
    const classes = useStyles();

    return (
        <StyledTooltip className={`ap-tooltip ${className}`} classes={classes} title={text} placement="right-start" {...props}>
            {children || <InfoIcon className={classes.popper} fontSize="small" />}
        </StyledTooltip>
    );
};
