import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApToaster.style';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { Snackbar, SnackbarOrigin, IconButton } from '@material-ui/core';
import { CheckCircle, Warning, Error, Close } from '../../icons';

const useStyles = makeStyles(style);

interface IProps {
    closeCallback: (event: MouseEvent | React.SyntheticEvent<Element, Event>, reason?: string) => void;
    variant?: Color;
    open: boolean;
    autoHideDuration?: number;
    icon?: React.ReactNode;
    message: string;
    anchorOrigin?: SnackbarOrigin;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const variantIcons = {
    success: <CheckCircle />,
    warning: <Warning />,
    error: <Error />,
};

export const ApToaster = ({ closeCallback, variant, open, autoHideDuration = 3000, icon, message, anchorOrigin = { vertical: 'bottom', horizontal: 'right' } }: IProps) => {
    const classes = useStyles();

    return (
        <Snackbar
            className={`${classes.root} ${variant}`}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={closeCallback}
            anchorOrigin={anchorOrigin}
            action={
                <IconButton onClick={closeCallback}>
                    <Close />
                </IconButton>
            }
        >
            <Alert onClose={closeCallback} severity={variant} icon={icon && variantIcons[variant]}>
                {message}
            </Alert>
        </Snackbar>
    );
};
