import { createContext } from 'react';
import { ApTableContext } from './types';

const BASE_CELL_WIDTH = 100;
const CHECKBOX_CELL_RATIO = 0.3;
const ROW_CLONE_WIDTH = '20rem';

const END_STICKY_ID = '-sticky';
const HEADER_CELL_SLOT_PREFIX = 'header-cell-';
const ROW_SLOT_PREFIX = 'row-slot-';
const CHECKBOX_CELL_KEY = 'checkbox';

const draggableRowIdPattern = /-draggable-row-([\d]+)/;

const noEditionCellTypes = ['boolean', 'select', 'button'];
const noEditionCellElements = ['ap-toggle', 'ap-select'];
const navigationKeys = ['Enter', 'Tab'];

const TableContext = createContext<ApTableContext>({});

export {
    BASE_CELL_WIDTH,
    CHECKBOX_CELL_RATIO,
    ROW_CLONE_WIDTH,
    END_STICKY_ID,
    HEADER_CELL_SLOT_PREFIX,
    ROW_SLOT_PREFIX,
    CHECKBOX_CELL_KEY,
    noEditionCellTypes,
    noEditionCellElements,
    draggableRowIdPattern,
    navigationKeys,
    TableContext,
};
