import { PlatformType, WebArticle, WebArticleType } from '../../web-article';
import { CreateItem, Item } from '../order/item';
import { DeliveryPlatform, OrderState, OrderType } from '../order/order/order';
import { DenyOrCancelOrderReason } from '../order/update-order-state/update-order-state';

/**
 * Return platform type from order type
 * @param type
 */
export function getPlatformType(type: OrderType): PlatformType {
    let platformType;
    switch (type) {
        case OrderType.ClickAndCollect:
            platformType = PlatformType.ClickAndCollect;
            break;
        case OrderType.Delivery:
            platformType = PlatformType.Delivery;
            break;
        case OrderType.Express:
            platformType = PlatformType.AtTable;
            break;
        default:
            platformType = PlatformType.ClickAndCollect;
            break;
    }
    return platformType;
}

/**
 * Check the availability of an article in the web menu.
 * If there is an error with the item, it will return the error and the item on which the error has happened
 *
 * @param item
 * @param articles
 * @param type
 * @param deliveryPlatform
 */
export function checkItemAvailability(
    item: CreateItem,
    articles: WebArticle[],
    type?: OrderType,
    deliveryPlatform?: DeliveryPlatform
): { error: DenyOrCancelOrderReason; itemId: string } {
    try {
        const article = articles.find((articleData) => articleData._id === item._id);
        const platformType = getPlatformType(type);

        if (!article || !(article.platformConfiguration && article.platformConfiguration[platformType]?.enabled)) {
            return {
                error: DenyOrCancelOrderReason.ItemIdMismatch,
                itemId: item._id,
            };
        }

        if (!article.isAvailableForSale) {
            return {
                error: DenyOrCancelOrderReason.OutOfItems,
                itemId: item._id,
            };
        }

        if (deliveryPlatform === DeliveryPlatform.PiClick) {
            if (article.platformConfiguration[platformType].price !== item.unitPrice) {
                return {
                    error: DenyOrCancelOrderReason.PriceMismatch,
                    itemId: item._id,
                };
            }
        }

        if (article.type === WebArticleType.Comment && article.platformConfiguration[platformType].price > 0) {
            return {
                error: DenyOrCancelOrderReason.CommentWithPrice,
                itemId: item._id,
            };
        }

        if (!item.selectedItems?.length) return null;

        let i = 0;
        let childError = null;
        while (i < item.selectedItems?.length && !childError) {
            childError = checkItemAvailability(item.selectedItems[i], articles, type);
            i++;
        }

        return childError;
    } catch (e) {
        console.error(e.message, 'OrdersController CheckItemAvailability');
        throw e;
    }
}

export function getTotalPrice(currentItem: Item, actualPrice: number): number {
    if (Reflect.has(currentItem, 'selectedItems')) {
        currentItem.selectedItems?.forEach((selItem) => {
            if (!!selItem.unitPrice) {
                actualPrice += selItem.unitPrice * selItem.quantity;
            }

            actualPrice = getTotalPrice(selItem, actualPrice);
        });
    }

    return actualPrice;
}

/**
 * Return true or false if the order can be modified.
 */
export function canOrderBeModified(baseState: OrderState, newState: OrderState): boolean {
    try {
        // If the order is already cancelled then it can't be updated anymore
        if (baseState === OrderState.Cancelled || baseState === OrderState.Denied) {
            return false;
        }

        // If the order has been collected then the order can't be cancelled anymore
        if ((baseState === OrderState.Collected || baseState === OrderState.ReadyForCollection) && (newState === OrderState.Cancelled || newState === OrderState.Denied)) {
            return false;
        }

        // If the order has already been accepted or cancelled then it can't be denied anymore
        return !(baseState !== OrderState.Created && baseState !== OrderState.PendingSiteConfirmation && newState === OrderState.Denied);
    } catch (e) {
        console.error(e.message, 'OrdersService CanOrderBeModified');
        throw e;
    }
}
