import { Brand, Site } from '@aphilia/shared/organizations/types';
import * as OrganizationsActions from './constants';
import { FSAction } from '@aphilia/shared/react';

///////////////////////////////////
//////// Site Information /////////
///////////////////////////////////
export function updateSites(sites: Site[]): FSAction<keyof typeof OrganizationsActions, Site[], undefined> {
    return {
        type: OrganizationsActions.UPDATE_SITES,
        payload: sites,
    };
}

export function updateLoadingSites(loading: boolean): FSAction<keyof typeof OrganizationsActions, boolean, undefined> {
    return {
        type: OrganizationsActions.UPDATE_LOADING_SITES,
        payload: loading,
    };
}

export function updateSiteSelected(site: Site): FSAction<keyof typeof OrganizationsActions, Site, undefined> {
    return {
        type: OrganizationsActions.UPDATE_SITE_SELECTED,
        payload: site,
    };
}

export function updateLoadingSiteSelected(loading: boolean): FSAction<keyof typeof OrganizationsActions, boolean, undefined> {
    return {
        type: OrganizationsActions.UPDATE_LOADING_SITE_SELECTED,
        payload: loading,
    };
}

///////////////////////////////////
//////// Brand Information /////////
///////////////////////////////////
export function updateBrands(brands: Brand[]): FSAction<keyof typeof OrganizationsActions, Brand[], undefined> {
    return {
        type: OrganizationsActions.UPDATE_BRANDS,
        payload: brands,
    };
}

export function updateLoadingBrands(loading: boolean): FSAction<keyof typeof OrganizationsActions, boolean, undefined> {
    return {
        type: OrganizationsActions.UPDATE_LOADING_BRANDS,
        payload: loading,
    };
}

export function updateBrandSelected(brand: Brand): FSAction<keyof typeof OrganizationsActions, Brand, undefined> {
    return {
        type: OrganizationsActions.UPDATE_BRAND_SELECTED,
        payload: brand,
    };
}

///////////////////////////////////
////////// Site Modal /////////////
///////////////////////////////////
export function updateIsSiteModalOpen(isOpen: boolean): FSAction<keyof typeof OrganizationsActions, boolean, undefined> {
    return {
        type: OrganizationsActions.UPDATE_OPEN_SITE_MODAL,
        payload: isOpen,
    };
}
