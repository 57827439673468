import { Theme, lighten } from '@material-ui/core/styles';

export default (theme: Theme) => ({
    root: {
        padding: '24px',
        margin: 'auto',
        '&.info': { background: lighten(theme.palette.primary.light, 0.45) },
        '&.error': { background: lighten(theme.palette.error.light, 0.45) },
        '&.success': { background: lighten(theme.palette.success.light, 0.45) },
        '&.warning': { background: lighten(theme.palette.warning.light, 0.45) },
        '& .MuiSvgIcon-root': {
            '&.info': { color: `${theme.palette.primary.main} !important` },
            '&.error': { color: `${theme.palette.error.dark} !important` },
            '&.success': { color: `${theme.palette.success.dark} !important` },
            '&.warning': { color: `${theme.palette.warning.dark} !important` },
        },
        '& svg': {
            fontSize: '2rem',
            verticalAlign: 'middle',
        },
        '& .banner-text': {
            color: theme.palette.common.black,
        },
    },
});
