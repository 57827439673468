import { valueToNumber } from '@aphilia/shared/utils';
import { ApOperatorConfig } from '../../types';

const operatorsConfig: ApOperatorConfig = {
    string: {
        contains: {
            tradId: 'stringContains',
            function: (key: string, value: string) => (current: any) => current[key].toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        },
        equal: {
            tradId: 'stringEqual',
            function: (key: string, value: string) => (current: any) => current[key].toLocaleLowerCase() === value.toLocaleLowerCase(),
        },
        startsWith: {
            tradId: 'stringStartsWith',
            function: (key: string, value: string) => (current: any) => current[key].toLocaleLowerCase().startsWith(value.toLocaleLowerCase()),
        },
        endsWith: {
            tradId: 'stringEndsWith',
            function: (key: string, value: string) => (current: any) => current[key].toLocaleLowerCase().endsWith(value.toLocaleLowerCase()),
        },
    },
    number: {
        equal: {
            tradId: 'numberEqual',
            function: (key: string, value: any) => (current: any) => {
                const dataValue = valueToNumber(current[key]);
                const filterValue = valueToNumber(value);

                return dataValue === filterValue;
            },
        },
        superior: {
            tradId: 'numberSuperior',
            function: (key: string, value: any) => (current: any) => {
                const dataValue = valueToNumber(current[key]);
                const filterValue = valueToNumber(value);

                return dataValue > filterValue;
            },
        },
        superiorOrEqual: {
            tradId: 'numberSuperiorOrEqual',
            function: (key: string, value: any) => (current: any) => {
                const dataValue = valueToNumber(current[key]);
                const filterValue = valueToNumber(value);

                return dataValue >= filterValue;
            },
        },
        inferior: {
            tradId: 'numberInferior',
            function: (key: string, value: any) => (current: any) => {
                const dataValue = valueToNumber(current[key]);
                const filterValue = valueToNumber(value);

                return dataValue < filterValue;
            },
        },
        inferiorOrEqual: {
            tradId: 'numberInferiorOrEqual',
            function: (key: string, value: any) => (current: any) => {
                const dataValue = valueToNumber(current[key]);
                const filterValue = valueToNumber(value);

                return dataValue <= filterValue;
            },
        },
    },
};

export { operatorsConfig };
