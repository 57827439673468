import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ApCard, ApCol, ApGrid, ApRow, ApToggle, ApButton, ApSelect, ApTypography, ApSpinner } from '@aphilia/shared-ui-core-react';
import { ApItemProps } from '@aphilia/shared-ui-core';
import { Uber, UpdateUber } from '@aphilia/deliveries/data';
import { customHistory } from '@aphilia/shared/react';
import { getUberSites, provisionUberStore } from '@aphilia/deliveries/data-access';
import { BlockValidation, useBlockData } from '../block-validation';

import './uber-information-card.scss';

interface Props {
    siteId: string;
    uberParameters: Uber;
    uberClientId: string;
    deliveriesUrl: string;
    onUpdateUberParameters: (updateUber: UpdateUber) => void;
}

export const UberInformationCard = ({ siteId, uberParameters, onUpdateUberParameters, uberClientId, deliveriesUrl }: Props) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const [localUberParameters, setLocalUberParameters] = useState<Uber>(uberParameters);
    const [code, setCode] = useState<string>(null);
    const [uberSites, setUberSites] = useState<ApItemProps[]>([]);
    const [uberSitesValue, setUberSitesValue] = useState<any[]>([]);
    const [tokenExpiredError, setTokenExpiredError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isUnchanged, resetInitialBlockData, getInitialBlockData } = useBlockData(localUberParameters);

    useEffect(() => {
        if (!uberParameters) return;
        resetInitialBlockData({
            enabled: uberParameters.enabled,
            locationId: uberParameters.locationId,
            posIntegrationEnabled: uberParameters.posIntegrationEnabled,
        });
    }, [uberParameters, resetInitialBlockData]);

    const getUberInformation = useCallback(
        async (codeParam?: string, uberTokenStored?: string) => {
            setTokenExpiredError(true);
            let uberInformation;
            let siteNameItem;
            const token = uberTokenStored ? uberTokenStored[siteId] : undefined;

            try {
                uberInformation = await getUberSites(deliveriesUrl, (window as any).authToken.default, window.location.href.toString(), codeParam ? codeParam : null, token);

                if (uberTokenStored) {
                    uberTokenStored[siteId] = uberInformation.token;
                    localStorage.setItem('uberToken', JSON.stringify(uberTokenStored));
                } else {
                    localStorage.setItem('uberToken', JSON.stringify({ [siteId]: uberInformation.token }));
                }

                const initialUberSitesValue = [];

                siteNameItem = uberInformation.sites.map((site, index) => {
                    if (uberParameters.locationId === site.store_id) {
                        initialUberSitesValue.push(site.store_id);
                    }
                    return {
                        label: (site as any).name,
                        value: site.store_id,
                    };
                });

                setUberSites(siteNameItem);
                setUberSitesValue(initialUberSitesValue);
                setIsLoading(false);
            } catch (e) {
                setTokenExpiredError(true);
                setIsLoading(false);
                throw e;
            }
        },
        [deliveriesUrl, siteId, uberParameters?.locationId]
    );

    useEffect(() => {
        if (!siteId) return;
        const uberTokenStored = JSON.parse(localStorage.getItem('uberToken'));
        if (uberTokenStored && uberTokenStored[siteId]) {
            setIsLoading(true);
            (async () => {
                await getUberInformation(null, uberTokenStored);
            })();
        }
    }, [siteId, getUberInformation]);

    useEffect(() => {
        if (!window.location.href.includes('authO')) return;
        const queryParams = new URLSearchParams(search);
        setCode(queryParams.get('code'));
    }, [search]);

    useEffect(() => {
        if (!code) return;
        setIsLoading(true);
        (async () => {
            await getUberInformation(code, JSON.parse(localStorage.getItem('uberToken')));
            const queryParams = new URLSearchParams(search);
            queryParams.delete('authO');
            queryParams.delete('code');
            customHistory.replace({
                search: queryParams.toString(),
            });
        })();
    }, [code, search, getUberInformation]);

    const handleOnChangeUberStore = useCallback(
        (ev) => {
            setUberSitesValue([ev.detail.value]);
            setLocalUberParameters({ ...localUberParameters, locationId: ev.detail.value, posIntegrationEnabled: true });
        },
        [localUberParameters]
    );

    const handleConfigurationCancel = useCallback(() => {
        const initialBlockData = getInitialBlockData();
        if (initialBlockData) setLocalUberParameters(initialBlockData);
    }, [getInitialBlockData]);

    const handleOnClickConnectUber = useCallback(() => {
        window.location.replace(
            `https://login.uber.com/oauth/v2/authorize?client_id=${uberClientId}&scopes=eats.pos_provisioning&response_type=code&redirect_uri=${window.location.href.toString()}?authO=false`
        );
    }, [uberClientId]);

    const handleOnConfirm = useCallback(async () => {
        if (!!localUberParameters?.locationId && localUberParameters?.locationId !== uberParameters?.locationId) {
            await provisionUberStore(
                deliveriesUrl,
                (window as any).authToken.default,
                siteId,
                localUberParameters.locationId,
                JSON.parse(localStorage.getItem('uberToken'))[siteId],
                localUberParameters.posIntegrationEnabled
            );
        }
        onUpdateUberParameters(localUberParameters);
    }, [localUberParameters, uberParameters, onUpdateUberParameters, deliveriesUrl, siteId]);

    return (
        <ApCard headerTitle={t('parameters.uber.card.title')} subtitle={t('parameters.uber.card.subtitle')}>
            <ApGrid>
                <ApRow justifyContent="center" alignItems="center">
                    <ApCol size={10} className="text-align-center">
                        {isLoading ? (
                            <ApSpinner className="m-s" color="primary" size="medium" />
                        ) : uberSites?.length ? (
                            <>
                                <ApSelect className="m-s" label="sites uber" options={uberSites} onItemSelected={handleOnChangeUberStore} value={uberSitesValue} />
                                {!!localUberParameters?.locationId && (
                                    <ApToggle
                                        className="m-s"
                                        label={t('parameters.uber.posIntegrationEnabled')}
                                        onApToggle={() => setLocalUberParameters({ ...localUberParameters, posIntegrationEnabled: !localUberParameters?.posIntegrationEnabled })}
                                        checked={localUberParameters?.posIntegrationEnabled}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <ApButton className="m-s" type="filled" color="primary" label={t('parameters.uber.button.connect')} onClick={handleOnClickConnectUber} />
                                {tokenExpiredError && (
                                    <ApTypography className="m-xs" color="error" position="center" type="small-text">
                                        {t('parameters.uber.error.tokenExpired')}
                                    </ApTypography>
                                )}
                            </>
                        )}
                    </ApCol>
                </ApRow>
                {uberSites?.length > 0 && !!localUberParameters?.locationId && (
                    <BlockValidation
                        isValid={!!localUberParameters?.locationId}
                        isUnchanged={isUnchanged}
                        cancelText={t('cancel')}
                        confirmText={t('parameters.saveText')}
                        onCancel={handleConfigurationCancel}
                        onConfirm={handleOnConfirm}
                    />
                )}
            </ApGrid>
        </ApCard>
    );
};
