import { WebArticlePlatformConfiguration } from '../../web-article-platform-configuration';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { WebArticleVatInformation } from '..';

export enum PlatformType {
    Delivery = 'delivery',
    AtTable = 'atTable',
    ClickAndCollect = 'clickAndCollect',
}

export enum WebArticleUnavailabilityPeriod {
    Service = 'SERVICE',
    Day = 'DAY',
    Unknown = 'UNKNOWN',
    None = 'None',
}

export enum WebArticleUnavailabilityType {
    SoldOut = 'SOLD_OUT',
    Success = 'SUCCESS',
}

export enum WebArticleType {
    Article = 'ARTICLE',
    ArticleMenu = 'ARTICLE_MENU',
    Menu = 'MENU',
    Comment = 'COMMENT',
}

export enum WebArticleEvent {
    UpdateWebArticle = 'UPDATE_WEB_ARTICLE',
}

export class WebArticle {
    /**
     * Id of article web information
     */
    readonly _id: string;

    /**
     * The site id of the article is related to
     */
    readonly siteId: string;

    /**
     * Id of the article in the pos system
     */
    readonly posNumber: number;

    /**
     * Id of the generic article that is associated with it
     */
    genericArticleId?: number;

    /**
     * Title of the article on the web.
     */
    title: Record<string, string>;

    /**
     * Short description of the article.
     */
    shortDescription?: Record<string, string>;

    /**
     * Long description of the article
     */
    longDescription?: Record<string, string>;

    /**
     * URL of the images to be used on the web
     */
    images?: string[];

    /**
     * All the configuration for the different platforms
     */
    platformConfiguration: Record<PlatformType, WebArticlePlatformConfiguration>;

    /**
     * The vat rate for the article
     */
    vatRate: WebArticleVatInformation[];

    /**
     * List of all the modifiers' ids that are associated with the article
     */
    modifiers?: string[];

    /**
     * Indicates if an article is available to be sell.
     */
    isAvailableForSale: boolean;

    /**
     * Indicates if an article hase the "is succes" tag when ou of stock
     */
    isSuccess?: boolean;

    /**
     * If an article is unavailable for sell, it indicates the period of time it is unavailable for.
     */
    periodOfUnavailability?: WebArticleUnavailabilityPeriod;

    /**
     * Describe the type of the article
     */
    type: WebArticleType;

    /**
     * Indicates if the web article is part of the favorite or not
     */
    isFavorite?: boolean;

    /**
     * Indicates if the article is a suggestion or not
     */
    isSuggestion?: boolean;

    /**
     * Indicates the number of calories (in kCal) of the article.
     */
    calories?: number;

    /**
     * List of all the allergens of the article
     */
    allergens?: string[];

    /**
     * List of all the diets of the article
     */
    diets?: string[];

    /**
     * Nutri score information about the ingredient
     */
    nutriScore?: 'A' | 'B' | 'C' | 'D' | 'E';

    /**
     * The order of the article
     */
    articleOrder?: number;

    /**
     * All the active periods for the web article if there is.
     * If there is non then the web article will be considered as always available
     */
    periods?: WeekdayTimePeriods[];

    /**
     * The labels of the article
     */
    labels?: string[];

    /**
     * Indicates if the article has an old price for promotion
     */
    oldPrice?: number;

    /**
     * Indicates if the article has a tag
     */
    tag?: string;

    /**
     * The number of the menu in the pos system
     */
    menuNumber?: number;

    constructor(props: WebArticle) {
        Object.assign(this, props);
    }
}
