import * as faker from 'faker';
import { BaseModel } from './base-model';
import moment from 'moment';

export const fakeBaseModelData = (): BaseModel => ({
    createdAt: moment(faker.date.past()),
    updatedAt: moment(faker.date.past()),
});

export const fakeBaseModel = (): BaseModel => new BaseModel(fakeBaseModelData());
