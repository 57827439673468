import { Moment } from 'moment';
import { BaseQueryModelPaginate } from '@aphilia/shared/utils';

export interface InitiateStripePayment {
    /**
     * The id of the payment that is related to the intent
     */
    payment_id: string;

    /**
     * The id of the customer that is going to make the payment
     */
    customer_id?: string;

    /**
     * The id of the payment method use by the intent
     */
    payment_method?: string;

    /**
     * Eht email of the customer
     */
    customer_email: string;
}

export interface PaymentMethod {
    /**
     * The last 4 digits use by the credit card
     */
    card_last_4: string;

    /**
     * The brand of the card use for the payment
     */
    card_brand: string;

    /**
     * The country of the card that serve for the payment
     */
    card_country: string;

    /**
     * Indicates if a three d secure has occurred on this payment method
     */
    three_d_secure: boolean;

    /**
     * Indicates if an exchange has happened because the currency of the card is not the same as the payment
     */
    currency_exchange: boolean;
}

export interface QueryPaginatePayment extends BaseQueryModelPaginate {
    /**
     * The site id to who the payments are related to
     */
    site_id: string;
}

export enum PaymentStatus {
    Created = 'created',
    Processing = 'processing',
    RequiresCapture = 'requires_capture',
    RequiresPaymentMethod = 'requires_payment_method',
    RequiresConfirmation = 'requires_confirmation',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Canceled = 'canceled',
    Refunded = 'refunded',
    PendingRefund = 'refund_pending',
    FailedRefund = 'refund_failed',
    Disputed = 'disputed',
    LostDispute = 'dispute_lost',
}

export interface CreatePayment {
    /**
     * The amount of the payment in cents
     */
    amount: number;

    /**
     * Indicates if the payment will be use in future usage
     */
    setup_future_usage?: boolean;

    /**
     * The site for which the payment must occur
     */
    site_id: string;

    /**
     * The webhook to receive notification for the payment
     */
    notification_webhook: string;

    /**
     * The URL where the user must be redirected to once the payment has been done
     */
    success_url: string;

    /**
     * The URL where the suer is redirected if the payment has failed
     */
    cancel_url: string;
}

export enum RefundReason {
    AskByClient = 'ask_by_client',
    Other = 'other',
    Offer = 'offer',
}

export interface RefundPayment {
    /**
     * The base reason to refund
     */
    refund_reason: RefundReason;

    /**
     * If the reason is other, then indicate this other reason
     */
    other_refund_reason?: string;

    /**
     * Indicates who made the refund of the payment
     */
    refund_by: string;
}

export enum PaymentEventName {
    Refunded = 'refunded',
    Canceled = 'canceled',
    Created = 'created',
    Initialized = 'initialized',
    Succeeded = 'succeeded',
    Failed = 'failed',
    InitiatedRefund = 'refund_initiated',
    FailedRefund = 'refund_failed',
    DisputeCreated = 'dispute_created',
    DisputeWon = 'dispute_won',
    DisputeLost = 'dispute_lost',
}

export interface PaymentEvent {
    /**
     * The name of the event
     */
    name: PaymentEventName;

    /**
     * The data in the event
     */
    data: any;

    /**
     * The date time the event occurred
     */
    occurred_at: Moment;

    /**
     * The id or the name of the system or user that triggered the refund
     */
    triggered_by: string;
}

export interface Payment {
    /**
     * The id of the payment
     */
    _id: string;

    /**
     * The charged amount of the payment
     */
    amount: number;

    /**
     * The site id for which the payment occurred
     */
    site_id: string;

    /**
     * Indicates if the payment will be use in future usage
     */
    setup_future_usage: boolean;

    /**
     * The webhook to receive notification for the payment
     */
    notification_webhook: string;

    /**
     * The URL where the user must be redirected to once the payment has been done
     */
    success_url: string;

    /**
     * The URL where the suer is redirected if the payment has failed
     */
    cancel_url: string;

    /**
     * Name of the platform use to make a payment
     */
    platform?: 'stripe';

    /**
     * The id of the payment related to this one in the partner platform
     */
    platform_id?: string;

    /**
     * The current status of the payment
     */
    status: PaymentStatus;

    /**
     * If the payment has failed it will indicate the reason
     */
    failed_reason?: string;

    /**
     * The id of the customer that made the payment
     */
    customer_id?: string;

    /**
     * The email of the customer
     */
    customer_email?: string;

    /**
     * The date time a payment has been created
     */
    createdAt: string;

    /**
     * The last date time a payment has been updated
     */
    updatedAt: string;

    /**
     * List of all the events that happened on the
     */
    events?: PaymentEvent[];

    /**
     * If the payment has been refunded, it will show the reason
     */
    refund_reason?: RefundReason;

    /**
     * If the reason of the refund is other, then it will show what is the reason
     */
    other_refund_reason?: string;

    /**
     * The id or name of the system that has made the refund
     */
    refund_by?: string;

    /**
     * If the refund has failed it will indicate the reason
     */
    refund_failed_reason?: string;

    /**
     * If the payment is canceled it will indicates who has cancelled the payment
     */
    cancel_by?: string;

    /**
     * If the payment is canceled it will indicates the reason
     */
    cancel_reason?: string;

    /**
     * The fee that we took on the payment
     */
    application_fee?: number;

    /**
     * The payment method information use for the payment
     */
    payment_method?: PaymentMethod;

    /**
     * The date time the balance transaction of the payment will be available on the account
     */
    available_at?: Moment;

    /**
     * The last date time possible to give evidence if the payment is disputed
     */
    dispute_evidence_due_by?: Moment;

    /**
     * The reason of the dispute if there is a dispute
     */
    dispute_reason?: string;

    /**
     * The id of the transfer regarding this payment
     */
    transfer_id?: string;
}

export interface CancelPayment {
    /**
     * The id or the name that canceled the payment
     */
    canceled_by: string;

    /**
     * The reason of the cancellation
     */
    cancel_reason?: string;
}

/**
 * Return the amount that the connected account perceived
 *
 * @param paymentStatus
 * @param amount
 * @param fees
 */
export const getAmountReceived = (paymentStatus: PaymentStatus, amount: number, fees: number): number => {
    switch (paymentStatus) {
        case PaymentStatus.Canceled:
        case PaymentStatus.Failed:
        case PaymentStatus.Processing:
        case PaymentStatus.RequiresCapture:
        case PaymentStatus.RequiresConfirmation:
        case PaymentStatus.RequiresPaymentMethod:
        case PaymentStatus.Created:
            return 0;
        case PaymentStatus.Succeeded:
        case PaymentStatus.Disputed:
        case PaymentStatus.FailedRefund:
        case PaymentStatus.PendingRefund:
            return amount - fees;
        case PaymentStatus.Refunded:
        case PaymentStatus.LostDispute:
            return -fees;
    }
};
