import { CreateWebArticleModifier } from './create-web-article-modifier';
import * as faker from 'faker';
import { fakeWebArticleArray } from '../../web-article';

export const fakeCreateWebArticleModifierData = (articles?: string[]): CreateWebArticleModifier => ({
    posNumber: faker.random.number(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    articles: articles || fakeWebArticleArray().map((article) => article._id),
    quantityMin: faker.random.number(5),
    quantityMax: faker.random.number(10),
});

export const fakeCreateWebArticleModifier = (articles?: string[]): CreateWebArticleModifier => new CreateWebArticleModifier(fakeCreateWebArticleModifierData(articles));

export const fakeCreateWebArticleModifierArray = (nbMax: number = faker.random.number({ min: 1, max: 20 }), articles?: string[]): CreateWebArticleModifier[] => {
    const webArticleModifier: CreateWebArticleModifier[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifier.push(fakeCreateWebArticleModifier(articles));
    }

    return webArticleModifier;
};
