import { ClickAndCollect, ClickAndCollectProvider } from './click-and-collect';
import * as faker from 'faker';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { PriceType } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeClickAndCollectData = (): ClickAndCollect => ({
    enabled: faker.random.boolean(),
    provider: faker.random.objectElement<ClickAndCollectProvider>(ClickAndCollectProvider),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    maxOrderPerInterval: faker.random.number(300),
    prepayment: 'MANDATORY',
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
    minOrderValue: faker.random.number(500),
});

export const fakeClickAndCollect = (): ClickAndCollect => new ClickAndCollect(fakeClickAndCollectData());

export const fakeClickAndCollectArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): ClickAndCollect[] => {
    const clickAndCollect: ClickAndCollect[] = [];

    for (let i = 0; i < nbMax; i++) {
        clickAndCollect.push(fakeClickAndCollect());
    }

    return clickAndCollect;
};
