import { DeliveryPlatform, Order, OrderCancelledBy, OrderState, OrderType } from './order';
import * as faker from 'faker';
import moment from 'moment';
import mongoose from 'mongoose';
import { fakeItemArray } from '../item';
import { fakeCustomerData } from '../customer';
import { fakePaymentData } from '../payment/payment/payment.data';
import { DenyOrCancelOrderReason } from '../..';
import { fakePromotionArray } from '../promotion';
import { fakeOrderDelivery } from '../order-delivery/order-delivery/order-delivery.data';

export const fakeOrderData = (siteId: string = faker.random.uuid()): Order => ({
    siteId: siteId,
    platformId: faker.random.uuid(),
    shortId: faker.random.alphaNumeric(5),
    posBillId: faker.random.number().toString(),
    _id: mongoose.Types.ObjectId().toHexString(),
    customer: fakeCustomerData(),
    currentlyUpdated: false,
    delivery: fakeOrderDelivery(),
    items: fakeItemArray(),
    payment: fakePaymentData(),
    state: faker.random.objectElement(OrderState) as OrderState,
    estimatedReadyForPickupAt: moment(faker.date.future().toISOString()),
    deliveryPlatform: faker.random.objectElement(DeliveryPlatform) as DeliveryPlatform,
    type: faker.random.objectElement(OrderType) as OrderType,
    createdAt: moment(faker.date.past().toISOString()),
    updatedAt: moment(faker.date.recent().toISOString()),
    cancelledBy: faker.random.objectElement<OrderCancelledBy>(OrderCancelledBy),
    asap: faker.random.boolean(),
    otherDenyOrCancelReason: faker.lorem.sentence(),
    denyOrCancelOrderReason: faker.random.objectElement<DenyOrCancelOrderReason>(DenyOrCancelOrderReason),
    promotions: fakePromotionArray(),
    sentToPos: faker.random.boolean(),
});

export const fakeOrder = (siteId: string = faker.random.uuid()) => new Order(fakeOrderData(siteId));

export const fakeOrders = (max: number = faker.random.number({ min: 1, max: 10 }), siteId: string = faker.random.uuid()) => {
    const fakerOrders: Order[] = [];
    for (let i = 0; i < max; i++) {
        fakerOrders.push(fakeOrder(siteId));
    }
    return fakerOrders;
};
