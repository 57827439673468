import { DeliveryParameters, PlatformType, PublicDeliveryParameters, UpdateDeliveryParameters } from '@aphilia/deliveries/data';
import axios from 'axios';

/**
 * Return the delivery parameters of a site by the site id
 *
 * @param deliveriesServiceUrl
 * @param siteId
 */
export const getDeliveryParametersBySiteId = async (deliveriesServiceUrl: string, siteId: string): Promise<DeliveryParameters> => {
    try {
        return (
            await axios.get<DeliveryParameters>(`${deliveriesServiceUrl}/parameters/${siteId}`, {
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Returns the public delivery parameters of a site by its site id.
 *
 * @param deliveriesServiceUrl
 * @param siteId
 * @param type
 */
export const getPublicDeliveryParameters = async (deliveriesServiceUrl: string, siteId: string, type: PlatformType): Promise<PublicDeliveryParameters> => {
    try {
        return (await axios.get<PublicDeliveryParameters>(`${deliveriesServiceUrl}/parameters/public/site/${siteId}?type=${type}`)).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Update the delivery parameters of a site by it's id
 *
 * @param deliveriesServiceUrl
 * @param siteId
 * @param updateDeliveryParameters
 */
export const updateDeliveryParametersBySiteId = async (
    deliveriesServiceUrl: string,
    siteId: string,
    updateDeliveryParameters: UpdateDeliveryParameters
): Promise<DeliveryParameters> => {
    try {
        return (
            await axios.put<DeliveryParameters>(`${deliveriesServiceUrl}/parameters/${siteId}`, updateDeliveryParameters, {
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return on site parameters by its siteId
 *
 * @param deliveriesServiceUrl
 * @param qrCodeId
 */
export const getPublicDeliveryParametersByQrCodeId = async (deliveriesServiceUrl: string, qrCodeId: string): Promise<{ brandId: string; siteId: string; tableNumber: number }> => {
    try {
        return (await axios.get(`${deliveriesServiceUrl}/parameters/token/${qrCodeId}`)).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
