import axios from 'axios';
import { Application } from '@aphilia/shared/organizations/types';

/**
 * Get an application by its id
 */
export const getApplicationById = async (organizationUrl: string, applicationId: string): Promise<Application> => {
    try {
        return (await axios.get<Application>(`${organizationUrl}/applications/${applicationId}`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } }))
            .data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
