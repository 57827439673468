export class QueryWebArticle {
    /**
     * Site id in which the web articles are included
     */
    siteId: string;

    /**
     * Free string field on which the article will be searched
     */
    search?: string;

    constructor(props: QueryWebArticle) {
        Object.assign(this, props);
    }
}
