import * as faker from 'faker';
import { Customer } from './customer';

export const fakeCustomerData = (): Customer => ({
    id: faker.random.uuid(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    phoneNumber: faker.phone.phoneNumber(),
    email: faker.internet.email(),
    address: faker.address.streetAddress(true),
    addressComment: faker.random.words(),
    jobId: faker.random.number(),
    trackingUrl: faker.internet.url(),
    token: faker.random.alphaNumeric(),
    orderedConsumables: {
        cutlery: faker.random.number(10),
        example1: faker.random.number(20),
        example2: faker.random.number(5),
        example3: faker.random.number(30),
    },
});

export const fakeCustomer = (): Customer => new Customer(fakeCustomerData());
