import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import style from '../ApTable.style';
import { ApSpinner } from '../../ApSpinner';
import { ApTypography } from '@aphilia/shared-ui-core-react';

interface Props {
    title: string;
    subtitle?: string;
    actions?: ReactNode;
    loading: boolean;
}

const useStyles = makeStyles(style);

export const NoRows = ({ title, subtitle, actions, loading }: Props) => {
    const classes = useStyles();
    return (
        <div className={`empty-table ${classes.emptyTable}`}>
            {loading && <ApSpinner />}
            {!loading && (
                <>
                    <ApTypography type="h3" position="center" weight="normal">
                        {title}
                    </ApTypography>
                    {subtitle && (
                        <ApTypography position="center" type="h4" weight="normal">
                            {subtitle}
                        </ApTypography>
                    )}
                    {actions && <div className="actions">{actions}</div>}
                </>
            )}
        </div>
    );
};
