import { DenyOrCancelOrderReason, UpdateOrderState } from './update-order-state';
import * as faker from 'faker';
import { OrderCancelledBy, OrderState } from '../order/order';
import mongoose from 'mongoose';

export const fakeUpdateOrderStateData = (): UpdateOrderState => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    state: faker.random.objectElement<OrderState>(OrderState),
    denyOrCancelOrderReason: faker.random.objectElement<DenyOrCancelOrderReason>(DenyOrCancelOrderReason),
    otherDenyOrCancelReason: faker.lorem.sentence(),
    cancelledBy: faker.random.objectElement<OrderCancelledBy>(OrderCancelledBy),
});

export const fakeUpdateOrderState = (): UpdateOrderState => new UpdateOrderState(fakeUpdateOrderStateData());
