import { Customer } from '../customer';
import moment, { Moment } from 'moment';
import { Payment } from '../payment/payment/payment';
import { Item } from '../item/item/item';
import { DenyOrCancelOrderReason } from '../..';
import { DenyOrCancelOrderReasonStuart } from '../update-order-state/update-order-state';
import { Promotion } from '../promotion';
import { OrderDelivery } from '../order-delivery/order-delivery/order-delivery';

export enum DeliveryPlatform {
    Uber = 'UBER',
    Deliveroo = 'DELIVEROO',
    PiClick = 'PI_CLICK',
    Innovorder = 'INNOVORDER',
    AllEatOne = 'ALL_EAT_ONE',
}

export enum OrderType {
    Delivery = 'DELIVERY',
    ClickAndCollect = 'CLICK_AND_COLLECT',
    Express = 'EXPRESS',
}

export enum OrderState {
    InProgress = 'in_progress',
    Created = 'created',
    InPreparation = 'in_preparation',
    ReadyForCollection = 'ready_for_collection',
    Collected = 'collected',
    Delivered = 'delivered',
    Cancelled = 'cancelled',
    Denied = 'denied',
    PendingSiteConfirmation = 'pending_site_confirmation',
}

export enum OrderEvent {
    Post = 'POST_ORDER',
    UpdateState = 'UPDATE_ORDER_STATE',
    Cancelled = 'CANCELLED_ORDER',
    Accepted = 'ACCEPTED_ORDER',
    Get = 'GET_ORDER',
}

export enum OrderCancelledBy {
    Customer = 'CUSTOMER',
    Restaurant = 'RESTAURANT',
    Stuart = 'STUART',
    System = 'System',
}

export class Order {
    /**
     * Id of the site the order belongs to
     */
    readonly siteId: string;

    /**
     * Id of the order in the database
     */
    _id: string;
    /**
     * Id of the order in the delivery platform database.
     */
    readonly platformId: string;

    /**
     * Id of the bill in the pos system
     */
    readonly posBillId?: string;
    /**
     * Id to be displayed to the end user.
     */
    readonly shortId: string;
    /**
     * Customer of the order
     */
    readonly customer: Customer;
    /**
     * Customer of the order
     */
    currentlyUpdated: Boolean;
    /**
     * Information about the delivery for a user
     */
    delivery?: OrderDelivery;
    /**
     * The state of the order.
     */
    state: OrderState;
    /**
     * Date time tje order is estimated ready to be delivered
     */
    estimatedReadyForPickupAt: Moment;
    /**
     * The platform where the order comes from
     */
    readonly deliveryPlatform: DeliveryPlatform;
    /**
     * The type of the order.
     */
    readonly type: OrderType;
    /**
     * Payment information about the order
     */
    payment: Payment;

    /**
     * All the items of the order
     */
    items: Item[];

    /**
     * Date time the order has been created.
     */
    readonly createdAt: Moment;
    /**
     * Date time the order has been updated the last time.
     */
    updatedAt: Moment;

    /**
     * Original json object of an order
     */
    originalPayload?: any;

    /**
     * Indicates who cancelled an order if it has been cancelled
     */
    cancelledBy?: OrderCancelledBy;

    /**
     * The reason why the order was denied or cancelled
     */
    denyOrCancelOrderReason?: DenyOrCancelOrderReason | DenyOrCancelOrderReasonStuart;

    /**
     * The reason why the order was denied or cancelled if it was other that was selected in the list of reason to deny or cancelled
     */
    otherDenyOrCancelReason?: string;

    /**
     * Additional information about order from client to restaurant
     */
    additionalInformation?: string;

    /**
     * Indicates if the order is for right now or not.
     */
    asap: boolean;

    /**
     * Indicates if the order has been send automatically to the pos system.
     */
    sentToPos?: boolean;

    /**
     * Contains relative information about promotion, related promotion items and values
     */
    promotions?: Promotion[];

    /**
     * The number of the table if the order is at table
     */
    tableNumber?: number;

    /**
     * The id of the master site if the site does not have its own pos system
     */
    originalSiteId?: string;

    constructor(props: Order) {
        Object.assign(this, props);
        this.customer = new Customer(this.customer);
        this.estimatedReadyForPickupAt = moment(this.estimatedReadyForPickupAt);
        this.createdAt = moment(this.createdAt);
        this.updatedAt = moment(this.updatedAt);

        this.items?.forEach((item) => new Item(item));
        this.payment = new Payment(this.payment);
    }
}
