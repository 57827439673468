import * as faker from 'faker';
import { PiclickV2StyleParameters } from './piclick-v2-parameters';

export const fakePiclickV2StyleParametersData = (): PiclickV2StyleParameters => ({
    darkMode: faker.random.boolean(),
    neutralColor: '#fcba03',
    primaryColor: '#fcbddc',
});

export const fakePiclickV2StyleParameters = (): PiclickV2StyleParameters => new PiclickV2StyleParameters(fakePiclickV2StyleParametersData());
