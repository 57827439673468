import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { PiclickV2 } from '../piclick-v2';

export class Piclick {
    /**
     * An url that is use to redirect the user when the user just pass an order
     */
    redirectionUrl?: string;

    /**
     * An url that is use to redirect the user after the payment. Mainly used for clients using piclick in iframe
     */
    redirectionAfterPayment?: string;

    /**
     * The url for the cgu of the site
     */
    cguUrl?: string;

    /**
     * Url on the gdpr
     */
    gdprUrl?: string;

    /**
     * The style of the application PiClick
     */
    style?: string;

    /**
     * The url of the logo for piclick
     */
    logoUrl?: string;

    /**
     * Use default loader
     */
    useDefaultLoader?: boolean;

    /**
     * The url for the loader
     */
    loaderUrl?: string;

    /**
     * All the time periods when a user can go on the PiClick site
     */
    timePeriods?: WeekdayTimePeriods[];

    /**
     * Indicates if PiClick is enabled for the client
     */
    enabled: boolean;

    /**
     * All relative Piclick v2 configuration
     */
    v2?: PiclickV2;

    constructor(props: Piclick) {
        Object.assign(this, props);
    }
}
