export class PiclickV2Consumable {
    /**
     * The consumable label
     */
    label: string;

    /**
     * The consumable max quantity
     */
    maxQuantity: number;

    /**
     * The corresponding posNumber if necessary
     */
    posNumber?: number;

    constructor(props: PiclickV2Consumable) {
        Object.assign(this, props);
    }
}
