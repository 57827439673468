import React, { useState, useCallback } from 'react';
import { OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApSearchBar.style';

const useStyles = makeStyles(style);

interface Props {
    placeholder: string;
    className?: string;
    onChange: (value: string) => void;
}

export const ApSearchBar = ({ placeholder, className = 'default', onChange }: Props) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            setInputValue(value);
            onChange(value);
        },
        [onChange]
    );

    const handleErase = useCallback(() => {
        setInputValue('');
        onChange('');
    }, [onChange]);

    return (
        <OutlinedInput
            className={`${classes.root} ${className} ${(inputValue.length && 'has-value') || ''} ap-searchbar`}
            id="standard-adornment-search"
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment className={inputValue.length ? '' : 'hidden'} position="end">
                    <IconButton onClick={handleErase}>
                        <CancelIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
