import { UpdateTimeSlot } from './update-time-slot';
import * as faker from 'faker';

export const fakeUpdateTimeSlotData = (): UpdateTimeSlot => {
    const startHour = faker.random.number(22);
    const endHour = startHour + faker.random.number(23 - startHour);
    return {
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        start: `${startHour}:${faker.random.number(60)}`,
        end: `${endHour}:${faker.random.number(60)}`,
    };
};

export const fakeUpdateTimeSlot = (): UpdateTimeSlot => new UpdateTimeSlot(fakeUpdateTimeSlotData());

export const fakeUpdateTimeSlotArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateTimeSlot[] => {
    const timeSlot: UpdateTimeSlot[] = [];

    for (let i = 0; i < nbMax; i++) {
        timeSlot.push(fakeUpdateTimeSlot());
    }

    return timeSlot;
};
