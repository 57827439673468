import React, { createContext, Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { UpdateUser, UserDetail } from '@aphilia/shared/organizations/types';
import { updateUser as updateUserFromAxios, getUser as getUserFromAxios } from '@aphilia/shared/organizations/data-access';
import { updateUser as updateUserAction } from './actions';
import * as Actions from './constants';
import { useOrganizationContext } from '..';

function createInitialState() {
    return {
        isAuthenticated: false,
        loading: true,
    };
}

export interface UserStateObject {
    user?: UserDetail;
    loading?: boolean;
    popupOpen?: boolean;
    isLoadingUser?: boolean;
    setUser?: () => Promise<UserDetail>;
    updateUser?: (updateObject: UpdateUser) => Promise<UserDetail>;
}

const store = createContext<UserStateObject>({});
const { Provider } = store;

const initialState = createInitialState();

interface Props {
    children: React.ReactNode;
    organizationsUrl: string;
}

const UserProvider = ({ children, organizationsUrl }: Props) => {
    const { siteSelected, setSiteSelected, brandSelected, setBrandSelected } = useOrganizationContext();

    const [state, dispatch] = useReducer<Reducer<UserStateObject, unknown>>(
        (currentState: unknown, action: { type: keyof typeof Actions; payload?: unknown }) => {
            switch (action.type) {
                case Actions.UPDATE_STATE:
                    return Object.assign({}, currentState, action.payload);
                case Actions.UPDATE_USER:
                    return Object.assign({}, currentState, {
                        user: action.payload,
                    });
                case Actions.UPDATE_LOADING_USER:
                    return Object.assign({}, currentState, {
                        isLoadingUser: action.payload,
                    });
                default:
                    throw new Error();
            }
        },
        Object.assign(initialState, {
            ...initialState,
        })
    );

    /**
     * Get the user information
     *
     * @param token
     */
    const getUser = useCallback(async (): Promise<UserDetail> => {
        try {
            const response = await getUserFromAxios(organizationsUrl);
            dispatch(updateUserAction(response));
            if (response.user_metadata?.brandId) await setBrandSelected(response.user_metadata?.brandId);
            if (response.user_metadata?.siteId) await setSiteSelected(response.user_metadata?.siteId);
            return response;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }, [organizationsUrl, setBrandSelected, setSiteSelected]);

    /**
     * Update the current user
     */
    const updateUser = useCallback(
        async (updateObject: UpdateUser) => {
            try {
                const response = await updateUserFromAxios(organizationsUrl, state.user?._id, updateObject);
                dispatch(updateUserAction(response));
                return response;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        [organizationsUrl, state.user?._id]
    );

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (siteSelected && state.user?._id && siteSelected?._id !== state.user?.user_metadata?.siteId) {
            updateUser({
                user_metadata: Object.assign(state.user?.user_metadata || {}, {
                    siteId: siteSelected._id,
                }),
            });
        }
    }, [siteSelected, state.user?._id, state.user?.user_metadata, updateUser]);

    useEffect(() => {
        if (brandSelected && state.user?._id && brandSelected?._id !== state.user?.user_metadata?.brandId) {
            updateUser({
                user_metadata: Object.assign(state.user?.user_metadata || {}, {
                    brandId: brandSelected._id,
                }),
            });
        }
    }, [brandSelected, state.user?._id, state.user?.user_metadata, updateUser]);

    return (
        <Provider
            value={{
                ...state,
                updateUser,
                setUser: getUser,
            }}
        >
            {children}
        </Provider>
    );
};

// Generate a hook to use the organization context
const useUserContext = () => useContext(store);

export { useUserContext, UserProvider };
