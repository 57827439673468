import { CreateWebArticleModifierDescriptiveSheet } from './create-web-article-modifier-descriptive-sheet';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleModifierType } from '../../web-article-modifier';

export const fakeCreateWebArticleModifierDescriptiveSheetData = (): CreateWebArticleModifierDescriptiveSheet => ({
    brandId: mongoose.Types.ObjectId().toHexString(),
    siteId: mongoose.Types.ObjectId().toHexString(),
    modifierNumber: faker.random.number(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    shortDescription: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    type: faker.random.objectElement<WebArticleModifierType>(WebArticleModifierType),
});

export const fakeCreateWebArticleModifierDescriptiveSheet = (): CreateWebArticleModifierDescriptiveSheet =>
    new CreateWebArticleModifierDescriptiveSheet(fakeCreateWebArticleModifierDescriptiveSheetData());

export const fakeCreateWebArticleModifierDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWebArticleModifierDescriptiveSheet[] => {
    const webArticleModifierDescriptiveSheet: CreateWebArticleModifierDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifierDescriptiveSheet.push(fakeCreateWebArticleModifierDescriptiveSheet());
    }

    return webArticleModifierDescriptiveSheet;
};
