import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        zIndex: 1300,
        '& .MuiSnackbarContent-root': {
            padding: '16px 24px',
            background: 'transparent',
            '&:before': {
                content: '""',
                position: 'absolute',
                zIndex: -1,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                backgroundColor: palette.common.black,
                opacity: 0.9,
                borderRadius: 3.14,
            },
        },
        '& .MuiSnackbarContent-message': {
            padding: 0,
            width: 208,
        },
        '& .MuiSnackbarContent-action': {
            marginRight: 0,
            paddingLeft: 88,
            '& button': {
                marginLeft: 24,
                '&:first-child': {
                    marginLeft: 0,
                },
            },
        },
    },
});
