import * as faker from 'faker';
import { OrderDeliveryPrice } from './order-delivery-price';

/**
 * Return order-delivery-price data generated by faker.
 *
 * @return OrderDeliveryPrice
 */
export const fakeOrderDeliveryPriceData = (): OrderDeliveryPrice => ({
    currency: faker.finance.currencyCode(),
    amount: parseInt(faker.finance.amount(), 10),
});

/**
 * Return an instance of OrderDeliveryPrice class with data generated by faker
 *
 * @return OrderDeliveryPrice
 */
export const fakeOrderDeliveryPrice = (): OrderDeliveryPrice => new OrderDeliveryPrice(fakeOrderDeliveryPriceData());
