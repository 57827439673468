import * as React from 'react';

function SvgExpandMore(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="expand_more_svg__a" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </defs>
            <use fill="currentColor" xlinkHref="#expand_more_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgExpandMore;
