import { ApCard, ApCol, ApMainPage, ApRow } from '@aphilia/shared-ui-core-react';
import React from 'react';
import { Link } from 'react-router-dom';

export const HomePage = () => {
    return (
        <ApMainPage pageTitle={'Produits'}>
            <ApRow>
                <ApCol size={4}>
                    <ApCard headerTitle={'Isphora'} subtitle={'Moyen de paiements web'}>
                        <ul>
                            <Link to="isphora">Gérer l'application</Link>
                        </ul>
                    </ApCard>
                </ApCol>
                <ApCol size={4}>
                    <ApCard headerTitle={'Web commande'} subtitle={'Gestion des commandes et intégration web'}>
                        <ul>
                            <Link to="webcommande/parameters">Gérer les paramètres</Link>
                            <Link to="webcommande/applications/piclick">Gérer les applications</Link>
                        </ul>
                    </ApCard>
                </ApCol>
            </ApRow>
        </ApMainPage>
    );
};
