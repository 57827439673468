import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    popper: {
        color: palette.grey[600],
        verticalAlign: 'middle',
        marginLeft: '10px',
    },
    tooltip: {
        color: palette.common.white,
        backgroundColor: palette.grey[700],
        borderRadius: '3.14px',
        margin: '0 8px',
        fontSize: theme.typography.pxToRem(12),
    },
});
