import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApSearchbar } from '@aphilia/shared-ui-core-react';
import { ApTable, useWindowSize, ApSpinner } from '@aphilia/shared/react';
import { useArticlesSearch } from './hooks';
import { DeliveryParameters, WebArticleAdditionalInformation, WebArticleDescriptiveSheet } from '@aphilia/deliveries/data';
import { PosArticle } from '@aphilia/shared/articles/data';

import './search-table.scss';

interface ApSearchableTableProps {
    columns: any[];
    rows: Record<string, any>;
    emptyTitleKey?: string;
    emptySubtitleKey?: string;
    footerKey?: string;
    isLoading?: boolean;
    searchOnColumns?: string[];
    ChoiceListComponent: React.ReactNode;
    onSearch?: (rows: string[]) => void;
    platformFilterKeys: string[];
    availabilityFilterKeys: string[];
    platformAndAvailabilityFilters?: string[];
    applyPlatformFilters: (filters: string[], article: Record<string, any>, deliveryParameters: DeliveryParameters) => boolean;
    applyAvailabilityFilters: (filters: string[], article: Record<string, any>) => boolean;
    deliveryParameters: DeliveryParameters;
}

interface CompleteArticle {
    item: PosArticle;
    descriptiveSheet?: WebArticleDescriptiveSheet;
    additionalInformation?: WebArticleAdditionalInformation;
}

const formatArticleRowForFilters = (row: Record<string, any>): Partial<CompleteArticle> => {
    return {
        additionalInformation: {
            ...row,
            atTableAvailability: row.atTable,
            deliveryAvailability: row.delivery,
            clickAndCollectAvailability: row.clickAndCollect,
        } as WebArticleAdditionalInformation,
    };
};

export function ApSearchableTable({
    columns,
    rows,
    emptyTitleKey,
    emptySubtitleKey,
    footerKey,
    searchOnColumns,
    isLoading,
    onSearch,
    ChoiceListComponent,
    platformFilterKeys,
    availabilityFilterKeys,
    platformAndAvailabilityFilters,
    applyPlatformFilters,
    applyAvailabilityFilters,
    deliveryParameters,
}: ApSearchableTableProps) {
    const { t } = useTranslation();
    const size = useWindowSize();
    const [finalRows, setFinalRows] = useState<string[]>([]);
    const { filteredRows, searchValue, handleSearch } = useArticlesSearch(rows, searchOnColumns, onSearch);

    useEffect(() => {
        if (platformAndAvailabilityFilters?.length > 0) {
            const rowsToFilter = filteredRows ? filteredRows : Object.keys(rows);
            const platformsFiltered = rowsToFilter.filter((article) => applyPlatformFilters(platformFilterKeys, formatArticleRowForFilters(rows[article]), deliveryParameters));

            let finalIds = [];
            if (platformFilterKeys.length) {
                finalIds = platformsFiltered;
            }

            if (availabilityFilterKeys.length) {
                finalIds = platformsFiltered.filter((article) => applyAvailabilityFilters(availabilityFilterKeys, formatArticleRowForFilters(rows[article])));
            }

            setFinalRows(finalIds);
        } else {
            setFinalRows(filteredRows);
        }
    }, [platformAndAvailabilityFilters, filteredRows, rows, platformFilterKeys, availabilityFilterKeys, deliveryParameters]);

    return (
        <div className="ap-searchable-table">
            <div className="ap-searchable-table-header">
                <ApSearchbar className="ap-table-search" placeholder={t('articles.search.label')} minSearchLength={2} onSearchChange={handleSearch} value={searchValue} />
                <div className="table-filters"> {ChoiceListComponent} </div>
            </div>

            <ApTable
                columns={columns}
                rows={rows}
                empty={{
                    title: emptyTitleKey ? t(emptyTitleKey) : '',
                    subtitle: emptySubtitleKey ? t(emptySubtitleKey) : '',
                }}
                height={size.height - 235}
                display={finalRows}
                footerText={footerKey ? t(footerKey, { count: finalRows ? finalRows.length : Object.keys(rows).length }) : ''}
            />
            {isLoading && (
                <div className="spinner-container">
                    <ApSpinner />
                </div>
            )}
        </div>
    );
}
