import axios from 'axios';

/**
 * Get all the brand ids for the deliveroo id
 *
 * @Param deliveriesService
 * @Param locationId
 */
export const getBrandIdsByLocationId = async (deliveriesService: string, locationId: string): Promise<string[]> => {
    try {
        const response = await axios.get<string[]>(`${deliveriesService}/deliveroo/${locationId}/brand`, {
            headers: {
                Authorization: `Bearer ${(window as any).authToken['default']}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
