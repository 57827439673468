import { Piclick } from './piclick';
import * as faker from 'faker';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { fakePiclickV2Data } from '../piclick-v2';

export const fakePiclickData = (): Piclick => ({
    redirectionUrl: faker.internet.url(),
    cguUrl: faker.internet.url(),
    gdprUrl: faker.internet.url(),
    style: faker.lorem.paragraph(),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    loaderUrl: faker.internet.url(),
    logoUrl: faker.internet.url(),
    enabled: faker.random.boolean(),
    v2: fakePiclickV2Data(),
});

export const fakePiclick = (): Piclick => new Piclick(fakePiclickData());

export const fakePiclickArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Piclick[] => {
    const delivery: Piclick[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakePiclick());
    }

    return delivery;
};
