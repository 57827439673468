import { Weekday } from '../../weekday';
import { TimePeriod } from '../../time-period';
import moment from 'moment-timezone';

export class WeekdayTimePeriods {
    /**
     * The weekday of the time period
     */
    weekday: Weekday;

    /**
     * Indicates if the day is actually open or not
     */
    isOpen?: boolean;

    /**
     * Array of all the time periods availabilities for the same weekday
     */
    timePeriods: TimePeriod[] = [];

    /**
     * The timezone of the period
     */
    timeZone = 'Europe/Paris';

    /**
     * The id of the period if it exists
     */
    _id?: string;

    constructor(props: Partial<WeekdayTimePeriods>) {
        Object.assign(this, props);
    }

    /**
     * Return true or false if we are in a time period
     */
    get isActuallyInTimePeriod(): boolean {
        return this.timePeriods.some((timePeriod) =>
            moment()
                .local()
                .isBetween(
                    moment.tz(timePeriod.startTime, 'HH:mm', this.timeZone).isoWeekday(this.weekday),
                    moment.tz(timePeriod.endTime, 'HH:mm', this.timeZone).isoWeekday(this.weekday),
                    'm',
                    '[]'
                )
        );
    }
}
