import { ApCard, ApCol, ApRow } from '@aphilia/shared-ui-core-react';
import React from 'react';
import { useStripeContext } from '@aphilia/payments/feature-main';
import './information.scss';

export const IsphoraAccountInformation = () => {
    const { formattedTotalBalance, account } = useStripeContext();

    return (
        <ApCard headerTitle="Information du compte">
            <ApRow>
                <ApCol>
                    <p>Nom</p>
                    <h3>{account?.business_profile?.name}</h3>
                </ApCol>
                <ApCol>
                    <p>Solde</p>
                    <h3>{formattedTotalBalance}</h3>
                </ApCol>
            </ApRow>
        </ApCard>
    );
};
