import { WebArticleModifierType } from '../../web-article-modifier';

export class CreateWebArticleModifierDescriptiveSheet {
    /**
     * The brand id of the article
     */
    brandId: string;

    /**
     * The site id of the descriptive sheet
     */
    siteId: string;

    /**
     * The number of the web modifier
     */
    modifierNumber: number;

    /**
     * Title of the article on the web.
     */
    title?: Record<string, string>;

    /**
     * Short description of the article.
     */
    shortDescription?: Record<string, string>;

    /**
     * The type of the modifier
     */
    type: WebArticleModifierType;

    constructor(props: CreateWebArticleModifierDescriptiveSheet) {
        Object.assign(this, props);
    }
}
