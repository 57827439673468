import { UpdateDayPeriod } from './update-day-period';
import * as faker from 'faker';

export const fakeUpdateDayPeriodData = (): UpdateDayPeriod => ({
    name: faker.name.findName(),
});

export const fakeUpdateDayPeriod = (): UpdateDayPeriod => new UpdateDayPeriod(fakeUpdateDayPeriodData());

export const fakeUpdateDayPeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateDayPeriod[] => {
    const dayPeriod: UpdateDayPeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        dayPeriod.push(fakeUpdateDayPeriod());
    }

    return dayPeriod;
};
