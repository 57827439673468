export class Customer {
    /**
     * Badge Id of the fidepi costumer
     *
     * @example WEB0000000009
     */
    id?: string;

    /**
     * First Name of the costumer
     *
     * @example John
     */
    firstName?: string;

    /**
     * Last Name of the costumer
     *
     * @example Doe
     */
    lastName?: string;

    /**
     * Phone number
     *
     * @format ISO
     * @example +3306000000
     */
    phoneNumber?: string;

    /**
     * The email of the consumer
     */
    email?: string;

    /**
     * The address of the customer
     */
    address?: string;

    /**
     * Comment about the address that the customer can add
     *
     * example: floor, code ...
     */
    addressComment?: string;

    /**
     * Job id of the drop off if there is one
     */
    jobId?: number;

    /**
     * Tracking URL for the delivery
     */
    trackingUrl?: string;

    /**
     * The details (consumable label and quantity) of consumables the user added to the order
     */
    orderedConsumables?: OrderedConsumables;

    /**
     * Indicates if the customer agrees or not to receive site information
     */
    wantSiteInformation?: boolean;

    /**
     * Token of the user if they are in a collectivity setting
     */
    token?: string;

    /**
     * Constructor for the customer object
     *
     * @param props - the costumer data
     */
    constructor(props: Customer) {
        Object.assign(this, props);
    }
}

export interface OrderedConsumables {
    [key: string]: number | boolean;
}
