import { TerminalMedia } from './terminal-media';
import * as faker from 'faker';

export const fakeTerminalMediaData = (): TerminalMedia => ({
    _id: faker.random.uuid(),
    url: 'www.faker.test',
});

export const fakeTerminalMedia = (): TerminalMedia => new TerminalMedia(fakeTerminalMediaData());

export const fakeTerminalMediaArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): TerminalMedia[] => {
    const terminal: TerminalMedia[] = [];

    for (let i = 0; i < nbMax; i++) {
        terminal.push(fakeTerminalMedia());
    }

    return terminal;
};
