import * as React from 'react';

function SvgDelete(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" id="delete_svg__a" />
            </defs>
            <use fill="currentColor" xlinkHref="#delete_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgDelete;
