import { Address } from './address';
import * as faker from 'faker';

export const fakeAddressData = (): Address => ({
    mainAddress: faker.address.streetAddress(),
    complementaryInformation: faker.address.secondaryAddress(),
    zipCode: faker.address.zipCode(),
    city: faker.address.city(),
    country: faker.address.country(),
});

export const fakeAddress = (): Address => new Address(fakeAddressData());

export const fakeAddressArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Address[] => {
    const address: Address[] = [];

    for (let i = 0; i < nbMax; i++) {
        address.push(fakeAddress());
    }

    return address;
};
