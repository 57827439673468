import { CreateClickAndCollect } from './create-click-and-collect';
import * as faker from 'faker';

export const fakeCreateClickAndCollectData = (): CreateClickAndCollect => ({
    name: faker.name.findName(),
});

export const fakeCreateClickAndCollect = (): CreateClickAndCollect => new CreateClickAndCollect(fakeCreateClickAndCollectData());

export const fakeCreateClickAndCollectArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateClickAndCollect[] => {
    const clickAndCollect: CreateClickAndCollect[] = [];

    for (let i = 0; i < nbMax; i++) {
        clickAndCollect.push(fakeCreateClickAndCollect());
    }

    return clickAndCollect;
};
