import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { generateThemeColors } from '@aphilia/shared/utils';

const OVERRIDE_STYLE = 'override-style';

function useStylesOverride(styles?) {
    const handleStylesOverride = useCallback((stylesData) => {
        const overrideStyleElement = document.getElementById(OVERRIDE_STYLE);

        if (isEmpty(stylesData)) {
            if (overrideStyleElement) {
                document.body.removeChild(overrideStyleElement);
            }
        } else {
            const themeColors = generateThemeColors(stylesData);
            const themeColorsString = Object.entries(themeColors).reduce((acc, current) => {
                return `${acc}--${current[0]}: ${current[1]};`;
            }, '');
            const stylesString = `:root {${themeColorsString}}`;

            if (overrideStyleElement) {
                overrideStyleElement.innerText = stylesString;
            } else {
                const bodyStyle = document.body.getElementsByTagName('style');

                const newOverrideStyleElement = document.createElement('style');
                newOverrideStyleElement.setAttribute('id', OVERRIDE_STYLE);
                newOverrideStyleElement.innerText = stylesString;

                if (bodyStyle.length > 0) {
                    bodyStyle[bodyStyle.length - 1]?.insertAdjacentElement('afterend', newOverrideStyleElement);
                } else {
                    document.body.insertAdjacentElement('afterbegin', newOverrideStyleElement);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (styles) {
            handleStylesOverride(styles);
        }
    }, [styles, handleStylesOverride]);

    return { handleStylesOverride };
}

export { useStylesOverride };
