import { UberServiceAvailability } from '../service-availability/service-availability';

export class UberMenu {
    /**
     * A unique identifying string for the menu, provided by the restaurant.
     */
    id: string;

    /**
     * The name of the menu to be displayed (by locale).
     */
    title: {
        translations: Record<string, string>;
    };

    /**
     * An optional subtitle for the menu (by locale).
     */
    subtitle?: {
        translations: Record<string, string>;
    };

    /**
     * The days and times of the day at which this menu should be made available.
     */
    service_availability: UberServiceAvailability[];

    /**
     * All of the IDs for the menu categories that will be made available while this menu is active.
     */
    category_ids: string[];

    constructor(menu: Partial<UberMenu>) {
        Object.assign(this, menu);

        // Parse all the objects
        this.service_availability.forEach((service) => new UberServiceAvailability(service));
    }
}
