export class QueryPeriod {
    /**
     * The site id of period
     */
    readonly siteId: string;

    constructor(props: QueryPeriod) {
        Object.assign(this, props);
    }
}
