import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) => ({
    root: {
        background: theme.palette.common.white,
        borderRadius: '3.14px',
        '& .MuiFilledInput-root': {
            overflow: 'hidden',
            border: `1px solid ${palette.grey[600]}`,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiSelect-select': {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiInputLabel-filled': {
            lineHeight: '20px',
            fontSize: theme.typography.pxToRem(14),
            transform: 'translate(8px, 14px) scale(1)',
        },
        '& .MuiInputLabel-shrink': {
            lineHeight: '12px',
            fontSize: theme.typography.pxToRem(10),
            transform: 'translate(8px, 8px) scale(1)',
        },
        '& .MuiFilledInput-input': {
            lineHeight: '26px',
            fontSize: theme.typography.pxToRem(14),
            padding: '18px 8px 8px',
            height: '20px',
            '& .MuiSvgIcon-root': {
                width: '18px',
            },
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '4px',
        },
        '&.disabled': {
            color: palette.grey[400],
            '& .MuiFilledInput-root': {
                border: `1px solid ${palette.grey[400]}`,
            },
            '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root, .MuiSelect-filled.MuiSelect-filled': {
                color: palette.grey[400],
            },
        },
        '&.ap-success': {
            '& .MuiFilledInput-root': {
                border: `1px solid ${palette.success.main}`,
            },
            '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                color: palette.success.main,
            },
            '&.disabled': {
                color: palette.success.light,
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.success.light}`,
                },
                '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                    color: palette.success.light,
                },
            },
        },
        '&.ap-warning': {
            '& .MuiFilledInput-root': {
                border: `1px solid ${palette.warning.main}`,
            },
            '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                color: palette.warning.main,
            },
            '&.disabled': {
                color: palette.warning.light,
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.warning.light}`,
                },
                '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                    color: palette.warning.light,
                },
            },
        },
        '&.ap-error': {
            '& .MuiFilledInput-root': {
                border: `1px solid ${palette.error.main}`,
            },
            '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                color: palette.error.main,
            },
            '&.disabled': {
                color: palette.error.light,
                '& .MuiFilledInput-root': {
                    border: `1px solid ${palette.error.light}`,
                },
                '& .MuiInputLabel-shrink, & input + i, & .MuiFormHelperText-root, & .MuiInputLabel-root': {
                    color: palette.error.light,
                },
            },
        },
        '&.ap-select-compact': {
            '& .MuiFilledInput-root': {
                padding: '6px 8px',
            },
            '& .MuiFilledInput-input': {
                padding: '0',
                lineHeight: '18px',
                height: '18px',
                paddingRight: 24,
            },
        },
    },
    readOnly: {
        border: 'none !important',
        '&:hover': {
            backgroundColor: designTokens.colors.statesDefaultHover + ' !important',
        },
        '& .MuiSelect-select': {
            cursor: 'default',
            backgroundColor: 'transparent !important',
        },
    },
});
