import { UpdateDelivery } from './update-delivery';
import * as faker from 'faker';
import { fakeWeekdayTimePeriodsArray, PriceType } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeUpdateDeliveryData = (): UpdateDelivery => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    maxOrderPerInterval: faker.random.number(300),
    minOrderValue: faker.random.number(500),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
});

export const fakeUpdateDelivery = (): UpdateDelivery => new UpdateDelivery(fakeUpdateDeliveryData());

export const fakeUpdateDeliveryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateDelivery[] => {
    const delivery: UpdateDelivery[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeUpdateDelivery());
    }

    return delivery;
};
