import { DeliverooPrice } from './price/price';
import { DeliverooNutritionalInfo } from './nutritional-info/nutritional-info';

export class DeliverooItem {
    /**
     * Unique identifier for the item.
     */
    id: string;

    /**
     * Translated name of the item.
     */
    name: Object;

    /**
     * Translated description of the item.
     */
    description?: Object;

    /**
     * Operational name the restaurant uses in-house for the item.
     */
    operational_name?: string;

    /**
     * Price information for the item. Allows context specific overrides.
     */
    price_info: DeliverooPrice;

    /**
     *  PLU (price lookup code) for the item.
     */
    plu?: string;

    /**
     * Image for the item.
     */
    image?: {
        /**
         * URL for the image
         */
        url?: string;
    };

    /**
     * Tax rate percentage (from 0-100) for the item.
     */
    tax_rate: number;

    /**
     * Modifiers associated with the item.
     */
    modifier_ids?: string[];

    /**
     * Item's allergy information. Permitted strings listed in the 'Item allergens' section above.
     */
    allergies?: string[];

    /**
     * Item's diet information. Permitted strings listed in the 'Item diets' section above.
     */
    diets?: string[];

    /**
     * Item's nutritional information.
     */
    nutritional_info?: DeliverooNutritionalInfo;

    /**
     * Describes if the item contains alcohol.
     */
    contains_alcohol: boolean;

    constructor(props: DeliverooItem) {
        Object.assign(this, props);
    }
}
