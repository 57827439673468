import { CreateWebArticleAdditionalInformation } from './create-web-article-additional-information';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleUnavailabilityPeriod } from '../../web-article';

export const fakeCreateWebArticleAdditionalInformationData = (): CreateWebArticleAdditionalInformation => ({
    articleId: mongoose.Types.ObjectId().toHexString(),
    atTableAvailability: faker.random.boolean(),
    clickAndCollectAvailability: faker.random.boolean(),
    deliveryAvailability: faker.random.boolean(),
    isFavorite: faker.random.boolean(),
    isAvailableForSale: faker.random.boolean(),
    isSuccess: faker.random.boolean(),
    periodOfUnavailability: faker.random.objectElement<WebArticleUnavailabilityPeriod>(WebArticleUnavailabilityPeriod),
    siteId: mongoose.Types.ObjectId().toHexString(),
    suggestion: faker.random.boolean(),
});

export const fakeCreateWebArticleAdditionalInformation = (): CreateWebArticleAdditionalInformation =>
    new CreateWebArticleAdditionalInformation(fakeCreateWebArticleAdditionalInformationData());

export const fakeCreateWebArticleAdditionalInformationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWebArticleAdditionalInformation[] => {
    const webArticleAdditionnalInformation: CreateWebArticleAdditionalInformation[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleAdditionnalInformation.push(fakeCreateWebArticleAdditionalInformation());
    }

    return webArticleAdditionnalInformation;
};
