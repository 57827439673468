import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    children: ReactNode;
}

const useStyles = makeStyles(() => ({
    root: {
        '& button + button': {
            marginLeft: 24,
        },
    },
}));

export const ApInlineButton = ({ children }: Props) => {
    const classes = useStyles();
    return <div className={`ap-inline-button ${classes.root}`}>{children}</div>;
};
