import { UpdateWebArticleCategory } from './update-web-article-category';
import * as faker from 'faker';

export const fakeUpdateWebArticleCategoryData = (): UpdateWebArticleCategory => ({
    _id: faker.random.uuid(),
    title: {
        fr: faker.name.findName(),
        en: faker.name.findName(),
    },
    subtitle: {
        fr: faker.name.findName(),
        en: faker.name.findName(),
    },
    order: 4,
    parentCategoryId: faker.random.number().toString(),
});

export const fakeUpdateWebArticleCategory = (): UpdateWebArticleCategory => new UpdateWebArticleCategory(fakeUpdateWebArticleCategoryData());

export const fakeUpdateWebArticleCategoryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticleCategory[] => {
    const webArticleCategory: UpdateWebArticleCategory[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleCategory.push(fakeUpdateWebArticleCategory());
    }

    return webArticleCategory;
};
