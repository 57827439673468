import { ApRowData, ApTableN } from '@aphilia/shared/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { ApBadge, ApCard, ApTag } from '@aphilia/shared-ui-core-react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { getAmountReceived, Payment, PaymentStatus, RefundReason } from '@aphilia/payments/types';
import { useTranslation } from 'react-i18next';
import { RefundModal } from '../refund-modal';
import { getPayments } from '@aphilia/payments/data-access';
import { PaymentsDetailsModal } from '../payments-details-modal';
import { RefundButton } from '../refund-button';
import { useStripeContext } from '../../store/stripe-context';
import { ApValueType } from '@aphilia/shared/react';

export const PaymentsTable = () => {
    const { t } = useTranslation();
    const [payments, setPayments] = useState<Payment[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfPayments, setNumberOfPayments] = useState<number>(0);
    const [refundPayment, setRefundPayment] = useState<{ _id: string; amount: number }>();
    const [paymentSelectedId, setPaymentSelectedId] = useState<string>(null);
    const { account, paymentsUrl, paymentEvent } = useStripeContext();
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);

    /**
     * If an event has occurred then we need we need to refresh the payments if necessary
     */
    useEffect(() => {
        if (paymentEvent?.event) {
            const allPayments = payments.map((payment) => (payment._id === paymentEvent?.payment?._id ? paymentEvent?.payment : payment));
            setPayments(allPayments);
        }
    }, [paymentEvent?.event, paymentEvent?.payment, payments]);

    /**
     * Load all the payments
     */
    const loadPayments = useCallback(
        async (rowNumber?: number) => {
            try {
                const result = await getPayments(paymentsUrl, {
                    site_id: account?.site_id,
                    page: currentPage,
                    limit: rowNumber || rowsPerPage,
                });
                setNumberOfPayments(result.totalDocs);
                setPayments(result.docs);
            } catch (e) {
                console.error(e);
            }
        },
        [account?.site_id, currentPage, rowsPerPage, paymentsUrl]
    );

    /**
     * When the account is updated then fetch the data
     */
    useEffect(() => {
        loadPayments();
    }, [loadPayments]);

    /**
     * Create a refund
     */
    const makeRefund = useCallback(
        async (payment: Payment) => {
            try {
                const localPayments = cloneDeep(payments);
                localPayments[localPayments.findIndex((paymentData) => paymentData._id === payment._id)] = payment;
                setPayments(localPayments);
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                setRefundPayment(undefined);
            }
        },
        [payments]
    );

    const chargesToDisplay: Record<string, ApRowData> = useMemo(() => {
        return (
            payments?.reduce(
                (acc, it) => ({
                    ...acc,
                    [it._id]: {
                        id: it._id,
                        displayedId: {
                            value: it._id,
                        },
                        amount: {
                            value: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(it.amount / 100),
                        },
                        fees: {
                            value: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(
                                it.status === PaymentStatus.LostDispute ? it.application_fee / 100 : it.application_fee / 100
                            ),
                        },
                        amountWithFees: {
                            value: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(
                                getAmountReceived(it.status, it.amount, it.application_fee) / 100
                            ),
                        },
                        created: {
                            value: moment(it.createdAt).format('LLL'),
                        },
                        status: {
                            value: it.status,
                            content: ({ value }) => {
                                if (it.status === 'refunded') {
                                    return <ApTag label={t(`payments.charges.status.refunded`)} type="ghost" color="neutral" />;
                                } else {
                                    return (
                                        <ApTag
                                            label={t(`payments.charges.status.${it.status}`)}
                                            type="ghost"
                                            color={
                                                it.status === 'succeeded'
                                                    ? 'success'
                                                    : [PaymentStatus.Failed, PaymentStatus.FailedRefund].indexOf(it.status) !== -1
                                                    ? 'error'
                                                    : [PaymentStatus.Disputed].indexOf(it.status) !== -1
                                                    ? 'warning'
                                                    : 'neutral'
                                            }
                                        />
                                    );
                                }
                            },
                        },
                        cardInformation: {
                            value: it.payment_method?.card_last_4 ? `**** ${it.payment_method?.card_last_4} ${it.payment_method?.card_brand}` : '',
                        },
                        customerEmail: {
                            value: it.customer_email,
                        },
                        refundReason: {
                            value: it.refund_reason,
                            content: () =>
                                it.refund_failed_reason
                                    ? t(`payments.refund.failedReason.${it.refund_failed_reason}`)
                                    : it.refund_reason === RefundReason.Other
                                    ? it.other_refund_reason
                                    : it.refund_reason
                                    ? t(`payments.refundReason.form.reason.${it.refund_reason}`)
                                    : undefined,
                        },
                        refund: {
                            value: it.status,
                            content: () => <RefundButton amount={it.amount} status={it.status} onClickOnRefund={() => setRefundPayment({ _id: it._id, amount: it.amount })} />,
                        },
                    },
                }),
                {}
            ) || {}
        );
    }, [payments, t]);

    /**
     * Handle the click on the table
     */
    const handleClick = useCallback((payment: string) => () => setPaymentSelectedId(payment), []);

    /**
     * List of all the columns that are injected in the table
     */
    const chargesColumns = useMemo(() => {
        return [
            {
                label: 'ID',
                cellRatio: 2,
                dataKey: 'displayedId',
                actions: {
                    onClick: handleClick,
                },
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.amount'),
                cellRatio: 1,
                dataKey: 'amount',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.fees'),
                cellRatio: 1,
                dataKey: 'fees',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.amountWithFees'),
                cellRatio: 1,
                dataKey: 'amountWithFees',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.status'),
                cellRatio: 1,
                dataKey: 'status',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.card'),
                cellRatio: 1.5,
                dataKey: 'cardInformation',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.customer.email'),
                cellRatio: 2,
                dataKey: 'customerEmail',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.created'),
                cellRatio: 2,
                dataKey: 'created',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.refundReason'),
                cellRatio: 2.5,
                dataKey: 'refundReason',
                valueType: 'string' as ApValueType,
            },
            {
                label: t('payments.charges.table.actions'),
                cellRatio: 2,
                dataKey: 'refund',
                valueType: 'string' as ApValueType,
            },
        ];
    }, [handleClick, t]);

    return (
        <>
            {payments && (
                <ApTableN
                    columns={chargesColumns}
                    rows={Object.values(chargesToDisplay)}
                    currentPage={currentPage}
                    onCurrentPageChange={(page) => {
                        setCurrentPage(page);
                    }}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(e);
                    }}
                    tableDisplayHeight="600px"
                    itemsCount={numberOfPayments}
                    tableId="payment-table"
                />
            )}
            <PaymentsDetailsModal onClickOnRefund={(_id, amount) => setRefundPayment({ _id, amount })} paymentId={paymentSelectedId} onHide={() => setPaymentSelectedId(null)} />
            <RefundModal
                paymentId={refundPayment?._id}
                amount={Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(refundPayment?.amount / 100)}
                isOpen={!!refundPayment}
                onHide={() => setRefundPayment(undefined)}
                onCancel={() => setRefundPayment(undefined)}
                onMadeRefund={(payment) => makeRefund(payment)}
                paymentsServiceUrl={paymentsUrl}
            />
        </>
    );
};
