import React, { ReactNode, FormEvent, useCallback } from 'react';
import { TextField, Grid, Typography, FilledTextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApTextField.style';

const useStyles = makeStyles(style);

interface Props extends Omit<FilledTextFieldProps, 'variant'> {
    label?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    size?: 'small' | 'medium';
    type?: string;
    helpText?: string;
    readonly?: boolean;
    borderLess?: boolean;
    maxLength?: number;
    min?: number;
    max?: number;
    fieldRef?: any;
}

const TextFieldInput = ({ label, endIcon, size, type = 'text', helpText, readonly, maxLength, ...props }: Props) => {
    const handleInput = useCallback(
        (event: FormEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            if (maxLength && input.value.length > maxLength) input.value = input.value.substring(0, maxLength);
        },
        [maxLength]
    );

    delete props.borderLess;
    delete props.startIcon;

    return (
        <TextField
            label={(size !== 'small' || !props.placeholder) && label}
            variant="filled"
            color="primary"
            InputProps={{
                endAdornment: endIcon,
                readOnly: readonly,
            }}
            inputProps={{
                min: props.min > 0 ? props.min : 0,
            }}
            inputRef={props?.fieldRef ? props?.fieldRef : null}
            size={size}
            type={type}
            helperText={helpText}
            onInput={handleInput}
            {...props}
        />
    );
};

export const ApTextField = (props: Props) => {
    const classes = useStyles();
    return props.startIcon ? (
        <Grid
            container
            className={`${classes.root} ${classes.prefixed} ${(props.readonly && classes.readOnly) || ''} ${(props.multiline && classes.multiline) || ''} ${
                (props.borderLess && classes.borderLess) || ''
            } ap-textfield ${props.className}  ${(props.error && 'ap-error') || ''} ${(props.disabled && 'disabled') || ''}`}
        >
            <Grid container item xs={12} className="input-grid">
                <Grid item className="start-icon">
                    {props.startIcon}
                </Grid>
                <Grid item>
                    <TextFieldInput {...props} helpText={null} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography color="secondary" className="MuiFormHelperText-root">
                    {props.helpText}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <TextFieldInput
            {...props}
            className={`${classes.root} ${(props.readonly && classes.readOnly) || ''} ${(props.multiline && classes.multiline) || ''} ${(props.error && 'ap-error') || ''} ${
                (props.borderLess && classes.borderLess) || ''
            } ap-textfield ${props.className} ${(props.disabled && 'disabled') || ''}`}
        />
    );
};
