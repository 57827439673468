import React, { forwardRef, useImperativeHandle, useCallback, ReactNode, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Table, Column } from 'react-virtualized';
import { Checkbox } from '@material-ui/core';
import { DragSourceMonitor } from 'react-dnd';

import { ApColumn } from './models/ApColumn';
import style from './ApTable.style';
import { NoRows } from './components/NoRows';
import { ApTableCell } from './components/ApTableCell';
import { ApTableHeaderCell, ApTableRow } from './components';
import { useHorizontalScroll, useSelection, useOrder, useSort, defaultSortBy, defaultSortDirection, useVerticalScroll } from './ApTable.hooks';
import { get } from 'lodash';
import { ApIcon, ApTypography } from '@aphilia/shared-ui-core-react';
import { createClassString } from '@aphilia/shared/utils';

export interface TableProps {
    columns: ApColumn[];
    rows: { [key: string]: object };
    disabledRows?: string[];
    isSelectable?: boolean;
    isDraggable?: boolean;
    endDrag?: (item: { type: string; rowData: any }, monitor: DragSourceMonitor) => void;
    onDrop?: (item: { type: string; rowData: any }, monitor: DragSourceMonitor) => void;
    empty?: {
        title: string;
        subtitle?: string;
        actions?: ReactNode;
    };
    footerText?: string;
    loading?: boolean;
    onSelect?: (selected: object[]) => void;
    onRowClick?: (articleId: string) => void;
    height?: number;
    display?: string[];
    sortByInitial?: string;
    sortDirectionInitial?: any;
    numberOfPage?: number;
    currentPage?: number;
    onPageChange?: (page: number) => void;
}

export interface ApTableHandles {
    resetSort(): void;
    resetAll(): void;
}

const useStyles = makeStyles(style);
const TableComponent = (
    {
        isSelectable,
        isDraggable,
        endDrag,
        onDrop,
        columns,
        rows,
        disabledRows,
        empty,
        footerText,
        loading = false,
        onSelect = () => {},
        onRowClick = () => {},
        sortByInitial,
        sortDirectionInitial,
        height,
        display,
        numberOfPage,
        currentPage,
        onPageChange = () => {},
    }: TableProps,
    ref: any
) => {
    const classes = useStyles();

    const rowHeight = 48;
    const headerRowHeight = 56;
    const colWidth = 100;
    const maxRows = 10;

    const [headerHeight, setHeaderHeight] = useState(rowHeight);
    const { verticalScroll, resetVerticalScroll, handleVerticalScroll } = useVerticalScroll();
    const { sortBy, sortDirection, handleSort } = useSort(sortByInitial, sortDirectionInitial, resetVerticalScroll);
    const { order, handleOrder } = useOrder(display, rows, sortBy, sortDirection);
    const { horizontalScroll, handleScroll, getLeftPosition } = useHorizontalScroll(isSelectable, columns, colWidth, rowHeight);
    const { selected, handleSelectAll, resetSelection, selectedAndDisplayedLength, handleSelected } = useSelection(order, disabledRows, rows, onSelect);

    let stickyWidth = (isSelectable && columns[0].sticky && rowHeight) || 0;
    let fullWidth = (isSelectable && rowHeight) || 0;

    columns.forEach((col) => {
        if (col.sticky) {
            stickyWidth += (col.colSpan || 1) * colWidth;
        }
        fullWidth += (col.colSpan || 1) * colWidth;
    });

    const handleRowClick = useCallback(
        ({ index, event, isDragging }: any) => {
            const disabled = disabledRows?.includes(order[index]);
            const cellKey = event?.target.dataset.key;

            if (cellKey !== 'checkbox' && typeof onRowClick === 'function' && !isDragging) onRowClick(order[index]);
            if (!disabled && cellKey === 'checkbox') {
                let newSelection = [];

                if (selected.includes(order[index])) {
                    if (isDragging === true) return;

                    const i = selected.indexOf(order[index]);

                    selected.splice(i, 1);
                    newSelection = [].concat(selected);
                } else {
                    newSelection = selected.concat([order[index]]);
                }

                handleSelected(newSelection);
                onSelect(newSelection.map((key) => rows[key]));
            }
        },
        [selected, order, disabledRows, isSelectable, onSelect, onRowClick, handleSelected]
    );

    const handleDrop = useCallback(
        (item, monitor) => {
            resetSelection();
            if (onDrop) onDrop(item, monitor);
        },
        [onDrop]
    );

    useImperativeHandle(ref, () => ({
        resetSort() {
            handleSort({ sortBy: defaultSortBy, sortDirection: defaultSortDirection });
            handleOrder(Object.keys(rows));
        },
        resetAll() {
            resetSelection();
        },
    }));

    useEffect(() => {
        setHeaderHeight(columns.some((col) => !!col.header) ? headerRowHeight : rowHeight);
    }, [columns]);

    return (
        <div className={`ap-table ${classes.tableContainer}`}>
            <div onScroll={handleScroll} style={{ width: '100%', overflow: 'auto' }}>
                <Table
                    height={
                        (order?.length && height && Math.min(height, headerHeight + rowHeight * order.length)) ||
                        (order?.length && headerHeight + rowHeight * Math.min(order.length, maxRows + 1)) ||
                        320
                    }
                    scrollTop={verticalScroll}
                    rowGetter={({ index }) => rows[order[index]] || {}}
                    onRowClick={handleRowClick}
                    onScroll={handleVerticalScroll}
                    rowCount={order?.length || 0}
                    width={fullWidth}
                    minWidth={'100%'}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    className={classes.table}
                    rowClassName={`${classes.tableRow} ${(isSelectable && classes.selectableTableRow) || ''} ${classes.flexContainer}`}
                    noRowsRenderer={() => <NoRows loading={loading} {...empty} />}
                    sort={handleSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    rowRenderer={(props) => (
                        <ApTableRow
                            onDrop={handleDrop}
                            disabled={disabledRows && disabledRows.includes(order[props.index])}
                            isDraggable={isDraggable}
                            endDrag={endDrag}
                            {...props}
                        />
                    )}
                >
                    {isSelectable && (
                        <Column
                            key="checkbox"
                            headerRenderer={({ dataKey }) => {
                                return (
                                    <ApTableHeaderCell
                                        dataKey={dataKey}
                                        rowHeight={headerHeight}
                                        cellData={
                                            <Checkbox
                                                checked={
                                                    display
                                                        ? display.length > 0 && selectedAndDisplayedLength() === display.length
                                                        : Object.keys(rows).length > 0 && selected.length === Object.keys(rows).length
                                                }
                                                value="checked"
                                                color="primary"
                                                indeterminate={
                                                    (display
                                                        ? selectedAndDisplayedLength() && selectedAndDisplayedLength() < display.length
                                                        : selected.length && selected.length < Object.keys(rows).length) || false
                                                }
                                                onChange={handleSelectAll}
                                                data-key={dataKey}
                                                inputProps={{
                                                    // @ts-ignore
                                                    'data-key': dataKey,
                                                }}
                                            />
                                        }
                                    />
                                );
                            }}
                            headerClassName={`${classes.flexContainer} ${(columns[0].sticky && classes.sticky) || ''} ${classes.checkBox}`}
                            className={`${classes.flexContainer} ${(columns[0].sticky && classes.sticky) || ''} ${classes.checkBox}`}
                            cellRenderer={({ rowIndex, dataKey }) => (
                                <ApTableCell
                                    dataKey={dataKey}
                                    selected={selected.includes(order[rowIndex])}
                                    rowIndex={rowIndex}
                                    rowHeight={rowHeight}
                                    cellData={
                                        <Checkbox
                                            inputProps={{
                                                // @ts-ignore
                                                'data-key': dataKey,
                                            }}
                                            data-key={dataKey}
                                            checked={selected.includes(order[rowIndex])}
                                            value="checked"
                                            color="primary"
                                        />
                                    }
                                />
                            )}
                            dataKey="checkbox"
                            minWidth={rowHeight}
                            width={rowHeight}
                            flexGrow={1}
                            style={{
                                left: horizontalScroll,
                            }}
                            headerStyle={{
                                left: horizontalScroll,
                            }}
                            disableSort
                        />
                    )}
                    {columns.map(({ dataKey, colSpan, sticky, label, sortable = true, align, content, headerTooltip, header }, index) => {
                        return (
                            <Column
                                key={dataKey}
                                label={label}
                                headerRenderer={({ sortDirection: sortDirectionValue }) => {
                                    return (
                                        <ApTableHeaderCell
                                            sortable={sortable}
                                            align={align}
                                            dataKey={dataKey}
                                            cellData={label}
                                            isSortedBy={dataKey === sortBy}
                                            sortDirection={sortDirectionValue}
                                            rowHeight={headerHeight}
                                            tooltip={headerTooltip}
                                            title={label?.toString() || null}
                                            header={header}
                                        />
                                    );
                                }}
                                headerClassName={`${classes.flexContainer} ${(sticky && classes.sticky) || ''}`}
                                className={`${classes.flexContainer} ${(sticky && classes.sticky) || ''}`}
                                cellDataGetter={({ rowData }) => {
                                    if (content) return content(rowData);
                                    const cellData = get(rowData, dataKey);
                                    if (typeof cellData === 'object' && cellData.component) return cellData.component;
                                    return cellData;
                                }}
                                cellRenderer={(props) => (
                                    <ApTableCell dataKey={dataKey} align={align} selected={selected.includes(order[props.rowIndex])} rowHeight={rowHeight} {...props} />
                                )}
                                dataKey={dataKey}
                                minWidth={colWidth * (colSpan || 1)}
                                maxWidth={(sticky && colWidth * (colSpan || 1)) || null}
                                width={colWidth * (colSpan || 1)}
                                flexGrow={1}
                                style={{
                                    left: getLeftPosition(sticky, index),
                                    marginLeft: (!sticky && columns[index - 1] && columns[index - 1].sticky && stickyWidth) || 0,
                                }}
                                headerStyle={{
                                    left: getLeftPosition(sticky, index),
                                    marginLeft: (!sticky && columns[index - 1] && columns[index - 1].sticky && stickyWidth) || 0,
                                }}
                                disableSort={!sortable}
                            />
                        );
                    })}
                </Table>
            </div>
            {numberOfPage && (
                <div className={classes.pagination}>
                    <ApIcon icon="chevron_left" onClick={() => onPageChange(currentPage - 1 > 1 ? currentPage - 1 : 1)} />
                    {Array(numberOfPage)
                        .fill(0)
                        .map((val, index) => (
                            <span className={currentPage === index + 1 ? 'active' : ''} onClick={() => onPageChange(index + 1)}>
                                {index + 1}
                            </span>
                        ))}
                    <ApIcon icon="chevron_right" onClick={() => onPageChange(currentPage + 1 < numberOfPage ? currentPage + 1 : numberOfPage)} />
                </div>
            )}
            {(order?.length && (
                <div className={`footer ${classes.footer}`}>
                    <ApTypography className="line-number" type="h4" color="neutral" weight="normal">
                        {footerText}
                    </ApTypography>
                </div>
            )) ||
                null}
        </div>
    );
};
export const ApTable = forwardRef(TableComponent);
