import { UberQuantityConstraintRules } from '../../quantity/quantity-constraint-rules/quantity-constraint-rules';
import { UberMenuEntity } from '../../menu/menu-entity/menu-entity';

export enum UberDisplayType {
    EXPANDED = 'expanded',
    COLLAPSED = 'collapsed',
}

export class UberModifierGroup {
    /**
     * A unique identifying string for the modifier group, provided by the restaurant.
     */
    id: string;

    /**
     * Free-form text field reserved for the restaurant’s use, e.g. for POS integrations.
     */
    external_data?: string;

    /**
     * The displayed name of the modifier group (by locale).
     */
    title: {
        translations: Record<string, string>;
    };

    /**
     * Constrains the quantities in which all items within the modifier group can be ordered.
     * Allows overrides dependent on the ordered item’s context.
     */
    quantity_info?: UberQuantityConstraintRules;

    /**
     * A list of menu entity objects representing available item options for the modifier group - all entities must be of type “ITEM”.
     */
    modifier_options: UberMenuEntity[];

    /**
     * Describes how this modifier group should be initially displayed - whether fully expanded (the default setting if this field is not provided), or collapsed.
     */
    display_type?: UberDisplayType;

    constructor(props: Partial<UberModifierGroup>) {
        Object.assign(this, props);
    }
}
