import { Theme, createStyles } from '@material-ui/core/styles';
import { designTokens } from '@aphilia/shared/ui/styles';
import { useRem } from '../../theme';

export default (theme: Theme) => {
    return createStyles({
        root: Object.assign(
            {
                borderRadius: '3.1px',
                '&.MuiChip-clickable': {
                    border: `1px solid ${theme.palette.primary.main}`,
                },
                '& .MuiChip-label': {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
            useRem(designTokens.typeStyles.captionWhite)
        ),
        reverse: {
            color: theme.palette.primary.main,
            background: designTokens.colors.statesPrimaryActive,
            '&.MuiChip-colorPrimary': {
                color: theme.palette.primary.main,
                background: designTokens.colors.statesPrimaryActive,
                '&.MuiChip-clickable': {
                    borderColor: theme.palette.primary.main,
                },
            },
            '&.MuiChip-colorSecondary': {
                color: theme.palette.secondary.main,
                background: designTokens.colors.statesDefaultActive,
                '&.MuiChip-clickable': {
                    borderColor: theme.palette.secondary.main,
                },
            },
            '&.inactive': {
                color: theme.palette.secondary.main,
            },
        },
        success: {
            background: theme.palette.success.main,
            '&.MuiChip-clickable': {
                borderColor: theme.palette.success.main,
            },
        },
        warning: {
            background: theme.palette.warning.main,
            '&.MuiChip-clickable': {
                borderColor: theme.palette.warning.main,
            },
        },
        error: {
            background: theme.palette.error.main,
            '&.MuiChip-clickable': {
                borderColor: theme.palette.error.main,
            },
        },
    });
};
