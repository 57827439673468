import { useCallback, useState, useEffect, useMemo, ChangeEvent } from 'react';

import { ApItemProps, ApItemValue } from '@aphilia/shared-ui-core';
import { ApColumnData, ApColumnFilter, ApColumnFilterType, ApOperatorConfig, ApOperatorTradConfig } from '../../types';
import { operatorsConfig } from './config';
import { mergeOperatorsConfig } from '../../utils';

function useOperatorsConfig(filtersTrads?: ApOperatorTradConfig): ApOperatorConfig {
    const [completeOperatorsConfig, setCompleteOperatorsConfig] = useState({});

    useEffect(() => {
        const mergedConfig = mergeOperatorsConfig(filtersTrads, operatorsConfig);
        setCompleteOperatorsConfig(mergedConfig);
    }, [filtersTrads]);

    return completeOperatorsConfig;
}

const defaultFilters = [{}];

function useFilteringConfiguration(onDataFiltering, onFiltersEdition) {
    const [filters, setFilters] = useState<ApColumnFilter[]>(defaultFilters);

    const handleFiltersEdition = useCallback(
        (edition: boolean) => () => {
            if (typeof onFiltersEdition === 'function') {
                onFiltersEdition(edition);
            }
        },
        [onFiltersEdition]
    );

    const handleFiltering = useCallback(
        (resetFilters = false) => () => {
            let dataFilters = filters;
            if (resetFilters) {
                dataFilters = defaultFilters;
                setFilters(dataFilters);
            }

            if (typeof onDataFiltering === 'function') {
                onDataFiltering(dataFilters);
            }

            if (typeof onFiltersEdition === 'function') {
                onFiltersEdition(false);
            }
        },
        [filters, onDataFiltering, onFiltersEdition]
    );

    const handleAddFilter = useCallback(
        (evt) => {
            evt.stopPropagation();
            const updatedFilters = [...filters];
            updatedFilters.push({});
            setFilters(updatedFilters);
        },
        [filters]
    );

    const handleUpdateFilter = useCallback(
        (filterIndex: number, filter?: ApColumnFilter) => {
            const updatedFilters = [...filters];
            updatedFilters[filterIndex] = filter;
            setFilters(updatedFilters);
        },
        [filters]
    );

    const deleteFilter = useCallback(
        (filterIndex: number) => {
            let updatedFilters = [...filters];
            updatedFilters.splice(filterIndex, 1);
            if (updatedFilters.length === 0) {
                updatedFilters = defaultFilters;
            }
            return updatedFilters;
        },
        [filters]
    );

    const handleDeleteFilter = useCallback(
        (filterIndex: number) => {
            setFilters(deleteFilter(filterIndex));
        },
        [deleteFilter]
    );

    const handleDeleteAndExecuteFilter = useCallback(
        (filterIndex: number) => () => {
            const updatedFilters = deleteFilter(filterIndex);
            setFilters(updatedFilters);
            if (typeof onDataFiltering === 'function') {
                onDataFiltering(updatedFilters);
            }
        },
        [deleteFilter, onDataFiltering]
    );

    const canAddFilter = useMemo(() => {
        const lastFilter = filters[filters.length - 1];
        const uncompleteLastFilter = !lastFilter.columnKey || !lastFilter.operator || !lastFilter.value;
        return !uncompleteLastFilter;
    }, [filters]);

    const canUseFilters = useMemo(() => {
        const uncompleteFilter = filters.find((filter) => !filter.columnKey || !filter.operator || !filter.value);
        return !uncompleteFilter;
    }, [filters]);

    return { filters, canAddFilter, canUseFilters, handleFiltering, handleFiltersEdition, handleAddFilter, handleUpdateFilter, handleDeleteFilter, handleDeleteAndExecuteFilter };
}

function useFilterEdition(columns: ApColumnData[], filter?: ApColumnFilter, filterIndex?: number, filtersTrads?: ApOperatorTradConfig, onUpdateFilter?, onDeleteFilter?) {
    const [editedFilter, setEditedFilter] = useState<ApColumnFilter>({});
    const [columnValue, setColumnValue] = useState<string>(null);
    const [operatorValue, setOperatorValue] = useState<string>(null);
    const completeOperatorsConfig = useOperatorsConfig(filtersTrads);

    const columnsOptions = useMemo<ApItemProps[]>(() => {
        const columnTypes = Object.keys(completeOperatorsConfig);
        return columns
            ?.filter((col) => {
                return columnTypes.includes(col.valueType);
            })
            .map((col) => {
                return {
                    label: col.label,
                    value: col.dataKey,
                };
            });
    }, [columns, editedFilter?.columnKey, completeOperatorsConfig]);

    const operatorOptions = useMemo<ApItemProps[]>(() => {
        const currentColumnType = editedFilter?.valueType ?? columns?.find((col) => col.dataKey === editedFilter?.columnKey)?.valueType;
        if (currentColumnType) {
            if (['string', 'number'].includes(currentColumnType)) {
                return Object.keys(completeOperatorsConfig[currentColumnType]).map((op) => {
                    const currentOperator = completeOperatorsConfig[currentColumnType][op];
                    return { label: currentOperator.labelKey, value: op };
                });
            }
            return [];
        }
        return [];
    }, [columns, editedFilter?.columnKey, editedFilter?.operator, editedFilter?.valueType, completeOperatorsConfig]);

    const handleColumn = useCallback(
        (evt: CustomEvent<ApItemValue>) => {
            const currentColumnType = columns?.find((col) => col.dataKey === evt.detail.value)?.valueType;
            const newEditedFilter = Object.assign({}, editedFilter, { columnKey: evt.detail.value, valueType: currentColumnType });
            setColumnValue(evt.detail.value);
            setEditedFilter(newEditedFilter);
            onUpdateFilter(filterIndex, newEditedFilter);
        },
        [onUpdateFilter, editedFilter, filterIndex, columns]
    );

    const handleOperator = useCallback(
        (evt: CustomEvent<ApItemValue>) => {
            const newEditedFilter = Object.assign({}, editedFilter, { operator: evt.detail.value });
            setOperatorValue(evt.detail.value);
            setEditedFilter(newEditedFilter);
            onUpdateFilter(filterIndex, newEditedFilter);
        },
        [onUpdateFilter, editedFilter, filterIndex]
    );

    const handleValue = useCallback(
        (evt: CustomEvent<ChangeEvent<HTMLInputElement>>) => {
            const newValue = evt.detail.target.value as ApColumnFilterType;
            const newEditedFilter = Object.assign({}, editedFilter, { value: newValue });
            setEditedFilter(newEditedFilter);
            onUpdateFilter(filterIndex, newEditedFilter);
        },
        [onUpdateFilter, editedFilter, filterIndex]
    );

    const handleDeleteFilter = useCallback(() => {
        if (typeof onDeleteFilter === 'function') {
            onDeleteFilter(filterIndex);
        }
    }, [filterIndex, onDeleteFilter]);

    useEffect(() => {
        if (filter) {
            setEditedFilter(filter);
        }
    }, [filter]);

    return {
        editedFilter,
        columnValue,
        operatorValue,
        columnsOptions,
        operatorOptions,
        handleColumn,
        handleOperator,
        handleValue,
        handleDeleteFilter,
    };
}

function useColumnsEdition() {
    const [columnsEdition, setColumnsEdition] = useState(false);

    const handleColumnsEdition = useCallback(() => {
        setColumnsEdition(!columnsEdition);
    }, [columnsEdition]);

    return { columnsEdition, handleColumnsEdition };
}

function useColumnsConfiguration() {
    const [columnsEdition, setColumnsEdition] = useState(false);

    const handleColumnsEdition = useCallback(() => {
        setColumnsEdition(!columnsEdition);
    }, [columnsEdition]);

    return { columnsEdition, handleColumnsEdition };
}

export { useFilteringConfiguration, useFilterEdition, useOperatorsConfig, useColumnsEdition, useColumnsConfiguration };
