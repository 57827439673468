import React, { useCallback } from 'react';
import { useDeliveryParametersContext } from '@aphilia/deliveries/feature-parameters';
import { ApCard, ApCol, ApRow, ApToggle, ApBanner } from '@aphilia/shared-ui-core-react';
import { environment } from '../../../../../environments/environment';
import { UpdateDeliveryParameters } from '@aphilia/deliveries/data';

export const DeliveryParametersApplicationsPiClickPage = () => {
    const { deliveryParameters, updateDeliveryParameters } = useDeliveryParametersContext();

    const onUpdatePiclickParameters = useCallback((value: UpdateDeliveryParameters) => {
        updateDeliveryParameters(value);
    }, []);

    return (
        <ApCard headerTitle="Activation de Piclick">
            <ApRow>
                <ApCol>
                    <ApRow>
                        <ApBanner
                            clickable={false}
                            text={`Pour intégrer piclick il faut donner l'url suivante au webmaster: ${environment.piclickUrl}/${deliveryParameters.brandId}/${deliveryParameters.siteId}`}
                        />
                    </ApRow>
                    <ApRow>
                        <ApCol>
                            <ApToggle
                                checked={deliveryParameters?.applications?.piClick?.enabled}
                                label="Piclick actif"
                                onApToggle={(e) => onUpdatePiclickParameters({ applications: { piClick: { enabled: e.detail } } })}
                            />
                        </ApCol>
                        <ApCol>
                            <ApToggle
                                checked={deliveryParameters?.applications?.piClick?.v2?.enabled}
                                label="Utilisation du V2"
                                disabled={!deliveryParameters?.applications?.piClick?.enabled}
                                onApToggle={(e) =>
                                    onUpdatePiclickParameters({
                                        applications: { piClick: { v2: { enabled: e.detail, deliveryParameters: { enabled: !e.detail ? false : undefined } } } },
                                    })
                                }
                            />
                        </ApCol>
                        <ApCol>
                            <ApToggle
                                checked={deliveryParameters?.applications?.piClick?.v2?.deliveryParameters?.enabled}
                                label="Livraison sur PiClick V2"
                                disabled={!deliveryParameters?.applications?.piClick?.enabled || !deliveryParameters?.applications?.piClick?.v2?.enabled}
                                onApToggle={(e) => updateDeliveryParameters({ applications: { piClick: { v2: { deliveryParameters: { enabled: e.detail } } } } })}
                            />
                        </ApCol>
                    </ApRow>
                </ApCol>
            </ApRow>
        </ApCard>
    );
};
