import * as faker from 'faker';
import { DeliveryApplication } from './delivery-application';
import { fakePiclick } from '../piclick/piclick/piclick.data';
import { fakeDeliverooData } from './deliveroo';
import { fakeUberData } from './uber';
import { fakeStuart } from './stuart';

export const fakeDeliveryApplicationData = (): DeliveryApplication => ({
    piClick: fakePiclick(),
    uber: fakeUberData(),
    deliveroo: fakeDeliverooData(),
    stuart: fakeStuart(),
});

export const fakeDeliveryApplication = (): DeliveryApplication => new DeliveryApplication(fakeDeliveryApplicationData());

export const fakeDeliveryApplicationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): DeliveryApplication[] => {
    const delivery: DeliveryApplication[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeDeliveryApplication());
    }

    return delivery;
};
