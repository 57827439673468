import moment from 'moment';
import { Site } from '@aphilia/shared/organizations/types';

export const generateOptinCsv = (allSites: Site[], orders: any, columns: string) => {
    try {
        // Numéro du site, nom du site, date, heure, numéro de ticket, nom, prénom, adresse physique, adresse mail, téléphone, optins
        const csvContent = `data:text/csv;charset=utf-8,${columns}\n${orders
            .map((order) => {
                const ordersSite = allSites.find((site) => site._id === order.siteId);
                return `${ordersSite.elyxCode},${ordersSite.name},${moment(order.estimatedReadyForPickupAt).format('DD/MM/YYYY')},${moment(order.estimatedReadyForPickupAt).format(
                    'HH:mm'
                )},${order.posBillId ? `"${order.posBillId}"` : ''},"${order.customer.lastName}","${order.customer.firstName?.replace('PI_CLICK ', '')}",${
                    order.customer.address ? `"${order.customer.address}"` : ''
                },${order.customer.email ? order.customer.email : ''},${order.customer.phoneNumber ? order.customer.phoneNumber : ''},${
                    order.customer.wantSiteInformation ? 'Oui' : 'Non'
                }`;
            })
            .join('\n')}`;

        return csvContent;
    } catch (error) {
        console.error(error);
        return 'data:text/csv;charset=utf-8,';
    }
};
