import { PlatformType, WebArticle, WebArticleType, WebArticleUnavailabilityPeriod } from './web-article';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { fakeWebArticlePlatformConfiguration } from '../../web-article-platform-configuration';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { WebArticleVatType } from '..';

export const fakeWebArticleData = (): WebArticle => {
    const modifiers = [];
    for (let i = 0; i < faker.random.number(15); i++) {
        modifiers.push(mongoose.Types.ObjectId().toHexString());
    }
    const images = [];
    for (let i = 0; i < faker.random.number(3); i++) {
        images.push(faker.image.food());
    }
    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        siteId: faker.random.number().toString(),
        posNumber: faker.random.number(),
        genericArticleId: faker.random.number(),
        title: {
            fr: faker.commerce.productName(),
            en: faker.commerce.productName(),
        },
        shortDescription: {
            fr: faker.commerce.productName(),
            en: faker.commerce.productName(),
        },
        longDescription: {
            fr: faker.lorem.paragraph(),
            en: faker.lorem.paragraph(),
        },
        platformConfiguration: {
            [PlatformType.AtTable]: fakeWebArticlePlatformConfiguration(),
            [PlatformType.Delivery]: fakeWebArticlePlatformConfiguration(),
            [PlatformType.ClickAndCollect]: fakeWebArticlePlatformConfiguration(),
        },
        periods: fakeWeekdayTimePeriodsArray(7),
        images,
        isSuccess: true,
        isAvailableForSale: faker.random.boolean(),
        periodOfUnavailability: faker.random.objectElement<WebArticleUnavailabilityPeriod>(WebArticleUnavailabilityPeriod),
        vatRate: [{ value: faker.random.number(99), type: faker.random.objectElement<WebArticleVatType>(WebArticleVatType) }],
        type: faker.random.objectElement<WebArticleType>(WebArticleType),
        isFavorite: faker.random.boolean(),
        nutriScore: faker.random.arrayElement(['A', 'B', 'C', 'D', 'E']),
        modifiers,
        isSuggestion: faker.random.boolean(),
        calories: faker.random.number(1000),
    };
};

export const fakeWebArticle = (): WebArticle => new WebArticle(fakeWebArticleData());

export const fakeWebArticleArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebArticle[] => {
    const webArticle: WebArticle[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticle.push(fakeWebArticle());
    }

    return webArticle;
};
