import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApDropdown.style';
import { Menu, FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(style);

export type horizontalPlacementsType = 'center' | 'left' | 'right' | number;
export type verticalPlacementsType = 'bottom' | 'center' | 'top' | number;

interface Props {
    className?: string;
    parentElement: HTMLElement;
    onClickAway: () => void;
    children?: React.ReactNode;
    placement?: { horizontal: horizontalPlacementsType; vertical: verticalPlacementsType };
    group?: Group;
    onChange?: (newValue: Group) => void;
}

interface Group {
    label: string;
    items: Item[];
}

interface Item {
    label: string;
    checked: boolean;
}

export const ApDropdown = ({ className, parentElement, onClickAway, children, placement = { horizontal: 'right', vertical: 'top' }, group, onChange = () => {} }: Props) => {
    const classes = useStyles();
    const side = /right|left/.test(placement.horizontal as string) ? 'on-side' : 'on-heights';

    const handleChange = (itemId: number) => () => {
        group.items[itemId].checked = !group.items[itemId].checked;
        onChange(group);
    };

    return (
        <Menu
            className={`${side}
                    ${placement.horizontal === 'right' ? 'right-side' : ''}
                    ${placement.horizontal === 'left' ? 'left-side' : ''}
                    ${placement.horizontal === 'center' ? 'h-center' : ''}
                    ${placement.vertical === 'bottom' ? 'bottom' : ''}
                    ${placement.vertical === 'top' ? 'top' : ''}
                    ${placement.vertical === 'center' ? 'v-center' : ''}
                    ${className}
                    ${classes.root}`}
            getContentAnchorEl={null} // don't remove this, it's mandatory for custom positionning
            anchorEl={parentElement}
            open={Boolean(parentElement)}
            onClose={onClickAway}
            anchorOrigin={placement}
            transformOrigin={{
                horizontal: placement.horizontal !== 'center' ? (placement.horizontal === 'left' ? 'right' : 'left') : 'center',
                vertical: placement.vertical !== 'center' ? (placement.vertical === 'bottom' ? 'bottom' : 'top') : 'center',
            }}
            elevation={1}
            onWheel={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            {group
                ? group.items.map((item: Item, itemId: number) => (
                      <FormControlLabel
                          key={item.label}
                          onChange={handleChange(itemId)}
                          value={item.label}
                          checked={item.checked}
                          control={<Checkbox color="primary" />}
                          label={item.label}
                          labelPlacement="end"
                      />
                  ))
                : children}
        </Menu>
    );
};
