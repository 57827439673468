import * as React from 'react';

function SvgCheckCircle(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" id="check_circle_svg__a" />
            </defs>
            <use fill="currentColor" xlinkHref="#check_circle_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgCheckCircle;
