import * as faker from 'faker';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { UpdatePiclick } from './update-piclick';
import { fakePiclickV2Data } from '../piclick-v2/piclick-v2.data';

export const fakeUpdatePiclickData = (): UpdatePiclick => ({
    redirectionUrl: faker.internet.url(),
    cguUrl: faker.internet.url(),
    gdprUrl: faker.internet.url(),
    style: faker.lorem.paragraph(),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    loaderUrl: faker.internet.url(),
    logoUrl: faker.internet.url(),
    v2: fakePiclickV2Data(),
});

export const fakeUpdatePiclick = (): UpdatePiclick => new UpdatePiclick(fakeUpdatePiclickData());

export const fakeUpdatePiclickArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdatePiclick[] => {
    const delivery: UpdatePiclick[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeUpdatePiclick());
    }

    return delivery;
};
