import { Delivery } from './delivery';
import * as faker from 'faker';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { PriceType } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeDeliveryData = (): Delivery => ({
    timePeriods: fakeWeekdayTimePeriodsArray(),
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    maxOrderPerInterval: faker.random.number(300),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
    minOrderValue: faker.random.number(500),
});

export const fakeDelivery = (): Delivery => new Delivery(fakeDeliveryData());

export const fakeDeliveryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Delivery[] => {
    const delivery: Delivery[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeDelivery());
    }

    return delivery;
};
