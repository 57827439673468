import { UpdatePeriod } from './update-period';
import * as faker from 'faker';
import mongoose from 'mongoose';
import { fakeTimeSlotArray } from '../../time-slot';

export const fakeUpdatePeriodData = (): UpdatePeriod => {
    return {
        siteId: mongoose.Types.ObjectId().toHexString(),
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        days: Array(7).fill(false),
        timeSlots: fakeTimeSlotArray(),
    };
};

export const fakeUpdatePeriod = (): UpdatePeriod => new UpdatePeriod(fakeUpdatePeriodData());

export const fakeUpdatePeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdatePeriod[] => {
    const period: UpdatePeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        period.push(fakeUpdatePeriod());
    }

    return period;
};
