import { Uber } from './uber';
import * as faker from 'faker';

export const fakeUberData = (): Uber => ({
    enabled: faker.random.boolean(),
    locationId: faker.random.uuid(),
    posIntegrationEnabled: faker.random.boolean(),
});

export const fakeUber = (): Uber => new Uber(fakeUberData());

export const fakeUberArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Uber[] => {
    const uber: Uber[] = [];

    for (let i = 0; i < nbMax; i++) {
        uber.push(fakeUber());
    }

    return uber;
};
