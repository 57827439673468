// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
    production: false,
    translationBucket: 'development-translations',
    auth: {
        clientId: 'VirrzJmrBuOXcbaStDlGfspsfhc3k7Dz',
        domain: 'auth.demo.mypi.net',
        audiences: ['https://payments.aphilia.io'],
    },
    organizationsUrl: 'https://organizations.demo.mypi.net',
    paymentsServiceUrl: 'https://payments.demo.mypi.net',
    deliveriesServiceUrl: 'https://deliveries.demo.mypi.net',
    piclickUrl: 'https://piclick.demo.mypi.net',
    pusher: {
        clientKey: '1cb2e36b6ea9d41f7974',
        cluster: 'eu',
    },
};
