import { UberContextType } from '../../enum';

export class UberModifierGroupOverride {
    /**
     * Type of the context in which to override.
     */
    context_type: UberContextType;

    /**
     * Identifying string (id) for the specified context.
     */
    context_value: string;

    /**
     * A list of the identifying strings (ids) of all modifier groups associated with the item in this context.
     */
    ids: string[];

    constructor(props: Partial<UberModifierGroupOverride>) {
        Object.assign(this, props);
    }
}
