import { PlatformType } from '../../web-article';

class UpdateWebPricePeriodPrice {
    /**
     * The platform / order type price by period
     */
    price: number;
}

export class UpdateWebPricePeriod {
    /**
     * Indicates if current period is enabled
     */
    enabled?: boolean;

    /**
     * "delivery" PlatformType price by period
     */
    delivery?: UpdateWebPricePeriodPrice;

    /**
     * "atTable" PlatformType price by period
     */
    atTable?: UpdateWebPricePeriodPrice;

    /**
     * "clickAndCollect" PlatformType price by period
     */
    clickAndCollect?: UpdateWebPricePeriodPrice;

    constructor(props: UpdateWebPricePeriod) {
        Object.assign(this, props);
    }
}

export class UpdateWebPrice {
    /**
     * The related site ID which the web price is attached to
     */
    siteId: string;

    /**
     * The related article ID which the web price is attached to
     */
    articleId: string;

    /**
     * Platform types (object keys) prices (object values)
     */
    price?: Record<PlatformType, number>;

    /**
     * Platform types prices by periods (object main keys)
     */
    periods?: Record<string, UpdateWebPricePeriod>;

    constructor(props: UpdateWebPrice) {
        Object.assign(this, props);
    }
}
