import { WebArticleModifier } from './web-article-modifier';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { fakeWebArticleArray } from '../../web-article';

export const fakeWebArticleModifierData = (articles?: string[]): WebArticleModifier => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    posNumber: faker.random.number(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    articles: articles || fakeWebArticleArray().map((article) => article._id),
    quantityMin: faker.random.number(5),
    quantityMax: faker.random.number(10),
});

export const fakeWebArticleModifier = (articles?: string[]): WebArticleModifier => new WebArticleModifier(fakeWebArticleModifierData(articles));

export const fakeWebArticleModifierArray = (nbMax: number = faker.random.number({ min: 1, max: 20 }), articles?: string[]): WebArticleModifier[] => {
    const webArticleModifier: WebArticleModifier[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifier.push(fakeWebArticleModifier(articles));
    }

    return webArticleModifier;
};
