import React, { useCallback, useEffect, useMemo } from 'react';

const openLayerClass = 'ap-drawer-open';
const transitionClass = 'ap-drawer-open-transition';

let transitionTimer = null;

function useDrawerPanel(children, drawerLayer, onHide, isOpen) {
    const handleTransition = useCallback(() => {
        if (drawerLayer?.current && !drawerLayer?.current?.classList?.contains(transitionClass)) {
            drawerLayer.current.remove();
            drawerLayer.current = null;
        }
    }, [drawerLayer?.current]);

    const DrawerPanel = useMemo(() => {
        return <div className="ap-drawer-panel">{children}</div>;
    }, [children]);

    const handleHide = useCallback(
        (evt: MouseEvent) => {
            if ((evt.target as HTMLElement).classList.contains('ap-closer')) {
                if (typeof onHide === 'function') {
                    onHide();
                }
            }
        },
        [drawerLayer?.current, onHide]
    );

    useEffect(() => {
        if (isOpen) {
            drawerLayer?.current?.classList?.add(transitionClass);
        } else {
            drawerLayer?.current?.classList?.remove(transitionClass);
        }
        if (transitionTimer) {
            clearTimeout(transitionTimer);
            transitionTimer = null;
        }
        transitionTimer = setTimeout(() => {
            handleTransition();
        }, 500);
    }, [isOpen, drawerLayer?.current, handleTransition]);

    return { DrawerPanel, handleHide };
}

export { useDrawerPanel, transitionClass, openLayerClass };
