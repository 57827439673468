import { FSAction } from '@aphilia/shared/react';
import { StripeAccount } from '@aphilia/payments/types';
import * as Actions from './constant';

///////////////////////////////////
/////////// Account ///////////////
///////////////////////////////////
export function updateStripeAccount(account: StripeAccount): FSAction<keyof typeof Actions, StripeAccount> {
    return {
        type: Actions.UPDATE_STRIPE_ACCOUNT,
        payload: account,
    };
}

export function updateIsLoadingAccount(isLoading: boolean): FSAction<keyof typeof Actions, boolean> {
    return {
        type: Actions.UPDATE_IS_LOADING_ACCOUNT,
        payload: isLoading,
    };
}

export function updateStripeBalance(balance: number): FSAction<keyof typeof Actions, number> {
    return {
        type: Actions.UPDATE_STRIPE_BALANCE,
        payload: balance,
    };
}
