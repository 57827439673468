import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

export const i18n = i18next.use(LanguageDetector).use(initReactI18next).use(XHR);

export const uselessWordsList = {
    fr: [
        'le',
        'la',
        'les',
        "l'",
        'sa',
        'son',
        'ses',
        'un',
        'une',
        'de',
        'des',
        'et',
        'avec',
        'sans',
        'nos',
        'notre',
        'vos',
        'votre',
        'leur',
        'leurs',
        'ce',
        'ces',
        'cet',
        'cette',
        'qui',
        'que',
        'quoi',
        'quand',
        'comment',
        'mais',
        'ou',
        'et',
        'donc',
        'or',
        'ni',
        'car',
    ],
    en: [],
};

export const getLanguage = () => i18n.language && i18n.language.split('-')[0];

export const translate = (translationObject: string | { [key: string]: string }) => {
    if (!translationObject) return '';
    if (typeof translationObject === 'string') return translationObject;
    let language = getLanguage();
    const availableLanguages = Object.keys(translationObject);
    if (!availableLanguages.includes(language)) language = availableLanguages[0];
    return translationObject[language];
};

export const removeUselessWords = (sentence: string) => {
    if (!sentence) return;
    const wordList = sentence
        .toLowerCase()
        .split(new RegExp("\\s|'|-", 'g'))
        .filter((word) => word !== '');
    let language = getLanguage();
    const availableLanguages = Object.keys(uselessWordsList);
    if (!availableLanguages.includes(language)) language = availableLanguages[0];

    return wordList.filter((word) => word.length > 1 && uselessWordsList[language].filter((u: string) => u === word).length < 1);
};

export const checkArticleLabels = (webLabel: string, posLabel: string) => {
    if (!webLabel || !posLabel) return;
    const newWebLabel = removeUselessWords(webLabel);
    const newRegisterLabel = removeUselessWords(posLabel);

    return newWebLabel.filter((label) => newRegisterLabel.find((l) => label === l));
};
