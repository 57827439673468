import { PlatformType } from '../../web-article';

class WebPricePeriodPrice {
    /**
     * The platform / order type price by period
     */
    price: number;
}

export class WebPricePeriod {
    /**
     * Indicates if current period is enabled
     */
    enabled?: boolean;

    /**
     * "delivery" PlatformType price by period
     */
    delivery?: WebPricePeriodPrice;

    /**
     * "atTable" PlatformType price by period
     */
    atTable?: WebPricePeriodPrice;

    /**
     * "clickAndCollect" PlatformType price by period
     */
    clickAndCollect?: WebPricePeriodPrice;

    constructor(props: WebPricePeriod) {
        Object.assign(this, props);
    }
}

export class WebPrice {
    /**
     * The related site ID which the web price is attached to
     */
    siteId: string;

    /**
     * The related article ID which the web price is attached to
     */
    articleId: string;

    /**
     * Platform types (object keys) prices (object values)
     */
    price: Record<PlatformType, number>;

    /**
     * Platform types prices by periods (object main keys)
     */
    periods: Record<string, WebPricePeriod>;

    constructor(props: WebPrice) {
        Object.assign(this, props);
    }
}
