import { Moment } from 'moment';

export class StuartJob {
    /**
     * A datetime (ISO 8601) indicating when you want the package to be picked.
     * Must be between the current time and 30 days in the future.
     * Not to be specified if you already specify a dropoff_at.
     */
    pickup_at?: string;

    dropoff_at?: string;

    assignment_code?: string;

    transport_type: string;

    pickups: {
        address: string;
        comment?: string;
        contact?: {
            firstname?: string;
            lastname?: string;
            phone?: string;
            email?: string;
            company?: string;
        };
        access_codes?: {
            code: string;
            type: 'text' | 'qr_text' | 'barcode_128';
            title?: string;
            instructions?: string;
        };
    }[];

    dropoffs: {
        package_type?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
        client_reference?: string;
        address: string;
        comment?: string;
        contact?: {
            firstname?: string;
            lastname?: string;
            phone?: string;
            email?: string;
            company?: string;
            comment?: string;
        };
    }[];

    constructor(props: StuartJob) {
        Object.assign(this, props);
    }
}
