export class UberTimePeriod {
    /**
     * The time at which the menu becomes available, in 24-hour HH:MM format, e.g. “08:30”, “23:00”
     */
    start_time: string;

    /**
     * The time at which the menu ceases to be available, in 24-hour HH:MM format, e.g. “08:30”, “23:00”
     */
    end_time: string;

    constructor(timePeriod: Partial<UberTimePeriod>) {
        Object.assign(this, timePeriod);
    }
}
