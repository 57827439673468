import * as faker from 'faker';
import moment from 'moment';
import { TimePeriod } from './time-period';

export const fakeTimePeriodData = (): TimePeriod => ({
    startTime: moment().format('HH:mm'),
    endTime: moment().add(3, 'h').format('HH:mm'),
});

export const fakeTimePeriod = (): TimePeriod => new TimePeriod(fakeTimePeriodData());

export const fakeTimePeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): TimePeriod[] => {
    const timePeriod: TimePeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        timePeriod.push(fakeTimePeriod());
    }

    return timePeriod;
};
