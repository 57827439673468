import React from 'react';

import { operatorsConfig } from './components';
import { BASE_CELL_WIDTH, draggableRowIdPattern, HEADER_CELL_SLOT_PREFIX, ROW_CLONE_WIDTH, ROW_SLOT_PREFIX } from './config';
import { ApColumnData, ApColumnFilter, ApOperatorConfig, ApOperatorTradConfig } from './types';

// Cell
function getCellWidth(ratio: number, base: number): string {
    if (typeof ratio === 'number') {
        return `${ratio * base}px`;
    }
    return 'auto';
}

function getClassFromCellType(cellType: string): string {
    if (!!cellType) {
        return `ap-${cellType}-cell`;
    }
    return '';
}

function getAncestorElementByClass(element: HTMLElement, className: string) {
    if (element) {
        if (element.classList.contains(className)) {
            return element;
        }
        return getAncestorElementByClass(element.parentElement, className);
    }
    return null;
}

function getCellFromChildren(element: HTMLElement) {
    return getAncestorElementByClass(element, 'ap-table-cell');
}

// Header cell
function renderHeaderCellClone(columns) {
    return function (provided, snapshot, rubric) {
        const { label, cellRatio } = columns[rubric.source.index] ?? {};
        const { draggableProps, dragHandleProps, innerRef } = provided ?? {};

        const cloneStyles = Object.assign({ width: getCellWidth(cellRatio, BASE_CELL_WIDTH) }, { ...draggableProps.style });

        return (
            <div {...draggableProps} {...dragHandleProps} style={cloneStyles} className="ap-header-cell-clone" ref={innerRef}>
                {label}
            </div>
        );
    };
}

function getHeaderCellSlotName(key: string, prefix = HEADER_CELL_SLOT_PREFIX): string {
    return `${prefix}${key}`;
}

function getContainerElementForClone(tableId) {
    return function () {
        return document.getElementById(`${tableId}-header-row`);
    };
}

// Body
function getBodyStyles(contentRows) {
    const rowsSlots = contentRows.map((row) => {
        if (typeof row === 'object' && Reflect.has(row, 'value')) {
            row = row.value;
        }
        return `[${ROW_SLOT_PREFIX}${row}] 2.5rem`;
    });
    return `
    .ap-table-body {
        grid-template-rows: ${rowsSlots.join(' ')} [body-row-placeholder] 1fr;
    }
    `;
}

// Row
function renderRowClone(columnKey, rows, selectedRows) {
    return function (provided, snapshot, rubric) {
        const dataMatch = rubric.draggableId.match(draggableRowIdPattern);
        const draggedRow = rows?.get(dataMatch[1]) ?? {};

        const label = selectedRows.length ? `${selectedRows.length} selected rows` : draggedRow?.[columnKey]?.value ?? '';
        const { draggableProps, dragHandleProps, innerRef } = provided ?? {};

        const cloneStyles = Object.assign({ ...draggableProps.style }, { width: ROW_CLONE_WIDTH });

        return (
            <div {...draggableProps} {...dragHandleProps} style={cloneStyles} className="ap-row-clone" ref={innerRef}>
                {label}
            </div>
        );
    };
}

// Column
function splitColumnsByStickyProp(columns: ApColumnData[]) {
    const stickyColumns = columns.filter((col) => col.isSticky);
    const nonStickyColumns = columns.filter((col) => !col.isSticky);

    return { stickyColumns, nonStickyColumns };
}

// Filtering
function getFiltersFunction(filters: ApColumnFilter[], config) {
    const filtersFunctions = [];
    filters.forEach((filter) => {
        const { columnKey, value, valueType, operator } = filter;

        if (config[valueType] && config[valueType][operator]) {
            const filterFunction = config[valueType][operator]?.function;
            if (filterFunction) {
                filtersFunctions.push(filterFunction(columnKey, value));
            }
        }
    });

    return filtersFunctions;
}

function getFilteredData(data: any[], filters: ApColumnFilter[], config = operatorsConfig) {
    const filterFunctions = getFiltersFunction(filters, config);
    let filteredData = data;
    filterFunctions.forEach((fFunc) => {
        filteredData = filteredData.filter(fFunc);
    });

    return filteredData;
}

function getOperatorKey(filter: ApColumnFilter, config: ApOperatorConfig = operatorsConfig): string {
    const { valueType, operator } = filter;
    if (config[valueType] && config[valueType][operator]) {
        return config[valueType][operator].displayKey;
    }
    return '';
}

function mergeOperatorsConfig(operatorsTrads: ApOperatorTradConfig, config: ApOperatorConfig) {
    if (!operatorsTrads || !config) return {};
    return Object.keys(config).reduce((acc, opType) => {
        const currentConfig = config[opType];
        acc[opType] = Object.keys(currentConfig).reduce((opAcc, operator) => {
            const currentOperator = currentConfig[operator];
            if (Reflect.has(currentOperator, 'tradId')) {
                opAcc[operator] = Object.assign({}, currentOperator, { ...operatorsTrads[currentOperator.tradId] });
            } else {
                opAcc[operator] = currentOperator;
            }
            return opAcc;
        }, {});
        return acc;
    }, {});
}

export {
    getCellWidth,
    getClassFromCellType,
    getCellFromChildren,
    getAncestorElementByClass,
    getFilteredData,
    getOperatorKey,
    renderRowClone,
    renderHeaderCellClone,
    getContainerElementForClone,
    splitColumnsByStickyProp,
    getHeaderCellSlotName,
    getBodyStyles,
    mergeOperatorsConfig,
};
