import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { createClassString } from '@aphilia/shared/utils';
import { ApNoDataMessage } from './ApNoDataMessage';
import { ApTableRow } from './ApTableRow';
import { ApCellData, ApCellPosition, ApColumnData } from '../../types';
import { END_STICKY_ID, ROW_SLOT_PREFIX } from '../../config';
import { getBodyStyles } from '../../utils';

interface ApTableBodyElementProps {
    // Required
    columns: ApColumnData[];
    rows: Map<string, ApCellData>;
    tableId: string;

    // Optional
    isSelectable?: boolean;

    noDataMessage?: string;
    isSticky?: boolean;
    bodyRef?: any;

    hoveredRow?: string;
    selectedRows?: string[];
    disabledRows?: string[];
    hasDnDContext?: boolean;
    rowsType?: string;
    draggingRowIndex?: number;
    hasCheck?: boolean;
    onCheck?: (key: string) => (evt) => void;
    onRow?: (key: string, value: any) => (evt) => void;
    onHoverRow?: (key: string, disabledRow) => () => void;
    cellsAreEditable?: boolean;
    onCellDoubleClick?: (rowId: string, column: string) => void;
    onCellEdition?: (rowId: string, key: string, value: any, valueType?: string) => void;
    onSelectClose?: () => void;
    editableCell?: ApCellPosition;
}

export function ApTableBodyElement({
    isSelectable,
    columns,
    noDataMessage,
    isSticky,
    bodyRef,
    rows,
    hoveredRow,
    selectedRows,
    disabledRows,
    hasDnDContext,
    rowsType,
    draggingRowIndex,
    hasCheck,
    onCheck,
    onRow,
    onHoverRow,
    cellsAreEditable,
    onCellDoubleClick,
    onCellEdition,
    onSelectClose,
    editableCell,
    tableId = '',
}: ApTableBodyElementProps) {
    const tableBodyClasses = createClassString(['ap-table-body', [isSelectable, 'ap-table-selectable'], [isSticky, 'ap-sticky']]);

    const contentRows = Array.from(rows.keys());

    return (
        <div className={tableBodyClasses} ref={bodyRef}>
            <style>{getBodyStyles(contentRows)}</style>
            {rows.size > 0 ? (
                contentRows.map((row, rowIndex) => {
                    const isSelected = selectedRows?.includes(row);
                    const isDisabled = disabledRows?.includes(row);
                    const rowKey = `row-${rowIndex}`;

                    if (hasDnDContext) {
                        if (draggingRowIndex === rowIndex) {
                            return null;
                        }
                        return (
                            <Draggable
                                draggableId={`${tableId ? `${tableId}-` : ''}draggable-row-${row}${isSticky ? END_STICKY_ID : ''}`}
                                index={rowIndex}
                                isDragDisabled={isDisabled}
                            >
                                {(provided, snapshot) => {
                                    const { isDragging } = snapshot;
                                    const dndProps = {
                                        ...provided.draggableProps,
                                        ...provided.dragHandleProps,
                                        isDragging,
                                        type: rowsType,
                                        ref: provided.innerRef,
                                    };

                                    return (
                                        <ApTableRow
                                            key={rowKey}
                                            rowId={row}
                                            rows={rows}
                                            contentColumns={columns}
                                            hoveredRow={hoveredRow}
                                            isSelected={isSelected}
                                            isSelectable={isSelectable}
                                            isDisabled={isDisabled}
                                            rowIndex={rowIndex}
                                            onCheck={onCheck}
                                            onRow={onRow}
                                            onHoverRow={onHoverRow}
                                            selectedRows={selectedRows}
                                            dndProps={dndProps}
                                            onCellDoubleClick={onCellDoubleClick}
                                            onCellEdition={onCellEdition}
                                            onSelectClose={onSelectClose}
                                            editableCell={editableCell}
                                            hasCheck={hasCheck}
                                            cellsAreEditable={cellsAreEditable}
                                        />
                                    );
                                }}
                            </Draggable>
                        );
                    } else {
                        return (
                            <ApTableRow
                                key={rowKey}
                                rowId={row}
                                rows={rows}
                                contentColumns={columns}
                                hoveredRow={hoveredRow}
                                isSelected={isSelected}
                                isSelectable={isSelectable}
                                isDisabled={isDisabled}
                                rowIndex={rowIndex}
                                onCheck={onCheck}
                                onRow={onRow}
                                onHoverRow={onHoverRow}
                                onCellDoubleClick={onCellDoubleClick}
                                onCellEdition={onCellEdition}
                                onSelectClose={onSelectClose}
                                editableCell={editableCell}
                                hasCheck={hasCheck}
                                cellsAreEditable={cellsAreEditable}
                            />
                        );
                    }
                })
            ) : (
                <ApNoDataMessage message={noDataMessage} />
            )}
        </div>
    );
}
