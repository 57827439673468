export class CreateStuart {
    /**
     * Example property of the entity
     */
    name: string;

    constructor(props: CreateStuart) {
        Object.assign(this, props);
    }
}
