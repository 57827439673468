import React, { useMemo, useState } from 'react';
import { ApCol, ApGrid, ApMainPage, ApRow, ApSpinner, ApTabs } from '@aphilia/shared-ui-core-react';
import { useDeliveryParametersContext } from '@aphilia/deliveries/feature-parameters';
import { DeliveryParametersApplicationsUberPage } from './components/uber';
import { DeliveryParametersApplicationsStuartPage } from './components/stuart';
import { DeliveryParametersPage } from './components/parameters';
import { DeliveryParametersApplicationsPiClickPage } from './components/piclick';
import { DeliveryParametersApplicationsDeliverooPage } from './components/deliveroo';

export const WebcommandePage = () => {
    const { isLoadingDeliveryParameters, deliveryParameters } = useDeliveryParametersContext();
    const [activeTab, setActiveTab] = useState<number>(0);
    const tabs = [
        {
            label: 'Paramètres',
            icon: '',
            index: 0,
            isActive: true,
        },
        {
            label: 'Applications',
            icon: '',
            index: 1,
            isActive: true,
        },
    ];

    const getTabContent = useMemo(() => {
        switch (activeTab) {
            case 0:
                return <DeliveryParametersPage />;
            case 1:
                return (
                    <ApRow>
                        <ApCol size={6}>
                            <DeliveryParametersApplicationsPiClickPage />
                        </ApCol>
                        <ApCol size={6}>
                            <DeliveryParametersApplicationsUberPage />
                        </ApCol>
                        <ApCol size={6}>
                            <DeliveryParametersApplicationsDeliverooPage />
                        </ApCol>
                        <ApCol size={6}>
                            <DeliveryParametersApplicationsStuartPage />
                        </ApCol>
                    </ApRow>
                );
        }
    }, [activeTab]);

    const getContent = useMemo(() => {
        if (isLoadingDeliveryParameters) {
            return <ApSpinner />;
        } else if (!deliveryParameters?.enabled) {
            return <p>Le module des webcommandes n'est pas activé pour le moment. Veuillez l'activer depuis le BO mypi afin de le paramétrer.</p>;
        } else if (deliveryParameters?.siteId) {
            return (
                <ApMainPage pageTitle="Webcommandes" pageSubtitle="Gérer tous les paramètres pour la webcommandes">
                    <ApGrid>
                        <ApRow>
                            <ApTabs tabs={tabs} currentTab={activeTab} onClickOnTab={(res) => setActiveTab(res.detail.index)} />
                        </ApRow>
                        {getTabContent}
                    </ApGrid>
                </ApMainPage>
            );
        }
    }, [isLoadingDeliveryParameters, deliveryParameters?.siteId, activeTab, tabs]);

    return <>{getContent}</>;
};
