import { OrderCancelledBy, OrderState } from '../order/order';

export enum DenyOrCancelOrderReason {
    OutOfItems = 'OUT_OF_ITEMS',
    ItemIdMismatch = 'ITEM_ID_MISMATCH',
    KitchenClosed = 'KITCHEN_CLOSED',
    CustomerCalledToCancel = 'CUSTOMER_CALLED_TO_CANCEL',
    RestaurantTooBusy = 'RESTAURANT_TOO_BUSY',
    Other = 'OTHER',
    LocationOffline = 'LOCATION_OFFLINE',
    PriceMismatch = 'PRICE_MISMATCH',
    CommentWithPrice = 'COMMENT_WITH_PRICE',
}

export enum DenyOrCancelOrderReasonStuart {
    duplicate_job = 'duplicate_job',
    customer_cancellation_requested = 'customer_cancellation_requested',
    pu_closed = 'pu_closed',
    address_error = 'address_error',
    no_supply = 'no_supply',
    wrong_transport_type = 'wrong_transport_type',
    incorrect_package = 'incorrect_package',
    technical_issue = 'technical_issue',
    courier_issue = 'courier_issue',
    package_not_ready = 'package_not_ready',
    package_damaged = 'package_damaged',
    other = 'other',
    job_expired = 'job_expired',
}

export class UpdateOrderState {
    /**
     * Id of the order
     */
    _id: string;

    /**
     * The new state of the order
     */
    state?: OrderState;

    /**
     * The reason why the order was denied or cancelled
     */
    denyOrCancelOrderReason?: DenyOrCancelOrderReason | DenyOrCancelOrderReasonStuart;

    /**
     * The reason why the order was denied or cancelled if it was other that was selected in the list of reason to deny or cancelled
     */
    otherDenyOrCancelReason?: string;

    /**
     * Indicates who cancelled the orders
     */
    cancelledBy?: OrderCancelledBy;

    constructor(props: UpdateOrderState) {
        Object.assign(this, props);
    }
}
