export class UpdateArticlesWebArticleCategory {
    /**
     * List of all the articles' additions' id to add to the category
     */
    additions: string[];

    /**
     * Id of the site the category belongs to
     */
    siteId: string;

    constructor(props: UpdateArticlesWebArticleCategory) {
        Object.assign(this, props);
    }
}
