import { WebArticleModifierDescriptiveSheet } from './web-article-modifier-descriptive-sheet';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleModifierType } from '../../web-article-modifier';

export const fakeWebArticleModifierDescriptiveSheetData = (): WebArticleModifierDescriptiveSheet => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    brandId: mongoose.Types.ObjectId().toHexString(),
    siteId: mongoose.Types.ObjectId().toHexString(),
    modifierNumber: faker.random.number(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    shortDescription: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    type: faker.random.objectElement<WebArticleModifierType>(WebArticleModifierType),
});

export const fakeWebArticleModifierDescriptiveSheet = (): WebArticleModifierDescriptiveSheet =>
    new WebArticleModifierDescriptiveSheet(fakeWebArticleModifierDescriptiveSheetData());

export const fakeWebArticleModifierDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebArticleModifierDescriptiveSheet[] => {
    const webArticleModifierDescriptiveSheet: WebArticleModifierDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifierDescriptiveSheet.push(fakeWebArticleModifierDescriptiveSheet());
    }

    return webArticleModifierDescriptiveSheet;
};
