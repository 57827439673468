import { CreateOrder } from './create-order';
import moment from 'moment';
import * as faker from 'faker';
import { OrderState, OrderType, DeliveryPlatform } from '../order/order';
import { fakePayment, fakeCreateCustomer } from '../..';
import { fakeCreateItemArray } from '../item/create-item/create-item.data';
import { fakeOrderDeliveryData } from '../order-delivery';
import { fakePromotionArray } from '../promotion';

export const fakeCreateOrderData = (): CreateOrder => ({
    siteId: faker.random.uuid(),
    platformId: faker.random.uuid(),
    shortId: faker.random.alphaNumeric(5),
    state: OrderState.Created,
    estimatedReadyForPickupAt: moment(faker.date.future().toISOString()),
    deliveryPlatform: faker.random.objectElement<DeliveryPlatform>(DeliveryPlatform),
    type: faker.random.objectElement<OrderType>(OrderType),
    asap: faker.random.boolean(),
    items: fakeCreateItemArray(),
    payment: fakePayment(),
    customer: fakeCreateCustomer(),
    updatedAt: moment(),
    createdAt: moment(),
    promotions: fakePromotionArray(),
    delivery: fakeOrderDeliveryData(),
});

export const fakeCreateOrder = (): CreateOrder => new CreateOrder(fakeCreateOrderData());

export const fakeCreateOrderArray = (nbMax: number = faker.random.number({ min: 1, max: 5 })) => {
    const createOrders = [];

    for (let i = 0; i < nbMax; i++) {
        createOrders.push(fakeCreateOrder());
    }

    return createOrders;
};
