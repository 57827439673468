export class UberClassifications {
    /**
     * Indicates whether the item can be served on its own.
     * This has implications in alcohol markets because in certain cases, alcohol must be sold with an entree (it cannot be sold alone).
     * In that case, we want to know whether each item can qualify as an entree.
     * Alcoholic items that also qualify as entrees (e.g. beer braised chicken) can be sold alone.
     * If an item is not alcoholic, we still want to know if it’s considered an entree so we can determine if
     * for example, the eater can buy a non-entree alcohol item and combine it with this one.
     */
    can_serve_alone?: boolean;

    /**
     * Indicates whether an item is vegetarian.
     *
     * A value of null or false indicates that the item is not vegetarian
     */
    is_vegetarian?: boolean;

    /**
     * Indicates if an item is alcoholic, and if so, how much alcohol content there is.
     * For example, an item “six-pack beer” should have alcoholic_items set to 6.
     * A value of null or 0 indicates that the item is non-alcoholic. This field is only used in whitelisted alcohol markets.
     */
    alcoholic_items?: number;

    constructor(props: Partial<UberClassifications>) {
        Object.assign(this, props);
    }
}
