export class OrderDeliveryPrice {
    /**
     * The amount of the delivery in cents
     */
    amount: number;

    /**
     * The currency of the delivery price
     */
    currency: string;

    constructor(props: OrderDeliveryPrice) {
        Object.assign(this, props);
    }
}
