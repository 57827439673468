import React, { useRef } from 'react';

import { ApIcon } from '@aphilia/shared-ui-core-react';
import { createClassString } from '@aphilia/shared/utils';
import { ApColumnSort, ApHoveredCell } from '../../types';
import { useColumnSort, useContent } from './hooks';
import { BASE_CELL_WIDTH } from '../../config';
import { getCellWidth, getHeaderCellSlotName } from '../../utils';

const sortIcon = {
    [ApColumnSort.NONE]: 'unfold_more',
    [ApColumnSort.ASC]: 'arrow_downward',
    [ApColumnSort.DESC]: 'arrow_upward',
};

interface ApTableHeaderCellProps extends React.ComponentPropsWithoutRef<'th'> {
    // Required

    // Optional
    cellData?: any;
    cellKey?: string;
    isStickyPart?: boolean;
    component?: React.ReactNode;
    isSortBy?: string;
    hasSortOrder?: ApColumnSort;
    cellRef?: any;
    hasDnDContext?: boolean;
    draggableProps?: any;
    dragHandleProps?: any;
    draggableSnapshot?: any;
    hoveredHeaderCell?: ApHoveredCell;
    headerCellIndex?: number;
    scrollRef?: any;

    cellRatio?: number;

    onSortBy?: (columnKey: string) => void;
    onSortOrder?: (columnOrder: ApColumnSort) => void;
}

export function ApTableHeaderCell({
    cellData,
    cellKey,
    isStickyPart,
    component,
    isSortBy,
    hasSortOrder,
    className,
    cellRef,
    hasDnDContext,
    draggableProps,
    dragHandleProps,
    draggableSnapshot,
    hoveredHeaderCell,
    headerCellIndex,
    scrollRef,
    cellRatio,
    onSortBy,
    onSortOrder,
}: ApTableHeaderCellProps) {
    const { handleClick } = useColumnSort(isSortBy, cellData?.isSortable, onSortBy, onSortOrder);
    const { renderContent } = useContent(cellData, component);

    const insertionRef = useRef(null);

    const activeSort = !!cellData?.dataKey ? isSortBy === cellData?.dataKey : false;
    const currentSortIcon = activeSort ? sortIcon[hasSortOrder] : sortIcon[ApColumnSort.NONE];

    const isHoveredCell = headerCellIndex === hoveredHeaderCell?.cellIndex && isStickyPart === hoveredHeaderCell?.isSticky;

    let isDragging = false;
    if (draggableSnapshot) {
        isDragging = draggableSnapshot.isDragging;
    }

    const headerCellClasses = createClassString([
        'ap-table-header-cell',
        [className, className],
        [cellData?.isSortable, 'ap-sortable'],
        [activeSort, 'ap-sort-active'],
        [!isDragging && isHoveredCell, 'ap-header-cell-hovered'],
    ]);

    const ratio = cellRatio ?? cellData?.cellRatio;
    const key = cellData?.dataKey ?? cellKey;

    const cellStyles = {
        width: getCellWidth(ratio, BASE_CELL_WIDTH),
        gridColumn: `${getHeaderCellSlotName(key)}`,
    };

    const insertionClasses = createClassString(['ap-insertion-point', [isHoveredCell, 'ap-hovered']]);

    if (isHoveredCell && insertionRef?.current && scrollRef?.current) {
        insertionRef.current.style.height = `${scrollRef?.current?.clientHeight}px`;
    }

    return (
        <div {...draggableProps} {...dragHandleProps} ref={cellRef} className={headerCellClasses} style={cellStyles} onPointerUp={handleClick(cellData?.dataKey, isDragging)}>
            {hasDnDContext && !isDragging && <span className={insertionClasses} ref={insertionRef} />}
            {renderContent()}
            {cellData?.isSortable && <ApIcon className="sort-icon" icon={currentSortIcon} color={activeSort ? 'primary' : 'neutral'} hover={false} size="small" />}
            {/*hasDnDContext && <ApIcon className="drag-icon" icon="more_vert" color="neutral" hover={false} size="small" />*/}
        </div>
    );
}
