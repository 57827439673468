import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApFilterList.style';

const useStyles = makeStyles(style);

interface Props {
    list: string[];
    onChange: Function;
    className?: string;
    checked: string[];
}

export const ApFilterList = ({ list, onChange, className, checked }: Props) => {
    const classes = useStyles();
    const handleToggle = (item: string) => () => {
        const currentIndex = checked.indexOf(item);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        onChange(newChecked);
    };

    return (
        <List className={`filter-list ${className} ${classes.root}`}>
            {list.map((item) => (
                <ListItem key={item} button onClick={handleToggle(item)} component="li" className={checked.includes(item) ? 'checked-item' : ''}>
                    <ListItemText id={item} primary={item} />
                    <Checkbox checked={checked.includes(item)} color="primary" />
                </ListItem>
            ))}
        </List>
    );
};
