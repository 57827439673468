import moment from 'moment';

export enum Weekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

/**
 * Return the string of the weekday depending on the number.
 *
 * @param weekday
 * @param locale
 */
export const numberWeekdayToString = (weekday: number, locale: string = 'en'): string => {
    if (weekday < 1 || weekday > 7) {
        throw new Error('Weekday must be between 1 and 7');
    }

    return moment().isoWeekday(weekday).locale(locale).format('dddd');
};

export function isCurrentDayInWeekdays(weekdays, currentDay): boolean {
    if (Array.isArray(weekdays)) {
        return weekdays.includes(currentDay);
    }
    return weekdays === currentDay;
}
