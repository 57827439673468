import { ApActionButtons, ApCard, ApCol, ApInput, ApRow, ApSelect } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripeContext } from '../../store/stripe-context';
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import { useForm, Controller } from 'react-hook-form';
import Stripe from 'stripe';
import { countryListAllIsoData } from '@aphilia/shared/utils';
import './business-address-settings.scss';

const schema = Joi.object({
    city: Joi.string().min(1).max(40),
    country: Joi.string().min(2).max(2),
    line1: Joi.string().max(50),
    line2: Joi.string().allow(null, '').max(50),
    postal_code: Joi.string().min(4).max(10),
    state: Joi.string().min(2).max(50),
});

export const BusinessAddressSettings = () => {
    const { account, updateAccount } = useStripeContext();
    const { t } = useTranslation();
    const [address, setAddress] = useState<Stripe.AccountAddressParam>();
    const {
        formState: { errors },
        setValue,
        control,
        trigger,
    } = useForm({
        resolver: joiResolver(schema),
    });
    const [isUpdatingAccount, setIsUpdatingAccount] = useState<boolean>(false);

    useEffect(() => {
        resetBusinessProfile();
    }, [account?.business_profile]);

    const resetBusinessProfile = useCallback(() => {
        setAddress(account?.business_profile?.support_address);
    }, [account?.business_profile?.support_address]);

    const updateAddress = useCallback(
        (key: string, value: string) => {
            setAddress({
                ...address,
                [key]: value,
            });
            setValue(key, value);
            trigger();
        },
        [address]
    );

    const submit = useCallback(async () => {
        try {
            setIsUpdatingAccount(true);
            await updateAccount({
                business_profile: {
                    support_address: address,
                },
                site_id: account.site_id,
                brand_id: account.brand_id,
            });
            setIsUpdatingAccount(false);
        } catch (e) {
            setIsUpdatingAccount(false);
            console.error(e);
            throw e;
        }
    }, [address, account?.site_id, account?.brand_id]);

    useEffect(() => {
        if (address) Object.keys(address).forEach((key) => setValue(key, address[key]));
    }, [address]);

    return (
        <ApCard elevation={1} headerTitle={t('payments.settings.address.form.title')} className="business-address-form">
            <Controller
                control={control}
                render={({ field }) => (
                    <ApInput
                        {...field}
                        color={errors?.line1?.message ? 'error' : undefined}
                        value={address?.line1}
                        onInputBlur={(e) => updateAddress('line1', e.detail.target.value)}
                        label={t('payments.settings.address.form.line1')}
                    />
                )}
                name="line1"
            />
            <Controller
                control={control}
                render={({ field }) => (
                    <ApInput
                        {...field}
                        color={errors?.line2?.message ? 'error' : undefined}
                        value={address?.line2}
                        onInputBlur={(e) => updateAddress('line2', e.detail.target.value)}
                        label={t('payments.settings.address.form.line2')}
                    />
                )}
                name="line2"
            />
            <ApRow>
                <ApCol>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <ApInput
                                {...field}
                                color={errors?.city?.message ? 'error' : undefined}
                                value={address?.city}
                                onInputBlur={(e) => updateAddress('city', e.detail.target.value)}
                                label={t('payments.settings.address.form.city')}
                            />
                        )}
                        name="city"
                    />
                </ApCol>
                <ApCol>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <ApInput
                                {...field}
                                color={errors?.postal_code?.message ? 'error' : undefined}
                                value={address?.postal_code}
                                onInputBlur={(e) => updateAddress('postal_code', e.detail.target.value)}
                                label={t('payments.settings.address.form.postalCode')}
                            />
                        )}
                        name="postal_code"
                    />
                </ApCol>
            </ApRow>
            <ApRow>
                <ApCol>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <ApInput
                                {...field}
                                color={errors?.state?.message ? 'error' : undefined}
                                value={address?.state}
                                onInputBlur={(e) => updateAddress('state', e.detail.target.value)}
                                label={t('payments.settings.address.form.state')}
                            />
                        )}
                        name="state"
                    />
                </ApCol>
                <ApCol>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <ApSelect
                                {...field}
                                value={[address?.country]}
                                options={countryListAllIsoData.map((country) => ({
                                    label: country.name,
                                    value: country.code,
                                }))}
                                color={errors?.country?.message ? 'error' : undefined}
                                onItemSelected={(e) => updateAddress('country', e.detail.value)}
                                label={t('payments.settings.address.form.country')}
                            />
                        )}
                        name="country"
                    />
                </ApCol>
            </ApRow>
            <ApActionButtons
                disabled={account?.business_profile?.support_address === address || Object.keys(errors).length > 0}
                confirmText={t('payments.settings.company.form.confirm')}
                cancelText={t('payments.settings.company.form.cancel')}
                onApCancel={resetBusinessProfile}
                onApConfirm={() => submit()}
                isLoading={isUpdatingAccount}
            />
        </ApCard>
    );
};
