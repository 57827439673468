import { DeliverySiteStatus } from '../delivery-parameters/delivery';

export class CreateDeliveryParameters {
    /**
     * The status of the delivery for the site.
     *
     * @default paused
     */
    status?: DeliverySiteStatus = DeliverySiteStatus.Paused;

    /**
     * Indicates if the site has enabled the delivery
     */
    hasPilink = false;

    constructor(props: CreateDeliveryParameters) {
        Object.assign(this, props);
    }
}
