export class DeliverooModifier {
    /**
     * Unique identifier for the modifier.
     */
    id: string;

    /**
     * Translated name of the modifier.
     */
    name: Object;

    /**
     * Translated description of the modifier.
     */
    description?: Object;

    /**
     * Minimum number of items the consumer can choose from the modifier.
     */
    min_selection: number;

    /**
     * Maximum number of items the consumer can choose from the modifier.
     */
    max_selection: number;

    /**
     * Describes whether a consumer can choose the same item inside a modifier group multiple times.
     */
    repeatable: boolean;

    /**
     * IDs of the items inside the modifier.
     */
    item_ids: string[];

    constructor(props: DeliverooModifier) {
        Object.assign(this, props);
    }
}
