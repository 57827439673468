import { Site } from '@aphilia/shared/organizations/types';
import axios from 'axios';

/**
 * Get the list of all the sites the user has accessed to
 */
export const getSitesByBrandId = async (organizationUrl: string, brandId: string): Promise<Site[]> => {
    try {
        return (await axios.get<Site[]>(`${organizationUrl}/sites/brand/${brandId}`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return the list of all the sites the user has accessed to
 *
 * @param organizationsUrl
 */
export const getSitesByConnectedUser = async (organizationsUrl: string): Promise<Site[]> => {
    try {
        return (await axios.get<Site[]>(`${organizationsUrl}/sites/me`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
