import { CreateDeliveroo } from './create-deliveroo';
import * as faker from 'faker';

export const fakeCreateDeliverooData = (): CreateDeliveroo => ({
    name: faker.name.findName(),
});

export const fakeCreateDeliveroo = (): CreateDeliveroo => new CreateDeliveroo(fakeCreateDeliverooData());

export const fakeCreateDeliverooArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateDeliveroo[] => {
    const deliveroo: CreateDeliveroo[] = [];

    for (let i = 0; i < nbMax; i++) {
        deliveroo.push(fakeCreateDeliveroo());
    }

    return deliveroo;
};
