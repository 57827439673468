import * as faker from 'faker';
import { QueryOrder } from './query-order';
import { DeliveryPlatform, OrderState, OrderType } from '../order/order';
import moment from 'moment';

export const fakeQueryOrderData = (): QueryOrder => ({
    siteId: faker.random.uuid(),
    ids: [faker.random.uuid()],
    state: [faker.random.objectElement<OrderState>(OrderState), faker.random.objectElement<OrderState>(OrderState)],
    type: [faker.random.objectElement<OrderType>(OrderType), faker.random.objectElement<OrderType>(OrderType)],
    deliveryPlatform: [faker.random.objectElement<DeliveryPlatform>(DeliveryPlatform), faker.random.objectElement<DeliveryPlatform>(DeliveryPlatform)],
    search: faker.random.word(),
    startDateTime: moment(faker.date.recent().toISOString()),
    endDateTime: moment(faker.date.future().toISOString()),
    page: 1,
    limit: 10,
});

export const fakeQueryOrder = (): QueryOrder => new QueryOrder(fakeQueryOrderData());
