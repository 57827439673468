function booleanFromString(value: string): boolean {
    const cleanValue = value.trim().toLowerCase();

    if (!cleanValue) {
        return false;
    } else if (cleanValue === 'false') {
        return false;
    } else {
        return true;
    }
}

export { booleanFromString };
