import { BillPaymentEventStatus, PaymentStatus } from '@aphilia/payments/types';
import i18next from 'i18next';
import { ApButton, ApSpinner } from '@aphilia/shared-ui-core-react';
import React from 'react';
import { useStripeContext } from '../../store/stripe-context';
import { useTranslation } from 'react-i18next';

interface Props {
    /**
     * The actual status of a payment
     */
    status: PaymentStatus | BillPaymentEventStatus;

    /**
     * The amount of the payment.
     */
    amount: number;

    /**
     * The amount of the payment.
     */
    isRefunding?: boolean;

    /**
     * The amount of the payment.
     */
    disabledOtherButtons?: boolean;

    /**
     * Event triggered when the user click on the button to refund.
     * It can be either the refund button or the canceled button.
     *
     * @param string
     * @param number
     */
    onClickOnRefund: () => void;
}

export const RefundButton = ({ status, amount, onClickOnRefund, isRefunding, disabledOtherButtons }: Props) => {
    const { totalBalance } = useStripeContext();
    const { t } = useTranslation();

    if (status === PaymentStatus.Refunded) {
        return (
            <>
                {t('payments.charges.refund.amount', {
                    amount: Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(amount / 100),
                })}
            </>
        );
    } else if (amount > totalBalance) {
        return <>{t('payments.charges.refund.notEnoughProvision')}</>;
    } else if (status === 'succeeded') {
        return (
            <ApButton
                label={t(`payments.charges.refund.cta`)}
                onClick={(e) => {
                    e.stopPropagation();
                    onClickOnRefund();
                }}
                loading={isRefunding}
                type="ghost"
                color={'warning'}
                disabled={disabledOtherButtons}
            />
        );
    } else if (status === 'requires_capture') {
        return (
            <ApButton
                label={t(`payments.charges.canceled.cta`)}
                onClick={(e) => {
                    e.stopPropagation();
                    onClickOnRefund();
                }}
                type="ghost"
                color={'error'}
            />
        );
    } else {
        return <></>;
    }
};
