import { useTranslation } from 'react-i18next';
import React from 'react';
import { ApCol, ApModal, ApRow } from '@aphilia/shared-ui-core-react';
import { useOrganizationContext } from '../../stores';
import { ApSiteSelector } from '../ApSiteSelector';

export const ApSiteModal = () => {
    const { t } = useTranslation();
    const { updateSiteModalState, isSiteModalOpen } = useOrganizationContext();

    return (
        <ApModal id="site" headerTitle={t('site.modalSelector.title')} canHide={false} isOpen={isSiteModalOpen}>
            <p>{t('site.modalSelector.description')}</p>
            <ApRow>
                <ApCol>
                    <ApSiteSelector onSiteSelected={() => updateSiteModalState(false)} />
                </ApCol>
            </ApRow>
        </ApModal>
    );
};
