export class UberDisplayOptions {
    /**
     * Whether to allow the user to supply instructions for preparing menu items to the restaurant - applies store-wide.
     */
    disable_item_instructions: boolean;

    constructor(props: Partial<UberDisplayOptions>) {
        Object.assign(this, props);
    }
}
