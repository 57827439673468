export class UpdateWeekdayTimePeriods {
    /**
     * Example property of the entity
     */
    name?: string;

    /**
     * Indicates if the day is open or not
     */
    isOpen?: boolean;

    constructor(props: UpdateWeekdayTimePeriods) {
        Object.assign(this, props);
    }
}
