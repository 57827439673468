import { BankReceiptData, Bill, BillNoteData, BillPaymentEvent, CreateBillPaymentEvent, QueryPaginatePaymentEvent } from '@aphilia/payments/types';
import axios from 'axios';
import { BaseModelPaginate } from '@aphilia/shared/utils';

/**
 * Return the bill base on a site id and a table id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param tableId
 */
export async function getBill(paymentsServiceUrl: string, siteId: string, tableId: string): Promise<Bill> {
    try {
        const result = await axios.get<Bill>(`${paymentsServiceUrl}/bills/sites/${siteId}/table/${tableId}`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e?.message ? new Error(e.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return the bill base on a site id and a table id
 *
 * @param paymentsServiceUrl
 * @param billPaymentEventId
 */
export async function getBillPaymentEvent(paymentsServiceUrl: string, billPaymentEventId: string): Promise<BillPaymentEvent> {
    try {
        const result = await axios.get<BillPaymentEvent>(`${paymentsServiceUrl}/bills/billPaymentEvent/${billPaymentEventId}`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return the bill base on a site id and a table id
 *
 * @param paymentsServiceUrl
 * @param billPaymentEventId
 */
export async function getPrivateBillPaymentEvent(paymentsServiceUrl: string, billPaymentEventId: string): Promise<BillPaymentEvent> {
    try {
        const result = await axios.get<BillPaymentEvent>(`${paymentsServiceUrl}/bills/billPaymentEvent/${billPaymentEventId}/private`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Make a refund request to pipay
 * @param paymentsServiceUrl
 * @param billPaymentEventId
 */
export async function makeRefundRequest(paymentsServiceUrl: string, billPaymentEventId: string): Promise<any> {
    return (
        await axios.get<BillPaymentEvent>(`${paymentsServiceUrl}/bills/billPaymentEvent/${billPaymentEventId}/refund`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        })
    ).data;
}

/**
 * Return the bill base on a site id and a table id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param billPosId
 */
export async function getBillPaymentEvents(paymentsServiceUrl: string, siteId: string, billPosId: string): Promise<BillPaymentEvent[]> {
    try {
        const result = await axios.get<BillPaymentEvent[]>(`${paymentsServiceUrl}/bills/${billPosId}/sites/${siteId}`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return the billPayment
 *
 * @param paymentsServiceUrl
 * @param bill
 * @param billPaymentEvent
 */
export async function createBillPaymentEvent(paymentsServiceUrl: string, bill: Bill, billPaymentEvent: CreateBillPaymentEvent): Promise<any> {
    try {
        const result = await axios.post<any>(`${paymentsServiceUrl}/bills/sites/${bill.site_id}/table/${bill.table_number}/new-bill-payment`, {
            site_id: bill.site_id,
            table_number: bill.table_number,
            bill_pos_id: bill.payment.bill_pos_id,
            amount: billPaymentEvent.amount,
            tips_amount: billPaymentEvent.tips,
            email: bill.payment?.email || undefined,
        });

        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param tikSys
 */
export async function getBillReceipt(paymentsServiceUrl: string, billPaymentEventId: string): Promise<string> {
    try {
        const { data } = await axios.get<any>(`${paymentsServiceUrl}/bills/bill-note/${billPaymentEventId}`);
        return data.ticket || '';
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while GetBillReceipt');
    }
}

/**
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param paymentEventId
 * @returns
 */
export const getBankReceipt = async (paymentsServiceUrl: string, siteId: string, paymentEventId: string): Promise<BankReceiptData> => {
    try {
        const result = await axios.get<BankReceiptData>(`${paymentsServiceUrl}/bills/${siteId}/bank-card-receipt/${paymentEventId}`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while GetBankReceipt');
    }
};

/**
 *
 * @param paymentsServiceUrl
 * @param query
 * @returns
 */
export const getBillPaymentEventsBySiteId = async (paymentsServiceUrl: string, query: QueryPaginatePaymentEvent): Promise<BaseModelPaginate<BillPaymentEvent>> => {
    try {
        const result = await axios.get<BaseModelPaginate<BillPaymentEvent>>(`${paymentsServiceUrl}/bills/payment-events`, {
            params: query,
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while getBillPaymentEventsBySiteId');
    }
};
