import { UpdateClickAndCollect } from './update-click-and-collect';
import * as faker from 'faker';
import { ClickAndCollectProvider } from '..';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeUpdateClickAndCollectData = (): UpdateClickAndCollect => ({
    enabled: faker.random.boolean(),
    provider: faker.random.objectElement<ClickAndCollectProvider>(ClickAndCollectProvider),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    maxOrderPerInterval: faker.random.number(300),
    minOrderValue: faker.random.number(500),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
});

export const fakeUpdateClickAndCollect = (): UpdateClickAndCollect => new UpdateClickAndCollect(fakeUpdateClickAndCollectData());

export const fakeUpdateClickAndCollectArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateClickAndCollect[] => {
    const clickAndCollect: UpdateClickAndCollect[] = [];

    for (let i = 0; i < nbMax; i++) {
        clickAndCollect.push(fakeUpdateClickAndCollect());
    }

    return clickAndCollect;
};
