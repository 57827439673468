import * as faker from 'faker';
import { UpdateDeliveryApplication } from './update-delivery-application';
import { fakePiclick } from '../piclick/piclick/piclick.data';

export const fakeUpdateDeliveryApplicationData = (): UpdateDeliveryApplication => ({
    piClick: fakePiclick(),
});

export const fakeUpdateDeliveryApplication = (): UpdateDeliveryApplication => new UpdateDeliveryApplication(fakeUpdateDeliveryApplicationData());

export const fakeUpdateDeliveryApplicationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateDeliveryApplication[] => {
    const delivery: UpdateDeliveryApplication[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeUpdateDeliveryApplication());
    }

    return delivery;
};
