import React, { ReactNode } from 'react';
import { Typography, Grid, Dialog, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { ApButton } from '../ApButton';
import style from './ApModalConfirm.style';
import { ApInlineButton } from '../ApButton/ApInlineButton';

export type modalConfirmSizeType = false | 'xs' | 'md' | 'lg' | 'sm' | 'xl';

interface ApModalConfirmProps {
    title?: string;
    children?: ReactNode;
    onConfirm?: (event: React.SyntheticEvent) => void;
    confirmDisabled?: boolean;
    onClose: () => void;
    maxWidth?: modalConfirmSizeType;
    open: boolean;
    confirmText?: ReactNode;
    cancelText?: ReactNode;
    color?: any;
}

const useStyles = makeStyles(style);

export function ApModalConfirm({
    open,
    children,
    onClose,
    onConfirm,
    confirmText,
    cancelText,
    maxWidth = 'xs',
    title = '',
    color = 'initial',
    confirmDisabled = false,
}: ApModalConfirmProps) {
    const classes = useStyles();

    return (
        <Dialog open={open} maxWidth={maxWidth} onClose={onClose} className={classes.container}>
            <header className={classes.header}>
                <Grid item xs={12} className="title">
                    {title && (
                        <Typography variant="h5" color={color}>
                            {title}
                        </Typography>
                    )}
                    <IconButton className={classes.close} onClick={onClose}>
                        <Close />
                    </IconButton>
                </Grid>
            </header>

            {children && (
                <Grid container className={classes.body}>
                    {children}
                </Grid>
            )}
            <Grid container component="footer" className={classes.footer}>
                <Grid item container spacing={2} xs={12} className="actions">
                    <ApInlineButton>
                        {confirmText && onConfirm && (
                            <ApButton clickCallback={onConfirm} disabled={confirmDisabled} color={color}>
                                {confirmText}
                            </ApButton>
                        )}
                        {cancelText && (
                            <ApButton
                                clickCallback={onClose}
                                disabled={false}
                                color={confirmText && onConfirm ? 'secondary' : color}
                                variant={confirmText && onConfirm ? 'text' : 'contained'}
                            >
                                {cancelText}
                            </ApButton>
                        )}
                    </ApInlineButton>
                </Grid>
            </Grid>
        </Dialog>
    );
}
