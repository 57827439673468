import { DenyOrCancelOrderReason, DenyOrCancelOrderReasonStuart, OrderState } from '@aphilia/deliveries/data';

/**
 * Return true or false if the order can be modified.
 */
export function getStuartError(error: string, lang?: string): string {
    switch (error) {
        case DenyOrCancelOrderReasonStuart.address_error:
            return 'Erreur d’adresse';
        case DenyOrCancelOrderReasonStuart.courier_issue:
            return 'Refus du coursier d’effectuer la commande';
        case DenyOrCancelOrderReasonStuart.customer_cancellation_requested:
            return 'Annulation demandée par le client final';
        case DenyOrCancelOrderReasonStuart.duplicate_job:
            return 'Commande dupliquée';
        case DenyOrCancelOrderReasonStuart.incorrect_package:
            return 'Mauvaise commande';
        case DenyOrCancelOrderReasonStuart.job_expired:
            return 'La demande livraison a expirée';
        case DenyOrCancelOrderReasonStuart.no_supply:
            return 'Pas de coursier disponible';
        case DenyOrCancelOrderReasonStuart.other:
            return 'Autre raison';
        case DenyOrCancelOrderReasonStuart.package_damaged:
            return 'Commande endommagée';
        case DenyOrCancelOrderReasonStuart.package_not_ready:
            return 'Commande pas prête';
        case DenyOrCancelOrderReasonStuart.pu_closed:
            return 'Point de retrait fermé';
        case DenyOrCancelOrderReasonStuart.technical_issue:
            return 'Problème technique';
        case DenyOrCancelOrderReasonStuart.wrong_transport_type:
            return 'Mauvais type de transport';
        default:
            return 'Raison non défini';
    }
}
