import moment, { Moment } from 'moment';

/**
 * Round a certain date to a certain minute time around.
 *
 * @example '2019-01-01T10:08:00' => '2019-01-01T10:15:00''
 *
 * @param date: date to be rounded
 * @param minute: the minute to round to (10:07:00.000 becomes 10:00:00.000 if minutes = 15)
 */
export const rounded = (date: Moment = moment(), minute: number = 15): number => {
    return Math.round(date.minute() / minute) * minute;
};

/**
 * Return all the minutes of a moment date
 *
 * @param m
 */
export function minutesOfDay(m: Moment) {
    return m.minutes() + m.hours() * 60;
}

export const NAME_PATTERN = new RegExp("^[\\w'\\-,.][^0-9_!¡?÷?¿\\/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,29}$");

export enum PriceType {
    Min = 'MIN',
    Max = 'MAX',
    Default = 'DEFAULT',
}

export function chr(codePt) {
    if (codePt > 0xffff) {
        codePt -= 0x10000;
        // tslint:disable-next-line:no-bitwise
        return String.fromCharCode(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff));
    }
    return String.fromCharCode(codePt);
}

export function ord(string) {
    const str = string + '';
    const code = str.charCodeAt(0);

    if (code >= 0xd800 && code <= 0xdbff) {
        const hi = code;
        if (str.length === 1) {
            return code;
        }
        const low = str.charCodeAt(1);
        return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
        return code;
    }

    return code;
}

/**
 * Generate a key
 *
 * @param cle
 */
export function getUsableKey(cle) {
    let hexStrKey = cle.substr(0, 38);
    const hexFinal = '' + cle.substr(38, 2) + '00';
    const cca0 = ord(hexFinal);
    if (cca0 > 70 && cca0 < 97) {
        hexStrKey += chr(cca0 - 23) + hexFinal.substr(1, 1);
    } else {
        if (hexFinal.substr(1, 1) === 'M') {
            hexStrKey += hexFinal.substr(0, 1) + '0';
        } else {
            hexStrKey += hexFinal.substr(0, 2);
        }
    }
    return Buffer.from(hexStrKey, 'hex');
}
