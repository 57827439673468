export const UberTaxLabels = [
    {
        tax_classification: 'Unheated Prepared Food',
        category_labels: ['CAT_PREPARED_FOOD'],
        temperature_label: ['TEMP_UNHEATED'],
    },
    {
        tax_classification: 'Unheated Deli Platter',
        category_labels: ['CAT_DELI_PLATTER'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Unheated Food Sold by Weight/Volume',
        category_labels: ['CAT_FOOD_BY_WT_VOL'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Unheated Sandwich/Wrap',
        category_labels: ['CAT_SANDWICH'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Ice Cream (hand scooped)',
        category_labels: ['CAT_ICECREAM'],
        temperature_label: ['TEMP_COLD'],
    },
    {
        tax_classification: 'Pre-Packaged Food',
        category_labels: ['CAT_PREPACKAGED_FOOD'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Pre-Packaged Ice Cream',
        category_labels: ['CAT_PREPACKAGED_FOOD', 'CAT_ICECREAM'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Pre-Packaged Ice Cream',
        category_labels: ['CAT_PREPACKAGED_FOOD', 'CAT_SNACK'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Candy',
        category_labels: ['CAT_CANDY'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Alcohol',
        category_labels: ['CAT_ALCOHOL'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: '100% Juice',
        category_labels: ['CAT_JUICE', 'TRAIT_PCT_100'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Noncarbonated, unflavored/unsweetened water',
        category_labels: ['CAT_WATER', 'TRAIT_NONCARB', 'TRAIT_UNFLV_UNSWT', 'CONTAINER_BOTTLED'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
    {
        tax_classification: 'Noncarbonated, flavored/sweetened water',
        category_labels: ['CAT_WATER', 'TRAIT_NONCARB', 'TRAIT_FLV_SWT', 'CONTAINER_BOTTLED'],
        temperature_label: ['TEMP_HEATED', 'TEMP_UNHEATED', 'TEMP_COLD'],
    },
];
