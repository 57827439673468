import { UpdateStuart } from './update-stuart';
import * as faker from 'faker';
import moment from 'moment';

export const fakeUpdateStuartData = (): UpdateStuart => ({
    enabled: faker.random.boolean(),
    clientId: faker.random.alphaNumeric(),
    clientSecret: faker.random.alphaNumeric(),
    token: faker.random.alphaNumeric(),
    tokenExpiration: moment().unix().valueOf(),
});

export const fakeUpdateStuart = (): UpdateStuart => new UpdateStuart(fakeUpdateStuartData());

export const fakeUpdateStuartArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateStuart[] => {
    const stuart: UpdateStuart[] = [];

    for (let i = 0; i < nbMax; i++) {
        stuart.push(fakeUpdateStuart());
    }

    return stuart;
};
