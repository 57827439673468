import * as React from 'react';

function SvgBrightness1(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <circle id="brightness_1_svg__a" cx={12} cy={12} r={10} />
            </defs>
            <use fill="currentColor" xlinkHref="#brightness_1_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgBrightness1;
