import { WebArticleUnavailabilityPeriod } from '../../web-article';
import { Moment } from 'moment';

export class WebArticleAdditionalInformation {
    /**
     * Example id property of the entity
     */
    readonly _id: string;

    /**
     * The article id the information is related to
     */
    articleId: string;

    /**
     * Is available for at table
     */
    atTableAvailability?: boolean;

    /**
     * Is Available for the click and collect
     */
    clickAndCollectAvailability?: boolean;

    /**
     * Is available for the delivery
     */
    deliveryAvailability?: boolean;

    /**
     * Is a favorite article
     */
    isFavorite?: boolean;

    /**
     * Indicates if an article is available for sale
     */
    isAvailableForSale?: boolean;

    /**
     * Indicates if the article is of type success when it's not available for sale
     */
    isSuccess?: boolean;

    /**
     * If an article is unavailable for sell, it indicates the period of time it is unavailable for.
     */
    periodOfUnavailability?: WebArticleUnavailabilityPeriod;

    /**
     * Order of the article in the category
     */
    categoryOrder?: number;

    /**
     * Indicates if the article is suggested or not
     */
    suggestion?: boolean;

    updatedAt?: Moment;

    constructor(props: WebArticleAdditionalInformation) {
        Object.assign(this, props);
    }
}
