import { useCallback, useState, useEffect } from 'react';

function useConfirm(open, onConfirm, error: boolean) {
    const [canConfirm, setCanConfirm] = useState(true);

    const handleConfirm = useCallback(() => {
        if (canConfirm) {
            if (typeof onConfirm === 'function') {
                onConfirm();
            }
        }
    }, [canConfirm, onConfirm]);

    useEffect(() => {
        if (!!open) {
            setCanConfirm(true);
        }
    }, [open]);

    useEffect(() => {
        if (typeof error === 'boolean') {
            setCanConfirm(!error);
        }
    }, [error]);

    return { canConfirm, handleConfirm };
}

export { useConfirm };
