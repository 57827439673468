import { CreateWebArticleCategory } from './create-web-article-category';
import * as faker from 'faker';
import { fakeWebArticleArray } from '../../web-article';

export const fakeCreateWebArticleCategoryData = (articles?: string[]): CreateWebArticleCategory => ({
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    subtitle: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    articles: articles || fakeWebArticleArray().map((article) => article._id),
    order: faker.random.number(),
    parentCategoryId: faker.random.number().toString(),
    images: [
        {
            data: faker.image.image(),
            contentType: 'jpg',
            contentLength: faker.random.number(),
        },
    ],
});

export const fakeCreateWebArticleCategory = (articles?: string[]): CreateWebArticleCategory => new CreateWebArticleCategory(fakeCreateWebArticleCategoryData(articles));

export const fakeCreateWebArticleCategoryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 }), articles?: string[]): CreateWebArticleCategory[] => {
    const webArticleCategory: CreateWebArticleCategory[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleCategory.push(fakeCreateWebArticleCategory(articles));
    }

    return webArticleCategory;
};
