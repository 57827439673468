import { UpdateWebMenu } from './update-web-menu';
import * as faker from 'faker';

export const fakeUpdateWebMenuData = (): UpdateWebMenu => ({
    name: faker.name.findName(),
});

export const fakeUpdateWebMenu = (): UpdateWebMenu => new UpdateWebMenu(fakeUpdateWebMenuData());

export const fakeUpdateWebMenuArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebMenu[] => {
    const webMenu: UpdateWebMenu[] = [];

    for (let i = 0; i < nbMax; i++) {
        webMenu.push(fakeUpdateWebMenu());
    }

    return webMenu;
};
