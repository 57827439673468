import React, { useContext, useRef } from 'react';

import { ApButton, ApCard, ApChip, ApSearchbar } from '@aphilia/shared-ui-core-react';
import { useItemsSearch } from '../../hooks';
import { ApColumnData, ApColumnFilter, ApOperatorTradConfig } from '../../types';
import { useColumnsEdition, useFilteringConfiguration, useOperatorsConfig } from './hooks';
import { ApTableFilterRow } from './ApTableFilterRow';
import { ApColumnsEditionModal } from './ApColumnsEditionModal';
import { getOperatorKey } from '../../utils';

import './ap-table-topbar.scss';
import { TableContext } from '../../config';

interface ApTableTopBarProps {
    // Required
    columns: ApColumnData[];

    // Optional
    isSearchable?: boolean;
    searchPlaceholder?: string;
    searchValue?: string;

    canFilter?: boolean;
    filterColumnText?: string;
    filterOperatorText?: string;
    filterValueText?: string;

    filtersEdition?: boolean;

    filtersTrads?: ApOperatorTradConfig;
    tradFunction?: (key: string, config?: object) => string;

    onSearch?: (search: string) => void;
    onDataFiltering?: (filters: ApColumnFilter[]) => void;
    onFiltersEdition?: (edition: boolean) => void;
}

export function ApTableTopBar({
    columns,
    isSearchable,
    searchPlaceholder,
    canFilter,
    filterColumnText,
    filterOperatorText,
    filterValueText,
    filtersEdition,
    filtersTrads,
    tradFunction,
    searchValue,
    onSearch,
    onDataFiltering,
    onFiltersEdition,
}: ApTableTopBarProps) {
    const editionBlock = useRef(null);
    const { handleSearch } = useItemsSearch(onSearch);

    const completeOperatorsConfig = useOperatorsConfig(filtersTrads);
    const {
        filters,
        canAddFilter,
        canUseFilters,
        handleFiltersEdition,
        handleFiltering,
        handleAddFilter,
        handleUpdateFilter,
        handleDeleteFilter,
        handleDeleteAndExecuteFilter,
    } = useFilteringConfiguration(onDataFiltering, onFiltersEdition);

    const displayedFilters = filters.filter((filter) => filter.columnKey && filter.operator && filter.value);

    const { columnsEdition, handleColumnsEdition } = useColumnsEdition();

    const { minSearchLength } = useContext(TableContext);

    return (
        <div className="ap-table-topbar">
            {isSearchable && (
                <ApSearchbar className="ap-table-search" placeholder={searchPlaceholder} onSearchChange={handleSearch} value={searchValue} minSearchLength={minSearchLength} />
            )}

            {/* <ApButton iconLeft="flip" size="small" color="neutral" type="ghost" onPointerUp={handleColumnsEdition} />
                <ApColumnsEditionModal columnsEdition={columnsEdition} /> */}

            {canFilter && (
                <>
                    {/* Column filters edition block start */}
                    <div className="ap-table-filters-edition" ref={editionBlock}>
                        <ApButton iconLeft="filter_list" size="small" color="neutral" type="ghost" onPointerUp={handleFiltersEdition(true)} />
                        {filtersEdition && (
                            <ApCard headerTitle="Filtrer le tableau" className="ap-table-filters-edition-modal" elevation={8}>
                                <ul className="ap-edition-filters-list">
                                    {filters?.map((filter, filterIndex) => (
                                        <ApTableFilterRow
                                            key={`filter-edition-${filterIndex}`}
                                            filter={filter}
                                            filterIndex={filterIndex}
                                            columns={columns}
                                            filterColumnText={filterColumnText}
                                            filterOperatorText={filterOperatorText}
                                            filterValueText={filterValueText}
                                            onDeleteFilter={handleDeleteFilter}
                                            onUpdateFilter={handleUpdateFilter}
                                            filtersTrads={filtersTrads}
                                        />
                                    ))}
                                </ul>
                                <div className="ap-edition-actions">
                                    <ApButton
                                        label="Ajouter un filtre"
                                        iconLeft="add"
                                        type="ghost"
                                        color="neutral"
                                        disabled={!canAddFilter}
                                        onPointerUp={canAddFilter ? handleAddFilter : null}
                                    />
                                    <ApButton label="Annuler" className="ap-end-button" type="ghost" color="neutral" onPointerUp={handleFiltering(true)} />
                                    <ApButton label="Filtrer" disabled={!canUseFilters} onPointerUp={canUseFilters ? handleFiltering() : null} />
                                </div>
                            </ApCard>
                        )}
                    </div>
                    {/* Column filters display block start */}
                    {!filtersEdition && (
                        <div className="ap-table-filters-list">
                            {displayedFilters?.map((filter, filterIndex) => {
                                const filterLabel = columns.find((col) => col.dataKey === filter.columnKey)?.label;
                                const operatorKey = getOperatorKey(filter, completeOperatorsConfig);
                                return (
                                    <ApChip
                                        key={`filter-display-${filterIndex}`}
                                        label={tradFunction(operatorKey, { column: filterLabel, value: filter.value })}
                                        onChipClose={handleDeleteAndExecuteFilter(filterIndex)}
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
