import { ApActionButtons, ApCard, ApInput } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripeContext } from '../../store/stripe-context';
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import { useForm, Controller } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import './business-profile-settings.scss';

const schema = Joi.object({
    name: Joi.string().required().min(3).max(40),
    support_email: Joi.string().email({ tlds: { allow: false } }),
    support_phone: Joi.string().custom((value, helper) => {
        if (parsePhoneNumber(value, 'FR')?.isValid()) {
            return value;
        } else {
            return helper.message({
                invalid: 'Invalid phone number',
            });
        }
    }),
});

export const BusinessProfileSettings = () => {
    const { account, updateAccount } = useStripeContext();
    const { t } = useTranslation();
    const [businessProfile, setBusinessProfile] = useState<{ name: string; support_email: string; support_phone: string }>();
    const {
        formState: { errors },
        setValue,
        control,
        trigger,
    } = useForm({
        resolver: joiResolver(schema),
    });
    const [isUpdatingAccount, setIsUpdatingAccount] = useState<boolean>(false);

    useEffect(() => {
        resetBusinessProfile();
    }, [account?.business_profile]);

    const resetBusinessProfile = useCallback(() => {
        setBusinessProfile({
            name: account?.business_profile?.name,
            support_email: account?.business_profile?.support_email,
            support_phone: account?.business_profile?.support_phone,
        });
    }, [account?.business_profile]);

    const updateBusinessAccount = useCallback(
        (key: string, value: string) => {
            setBusinessProfile({
                ...businessProfile,
                [key]: value,
            });
            setValue(key, value);
            trigger();
        },
        [businessProfile]
    );

    const submit = useCallback(async () => {
        try {
            setIsUpdatingAccount(true);
            await updateAccount({
                business_profile: businessProfile,
                site_id: account.site_id,
                brand_id: account.brand_id,
            });
            setIsUpdatingAccount(false);
        } catch (e) {
            setIsUpdatingAccount(false);
            console.error(e);
            throw e;
        }
    }, [businessProfile, account?.site_id, account?.brand_id]);

    useEffect(() => {
        if (businessProfile) Object.keys(businessProfile).forEach((key) => setValue(key, businessProfile[key]));
    }, [businessProfile]);

    const isDisabled = useMemo(
        () =>
            (businessProfile?.name === account?.business_profile?.name &&
                businessProfile?.support_phone === account?.business_profile?.support_phone &&
                businessProfile?.support_email === account?.business_profile?.support_email) ||
            Object.keys(errors).length > 0,
        [businessProfile, errors, account?.business_profile?.name, account?.business_profile?.support_phone, account?.business_profile?.support_email]
    );

    return (
        <ApCard elevation={1} headerTitle={t('payments.settings.company.form.title')} className="business-profile-form">
            <Controller
                control={control}
                render={({ field }) => (
                    <ApInput
                        {...field}
                        color={errors?.name?.message ? 'error' : undefined}
                        value={businessProfile?.name}
                        onInputBlur={(e) => updateBusinessAccount('name', e.detail.target.value)}
                        label={t('payments.settings.company.form.name')}
                    />
                )}
                name="name"
            />
            <Controller
                control={control}
                render={({ field }) => (
                    <ApInput
                        {...field}
                        color={errors?.support_email?.message ? 'error' : undefined}
                        value={businessProfile?.support_email}
                        onInputBlur={(e) => updateBusinessAccount('support_email', e.detail.target.value)}
                        label={t('payments.settings.company.form.email')}
                    />
                )}
                name="support_email"
            />
            <Controller
                control={control}
                render={({ field }) => (
                    <ApInput
                        {...field}
                        color={errors?.support_phone?.message ? 'error' : undefined}
                        value={businessProfile?.support_phone}
                        onInputBlur={(e) => updateBusinessAccount('support_phone', e.detail.target.value)}
                        label={t('payments.settings.company.form.phone')}
                    />
                )}
                name="support_phone"
            />
            <ApActionButtons
                disabled={isDisabled}
                confirmText={t('payments.settings.company.form.confirm')}
                cancelText={t('payments.settings.company.form.cancel')}
                onApCancel={resetBusinessProfile}
                onApConfirm={() => submit()}
                isLoading={isUpdatingAccount}
            />
        </ApCard>
    );
};
