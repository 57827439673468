import { UberPromotionType } from '../enum';

class UberPromotionsItem {
    external_id: string;
    discounted_quantity: number;
}

export class UberPromotions {
    discount_items: UberPromotionsItem[];
    promo_discount_value: number;
    promo_discount_percentage: number;
    promo_type: UberPromotionType;
}
