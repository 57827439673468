export class CreateUber {
    /**
     * Indicates if the integration with uber is enabled or not
     */
    enabled: boolean;

    /**
     * Uber location id of the site
     */
    locationId: string;

    constructor(props: CreateUber) {
        Object.assign(this, props);
    }
}
