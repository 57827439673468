import { WebArticleModifierType } from '../../web-article-modifier';

export class UpdateWebArticleModifierDescriptiveSheet {
    /**
     * The number of the web modifier
     */
    readonly modifierNumber: number;

    /**
     * The site id of the descriptive sheet
     */
    readonly siteId: string;

    /**
     * The brand id of the descriptive sheet
     */
    readonly brandId: string;

    /**
     * Title of the article on the web.
     */
    title?: Record<string, string>;

    /**
     * Short description of the article.
     */
    shortDescription?: Record<string, string>;

    /**
     * The type of the web modifier
     */
    type: WebArticleModifierType;

    constructor(props: UpdateWebArticleModifierDescriptiveSheet) {
        Object.assign(this, props);
    }
}
