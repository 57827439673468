import { Role } from '@aphilia/shared/organizations/types';
import axios from 'axios';

/**
 * Return all the roles by brandId and by applicationId
 *
 * @param serviceUrl
 * @param brandId
 * @param application
 */
export const getRoles = async (serviceUrl: string, brandId: string, application: string) => {
    try {
        return (
            await axios.get<Role[]>(`${serviceUrl}/roles?applicationId=${application}`, {
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                    'x-site-id': (window as any).siteSelectedId,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Get a role by its id
 *
 * @param serviceUrl
 * @param roleId
 */
export const getRoleById = async (serviceUrl: string, roleId: string): Promise<Role> => {
    try {
        return (
            await axios.get<Role>(`${serviceUrl}/roles/${roleId}`, {
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                    'x-site-id': (window as any).siteSelectedId,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Add a users to a role
 *
 * @param serviceUrl
 * @param roleId
 * @param users
 */
export const addUserToRole = async (serviceUrl: string, roleId: string, users: string[]) => {
    try {
        return (
            await axios.put<Role>(`${serviceUrl}/roles/${roleId}/users`, users, {
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                    'x-site-id': (window as any).siteSelectedId,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Delete users from a role
 *
 * @param serviceUrl
 * @param roleId
 * @param users
 */
export const deleteUserFromRole = async (serviceUrl: string, roleId: string, users: string[]) => {
    try {
        return (
            await axios.delete<Role>(`${serviceUrl}/roles/${roleId}/users`, {
                data: users,
                headers: {
                    authorization: `Bearer ${(window as any).authToken['default']}`,
                    'x-site-id': (window as any).siteSelectedId,
                },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
