import * as faker from 'faker';
import { UpdateWebPrice, UpdateWebPricePeriod } from './update-web-price';

const getPrice = () => {
    return {
        price: faker.random.number({ min: 500, max: 10000 }),
    };
};

const getPeriodPrice = (): UpdateWebPricePeriod => {
    return {
        enabled: faker.random.boolean(),
        delivery: getPrice(),
        atTable: getPrice(),
        clickAndCollect: getPrice(),
    };
};

export const fakeUpdateWebPriceData = (): UpdateWebPrice => {
    return {
        siteId: faker.random.number(300).toString(),
        articleId: faker.random.number(1000).toString(),
        price: {
            delivery: faker.random.number({ min: 500, max: 10000 }),
            atTable: faker.random.number({ min: 500, max: 10000 }),
            clickAndCollect: faker.random.number({ min: 500, max: 10000 }),
        },
        periods: {
            id1: getPeriodPrice(),
            id2: getPeriodPrice(),
            id3: getPeriodPrice(),
            id4: getPeriodPrice(),
            id5: getPeriodPrice(),
        },
    };
};

export const fakeUpdateWebPrice = (): UpdateWebPrice => new UpdateWebPrice(fakeUpdateWebPriceData());

export const fakeUpdateWebPriceArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebPrice[] => {
    const webPrice: UpdateWebPrice[] = [];

    for (let i = 0; i < nbMax; i++) {
        webPrice.push(fakeUpdateWebPrice());
    }

    return webPrice;
};
