export class CreateAtTable {
    /**
     * Example property of the entity
     */
    name: string;

    constructor(props: CreateAtTable) {
        Object.assign(this, props);
    }
}
