import { PiclickV2Consumable } from './piclick-v2-consumable/piclick-v2-consumable';
import { PiclickV2StyleParameters } from './piclick-v2-parameters/piclick-v2-parameters';
import { PiClickV2DeliveryParameters } from './piclick-v2-delivery-parameters/piclick-v2-delivery-parameters';
import { PiclickV2ConsummerParameters } from '.';

export class PiclickV2 {
    /**
     * Indicates if Piclick v2 is enabled
     */
    enabled?: boolean;

    /**
     * Embeds style parameters and values
     */
    style?: PiclickV2StyleParameters;

    /**
     * Desktop cover image
     */
    desktopCover?: string;

    /**
     * Embeds consumable labels and max quantity
     */
    consumables?: PiclickV2Consumable[];

    /**
     * Use default loader for the in preparation stage
     */
    useDefaultPreparationLoader?: boolean;

    /**
     * URL of the loader for the in preparation stage
     */
    inPreparationLoaderUrl?: string;

    /**
     * Indicates if alcohol prevention banner is enabled in PiClick template
     */
    alcoholPrevention?: boolean;

    /**
     * The parameters for the delivery
     */
    deliveryParameters?: PiClickV2DeliveryParameters;

    /**
     * The parameters for piclick consummer optin system
     */
    consummerParameters?: PiclickV2ConsummerParameters;

    constructor(props: PiclickV2) {
        Object.assign(this, props);
    }
}
