import { UpdateAtTable } from './update-at-table';
import * as faker from 'faker';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeUpdateAtTableData = (): UpdateAtTable => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    minOrderValue: faker.random.number(500),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
});

export const fakeUpdateAtTable = (): UpdateAtTable => new UpdateAtTable(fakeUpdateAtTableData());

export const fakeUpdateAtTableArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateAtTable[] => {
    const atTable: UpdateAtTable[] = [];

    for (let i = 0; i < nbMax; i++) {
        atTable.push(fakeUpdateAtTable());
    }

    return atTable;
};
