import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import '../../../../../ui/styles/src/lib/global.scss';
import { ApButton } from '../ApButton';
import { ApPILogo } from '../ApPILogo';
import style from './ApNavigation.style';
import { useLocation } from 'react-router-dom';
import ChevronLeft from '../../icons/SvgChevronLeft';

export interface Props {
    categories: NavElement[][];
}

interface NavElement {
    text: string;
    icon?: string | React.ReactNode;
    description?: string;
    route?: string;
    children?: NavElement[];
    parent?: NavElement;
}

interface Item {
    text: string;
    icon: string | React.ReactNode;
    description?: string;
    route?: string;
    children?: SubItem[];
}
interface SubItem {
    text: string;
    description: string;
    children?: ThirdLevel[];
    route?: string;
}

interface ThirdLevel {
    text: string;
    route: string;
}

const useStyles = makeStyles(style);

export const ApNavigation = ({ categories }: Props) => {
    const location = useLocation();
    const classes = useStyles();
    const [drawer, setDrawer] = React.useState({} as Item);
    const [open, setOpen] = React.useState(false);
    const [activeSubItem, setActiveSubItem] = React.useState({} as SubItem);
    const [showSubItem, setShowSubItem] = React.useState(false);
    let close: any;

    useEffect(() => {
        let nodesToCheck: NavElement[] = [].concat(...categories);
        let i = 0;
        let current = {} as NavElement;
        let found = nodesToCheck.length === 0 ? true : false;
        while (!found) {
            current = nodesToCheck[i] as NavElement;
            if (typeof current === 'undefined') {
                i++;
                return;
            }
            if ('route' in current && current.route === location.pathname) {
                found = true;
                if ('icon' in current) {
                    // The current route refers to an Item
                    setShowSubItem(false);
                } else {
                    // The current route refers to a ThirdLevel
                    setActiveSubItem(current.parent as SubItem);
                    setShowSubItem(true);
                    setOpen(false);
                }
            } else if ('children' in current) {
                nodesToCheck = nodesToCheck.concat(
                    current.children.map((child: NavElement) => {
                        child.parent = current;
                        return child;
                    })
                );
            }
            i++;
        }
    }, [location]);

    const handleItemMouseOver = (item: Item) => (_: React.MouseEvent) => {
        if (!item.description || showSubItem) return;
        clearTimeout(close);
        setDrawer(item);
        setOpen(true);
    };

    const handleItemMouseOut = (item: Item) => (_: React.MouseEvent) => {
        if (!item.description) return;
        close = setTimeout(() => {
            setOpen(false);
        }, 100);
    };

    const handleClickBack = () => {
        setShowSubItem(false);
    };

    const handleSubItemClick = (subItem: SubItem) => () => {
        setActiveSubItem(subItem);
        setShowSubItem(true);
        setOpen(false);
    };

    return (
        <Paper elevation={0} square={true} className={`ap-navigation ${classes.root}`}>
            <Box className="left">
                <ApPILogo />
                <Box className="main">
                    {categories.map((cat: any[], i) => (
                        <List key={i} component="nav">
                            {cat.map((el: any, j) => (
                                <ListItem
                                    to={el.route}
                                    component={(el.route && Link) || Box}
                                    button
                                    // className={`${(location.pathname === el.route && 'active') || ''}
                                    className={`${(location.pathname.indexOf(el.route) > -1 && 'active') || ''}
                                        ${(el.description && open && 'open') || ''}
                                        ${(el === drawer && 'has-children') || ''}`}
                                    key={j}
                                    onMouseOver={handleItemMouseOver(el)}
                                    onMouseOut={handleItemMouseOut(el)}
                                >
                                    <ListItemIcon>{(typeof el.icon === 'string' && <i className={el.icon}></i>) || el.icon}</ListItemIcon>
                                    <ListItemText primary={el.text} />
                                </ListItem>
                            ))}
                        </List>
                    ))}
                </Box>
                <Box className={`secondary ${(showSubItem && 'active') || ''}`}>
                    <ApButton clickCallback={handleClickBack} variant="text" color="secondary" startIcon={<ChevronLeft />}>
                        Menu
                    </ApButton>
                    {activeSubItem && (
                        <div className="content">
                            <Typography variant="h6">{activeSubItem.text}</Typography>
                            <Typography variant="caption" component="div">
                                {activeSubItem.description}
                            </Typography>
                            <List>
                                {activeSubItem.children &&
                                    activeSubItem.children.map((child: ThirdLevel, index: number) => (
                                        <ListItem
                                            button
                                            key={index}
                                            className={`${(location.pathname === child.route && 'active') || ''}`}
                                            to={child.route}
                                            component={(child.route && Link) || Box}
                                        >
                                            <ListItemText primary={child.text} />
                                        </ListItem>
                                    ))}
                            </List>
                        </div>
                    )}
                </Box>
            </Box>
            <Box className={`sub-menu ${open && 'open'}`} onMouseOver={handleItemMouseOver(drawer)} onMouseOut={handleItemMouseOut(drawer)}>
                <Typography variant="h6">{drawer.text}</Typography>
                <Typography variant="body2">{drawer.description}</Typography>
                <List>
                    {drawer.children &&
                        drawer.children.map((child: SubItem, index: number) => (
                            <ListItem key={index} button onClick={handleSubItemClick(child)} to={child.route} component={(child.route && Link) || Box}>
                                <ListItemText primary={child.text} />
                            </ListItem>
                        ))}
                </List>
            </Box>
        </Paper>
    );
};
