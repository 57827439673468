import { UpdateWeekdayTimePeriods } from './update-weekday-time-periods';
import * as faker from 'faker';

export const fakeUpdateWeekdayTimePeriodsData = (): UpdateWeekdayTimePeriods => ({
    name: faker.name.findName(),
});

export const fakeUpdateWeekdayTimePeriods = (): UpdateWeekdayTimePeriods => new UpdateWeekdayTimePeriods(fakeUpdateWeekdayTimePeriodsData());

export const fakeUpdateWeekdayTimePeriodsArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWeekdayTimePeriods[] => {
    const weekdayTimePeriods: UpdateWeekdayTimePeriods[] = [];

    for (let i = 0; i < nbMax; i++) {
        weekdayTimePeriods.push(fakeUpdateWeekdayTimePeriods());
    }

    return weekdayTimePeriods;
};
