import moment, { Moment } from 'moment';
import { minutesOfDay } from '../utils';
import { DayPeriod } from './day-period/day-period';

export * from './day-period/day-period';
export * from './day-period/day-period.data';
export * from './create-day-period/create-day-period';
export * from './create-day-period/create-day-period.data';
export * from './update-day-period/update-day-period';
export * from './update-day-period/update-day-period.data';
export * from './day-period.permissions';

/**
 * Return the period depending on the hours and minutes of a day
 *
 * @param time
 * @param dayPeriods
 */
export function getPeriodByHourMinutes(time: Moment, dayPeriods: DayPeriod[]): DayPeriod {
    let orderedTimePeriod: DayPeriod[] = [];

    orderedTimePeriod = dayPeriods.sort((timeA: DayPeriod, timeB: DayPeriod) => minutesOfDay(moment(timeA.startTime, 'HH:mm')) - minutesOfDay(moment(timeB.startTime, 'HH:mm')));

    for (let i = 0; i < orderedTimePeriod.length; i++) {
        if (minutesOfDay(time) - minutesOfDay(moment(orderedTimePeriod[i].startTime, 'HH:mm')) < 0) {
            if (i === 0) {
                return orderedTimePeriod[orderedTimePeriod.length - 1];
            } else {
                return orderedTimePeriod[i - 1];
            }
        }
    }

    return orderedTimePeriod[orderedTimePeriod.length - 1];
}
