import { UberContextType } from '../../enum';

export class UberPriceOverride {
    /**
     * Type of the context in which to override.
     */
    context_type: UberContextType;

    /**
     * Identifying string (id) for the specified context.
     */
    context_value: string;

    /**
     * Price of the item in the lowest local currency denomination, e.g. cents.
     */
    price: number;

    constructor(props: Partial<UberPriceOverride>) {
        Object.assign(this, props);
    }
}
