import { UberPriceRules } from '../price';
import { UberSuspensionRules } from '../suspension';

export class UberUpdateItem {
    /**
     * (optional) Specifies the price to charge for ordering the item. Allows overrides dependant on the ordered item’s context
     */
    price_info?: UberPriceRules;

    /**
     * (optional) Suspends the item from sale, e.g. when out of stock, until a specified time. Allows overrides dependent on context
     */
    suspension_info?: UberSuspensionRules;
}
