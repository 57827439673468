import * as React from 'react';

function SvgChevronRight(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="chevron_right_svg__a" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </defs>
            <use fill="currentColor" xlinkHref="#chevron_right_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgChevronRight;
