export class UpdateStuart {
    /**
     * Indicates if stuart is enabled
     */
    enabled?: boolean;

    /**
     * The client id to connect to stuart
     */
    clientId?: string;

    /**
     * The client secret to access stuart
     */
    clientSecret?: string;

    /**
     * The token to call stuart
     */
    token?: string;

    /**
     * The token expiration in s
     */
    tokenExpiration?: number;

    constructor(props: UpdateStuart) {
        Object.assign(this, props);
    }
}
