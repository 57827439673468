import { WebArticleUnavailabilityPeriod } from '..';

export class UpdateWebArticle {
    /**
     * The id of the article
     */
    articleId: string;

    /**
     * The site id the article is related to.
     */
    siteId: string;

    /**
     * Indicates if an article is available to be sell.
     */
    isAvailableForSale?: boolean;

    /**
     * Indicates if an article isSuccess state for unavailability
     */
    isSuccess?: boolean;

    /**
     * If an article is unavailable for sell, it indicates the period of time it is unavailable for.
     */
    periodOfUnavailability?: WebArticleUnavailabilityPeriod;

    constructor(props: UpdateWebArticle) {
        Object.assign(this, props);
    }
}
