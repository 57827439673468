import { DeliverooMealtime } from './mealtime/mealtime';
import { DeliverooCategory } from './category/category';
import { DeliverooItem } from './item/item';
import { DeliverooModifier } from './modifier/modifier';

export class DeliverooMenu {
    /**
     * Name of the menu.
     */
    name: string;

    /**
     * ID of the site the menu should be published to.
     * Currently we only support a single menu per site, therefore only a single site ID should be inside the array.
     */
    site_ids: string[];

    menu: {
        /**
         * List of mealtimes. A mealtime act as a wrapper around a group of categories enabling them to be displayed during specified time periods.
         * Timed menus is not yet supported so only a single mealtime entity is currently accepted in the array
         */
        mealtimes: DeliverooMealtime[];

        /**
         * List of categories.
         */
        categories: DeliverooCategory[];

        /**
         * List of items.
         */
        items: DeliverooItem[];

        /**
         * List of modifiers.
         */
        modifiers: DeliverooModifier[];
    };

    constructor(props: DeliverooMenu) {
        Object.assign(this, props);
    }
}
