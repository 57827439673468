import { CreateOrder, CreateOrderDelivery, Order, OrderDeliveryPrice } from '@aphilia/deliveries/data';
import axios from 'axios';

export const postNewUnpaidOrder = async (
    deliveriesService: string,
    order: CreateOrder
): Promise<{ order: Order; MAC: string; webhookBaseUrl: string; timeout: string; token: string; paymentId?: string }> => {
    try {
        const result = await axios.post<{ order: Order; MAC: string; webhookBaseUrl: string; timeout: string; token: string; paymentId?: string }>(
            `${deliveriesService}/orders/new`,
            order
        );
        return {
            order: new Order(result.data.order),
            MAC: result.data.MAC,
            timeout: result.data.timeout,
            webhookBaseUrl: result.data.webhookBaseUrl,
            token: result.data.token,
            paymentId: result.data.paymentId,
        };
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const cancelOrder = async (deliveriesService: string, platformId: string, token: string): Promise<any> => {
    try {
        return await axios.put(
            `${deliveriesService}/orders/piclick/cancel`,
            { platformId: platformId },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getOrderById = async (deliveriesService: string, orderId: string, token: string): Promise<Order> => {
    try {
        return new Order(
            (
                await axios.get<Order>(`${deliveriesService}/orders/piclick/${orderId}`, {
                    headers: {
                        Authorization: token,
                    },
                })
            ).data
        );
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getOrderDeliveryPrice = async (deliveriesService: string, createOrderDelivery: CreateOrderDelivery, siteId: string): Promise<OrderDeliveryPrice> => {
    try {
        return new OrderDeliveryPrice((await axios.post(`${deliveriesService}/orders/delivery/pricing/${siteId}`, createOrderDelivery)).data);
    } catch (e) {
        console.error(e);
        throw e;
    }
};

const getKeyFromType = (type: number) => {
    switch (type) {
        case 10:
            return 'getOrderTotal.lines.subTotal';
        case 51:
            return 'getOrderTotal.lines.admission';
        case 52:
            return 'getOrderTotal.lines.royalty1';
        case 54:
            return 'getOrderTotal.lines.royalty2';
        case 55:
            return 'getOrderTotal.lines.subvention';
        case 56:
            return 'getOrderTotal.lines.employerAdmission1';
        case 57:
            return 'getOrderTotal.lines.employerAdmission2';
        case 58:
            return 'getOrderTotal.lines.employerRoyalty1';
        case 59:
            return 'getOrderTotal.lines.employerRoyalty2';
    }
};

export const getOrderCalculatedTotal = async (
    deliveriesService: string,
    order: CreateOrder
): Promise<{
    hasEnough?: boolean;
    total?: number;
    lines?: { label: string; value: number }[];
    balance?: number;
    error?: { code_retour?: number; code?: string; libelle_erreur?: string };
}> => {
    try {
        const bill = (await axios.post(`${deliveriesService}/orders/calculate-total`, order)).data;

        let total: number;

        const lines = bill?.lignes?.reduce((lines: { label: string; value: number }[], line: { type: number; libelle: string; prix: number }) => {
            if (line.type === 11) total = line.prix;
            if (line.type === 1 || line.type === 11) return lines;

            lines.push({
                label: line.libelle,
                value: line.prix,
            });
            return lines;
        }, []);

        return {
            hasEnough: bill?.a_payer === 0,
            balance: bill?.solde_compte || 0,
            total,
            lines,
            error: bill?.code_retour !== 1 ? bill : undefined,
        };
    } catch (e) {
        console.error(e);
        return {
            error: {
                code_retour: -1,
                code: e?.response?.status?.toString(),
                libelle_erreur: e?.message,
            },
        };
    }
};
