import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeliveryParametersContext } from '@aphilia/deliveries/feature-parameters';
import { ApBanner, ApCard, ApCol, ApInput, ApRow, ApToggle } from '@aphilia/shared-ui-core-react';
import { ApForm } from '@aphilia/shared/react';
import { Stuart } from '@aphilia/deliveries/data';

export const DeliveryParametersApplicationsStuartPage = () => {
    const { deliveryParameters, updateDeliveryParameters } = useDeliveryParametersContext();
    const [localStuartParameters, setLocalStuartParameters] = useState<Stuart>();

    useEffect(() => {
        setLocalStuartParameters(deliveryParameters?.applications?.stuart);
    }, [deliveryParameters?.applications?.stuart]);

    const updateStuart = useCallback(
        (property: string, value: any) => {
            setLocalStuartParameters({
                ...localStuartParameters,
                [property]: value,
            });
        },
        [localStuartParameters]
    );

    const handleOnSubmit = useCallback(async () => {
        await updateDeliveryParameters({ applications: { stuart: localStuartParameters } });
    }, [localStuartParameters]);

    const getContent = useMemo(() => {
        if (deliveryParameters?.applications?.piClick?.v2?.deliveryParameters?.enabled && deliveryParameters?.delivery?.enabled) {
            return (
                <ApCard headerTitle="Stuart">
                    <p>Vous pouvez retrouver la documentation de stuart ici: https://support.pielectronique.com/hc/fr/articles/4408802013713-Stuart-PiClick-livraison-</p>
                    <ApForm
                        onApSubmit={handleOnSubmit}
                        errorMessage={'Une erreur est survenue'}
                        successMessage={'La configuration Stuart est bien modifiée'}
                        cancelText={'Annuler'}
                        confirmText={'Confirmer'}
                    >
                        <ApRow>
                            <ApCol size={4}>
                                <ApToggle checked={deliveryParameters?.applications?.stuart?.enabled} label="Stuart actif" onApToggle={(e) => updateStuart('enabled', e.detail)} />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApInput
                                    label="Client ID"
                                    helpText="Code disponible sur le dashboard restaurateur Stuart"
                                    value={deliveryParameters?.applications?.stuart?.clientId}
                                    onInputChange={(e) => updateStuart('clientId', e.detail.target.value)}
                                />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApInput
                                    label="Client Secret"
                                    helpText="Code disponible sur le dashboard restaurateur Stuart"
                                    value={deliveryParameters?.applications?.stuart?.clientSecret}
                                    onInputChange={(e) => updateStuart('clientSecret', e.detail.target.value)}
                                />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApInput
                                    label="Token"
                                    helpText="A ne pas modifier (il se modifie tout seul au besoin)"
                                    value={deliveryParameters?.applications?.stuart?.token}
                                    onInputChange={(e) => updateStuart('token', e.detail.target.value)}
                                />
                            </ApCol>
                        </ApRow>
                    </ApForm>
                </ApCard>
            );
        } else {
            if (!deliveryParameters?.delivery?.enabled) {
                return (
                    <ApCard headerTitle="Stuart">
                        <ApBanner
                            text="Vous pouvez retrouver la documentation de stuart ici: https://support.pielectronique.com/hc/fr/articles/4408802013713-Stuart-PiClick-livraison-"
                            clickable={false}
                        />
                        <ApBanner text="Veuillez activer le mode de livraison pour ce site." type="warning" clickable={false} />
                    </ApCard>
                );
            } else if (!deliveryParameters?.applications?.piClick?.v2?.deliveryParameters?.enabled) {
                return (
                    <ApCard headerTitle="Stuart">
                        <ApBanner
                            text="Vous pouvez retrouver la documentation de stuart ici: https://support.pielectronique.com/hc/fr/articles/4408802013713-Stuart-PiClick-livraison-"
                            clickable={false}
                        />
                        <ApBanner text="Veuillez activer la livraison sur PI Click V2." type="warning" clickable={false} />
                    </ApCard>
                );
            }
        }
    }, [deliveryParameters?.applications?.piClick?.v2?.deliveryParameters?.enabled, deliveryParameters?.delivery?.enabled]);

    return <>{getContent}</>;
};
