import { ClickAndCollectProvider, DeliverySiteStatus } from '../..';
import { WeekdayTimePeriods, PriceType } from '@aphilia/shared/utils';

export class UpdateClickAndCollect {
    /**
     * Indicates if the click and collect is enabled
     */
    enabled?: boolean;

    /**
     * Indicates the click and collect name of the provider
     */
    provider?: ClickAndCollectProvider;

    /**
     * The price level use for the click and collect platform
     */
    priceLevel?: PriceType;

    /**
     * All the time periods for the click and collect
     */
    timePeriods?: WeekdayTimePeriods[];

    /**
     * Indicates the maximum number of orders per interval.
     */
    maxOrderPerInterval?: number;

    /**
     * Minimum amount authorized for the order
     */
    minOrderValue?: number;

    /**
     * Indicates if the click and collect platform is paused. Set to false to indicate that the plateform is paused
     */
    status?: DeliverySiteStatus;

    constructor(props: UpdateClickAndCollect) {
        Object.assign(this, props);
    }
}
