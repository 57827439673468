import * as React from 'react';

function SvgHttps(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path
                    // tslint:disable-next-line: max-line-length
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
                    id="https_svg__a"
                />
            </defs>
            <use fill="currentColor" xlinkHref="#https_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgHttps;
