import { UberVisibilityHours } from '../visibility-hours/visibility-hours';

export class UberVisibilityInfo {
    /**
     * List of time periods when an item should be visible to the eater
     */
    hours: UberVisibilityHours[];

    constructor(props: Partial<UberVisibilityInfo>) {
        this.hours = props.hours.map((hour) => new UberVisibilityHours(hour));
    }
}
