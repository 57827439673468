import { UberTaxLabelsInfo } from '../tax-labels-info/tax-labels-info';

export class UberTaxLabelsRuleSet {
    /**
     * The default value used.
     */
    default_value: UberTaxLabelsInfo;

    constructor(props: Partial<UberTaxLabelsRuleSet>) {
        this.default_value = new UberTaxLabelsInfo(props.default_value);
    }
}
