import { CreateDeliveryParameters } from './create-delivery';
import * as faker from 'faker';
import { DeliverySiteStatus } from '../delivery-parameters/delivery';

export const fakeCreateDeliveryParametersData = (): CreateDeliveryParameters => ({
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
    hasPilink: faker.random.boolean(),
});

export const fakeCreateDeliveryParameters = (): CreateDeliveryParameters => new CreateDeliveryParameters(fakeCreateDeliveryParametersData());

export const fakeCreateDeliveryParametersArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateDeliveryParameters[] => {
    const delivery: CreateDeliveryParameters[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeCreateDeliveryParameters());
    }

    return delivery;
};
