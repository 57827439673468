import React, { createContext, Reducer, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Brand, Site } from '@aphilia/shared/organizations/types';
import * as OrganizationsActions from './constants';
import {
    updateBrands,
    updateBrandSelected,
    updateIsSiteModalOpen,
    updateLoadingBrands,
    updateLoadingSites,
    updateLoadingSiteSelected,
    updateSites,
    updateSiteSelected,
} from './actions';
import { ApSpinner } from '@aphilia/shared-ui-core-react';
import { ApSiteModal } from '../../components/ApSiteModal';
import { getBrands, getSitesByBrandId, getSitesByConnectedUser } from '@aphilia/shared/organizations/data-access';

function createInitialState() {
    return {
        isLoadingSites: true,
        isLoadingSelectedSite: true,
        isSiteModalOpen: false,
        forceSiteSelected: true,
        useBrands: false,
        siteSelected: {} as Site,
        sites: [],
        brands: [],
        brandSelected: {} as Brand,
    };
}

export interface OrganizationsStateObject {
    organizationsUrl?: string;
    siteSelected?: Site;
    sites?: Site[];
    isLoadingSites?: boolean;
    isLoadingSelectedSite?: boolean;
    setSites?: () => Promise<Site[]>;
    setSiteSelected?: (siteId: string) => Promise<Site>;
    brands?: Brand[];
    setBrands?: () => Promise<Brand[]>;
    setBrandSelected?: (brandId: string) => Promise<Brand>;
    brandSelected?: Brand;
    isLoadingBrands?: boolean;
    isLoadingBrandSelected?: boolean;
    isSiteModalOpen?: boolean;
    updateSiteModalState?: (state: boolean) => void;
    useBrands?: boolean;
}

const OrganizationsContext = createContext<OrganizationsStateObject>({});

const initialState = createInitialState();

interface Props {
    children: React.ReactNode;
    initial?: OrganizationsStateObject;
    organizationsUrl: string;
    customBrandLoader?: () => Promise<Brand[]>;
}

const OrganizationsStateProvider = ({ children, initial, organizationsUrl, customBrandLoader }: Props) => {
    const [isInitializing, setIsInitializing] = useState<boolean>(true);

    const [state, dispatch] = useReducer<Reducer<OrganizationsStateObject, any>>(
        (currentState: OrganizationsStateObject, action: { type: keyof typeof OrganizationsActions; payload?: any }) => {
            switch (action.type) {
                case OrganizationsActions.UPDATE_STATE:
                    return Object.assign({}, currentState, action.payload);
                case OrganizationsActions.UPDATE_SITE_SELECTED:
                    return Object.assign({}, currentState, {
                        siteSelected: action.payload,
                    });
                case OrganizationsActions.UPDATE_SITES:
                    return Object.assign({}, currentState, {
                        sites: action.payload,
                    });
                case OrganizationsActions.UPDATE_LOADING_SITES:
                    return Object.assign({}, currentState, {
                        isLoadingSites: action.payload,
                    });
                case OrganizationsActions.UPDATE_LOADING_SITE_SELECTED:
                    return Object.assign({}, currentState, {
                        isLoadingSelectedSite: action.payload,
                    });
                case OrganizationsActions.UPDATE_OPEN_SITE_MODAL:
                    return Object.assign({}, currentState, {
                        isSiteModalOpen: action.payload,
                    });
                case OrganizationsActions.UPDATE_BRANDS:
                    return Object.assign({}, currentState, {
                        brands: action.payload,
                    });
                case OrganizationsActions.UPDATE_LOADING_BRANDS:
                    return Object.assign({}, currentState, {
                        isLoadingBrands: action.payload,
                    });
                case OrganizationsActions.UPDATE_BRAND_SELECTED:
                    return Object.assign({}, currentState, {
                        brandSelected: action.payload,
                    });
                default:
                    throw new Error();
            }
        },
        {
            ...initialState,
            ...initial,
        }
    );

    /**
     * Use effect when the user has been set
     */
    useEffect(() => {
        if (state.useBrands) {
            setBrands().then(async (res) => {
                await setBrandSelected(res[0]._id, res);
                setIsInitializing(false);
            });
        } else {
            setSites().then(() => setIsInitializing(false));
        }
    }, [state.useBrands]);

    /**
     * Trigger when the selected brand id is updated.
     * It will load the sites of the selected brand
     */
    useEffect(() => {
        if (state.useBrands && state.brandSelected?._id) {
            // Set timeout so the dispatch has time to happen
            setSites().then(async (res) => {
                await setSiteSelected(res[0]?._id, res);
                setIsInitializing(false);
            });
        }
    }, [state.brandSelected?._id]);

    /**
     * Set the site selected and update the user metadata
     */
    const setSiteSelected = useCallback(
        async (siteId: string, sites?: Site[]) => {
            try {
                dispatch(updateLoadingSiteSelected(true));
                const newSite = sites ? sites?.find((site) => site._id === siteId) : state.sites?.find((site) => site._id === siteId);
                dispatch(updateSiteSelected(newSite));
                (window as any).siteSelectedId = newSite?._id;
                (window as any).brandSelectedId = newSite?.brandId;
                return newSite;
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                dispatch(updateLoadingSiteSelected(false));
            }
        },
        [state.sites]
    );

    /**
     * Load all the sites that the use has accessed to.
     *
     * It will set the sites in the sites variable of the organizations provider
     */
    const setSites = useCallback(async (): Promise<Site[]> => {
        try {
            dispatch(updateLoadingSites(true));
            let allSites = [];

            // If we use the brands, then load the sites with the brandId Selected
            if (state.useBrands) {
                if (state.brandSelected?._id) allSites = await getSitesByBrandId(organizationsUrl, state.brandSelected?._id);
            } else {
                allSites = await getSitesByConnectedUser(organizationsUrl);
            }

            // If the site selected doesn't exist in the list of site anymore then we need to set the selected site as undefined.
            if (!allSites.some((site: Site) => site._id === state.siteSelected?._id)) {
                dispatch(updateSiteSelected(undefined));
            }

            dispatch(updateSites(allSites));
            return allSites;
        } catch (e) {
            console.error(e);
            dispatch(updateLoadingSites(false));
            throw e;
        } finally {
            dispatch(updateLoadingSites(false));
        }
    }, [organizationsUrl, state.useBrands, state.brandSelected?._id, state.siteSelected?._id]);

    /**
     * Open the site modal so the user can choose a site
     */
    const updateSiteModalState = useCallback((isOpen: boolean) => {
        dispatch(updateIsSiteModalOpen(isOpen));
    }, []);

    /**
     * Load the brands from the database and set them in the brand variable.
     */
    const setBrands = useCallback(async () => {
        try {
            dispatch(updateLoadingBrands(true));
            const allBrands = (customBrandLoader && (await customBrandLoader())) || (await getBrands(organizationsUrl));
            dispatch(updateBrands(allBrands));
            dispatch(updateLoadingBrands(false));
            return allBrands;
        } catch (e) {
            console.error(e);
            dispatch(updateLoadingBrands(false));
            throw e;
        }
    }, [organizationsUrl]);

    /**
     * Load all the sites that the use has accessed to.
     *
     * It will set the sites in the sites variable of the organizations provider
     */
    const setBrandSelected = useCallback(
        async (brandId: string, brands?: Brand[]) => {
            try {
                const newBrand = brands ? brands?.find((brand) => brand._id === brandId) : state.brands?.find((brand) => brand._id === brandId);
                dispatch(updateBrandSelected(newBrand));
                return newBrand;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        [state.brands]
    );

    return (
        <OrganizationsContext.Provider
            value={{
                ...state,
                setSites,
                siteSelected: state.siteSelected,
                setSiteSelected,
                updateSiteModalState,
                setBrands,
                setBrandSelected,
                organizationsUrl,
            }}
        >
            {isInitializing ? <ApSpinner /> : <>{children}</>}
            <ApSiteModal />
        </OrganizationsContext.Provider>
    );
};

// Generate a hook to use the organization context
const useOrganizationContext = () => useContext(OrganizationsContext);

export { useOrganizationContext, OrganizationsStateProvider, OrganizationsContext };
