import { DayPeriod } from './day-period';
import * as faker from 'faker';
import moment from 'moment';

export const fakeDayPeriodData = (): DayPeriod => ({
    name: faker.name.findName(),
    startTime: moment().format('HH:mm'),
    color: faker.internet.color(),
});

export const fakeDayPeriod = (): DayPeriod => new DayPeriod(fakeDayPeriodData());

export const fakeDayPeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): DayPeriod[] => {
    const dayPeriod: DayPeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        dayPeriod.push(fakeDayPeriod());
    }

    return dayPeriod;
};
