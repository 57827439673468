import { ApCol, ApRow, ApSelect, ApToggle } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useMemo } from 'react';
import { useDeliveryParametersContext } from '../../stores';
import { PriceType } from '@aphilia/shared/utils';
import { UpdateDeliveryParameters } from '@aphilia/deliveries/data';
import { useToastContext } from '@aphilia/shared/react';

export const DeliveryParametersPlatformConfiguration = () => {
    const { deliveryParameters, updateDeliveryParameters } = useDeliveryParametersContext();
    const { showErrorToast, showSuccessToast } = useToastContext();

    const levelPriceOptions = useMemo(
        () => [
            {
                value: PriceType.Default,
                label: 'VALEUR',
            },
            {
                value: PriceType.Max,
                label: 'MIN',
            },
            {
                value: PriceType.Min,
                label: 'MAX',
            },
        ],
        []
    );

    const updateParameters = useCallback(
        async (parameters: UpdateDeliveryParameters) => {
            try {
                await updateDeliveryParameters(parameters);
                showSuccessToast('Les paramètres ont été mis à jour');
            } catch (e) {
                console.error(e);
                showErrorToast('Une erreur est survenue, veuillez ré-essayer');
            }
        },
        [updateDeliveryParameters]
    );

    const priceLevelDelivery = useMemo(() => levelPriceOptions.find((price) => price.value === deliveryParameters.delivery?.priceLevel), [
        deliveryParameters.delivery?.priceLevel,
        levelPriceOptions,
    ]);
    const priceLevelClickAndCollect = useMemo(() => levelPriceOptions.find((price) => price.value === deliveryParameters.clickAndCollect?.priceLevel), [
        deliveryParameters.clickAndCollect?.priceLevel,
        levelPriceOptions,
    ]);
    const priceLevelAtTable = useMemo(() => levelPriceOptions.find((price) => price.value === deliveryParameters.atTable?.priceLevel), [
        deliveryParameters.atTable?.priceLevel,
        levelPriceOptions,
    ]);

    return (
        <ApRow>
            <ApCol>
                <ApRow>
                    <ApCol>
                        <h5>Livraison</h5>
                        <ApRow alignItems="center">
                            <ApCol>
                                <ApToggle
                                    checked={deliveryParameters.delivery?.enabled}
                                    label="Actif"
                                    onApToggle={(e) => updateDeliveryParameters({ delivery: { enabled: e.detail } })}
                                />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApSelect
                                    value={[priceLevelDelivery.value]}
                                    options={levelPriceOptions}
                                    label="Niveau de prix"
                                    onItemSelected={(e) => updateDeliveryParameters({ delivery: { priceLevel: e.detail.value } })}
                                />
                            </ApCol>
                        </ApRow>
                    </ApCol>
                    <ApCol>
                        <h5>Click And Collect</h5>
                        <ApRow alignItems="center">
                            <ApCol>
                                <ApToggle
                                    label="Actif"
                                    checked={deliveryParameters.clickAndCollect?.enabled}
                                    onApToggle={(e) => updateDeliveryParameters({ clickAndCollect: { enabled: e.detail } })}
                                />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApSelect
                                    value={[priceLevelClickAndCollect.value]}
                                    options={levelPriceOptions}
                                    label="Niveau de prix"
                                    onItemSelected={(e) => updateDeliveryParameters({ clickAndCollect: { priceLevel: e.detail.value } })}
                                />
                            </ApCol>
                        </ApRow>
                    </ApCol>
                    <ApCol>
                        <h5>A Table</h5>
                        <ApRow alignItems="center">
                            <ApCol size={3}>
                                <ApToggle
                                    label="Actif"
                                    checked={deliveryParameters.atTable?.enabled}
                                    onApToggle={(e) => updateDeliveryParameters({ atTable: { enabled: e.detail } })}
                                />
                            </ApCol>
                        </ApRow>
                        <ApRow>
                            <ApCol>
                                <ApSelect
                                    value={[priceLevelAtTable.value]}
                                    options={levelPriceOptions}
                                    label="Niveau de prix"
                                    onItemSelected={(e) => updateDeliveryParameters({ atTable: { priceLevel: e.detail.value } })}
                                />
                            </ApCol>
                        </ApRow>
                    </ApCol>
                </ApRow>
            </ApCol>
        </ApRow>
    );
};
