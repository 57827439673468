import * as faker from 'faker';
import { PiclickV2 } from './piclick-v2';
import { fakePiclickV2ConsumableArray } from './piclick-v2-consumable/piclick-v2-consumable.data';
import { fakePiclickV2StyleParametersData } from './piclick-v2-parameters/piclick-v2-parameters.data';
import { fakePiClickV2DeliveryParametersData } from './piclick-v2-delivery-parameters/piclick-v2-delivery-parameters.data';

export const fakePiclickV2Data = (): PiclickV2 => ({
    style: fakePiclickV2StyleParametersData(),
    enabled: faker.random.boolean(),
    consumables: fakePiclickV2ConsumableArray(),
    desktopCover: faker.random.image(),
    inPreparationLoaderUrl: faker.random.image(),
    deliveryParameters: fakePiClickV2DeliveryParametersData(),
});

export const fakePiclickV2 = (): PiclickV2 => new PiclickV2(fakePiclickV2Data());
