import React, { ReactNode, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ApModal } from '@aphilia/shared-ui-core-react';

interface ApPortalModalProps {
    children?: any;
    isOpen?: boolean;
    headerTitle?: string;
    cancelText?: string;
    confirmText?: string;
    canHide?: boolean;
    headerIcon?: string;
    color?: any;
    hideCloseButton?: boolean;
    isLoading?: boolean;
    fullScreenMobile?: boolean;
    centerTitle?: boolean;
    onConfirm?: any;
    onHide?: any;
}

function ApPortalModal({
    children,
    isOpen = false,
    headerTitle,
    cancelText,
    confirmText,
    canHide = true,
    headerIcon,
    color = 'primary',
    hideCloseButton = false,
    isLoading = false,
    fullScreenMobile = false,
    centerTitle = false,
    onConfirm,
    onHide,
}: ApPortalModalProps) {
    const modalLayer = useRef(null);

    const renderModal = useCallback(() => {
        return (
            <ApModal
                headerTitle={headerTitle}
                centerTitle={centerTitle}
                color={color}
                isOpen={isOpen}
                fullScreenMobile={fullScreenMobile}
                confirmText={confirmText}
                cancelText={cancelText}
                onConfirm={onConfirm}
                onHide={onHide}
                canHide={canHide}
                headerIcon={headerIcon}
                hideCloseButton={hideCloseButton}
                isLoading={isLoading}
            >
                {children}
            </ApModal>
        );
    }, [isOpen, children, fullScreenMobile, centerTitle, headerTitle, cancelText, confirmText, canHide, headerIcon, color, hideCloseButton, isLoading, onConfirm, onHide]);

    if (isOpen) {
        if (modalLayer?.current) {
            modalLayer.current.classList.add('ap-modal-open');
        } else {
            modalLayer.current = document.createElement('div');
            modalLayer.current.classList.add('ap-modal-layer', 'ap-modal-open');
            document.body.appendChild(modalLayer.current);
        }

        return ReactDOM.createPortal(renderModal(), modalLayer.current);
    } else {
        if (modalLayer?.current) {
            modalLayer?.current.classList.remove('ap-modal-open');
            modalLayer.current.remove();
            modalLayer.current = null;
        }
    }

    return null;
}

export { ApPortalModal /*, ApPortalModal as ApModal*/ };
