import { QueryWebArticleCategory } from './query-web-article-category';
import * as faker from 'faker';

export const fakeQueryWebArticleCategoryData = (): QueryWebArticleCategory => ({
    siteId: faker.random.uuid(),
});

export const fakeQueryWebArticleCategory = (): QueryWebArticleCategory => new QueryWebArticleCategory(fakeQueryWebArticleCategoryData());

export const fakeQueryWebArticleCategoryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): QueryWebArticleCategory[] => {
    const webArticleCategory: QueryWebArticleCategory[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleCategory.push(fakeQueryWebArticleCategory());
    }

    return webArticleCategory;
};
