export class UpdateCustomer {
    /**
     * First Name of the costumer
     *
     * @example John
     */
    firstName?: string;

    /**
     * Last Name of the costumer
     *
     * @example Doe
     */
    lastName?: string;

    /**
     * Phone number
     *
     * @format ISO
     * @example +3306000000
     */
    phoneNumber?: string;

    /**
     * The email of the consumer
     */
    email?: string;

    /**
     * The address of the customer
     */
    address?: string;

    /**
     * Comment about the address that the customer can add
     *
     * example: floor, code ...
     */
    addressComment?: string;

    /**
     * Job id of the drop off if there is one
     */
    jobId?: number;

    /**
     * Tracking URL for the delivery
     */
    trackingUrl?: string;

    /**
     * Indicates if the customer agrees to receive site information
     */
    wantSiteInformation?: boolean;

    /**
     * Token of the user if they are in a collectivity setting
     */
    token?: string;

    constructor(props: UpdateCustomer) {
        Object.assign(this, props);
    }
}
