export class UpdateUber {
    /**
     * Indicates if the integration with uber is enabled or not
     */
    enabled?: boolean;

    /**
     * Uber location id of the site
     */
    locationId?: string;

    /**
     * Indicates if the pos integration is enabled or not.
     * The Uber integration can be enabled while the pos integration is not.
     * If it's not enabled, orders won't be send automatically from Uber to the pos system
     */
    posIntegrationEnabled?: boolean;

    constructor(props: UpdateUber) {
        Object.assign(this, props);
    }
}
