import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ApActionButtons, ApRow } from '@aphilia/shared-ui-core-react';

import { useBlockActions } from './block-validation.hooks';

import style from './block-validation.style';

interface BlockValidationProps {
    isUnchanged: boolean;
    isValid?: boolean;
    isLoading?: boolean;
    cancelText: string;
    confirmText: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const useStyles = makeStyles(style);

export function BlockValidation({ cancelText, confirmText, isUnchanged, onCancel, onConfirm, isLoading = false, isValid = true }: BlockValidationProps) {
    const classes = useStyles();
    const { handleConfirm, handleCancel } = useBlockActions(onConfirm, onCancel);

    return (
        <ApRow className={classes.root} justifyContent="end">
            <ApActionButtons
                cancelText={cancelText}
                confirmText={confirmText}
                disabled={!isValid || isUnchanged}
                onApCancel={handleCancel}
                onApConfirm={handleConfirm}
                isLoading={isLoading}
            />
        </ApRow>
    );
}
