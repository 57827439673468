import { WeekdayTimePeriods } from '@aphilia/shared/utils';

export class WebArticleCategory {
    /**
     * Category id in the database.
     */
    readonly _id: string;

    /**
     * The brand id of the category
     */
    readonly brandId: string;

    /**
     * The id of the category's descriptive sheet
     */
    readonly descriptiveSheetId: string;

    /**
     * The id of the category's parent category
     */
    readonly parentCategoryId?: string;

    /**
     * Name of the category depending on the language.
     */
    title: Record<string, string>;

    /**
     * Subtitle of the category
     */
    subtitle?: Record<string, string>;

    /**
     * List of all the ids of the articles that are in this category
     */
    articles?: string[];

    /**
     * Order of the category in the list of categories
     */
    order: number;

    /**
     * Can category order be changed
     */
    sortable?: boolean;

    /**
     * Image urls of the category
     */
    images?: string[];

    /**
     * Level of the category
     */
    level?: number;

    /**
     * All the active periods for the web category if there is.
     * If there is non then the web article will be considered as always available
     */
    periods?: WeekdayTimePeriods[];

    constructor(props: WebArticleCategory) {
        Object.assign(this, props);
    }
}
