import { WeekdayTimePeriods } from './weekday-time-periods';
import * as faker from 'faker';
import { Weekday } from '../../weekday';
import { fakeTimePeriodArray } from '../../time-period';

export const fakeWeekdayTimePeriodsData = () => ({
    weekday: faker.random.objectElement<Weekday>(Weekday as any),
    timePeriods: fakeTimePeriodArray(),
    isOpen: true,
});

export const fakeWeekdayTimePeriods = (): WeekdayTimePeriods => new WeekdayTimePeriods(fakeWeekdayTimePeriodsData());

export const fakeWeekdayTimePeriodsArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WeekdayTimePeriods[] => {
    const weekdayTimePeriods: WeekdayTimePeriods[] = [];

    for (let i = 0; i < nbMax; i++) {
        weekdayTimePeriods.push(fakeWeekdayTimePeriods());
    }

    return weekdayTimePeriods;
};
