import { WebArticlePlatformConfiguration } from './web-article-platform-configuration';
import * as faker from 'faker';

export const fakeWebArticlePlatformConfigurationData = (): WebArticlePlatformConfiguration => ({
    price: parseInt(faker.finance.amount(), 10),
    enabled: faker.random.boolean(),
});

export const fakeWebArticlePlatformConfiguration = (): WebArticlePlatformConfiguration => new WebArticlePlatformConfiguration(fakeWebArticlePlatformConfigurationData());

export const fakeWebArticlePlatformConfigurationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebArticlePlatformConfiguration[] => {
    const webArticlePlatformConfiguration: WebArticlePlatformConfiguration[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticlePlatformConfiguration.push(fakeWebArticlePlatformConfiguration());
    }

    return webArticlePlatformConfiguration;
};
