export class PiclickV2ConsummerParameters {
    /**
     * Optin hook url
     */
    serverAddress: string;

    /**
     * Hook update frequency
     */
    frequency: string;

    /**
     * Hook update sending time
     */
    sendingTime: string;
}
