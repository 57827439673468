export class UpdateMultipleOrdersState {
    /**
     * Id of the site
     */
    siteId: string;

    /**
     * All the orders id
     */
    ordersId: string[];

    /**
     * All the orders id
     */
    asap?: boolean;

    constructor(props: UpdateMultipleOrdersState) {
        Object.assign(this, props);
    }
}
