import { DeliverySiteStatus } from '@aphilia/deliveries/data';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { PriceType } from '@aphilia/shared/utils';

export class Delivery {
    /**
     * Indicates if the delivery is enabled or not
     */
    enabled: boolean;

    /**
     * The price level use for the delivery
     */
    priceLevel: PriceType;

    /**
     * All the time periods when the menu is available
     */
    timePeriods: WeekdayTimePeriods[];

    /**
     * Cover image for the delivery
     */
    coverImage?: string;

    /**
     * Indicates the maximum number of orders per interval.
     */
    maxOrderPerInterval?: number;

    /**
     * Indicates the prepayment options for the platform
     */
    prepayment?: 'MANDATORY' | 'OPTIONAL' | 'NONE';

    /**
     * Minimum amount authorized for the order
     */
    minOrderValue?: number;

    /**
     * Indicates if the delivery platform is paused. Set to false to indicate that the plateform is paused
     */
    status: DeliverySiteStatus;

    constructor(props: Delivery) {
        Object.assign(this, props);

        this.timePeriods = this.timePeriods?.map((timePeriod) => new WeekdayTimePeriods(timePeriod)) || [];
    }
}
