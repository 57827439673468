import Stripe from 'stripe';
import { Payment, RefundPayment } from '@aphilia/payments/types';
import { QueryPaginatePayment, StripeAccount, UpdateStripeAccount } from '@aphilia/payments/types';
import { BaseModelPaginate } from '@aphilia/shared/utils';
import axios from 'axios';
import moment, { Moment } from 'moment';

/**
 * Return an account base on the site Id of the user
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getStripeAccount(paymentsServiceUrl: string, siteId: string): Promise<StripeAccount> {
    try {
        const result = await axios.get<StripeAccount>(`${paymentsServiceUrl}/stripe/account/${siteId}`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return a link to finish completing the account
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getStripeAccountLink(paymentsServiceUrl: string, siteId: string): Promise<Stripe.AccountLink> {
    try {
        const result = await axios.get<Stripe.AccountLink>(`${paymentsServiceUrl}/stripe/account/${siteId}/link`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Create or update a stripe account
 *
 * @param stripeAccount
 * @param paymentsServiceUrl
 */
export async function createOrUpdateStripeAccount(paymentsServiceUrl: string, stripeAccount: UpdateStripeAccount): Promise<StripeAccount> {
    try {
        const result = await axios.put<StripeAccount>(`${paymentsServiceUrl}/stripe/account/`, stripeAccount, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Update a stripe account
 *
 * @param paymentsServiceUrl
 * @param updateAccount
 * @param siteId
 */
export async function updateStripeAccountBySiteId(paymentsServiceUrl: string, siteId: string, updateAccount: UpdateStripeAccount): Promise<StripeAccount> {
    try {
        const result = await axios.put(`${paymentsServiceUrl}/stripe/account/`, updateAccount, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Create or update a stripe account
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getDashboardLink(paymentsServiceUrl: string, siteId: string): Promise<Stripe.LoginLink> {
    try {
        const result = await axios.get(`${paymentsServiceUrl}/stripe/account/${siteId}/dashboard`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Get all the payments of a stripe account
 *
 * @param paymentsServiceUrl
 * @param query
 */
export async function getPayments(paymentsServiceUrl: string, query: QueryPaginatePayment): Promise<BaseModelPaginate<Payment>> {
    try {
        const result = await axios.get<BaseModelPaginate<Payment>>(`${paymentsServiceUrl}`, {
            params: query,
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Get all the charges of a stripe account
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getBalance(paymentsServiceUrl: string, siteId: string): Promise<number> {
    try {
        const result = await axios.get<number>(`${paymentsServiceUrl}/stripe/account/${siteId}/balance`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Create a new refund for a charge
 *
 * @param paymentsServiceUrl
 * @param paymentId
 * @param refund
 */
export async function createRefund(paymentsServiceUrl: string, paymentId: string, refund: RefundPayment): Promise<Payment> {
    try {
        const result = await axios.put<Payment>(`${paymentsServiceUrl}/${paymentId}/refund`, refund, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return the list of all the payouts made for the connected account
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param startAfter
 * @param endBefore
 */
export async function getPayouts(paymentsServiceUrl: string, siteId: string, startAfter?: string, endBefore?: string): Promise<{ payouts: Stripe.Payout[]; hasMore: boolean }> {
    try {
        const result = await axios.get<{ payouts: Stripe.Payout[]; hasMore: boolean }>(`${paymentsServiceUrl}/stripe/account/${siteId}/payouts`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
            params: { startAfter, endBefore },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

/**
 * Return the latest payout of an account
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getLatestPayoutBySiteId(paymentsServiceUrl: string, siteId: string): Promise<Stripe.Payout> {
    try {
        const result = await axios.get<Stripe.Payout>(`${paymentsServiceUrl}/stripe/account/${siteId}/payouts/latest`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
