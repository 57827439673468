import React, { ReactNode, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApSelect.style';
import { FormHelperText, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(style);

interface Props {
    className?: string;
    children: ReactNode;
    label?: string;
    value?: any;
    id: string;
    multiple?: boolean;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
    disabled?: boolean;
    helpText?: string;
    placeHolder?: string;
    readonly?: boolean;
}

export const ApSelect = ({ className, label, value, id, multiple, onChange, children, disabled = false, helpText, placeHolder, readonly }: Props) => {
    const classes = useStyles();
    const [rotation, setRotation] = useState(0);

    const toggleOpen = () => {
        setRotation((oldRotation) => {
            if (!oldRotation) return 180;
            return 0;
        });
    };

    return (
        <FormControl variant="filled" className={`${classes.root} ap-select${className ? ' ' + className : ''}${!label ? ' no-label' : ''}${disabled ? ' disabled' : ''}`}>
            {label && className !== 'ap-select-compact' && <InputLabel id={id}>{label}</InputLabel>}
            <Select
                className={(readonly && classes.readOnly) || ''}
                displayEmpty
                multiple={multiple}
                labelId={id}
                value={value}
                onChange={onChange}
                onOpen={toggleOpen}
                onClose={toggleOpen}
                inputProps={{
                    disabled,
                    readOnly: readonly,
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                IconComponent={(props) => <ExpandMoreIcon color="secondary" style={{ transform: `rotateZ(${rotation}deg)` }} {...props} />}
            >
                {placeHolder && (
                    <MenuItem disabled value="">
                        {placeHolder}
                    </MenuItem>
                )}
                {children}
            </Select>
            {helpText && <FormHelperText>{helpText}</FormHelperText>}
        </FormControl>
    );
};
