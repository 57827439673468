export class UpdateWebArticleModifier {
    /**
     * The site id of the modifier
     */
    siteId: string;

    /**
     * Name of the article in the current language
     */
    title?: Record<string, string>;

    constructor(props: UpdateWebArticleModifier) {
        Object.assign(this, props);
    }
}
