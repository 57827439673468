function createSQLCaseItems(caseField, caseData) {
    const allCases = caseData.map((currentCase) => {
        const caseValue =
            typeof currentCase[1] === 'number' || (typeof currentCase[1] === 'string' && currentCase[1].toUpperCase() === 'NULL') ? currentCase[1] : `'${currentCase[1]}'`;
        return `WHEN ${caseField} = ${currentCase[0]} THEN ${caseValue}`;
    });

    return allCases.join(' ');
}

export { createSQLCaseItems };
