import { CancelPayment, CreatePayment, Payment } from '@aphilia/payments/types';
import axios from 'axios';

/**
 * Return an account base on the site Id of the user
 *
 * @param paymentsServiceUrl
 * @param createPaymentData
 */
export async function createPayment(paymentsServiceUrl: string, createPaymentData: CreatePayment): Promise<{ payment: Payment; redirectionUrl: string }> {
    try {
        const result = await axios.post<{ payment: Payment; redirectionUrl: string }>(`${paymentsServiceUrl}`, createPaymentData, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Return a private payment by its id
 *
 * @param paymentsServiceUrl
 * @param paymentId
 */
export async function getPaymentById(paymentsServiceUrl: string, paymentId: string): Promise<Payment> {
    try {
        const result = await axios.get<Payment>(`${paymentsServiceUrl}/${paymentId}`, {
            headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}

/**
 * Get a public payment by its id
 *
 * @param paymentsServiceUrl
 * @param paymentId
 */
export const getPublicPaymentById = async (paymentsServiceUrl: string, paymentId: string): Promise<Payment> => {
    try {
        const result = await axios.get<Payment>(`${paymentsServiceUrl}/${paymentId}`, {
            headers: {
                'Cache-Control': 'no-cache, no-store',
                Pragma: 'no-cache',
                Expires: '0',
            },
        });
        return result.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Cancel a payment by its id
 *
 * @param paymentsServiceUrl
 * @param paymentId
 * @param cancelData
 */
export async function cancelPaymentById(paymentsServiceUrl: string, paymentId: string, cancelData: CancelPayment): Promise<Payment> {
    try {
        const result = await axios.put<Payment>(`${paymentsServiceUrl}/${paymentId}/cancel`, cancelData);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('Une erreur est survenue');
    }
}
