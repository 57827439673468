import { Order } from '@aphilia/deliveries/data';

export class PaginateOrder {
    /**
     * All the orders within the page
     */
    docs: Order[];

    /**
     * Total number of documents in collection that match a query
     */
    totalDocs?: number;

    /**
     * Limit that was used
     */
    limit?: number;

    /**
     * Total number of pages
     */
    totalPages?: number;

    /**
     * Current page number
     */
    page?: number;

    /**
     * The starting sl. number of first document.
     */
    pagingCounter?: number;

    /**
     * Availability of prev page.
     */
    hasPrevPage?: boolean;

    /**
     * Availability of next page.
     */
    hasNextPage?: boolean;

    /**
     * Previous page number if available or NULL.
     */
    prevPage?: number;

    /**
     * Next page number if available or NULL
     */
    nextPage?: number;
}
