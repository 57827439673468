import React from 'react';
import { ListItem, Typography } from '@material-ui/core';

interface Props {
    children: React.ReactNode;
    icon?: React.ReactNode;
}

export const ApListItem = ({ children, icon }: Props) => {
    return (
        <ListItem>
            <Typography>
                {icon}
                {children}
            </Typography>
        </ListItem>
    );
};
