export class DeliverooCategory {
    /**
     * Unique identifier for the category.
     */
    id: string;

    /**
     * Translated name of the category.
     */
    name: Object;

    /**
     * Translated description of the category.
     */
    description: Object;

    /**
     * IDs of the items inside the category.
     */
    item_ids: string[];

    constructor(props: DeliverooCategory) {
        Object.assign(this, props);
    }
}
