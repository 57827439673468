import React from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ApButton } from '../ApButton';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApCheckList.style';

const useStyles = makeStyles(style);

interface Props {
    group: Group;
    onChange?: (newValue: Group) => void;
    maxRows?: number;
}

interface Item {
    label: string;
    checked: boolean;
}

interface Group {
    label: string;
    items: Item[];
}

export const ApCheckList = ({ group, onChange = () => {}, maxRows = 2 }: Props) => {
    const classes = useStyles();
    const [moreItems, seeMore] = React.useState(false);
    const showMore = () => {
        seeMore(true);
    };

    const handleChange = (itemId: number) => () => {
        group.items[itemId].checked = !group.items[itemId].checked;
        onChange(group);
    };

    const handleHeaderClick = () => () => {
        let newValue = true;
        if (isGroupChecked() || isGroupIndeterminate()) {
            newValue = false;
        }

        group.items = group.items.map((item) => {
            item.checked = newValue;
            return item;
        });

        onChange(group);
        seeMore(true);
    };

    const isGroupChecked = () => group.items.every((current) => current.checked === true);

    const isGroupIndeterminate = () => {
        return !isGroupChecked() && group.items.some((current) => current.checked === true);
    };

    return (
        <Grid container key={group.label} className={`${classes.root} ap-checklist`}>
            <FormControlLabel
                className="ap-checklist-header"
                onChange={handleHeaderClick()}
                value={group.label}
                checked={isGroupChecked()}
                control={<Checkbox indeterminate={isGroupIndeterminate()} color="primary" />}
                label={group.label}
                labelPlacement="end"
            />
            <Collapse in={moreItems} collapsedHeight={maxRows * 40}>
                <Grid container>
                    {group.items.map((item: Item, itemId: number) => (
                        <Grid item xs={4} key={item.label}>
                            <FormControlLabel
                                key={item.label}
                                className="ap-checklist-item"
                                onChange={handleChange(itemId)}
                                value={item.label}
                                checked={item.checked}
                                control={<Checkbox color="primary" />}
                                label={item.label}
                                labelPlacement="end"
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
            {group.items.length > maxRows * 3 && !moreItems && (
                <ApButton clickCallback={showMore} disabled={false} variant="text" className="expand" color="secondary" endIcon={<i className="ap-expand-more"></i>}>
                    Afficher plus
                </ApButton>
            )}
        </Grid>
    );
};
