import { WebArticleAdditionalInformation } from './web-article-additional-information';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleUnavailabilityPeriod } from '../../web-article';
import moment from 'moment';

export const fakeWebArticleAdditionalInformationData = (): WebArticleAdditionalInformation => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    articleId: mongoose.Types.ObjectId().toHexString(),
    atTableAvailability: faker.random.boolean(),
    clickAndCollectAvailability: faker.random.boolean(),
    deliveryAvailability: faker.random.boolean(),
    isFavorite: faker.random.boolean(),
    periodOfUnavailability: faker.random.objectElement<WebArticleUnavailabilityPeriod>(WebArticleUnavailabilityPeriod),
    isAvailableForSale: faker.random.boolean(),
    suggestion: faker.random.boolean(),
    isSuccess: faker.random.boolean(),
    categoryOrder: faker.random.number(20),
    updatedAt: moment(),
});

export const fakeWebArticleAdditionalInformation = (): WebArticleAdditionalInformation => new WebArticleAdditionalInformation(fakeWebArticleAdditionalInformationData());

export const fakeWebArticleAdditionalInformationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebArticleAdditionalInformation[] => {
    const webArticleAdditionnalInformation: WebArticleAdditionalInformation[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleAdditionnalInformation.push(fakeWebArticleAdditionalInformation());
    }

    return webArticleAdditionnalInformation;
};
