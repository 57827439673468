import * as faker from 'faker';
import { CreateItem } from './create-item';

export const fakeCreateItemData = (_nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): CreateItem => ({
    _id: faker.random.uuid(),
    unitPrice: parseInt(faker.finance.amount(), 10),
    specialInstructions: faker.lorem.sentence(),
    selectedItems: [
        {
            _id: faker.random.uuid(),
            unitPrice: parseInt(faker.finance.amount(), 10),
            specialInstructions: faker.lorem.sentence(),
            title: faker.commerce.productName(),
            quantity: faker.random.number({ min: 0, max: 10 }),
            posNumber: faker.random.number(),
            selectedItems: [],
        },
        {
            _id: faker.random.uuid(),
            unitPrice: parseInt(faker.finance.amount(), 10),
            specialInstructions: faker.lorem.sentence(),
            title: faker.commerce.productName(),
            quantity: faker.random.number({ min: 0, max: 10 }),
            posNumber: faker.random.number(),
            selectedItems: [],
        },
        {
            _id: faker.random.uuid(),
            unitPrice: parseInt(faker.finance.amount(), 10),
            specialInstructions: faker.lorem.sentence(),
            title: faker.commerce.productName(),
            quantity: faker.random.number({ min: 0, max: 10 }),
            posNumber: faker.random.number(),
            selectedItems: [],
        },
    ],
    title: faker.commerce.productName(),
    quantity: faker.random.number({ min: 0, max: 10 }),
    posNumber: faker.random.number(),
});

export const fakeCreateItem = (nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): CreateItem => new CreateItem(fakeCreateItemData(nbModifierGroups));

export const fakeCreateItemArray = (nbMax: number = faker.random.number({ min: 1, max: 10 }), nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): CreateItem[] => {
    const items = [];

    for (let i = 0; i < nbMax; i++) {
        items.push(fakeCreateItem(nbModifierGroups));
    }

    return items;
};
