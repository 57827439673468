export enum WebArticleVatType {
    OnSite = 'on_site',
    TakeAway = 'take_away',
}

export class WebArticleVatInformation {
    /**
     * The value of the vat rate
     */
    value: number;

    /**
     * The type of the vat
     */
    type: WebArticleVatType;
}
