import React from 'react';

import { ApSelect, ApPagination } from '@aphilia/shared-ui-core-react';
import { usePages } from './hooks';

import './ap-table-footer.scss';

interface ApTableFooterProps {
    paginationText: string;
    paginationOptions: any[];
    rowsPerPage: number;
    currentPage: number;
    itemsCount: number;
    onRowsPerPageChange: (evt: CustomEvent) => void;
    onCurrentPageChange: (page: number) => void;
}

export function ApTableFooter({ paginationText, paginationOptions, rowsPerPage, itemsCount, currentPage, onRowsPerPageChange, onCurrentPageChange }: ApTableFooterProps) {
    const { handleCurrentPage } = usePages(onCurrentPageChange);

    return (
        <div className="ap-table-footer">
            <div className="ap-pagination-choice">
                {paginationText}
                <ApSelect className="ap-choice-select" options={paginationOptions} value={[rowsPerPage]} label="" compact={true} onItemSelected={onRowsPerPageChange} />
            </div>
            <ApPagination hasStartEndButtons={true} currentPage={currentPage} itemsCount={itemsCount} itemsPerPage={rowsPerPage} onPageChange={handleCurrentPage} />
        </div>
    );
}
