import { CreateWeekdayTimePeriods } from './create-weekday-time-periods';
import * as faker from 'faker';

export const fakeCreateWeekdayTimePeriodsData = (): CreateWeekdayTimePeriods => ({
    name: faker.name.findName(),
});

export const fakeCreateWeekdayTimePeriods = (): CreateWeekdayTimePeriods => new CreateWeekdayTimePeriods(fakeCreateWeekdayTimePeriodsData());

export const fakeCreateWeekdayTimePeriodsArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWeekdayTimePeriods[] => {
    const weekdayTimePeriods: CreateWeekdayTimePeriods[] = [];

    for (let i = 0; i < nbMax; i++) {
        weekdayTimePeriods.push(fakeCreateWeekdayTimePeriods());
    }

    return weekdayTimePeriods;
};
