import { WebArticleCategory } from './web-article-category';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { fakeWebArticleArray } from '../../web-article';

export const fakeWebArticleCategoryData = (articles?: string[]): WebArticleCategory => {
    const images = [];
    for (let i = 0; i < faker.random.number(3); i++) {
        images.push(faker.image.food());
    }
    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        descriptiveSheetId: mongoose.Types.ObjectId().toHexString(),
        title: {
            fr: faker.commerce.productName(),
            en: faker.commerce.productName(),
        },
        subtitle: {
            fr: faker.commerce.productName(),
            en: faker.commerce.productName(),
        },
        articles: articles || fakeWebArticleArray().map((article) => article._id),
        order: 2,
        sortable: faker.random.boolean(),
        images,
        brandId: faker.random.uuid(),
        level: faker.random.number(),
    };
};

export const fakeWebArticleCategory = (articles?: string[]): WebArticleCategory => new WebArticleCategory(fakeWebArticleCategoryData(articles));

export const fakeWebArticleCategoryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 }), articles?: string[]): WebArticleCategory[] => {
    const webArticleCategory: WebArticleCategory[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleCategory.push(fakeWebArticleCategory(articles));
    }

    return webArticleCategory;
};
