export class TimePeriod {
    /**
     * The id of the period if it exits
     */
    _id?: string;

    /**
     * The start time of the period in the HH:mm format
     */
    startTime: string;

    /**
     * The end time of the period in the HH:mm format
     */
    endTime: string;

    /**
     * The name of the time period if needed
     */
    name?: string;

    /**
     * Color in HEX format use to display the background of this service
     */
    color?: string;

    /**
     * Array of days in a week (true: active, false inactive)
     */
    days?: boolean[];

    /**
     * Id of associated Period
     */
    periodId?: string;

    /**
     * Id of associated TimeSlot
     */
    timeSlotId?: string;

    constructor(props: TimePeriod) {
        Object.assign(this, props);
    }
}
