export class UpdateWebArticleCategory {
    /**
     * Unique identifier of the category
     */
    _id?: string;

    /**
     * Title of the category
     */
    title?: Record<string, string>;

    /**
     * Sub title of the category
     */
    subtitle?: Record<string, string>;

    /**
     * Order of the category
     */
    order?: number;

    /**
     * Id of the parent category
     */
    parentCategoryId?: string;

    constructor(props: UpdateWebArticleCategory) {
        Object.assign(this, props);
    }
}
