import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) =>
    createStyles({
        container: {
            '& .MuiDialog-paper': {
                padding: 24,
            },
        },
        header: {
            flexShrink: 0,
            marginBottom: 24,
            position: 'relative',
            '& .title': {
                display: 'flex',
                alignItems: 'center',
                paddingRight: 24,
                '& h5': {
                    marginRight: 24,
                },
            },
            '& .close': {
                position: 'absolute',
                right: 0,
                cursor: 'pointer',
            },
            '& .sub-header': {
                marginTop: 8,
            },
        },
        body: {
            overflow: 'auto',
            padding: '0 10px',
            width: 'calc(100% + 20px)',
            marginLeft: -10,
            marginRight: -10,
        },
        footer: {
            paddingTop: 40,
            flexShrink: 0,
            '& .actions': {
                marginBottom: 0,
                marginTop: 0,
                '& .ap-inline-button': {
                    marginLeft: 'auto',
                },
            },
        },
    });
