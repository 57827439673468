import colorsArray from './colors.json';
import textStylesArray from './textStyles.json';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const camelize = (str: string, separator: string) => {
    const arr = str.split(new RegExp(`[${separator}| ]`, 'gm'));
    const capital = arr.map((item: string, index: number) => (index ? item.charAt(0).toUpperCase() : item.charAt(0).toLowerCase()) + item.slice(1).toLowerCase());
    return capital.join('');
};

const colors = colorsArray.reduce((obj, color) => {
    const colorName = camelize(color.name.replace('_', '-'), '-');
    obj[colorName] = `#${color.hex}`;
    if (color.alpha < 1) {
        let value = Math.round(color.alpha * 255).toString(16);
        if (value.length === 1) value = '0' + value;
        obj[colorName] += value;
    }
    return obj;
}, {});

const typeStyles = textStylesArray.reduce((obj, textStyle) => {
    const name = camelize(textStyle.name, '/');
    obj[name] = {
        color: `#${textStyle.color.hex}`,
        alignment: textStyle.alignment,
        letterSpacing: textStyle.letterSpacing,
        lineHeight: textStyle.lineHeight,
    };

    Object.keys(textStyle.font).forEach((property) => {
        if (property === 'weight') {
            switch (textStyle.font[property]) {
                case 'light':
                    obj[name].fontWeight = 100;
                    break;
                case 'medium':
                    obj[name].fontWeight = 500;
                    break;
                case 'bold':
                    obj[name].fontWeight = 700;
                    break;
                default:
                    obj[name].fontWeight = 400;
                    break;
            }
        } else {
            obj[name][`font-${property}`] = textStyle.font[property];
        }
    });
    return obj;
}, {});

export const designTokens: {
    colors: { [key: string]: string };
    typeStyles: { [key: string]: CSSProperties };
} = {
    colors,
    typeStyles,
};
