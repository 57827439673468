import { UberQuantityConstraint } from '../quantity-constraint/quantity-constraint';
import { UberQuantityConstraintOverride } from '../quantity-constraint-override/quantity-constraint-override';

export class UberQuantityConstraintRules {
    /**
     * Default quantity constraints for the item.
     */
    quantity: UberQuantityConstraint;

    /**
     * Overrides for the quantity constraints in different contexts.
     */
    overrides?: UberQuantityConstraintOverride[];

    constructor(props: Partial<UberQuantityConstraintRules>) {
        Object.assign(this, props);
    }
}
