import { Ability, AbilityBuilder } from '@casl/ability';

export function baseDefineAbilityFor<T extends string, K>(permissions: string[], isAdmin: boolean) {
    const { can, build } = new AbilityBuilder<Ability<[T, K]>>(Ability);

    if (isAdmin) {
        // @ts-ignore
        can('manage', 'all'); // read-write access to everything
    } else {
        // We need to parse the permissions data
        // All permissions must be set with the following convention: <action>:<subject>
        // So for example you will have the read:user permission

        permissions.forEach((permission) => {
            const perm = permission.split(':');
            // @ts-ignore
            can(perm[0], perm[1]); // read-only access to everything
        });
    }

    return build();
}
