import { useEffect, useState } from 'react';
import { DeliveryParameters, DeliveryParametersEvent } from '@aphilia/deliveries/data';
import Pusher from 'pusher-js';

interface Props {
    pusherClientKey: string;
    pusherCluster: string;
    deliveriesUrl: string;
    initialDeliveryParameters: DeliveryParameters;
}

function useDeliveryParametersSockets({ pusherClientKey, pusherCluster, deliveriesUrl, initialDeliveryParameters }: Props) {
    const [pusher, setPusher] = useState<Pusher>();
    const [channel, setChannel] = useState<any>();
    const [deliveryParameters, setDeliveryParameters] = useState<DeliveryParameters>(initialDeliveryParameters);
    const [siteId, setSiteId] = useState<string>();

    useEffect(() => {
        if (!siteId) return;
        channel?.unsubscribe();
        setPusher((oldValue) => {
            oldValue?.disconnect();
            return new Pusher(pusherClientKey, {
                cluster: pusherCluster,
                authEndpoint: `${deliveriesUrl}/parameters/${siteId}/pusher`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${(window as any).authToken}`,
                    },
                },
            });
        });
    }, [siteId]);

    useEffect(() => {
        if (!pusher) return;
        setChannel(pusher.subscribe(`private-delivery-parameters-${siteId}`));
    }, [pusher]);

    useEffect(() => {
        if (channel) {
            channel?.bind(DeliveryParametersEvent.UpdateDeliveryParameters, (parameters: DeliveryParameters) => {
                setDeliveryParameters(parameters);
            });
        }
    }, [channel]);

    return { deliveryParameters, setSiteId };
}

export { useDeliveryParametersSockets };
