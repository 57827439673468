import { Theme } from '@material-ui/core/styles';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) => {
    return {
        root: {
            '& .MuiList-padding': {
                padding: 0,
            },
            '&.on-side': {
                '&.right-side': {
                    '& .MuiPaper-root': {
                        margin: `0 0 0 ${theme.spacing(1)}px`,
                    },
                },
                '&.left-side': {
                    '& .MuiPaper-root': {
                        margin: `0 0 0 -${theme.spacing(1)}px`,
                    },
                },
            },
            '&.on-heights': {
                '&:not(.center) .MuiPaper-root': {
                    margin: `${theme.spacing(1)}px 0`,
                },
                '&.h-center.bottom .MuiPaper-root': {
                    transform: 'translate(0, -56px) !important',
                },
                '&.h-center.top .MuiPaper-root': {
                    transform: 'translate(0, 40px) !important',
                },
                '&.h-center.v-center .MuiPaper-root': {
                    top: 'calc(50% + 21px) !important',
                },
            },
            '& .MuiPaper-root': {
                borderRadius: 3.1,
                boxShadow: '0 8px 16px 0 rgba(35, 35, 35, 0.2)',
                '& .MuiMenu-list > *': {
                    display: 'flex',
                    outline: 'none',
                    padding: '9px 16px',
                    borderBottom: `1px solid ${designTokens.colors.statesDefaultActive}`,
                    '&:hover': {
                        background: designTokens.colors.statesDefaultHover,
                    },
                    '&:active': {
                        background: designTokens.colors.statesPrimaryHover,
                    },
                    '&:last-child': {
                        borderBottom: 'none',
                    },
                    '& .MuiCheckbox-root': {
                        padding: '0 8px 0 0',
                        marginLeft: -8,
                        '&.Mui-checked + .MuiFormControlLabel-label': {
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },
        },
    };
};
