import { UberPriceOverride } from '../price-override/price-override';

export class UberPriceRules {
    /**
     * Price of the item in the lowest local currency denomination, e.g. cents.
     */
    price: number;

    /**
     * Overrides for the price in different contexts.
     */
    overrides?: UberPriceOverride[];

    constructor(props: Partial<UberPriceRules>) {
        Object.assign(this, props);

        if (this.overrides) {
            this.overrides.forEach((override) => new UberPriceOverride(override));
        }
    }
}
