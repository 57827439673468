export class DeliverooMealtime {
    /**
     * Id of the mealtime
     */
    id: string;

    /**
     * Translated name of the mealtime
     */
    name: Object;

    /**
     * Translated description of the mealtime
     */
    description?: Object;

    /**
     * Image for the mealtime.
     */
    image?: {
        /**
         * URL for the image.
         */
        url?: string;
    };

    /**
     * IDs of the categories inside the mealtime.
     */
    category_ids: string[];
}
