import React, { ElementType, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApButton.style';

const useStyles = makeStyles(style);

interface Props {
    children: any;
    clickCallback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success';
    variant?: 'contained' | 'text';
    component?: ElementType;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    className?: string;
    icon?: boolean;
    onMouseOver?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseOut?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    href?: string;
    to?: string;
    active?: boolean;
    title?: string;
}

export const ApButton = ({
    children,
    clickCallback,
    className,
    disabled,
    endIcon,
    startIcon,
    icon,
    color = 'primary',
    variant = 'contained',
    component,
    href,
    onMouseOver,
    onMouseOut,
    active,
    title,
    to,
}: Props) => {
    const classes = useStyles();

    const materialColor = color !== 'primary' ? 'secondary' : 'primary';
    const variantColor = variant === 'text' ? `${variant}-${materialColor}` : `${variant}-${color}`;

    const renderButton = useCallback(() => {
        return (
            <Button
                href={href}
                to={to}
                onClick={clickCallback}
                className={`${classes.root} ${classes[variantColor]} ${(icon && classes.icon) || ''} ${(active && 'active') || ''} ${className}`}
                disabled={disabled}
                endIcon={endIcon}
                startIcon={startIcon}
                disableElevation
                disableFocusRipple
                color={materialColor as 'primary' | 'secondary'}
                variant={variant}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                component={component}
            >
                {children}
            </Button>
        );
    }, [href, clickCallback, classes, variantColor, materialColor, icon, active, className, disabled, endIcon, startIcon, variant, onMouseOver, onMouseOut, component]);

    return <>{title ? <Tooltip title={title}>{renderButton()}</Tooltip> : renderButton()}</>;
};
