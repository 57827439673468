import { CreateAddress } from './create-address';
import * as faker from 'faker';

export const fakeCreateAddressData = (): CreateAddress => ({
    name: faker.name.findName(),
});

export const fakeCreateAddress = (): CreateAddress => new CreateAddress(fakeCreateAddressData());

export const fakeCreateAddressArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateAddress[] => {
    const address: CreateAddress[] = [];

    for (let i = 0; i < nbMax; i++) {
        address.push(fakeCreateAddress());
    }

    return address;
};
