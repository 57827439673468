import { Deliveroo } from './deliveroo';
import { Uber } from './uber';
import { Piclick } from '../piclick/piclick/piclick';
import { Stuart } from './stuart';

export class DeliveryApplication {
    /**
     * The piclick configuration
     */
    piClick?: Piclick;

    /**
     * All The information about the uber integration
     */
    uber?: Uber;

    /**
     * All the information about the deliveroo integration
     */
    deliveroo?: Deliveroo;

    /**
     * All the information about the stuart integration
     */
    stuart?: Stuart;

    constructor(props: DeliveryApplication) {
        Object.assign(this, props);
    }
}
