import { CreateWebMenu } from './create-web-menu';
import mongoose from 'mongoose';
import * as faker from 'faker';

export const fakeCreateWebMenuData = (): CreateWebMenu => ({
    siteId: mongoose.Types.ObjectId().toHexString(),
});

export const fakeCreateWebMenu = (): CreateWebMenu => new CreateWebMenu(fakeCreateWebMenuData());

export const fakeCreateWebMenuArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWebMenu[] => {
    const webMenu: CreateWebMenu[] = [];

    for (let i = 0; i < nbMax; i++) {
        webMenu.push(fakeCreateWebMenu());
    }

    return webMenu;
};
