import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

let debounce = null;

function useArticlesSearch(rows: Record<string, any>, searchOn = [], onSearch?: (rows: string[]) => void) {
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const getFilteredRows = useCallback((usedRows: Record<string, any>, search: string) => {
        if (!search) return Object.keys(rows);

        return Object.keys(usedRows).reduce((list, current) => {
            let hasValue = false;
            searchOn.forEach((col) => {
                if (usedRows[current][col].toString().toLowerCase().includes(search.toLowerCase())) hasValue = true;
            });
            if (hasValue) {
                list.push(current);
            }
            return list;
        }, []);
    }, []);

    const updateSearch = useCallback(
        (search: string, forcedRows?) => {
            if (debounce) clearTimeout(debounce);

            const usedRows = forcedRows || rows;
            debounce = setTimeout(() => {
                setFilteredRows(getFilteredRows(usedRows, search));
                setSearchValue(search);
            }, 300);
        },
        [rows, searchOn]
    );

    const handleSearch = useCallback(
        (evt) => {
            updateSearch(evt.detail);
        },
        [updateSearch]
    );

    useEffect(() => {
        if (!isEmpty(rows)) {
            setFilteredRows(searchValue ? getFilteredRows(rows, searchValue) : Object.keys(rows));
        }
    }, [rows]);

    useEffect(() => {
        if (typeof onSearch === 'function') onSearch(filteredRows);
    }, [onSearch, filteredRows]);

    return { filteredRows, searchValue, handleSearch };
}

export { useArticlesSearch };
