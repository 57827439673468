import { UberMenuEntity } from '../menu-entity/menu-entity';

export class UberCategory {
    /**
     * A unique identifying string for the category, provided by the restaurant.
     */
    id: string;

    /**
     * The displayed name for the category (by locale).
     */
    title: {
        translations: Record<string, string>;
    };

    /**
     * A subtitle for the category (by locale).
     */
    subtitle?: {
        translations: Record<string, string>;
    };

    /**
     * The top-level menu items available for sale within the category - all entities must be of type “ITEM”.
     */
    entities: UberMenuEntity[];

    constructor(category: Partial<UberCategory>) {
        Object.assign(this, category);
        this.entities.forEach((entity) => new UberMenuEntity(entity));
    }
}
