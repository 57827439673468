import axios from 'axios';
import { PosParametersStatusData } from '@aphilia/payments/types';
import { Pos } from '@aphilia/shared/pos/data';

export const getPosParametersStatus = async (paymentsServiceUrl: string, siteId: string): Promise<PosParametersStatusData> => {
    try {
        const { data } = await axios.get<PosParametersStatusData>(`${paymentsServiceUrl}/pos/${siteId}`);
        return data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while GetPosParametersStatus');
    }
};

/**
 * Get PoS information from site
 * @param posServiceUrl
 * @param siteId
 */
export const getAllPos = async (posServiceUrl: string, siteId: string): Promise<Pos[]> => {
    try {
        const response = await axios.get<Pos[]>(posServiceUrl, {
            headers: { authorization: `Bearer ${(window as any).authToken['default']}` },
            params: { siteId },
        });

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * Update the selected pos
 * @param posServiceUrl
 * @param siteId
 * @param posId
 */
export const updateSelectedPos = async (posServiceUrl: string, siteId: string, posId: number): Promise<Pos> => {
    try {
        const result = await axios.put<Pos>(`${posServiceUrl}/${posId}/select?siteId=${siteId}`, null, {
            headers: { authorization: `Bearer ${(window as any).authToken['default']}` },
        });
        return new Pos(result.data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};
