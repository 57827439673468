import { Theme, lighten } from '@material-ui/core/styles';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) => {
    const style = {
        root: {
            borderRadius: '3.14px',
            padding: 8,
            lineHeight: '24px',
            '& .MuiButton-iconSizeMedium > *:first-child': {
                fontSize: 24,
                width: 24,
                height: 24,
            },
            '& .MuiButton-startIcon': {
                marginLeft: 0,
            },
            '& .MuiButton-endIcon': {
                marginRight: 0,
            },
            '& .spinner, & .spinner .MuiCircularProgress-root': {
                width: '24px !important',
                height: '24px !important',
            },
        },
        icon: {
            minWidth: 40,
            '& svg, & i': {
                fontSize: 24,
                width: 24,
                height: 24,
            },
        },
        'text-primary': {
            backgroundColor: 'transparent',
            '&[disabled]': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.light,
            },
            '&:hover, &:active': {
                backgroundColor: designTokens.colors.statesPrimaryHover,
            },
            '&:active:focus, &.active': {
                backgroundColor: designTokens.colors.statesPrimaryHover,
                boxShadow: `0 0 0 2px ${theme.palette.primary.main} !important`,
            },
        },
        'text-secondary': {
            backgroundColor: 'transparent',
            '&[disabled]': {
                backgroundColor: 'transparent',
                color: theme.palette.secondary.light,
            },
            '&:hover, &:active': {
                backgroundColor: designTokens.colors.statesDefaultHover,
            },
            '&:active:focus, &.active': {
                backgroundColor: designTokens.colors.statesDefaultHover,
                boxShadow: `0 0 0 2px ${theme.palette.secondary.light} !important`,
            },
        },
    };
    ['primary', 'secondary', 'error', 'warning', 'success'].forEach((color) => {
        style[`contained-${color}`] = {
            backgroundColor: theme.palette[color].main,
            '&[disabled]': {
                backgroundColor: lighten(theme.palette[color].main, 0.45),
                color: theme.palette.common.white,
            },
            '&:hover, &:active': {
                backgroundColor: theme.palette[color].dark,
            },
            '&:active:focus, &.active': {
                backgroundColor: theme.palette[color].dark,
                boxShadow: `0 0 0 2px ${theme.palette[color].light} !important`,
            },
        };
    });
    return style;
};
