import * as React from 'react';

function SvgPiLogo(props) {
    return (
        <svg width={24} height={24} {...props}>
            <path
                // tslint:disable-next-line: max-line-length
                d="M21.9 3.1H2.2c-.1 0-.2 0-.2.2v2.2c0 .1.1.2.2.2h3.6c.1 0 .2.1.2.2v14.9c0 .1.1.2.2.2h2.2c.1 0 .2-.1.2-.2V5.9c0-.1.1-.2.2-.2h6.5c.1 0 .2.1.2.2v14.9c0 .1.1.2.2.2h2.2c.1 0 .2-.1.2-.2V5.9c0-.1.1-.2.2-.2H22c.1 0 .2-.1.2-.2V3.3c-.2-.1-.2-.2-.3-.2z"
                fill={props?.fill || '#232323'}
            />
        </svg>
    );
}

export default SvgPiLogo;
