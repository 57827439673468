import { Pos } from './pos';
import * as faker from 'faker';
import moment from 'moment';

export const fakePosData = (): Pos => ({
    _id: faker.random.number(),
    number: faker.random.number(600),
    siteId: faker.random.number().toString(),
    isSelected: faker.random.boolean(),
    isOnline: faker.random.boolean(),
    name: faker.commerce.productMaterial(),
    lasConnectionDate: moment(faker.date.past()),
});

export const fakePos = (): Pos => new Pos(fakePosData());

export const fakePosArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Pos[] => {
    const pos: Pos[] = [];

    for (let i = 0; i < nbMax; i++) {
        pos.push(fakePos());
    }

    return pos;
};
