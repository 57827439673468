import { DeliverySiteStatus } from '@aphilia/deliveries/data';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { PriceType } from '@aphilia/shared/utils';

export enum ClickAndCollectProvider {
    INNOVORDER = 'INNOVORDER',
}

export class ClickAndCollect {
    /**
     * Indicates if the click and collect is enabled
     */
    enabled?: boolean;

    /**
     * Indicates the click and collect name of the provider
     */
    provider?: ClickAndCollectProvider;

    /**
     * All the time periods of the click and collect system
     */
    timePeriods: WeekdayTimePeriods[];

    /**
     * The price level use for the click and collect platform
     */
    priceLevel: PriceType;

    /**
     * Indicates the maximum number of orders per interval.
     */
    maxOrderPerInterval?: number;

    /**
     * Indicates the prepayment options for the platform
     */
    prepayment?: 'MANDATORY' | 'OPTIONAL' | 'NONE';

    /**
     * Minimum amount authorized for the order
     */
    minOrderValue?: number;

    /**
     * Indicates if the click and collect platform is paused. Set to false to indicate that the plateform is paused
     */
    status: DeliverySiteStatus;

    constructor(props: ClickAndCollect) {
        Object.assign(this, props);

        this.timePeriods = this.timePeriods?.map((timePeriod) => new WeekdayTimePeriods(timePeriod)) || [];
    }
}
