import { ApSpinner } from '@aphilia/shared-ui-core-react';
import React, { useEffect, useState } from 'react';
import { ForbiddenPage } from '..';
import { isModuleAccessibleByUserId } from '@aphilia/shared/organizations/data-access';

interface Props {
    children: React.ReactNode;
    moduleId: string;
    organizationsServiceUrl: string;
}

export const ModuleAccess = ({ children, moduleId, organizationsServiceUrl }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasAccess, setHasAccess] = useState<boolean>();

    useEffect(() => {
        isModuleAccessibleByUserId(organizationsServiceUrl, moduleId)
            .then(() => {
                setIsLoading(false);
                setHasAccess(true);
            })
            .catch(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <ApSpinner />;
    } else if (hasAccess) {
        return <>{children}</>;
    } else {
        return <ForbiddenPage />;
    }
};
