import { Theme, createStyles } from '@material-ui/core/styles';
import { palette, useRem } from '../../theme';
import { designTokens } from '@aphilia/shared/ui/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default (theme: Theme) =>
    createStyles({
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
        },
        tableContainer: {
            width: '100%',
        },
        table: {
            // temporary right-to-left patch, waiting for
            // https://github.com/bvaughn/react-virtualized/issues/454
            '& .ReactVirtualized__Table__headerRow': {
                flip: false,
                paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
            },
            '& .ReactVirtualized__Table__Grid, & .ReactVirtualized__Grid__innerScrollContainer': {
                minWidth: '100%',
            },
            '& .ReactVirtualized__Table__Grid, & .ReactVirtualized__Table__row, & .ReactVirtualized__Table__headerColumn': {
                outline: 'none',
            },
            '& .ReactVirtualized__Table__rowColumn': {
                background: '#fff',
            },
            '& .disabled-row': {
                opacity: 0.5,
                cursor: 'auto',
                '& .MuiButtonBase-root': {
                    cursor: 'auto',
                },
            },
            '& .MuiTableCell-head': {
                fontSize: '0.75rem',
                padding: '4px 8px',
                '&.head-48': {
                    justifyContent: 'center',
                },
            },
        },
        container: {
            display: 'flex',
        },
        sticky: {
            position: 'absolute',
            flexGrow: 0,
            '& .MuiTableCell-root': {
                flexGrow: 0,
            },
        },
        scroll: {
            overflowX: 'auto',
        },
        tableRow: {
            minWidth: '100%',
            position: 'relative',
            '&:hover .MuiTableCell-body:not(.selected)': {
                background: designTokens.colors.statesDefaultHover,
            },
        },
        selectableTableRow: {
            cursor: 'pointer',
        },
        tableCell: Object.assign(useRem(designTokens.typeStyles.body1LeftAlignGrey), {
            width: '100%',
            color: palette.secondary.main,
            borderBottom: 'none',
            background: palette.common.white,
            fontSize: '0.8rem',
            padding: '0.25rem 0.5rem',
            '&.selected': {
                background: designTokens.colors.statesPrimaryActive,
            },
            '& > span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                padding: '2px 0',
            },
        }) as CSSProperties,
        greyTableCell: {
            background: palette.grey.A200,
            '&.selected': {
                background: designTokens.colors.statesPrimaryActive,
            },
        },
        tableHeaderCell: Object.assign(useRem(designTokens.typeStyles.body2Black), {
            background: palette.common.white,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            borderBottom: `1px solid ${palette.grey[50]}`,
            color: palette.secondary.main,
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none',
            '-khtml-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none',
            cursor: 'initial',
            flexDirection: 'column',
            alignItems: 'start',
            '&.MuiTableCell-alignCenter': {
                textAlign: 'center',
                alignItems: 'center',
                '& > span': {
                    margin: 'auto',
                },
            },
            '&.sortable': {
                cursor: 'pointer',
            },
            '&.header-cell-active': {
                color: palette.primary.main,
                fontWeight: 500,
                '& > .sort-icon': {
                    color: palette.primary.main,
                },
            },
            '&:not(.header-cell-active):hover .sort-icon': {
                color: palette.secondary.main,
            },
            '& .first-header-row': {
                overflow: 'hidden',
                maxWidth: '100%',
                '& > span': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '& > .sort-icon': {
                    flexShrink: 0,
                    marginLeft: 'auto',
                    color: palette.secondary.light,
                },
            },
        }) as CSSProperties,
        emptyTable: {
            padding: '100px 0',
            '& h5': {
                marginBottom: 24,
            },
            '& .actions': {
                marginTop: 32,
                textAlign: 'center',
            },
        },
        checkBox: {
            maxWidth: 52,
            '& .MuiTableCell-root': {
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            },
        },
        pagination: {
            '& span': {
                cursor: 'pointer',
                margin: '0.25rem',
                fontSize: '12px',
                color: palette.grey['400'],
            },
            '& .active': {
                color: palette.primary.main,
                textDecoration: 'underline',
            },
            '& ap-icon': {
                cursor: 'pointer',
            },
        },
        footer: {
            borderTop: `1px solid ${designTokens.colors.statesDefaultActive}`,
            padding: 8,
            '& .MuiTypography-root': {
                borderBottom: 'none',
            },
        },
    });
