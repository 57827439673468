import { UberModifierGroupOverride } from '../modifier-group-override/modifier-group-override';

export class UberModifierGroupRules {
    /**
     * A list of the identifying strings (ids) of all modifier groups associated with the item.
     */
    ids: string[];

    /**
     * Overrides for the list of modifier groups in different contexts.
     */
    overrides?: UberModifierGroupOverride[];

    constructor(props: Partial<UberModifierGroupRules>) {
        Object.assign(this, props);

        if (this.overrides) {
            this.overrides.forEach((override) => new UberModifierGroupOverride(override));
        }
    }
}
