import { CreateWebArticle } from './create-web-article';
import * as faker from 'faker';

export const fakeCreateWebArticleData = (): CreateWebArticle => {
    const images = [];
    for (let i = 0; i < faker.random.number(3); i++) {
        images.push(faker.image.food());
    }
    return {
        posId: faker.random.number(),
        genericArticleId: faker.random.number(),
        title: {
            fr: faker.commerce.productName(),
            en: faker.commerce.productName(),
        },
        shortDescription: {
            fr: faker.lorem.slug(),
            en: faker.lorem.slug(),
        },
        longDescription: {
            fr: faker.lorem.paragraph(),
            en: faker.lorem.paragraph(),
        },
        images,
        price: faker.random.number({ min: 500 }),
        quantityMin: faker.random.number(5),
        quantityMax: faker.random.number(10),
        vatRate: faker.random.number(99),
        nutriScore: faker.random.arrayElement(['A', 'B', 'C', 'D', 'E']),
    };
};

export const fakeCreateWebArticle = (): CreateWebArticle => new CreateWebArticle(fakeCreateWebArticleData());

export const fakeCreateWebArticleArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateWebArticle[] => {
    const webArticle: CreateWebArticle[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticle.push(fakeCreateWebArticle());
    }

    return webArticle;
};
