import { replaceSpaces, stripTags } from './string';

function createIdFromText(htmlText: string): string {
    // TODO : improve : only 'A-Z', 'a-z', '0-9', '-', '_', '.' are allowed in html id values
    // In HTML 4, id must start with a letter, it is not required in HTML 5
    if (htmlText) {
        return replaceSpaces(stripTags(htmlText));
    }
    return null;
}

function createAttributeValueFromText(htmlText: string): string {
    return stripTags(htmlText);
}

export { createIdFromText, createAttributeValueFromText };
