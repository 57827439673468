import * as React from 'react';

function SvgExpandLess(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="expand_less_svg__a" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
            </defs>
            <use fill="currentColor" xlinkHref="#expand_less_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgExpandLess;
