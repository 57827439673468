import * as React from 'react';

function SvgBoltOutlined(props) {
    return (
        <svg width={24} height={24} {...props}>
            <path id="bolt_1_svg__a" d="M14 1v8h7L10 23v-8H3L14 1zm-2 5.767l-4.894 6.246H12v4.258l4.886-6.29H12V6.767z" fill="currentColor" />
        </svg>
    );
}

export default SvgBoltOutlined;
