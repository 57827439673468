export class UberTaxLabelsInfo {
    /**
     * A list of strings from the allowed enums.
     * Allowed enums available in the TaxLabels table below. Category and Temperature Labels required.
     */
    labels: string[];

    /**
     * Must be set to “MANUAL”.
     */
    readonly source: string = 'MANUEL';

    constructor(props: Partial<UberTaxLabelsInfo>) {
        Object.assign(this, props);
        this.source = 'MANUEL';
    }
}
