import { UberAllergen } from './allergen';

export class UberAllergy {
    /**
     * Array of allergens that should be taken into account when preparing this item.
     */
    allergens_to_exclude: UberAllergen[];

    /**
     * Instructions from the eater related to allergies.
     */
    allergy_instructions: string;
}
