import { TimeSlot } from '../../time-slot';

export class UpdatePeriod {
    /**
     * The site id of period
     */
    readonly siteId: string;

    /**
     * Label of the period
     */
    label: Record<string, string>;

    /**
     * Days in which the period is active
     */
    days?: boolean[];

    /**
     * The timeslots that make up this period
     */
    timeSlots?: TimeSlot[];

    /**
     * The articles attached to this period
     */
    articleIds?: string[];

    /**
     * The IDs of the categories attached to this period
     */
    categoryIds?: string[];

    constructor(props: UpdatePeriod) {
        Object.assign(this, props);
    }
}
