import { UberEnergInfo } from '../energy-info/energ-info';

export class UberNutritionalInfo {
    /**
     * Energy content of the item, in calories
     */
    calories?: UberEnergInfo;

    /**
     * Energy content of the item, in kilojoules
     */
    kilojoules?: UberEnergInfo;

    constructor(props: Partial<UberNutritionalInfo>) {
        Object.assign(this, props);
    }
}
