import React, { ReactNode } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles, Tooltip } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { SortDirection } from 'react-virtualized';

import style from '../ApTable.style';
import { ApTooltip } from '../../ApTooltip';
import { createClassString } from '@aphilia/shared/utils';

interface Props {
    dataKey: string;
    cellData?: ReactNode;
    rowHeight: number;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    disableSort?: boolean;
    sortBy?: string;
    sortDirection?: false | 'ASC' | 'DESC';
    sticky?: boolean;
    sortable?: boolean;
    isSortedBy?: boolean;
    tooltip?: string;
    title?: string;
    header?: React.ReactNode;
}

const useStyles = makeStyles(style);

function getIcon(sortable, isSortedBy, sortDirection) {
    if (sortable) {
        if (isSortedBy) {
            if (sortDirection === SortDirection.ASC) {
                return <ArrowDownwardIcon fontSize="small" className="sort-icon" />;
            } else if (sortDirection === SortDirection.DESC) {
                return <ArrowUpwardIcon fontSize="small" className="sort-icon" />;
            }
            return <UnfoldMoreIcon fontSize="small" className="sort-icon" />;
        }
        return <UnfoldMoreIcon fontSize="small" className="sort-icon" />;
    }
    return null;
}

export const ApTableHeaderCell = ({ dataKey, sticky, rowHeight, cellData, sortable, isSortedBy, sortDirection, tooltip, title, align, header, ...props }: Props) => {
    const classes = useStyles();

    const cellClasses = createClassString([
        classes.tableCell,
        classes.tableHeaderCell,
        classes.flexContainer,
        [isSortedBy, 'header-cell-active'],
        [sticky, classes.sticky],
        [sortable, 'sortable'],
        `head-${rowHeight}`,
    ]);

    return (
        <TableCell component="div" className={cellClasses} variant="head" style={{ height: rowHeight }} align={align} data-key={dataKey} {...props}>
            <div className={`first-header-row ${classes.flexContainer}`}>
                {(title && (
                    <Tooltip title={title}>
                        <span data-key={dataKey}>{cellData}</span>
                    </Tooltip>
                )) || <span data-key={dataKey}>{cellData}</span>}
                {tooltip && <ApTooltip text={tooltip} />}
                {getIcon(sortable, isSortedBy, sortDirection)}
            </div>
            {!!header && <div className="header-component">{header}</div>}
        </TableCell>
    );
};
