import { UberOrderItem } from './item';

export class UberCart {
    /**
     * All of the line items contained within the shopping cart.
     */
    items: UberOrderItem[];

    /**
     * Instructions from an Eater pertaining to the preparation of this order.
     */
    special_instructions: string;
}
