import * as faker from 'faker';
import { CreateOrderDelivery } from './create-order-delivery';
import moment from 'moment';
import { CreateCustomer, fakeCreateCustomer } from '../../customer';
import { boolean } from '@storybook/addon-knobs';

/**
 * Return create-order-delivery data generated by faker.
 * @param withCustomer specified if customer is needed
 * @return CreateOrderDelivery
 */
export const fakeCreateOrderDeliveryData = (withCustomer?: boolean): CreateOrderDelivery => ({
    dropAddress: faker.address.streetAddress(),
    pickupAt: moment(),
    customer: withCustomer ? fakeCreateCustomer() : undefined,
});

/**
 * Return an instance of CreateOrderDelivery class with data generated by faker
 *
 * @return CreateOrderDelivery
 */
export const fakeCreateOrderDelivery = (): CreateOrderDelivery => new CreateOrderDelivery(fakeCreateOrderDeliveryData());
