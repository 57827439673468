import { WebArticleType } from '../../web-article';

export class UpdateWebArticleDescriptiveSheet {
    /**
     * Type of the article to update
     */
    type: WebArticleType;

    /**
     * Id of the generic article the sheet is related to
     */
    readonly genericArticleId: string;

    /**
     * If the article is of type menu then the menu number must be indicated
     */
    readonly menuNumber?: number;

    /**
     * The site id of the descriptive sheet
     */
    siteId?: string;

    /**
     * Title of the article on the web.
     */
    title?: Record<string, string>;

    /**
     * Short description of the article.
     */
    shortDescription?: Record<string, string>;

    /**
     * Long description of the article
     */
    longDescription?: Record<string, string>;

    /**
     * Updates images for the article descriptive sheet
     */
    images?: (
        | string
        | {
              data: string;
              contentType: string;
              contentLength: number;
          }
    )[];

    /**
     * Calories information for the descriptive sheet
     */
    calories?: number;

    /**
     * Nutri score information about the ingredient
     */
    nutriScore?: 'A' | 'B' | 'C' | 'D' | 'E';

    /**
     * List of all the ingredients information (gluten free, eggs, alcohol, ...)
     */
    allergens?: string[];

    /**
     * A tag can be applied to the article
     */
    tag?: string;

    /**
     * The list of all the special diets for the article
     */
    specialDiets?: string[];

    /**
     * The use can apply an old price => it will display on the piclick application a price barred as if there was a promotion
     */
    oldPrice?: number;

    /**
     * List of all the labels for the product
     */
    labels?: string[];

    constructor(props: UpdateWebArticleDescriptiveSheet) {
        Object.assign(this, props);
    }
}
