import * as faker from 'faker';
import { AllergenType, Item } from './item';

export const fakeItemData = (_nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): Item => ({
    _id: faker.random.uuid(),
    posNumber: faker.random.number(),
    unitPrice: parseInt(faker.finance.amount(), 10),
    title: faker.commerce.productName(),
    specialInstructions: faker.lorem.sentence(),
    selectedItems: [],
    quantity: faker.random.number({ min: 0, max: 10 }),
    allergen: [faker.random.objectElement<AllergenType>(AllergenType)],
    allergenInstructions: faker.lorem.sentence(),
});

export const fakeItem = (nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): Item => new Item(fakeItemData(nbModifierGroups));

export const fakeItemArray = (nbMax: number = faker.random.number({ min: 1, max: 10 }), nbModifierGroups: number = faker.random.number({ min: 0, max: 4 })): Item[] => {
    const items = [];

    for (let i = 0; i < nbMax; i++) {
        items.push(fakeItem(nbModifierGroups));
    }

    return items;
};
