import { OrderPaymentStatus } from '../payment/payment';

export class UpdatePayment {
    /**
     * ISO 4217 code for the currency of the monetary amount.
     */
    currencyCode?: string;

    /**
     * The cost of the entire order, including taxes and fees.
     * The total is expressed as integer with like 17.30 => 17.30 €
     */
    total?: number;

    /**
     * The status of the payment.
     */
    status?: OrderPaymentStatus;

    /**
     * The url to refund the user
     */
    refundUrl?: string;

    constructor(props: UpdatePayment) {
        Object.assign(this, props);
    }
}
