import { CreateJob } from './create-job';
import * as faker from 'faker';

export const fakeCreateJobData = (): CreateJob => ({
    name: faker.name.findName(),
});

export const fakeCreateJob = (): CreateJob => new CreateJob(fakeCreateJobData());

export const fakeCreateJobArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateJob[] => {
    const job: CreateJob[] = [];

    for (let i = 0; i < nbMax; i++) {
        job.push(fakeCreateJob());
    }

    return job;
};
