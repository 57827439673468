import React from 'react';
import { ApHeader, ApRootPage } from '@aphilia/shared-ui-core-react';
import { customHistory } from '@aphilia/shared/react';
import { Route, Router } from 'react-router-dom';
import { HomePage } from './routes/home';
import { IsphoraPage } from './routes/isphora';
import { ApBrandSelector, ApSiteSelector } from '@aphilia/shared/organizations/feature-main';
import { StripeContextProvider } from '@aphilia/payments/feature-main';
import { environment } from '../environments/environment';
import { DeliveryParametersContextProvider } from '@aphilia/deliveries/feature-parameters';
import { WebcommandePage } from './routes/webcommande';
import { LogoutPage } from '@aphilia/shared/organizations/feature-authentication';
import { NavigationDrawer } from './components/navigation';

export function App() {
    return (
        <Router history={customHistory}>
            <ApRootPage>
                <ApHeader appTitle="Piso" onApplicationLogoClick={() => (window.location.href = '/')}>
                    <div slot="main-content">
                        <ApBrandSelector />
                        <ApSiteSelector />
                    </div>
                </ApHeader>
                <main>
                    <NavigationDrawer />
                    <Route exact path="/" component={HomePage} />
                    <Route path="/isphora">
                        <StripeContextProvider
                            initial={{
                                paymentsUrl: environment.paymentsServiceUrl,
                                pusherCluster: environment.pusher.cluster,
                                pusherClientKey: environment.pusher.clientKey,
                                paymentEvent: undefined,
                            }}
                        >
                            <IsphoraPage />
                        </StripeContextProvider>
                    </Route>
                    <Route path="/webcommande">
                        <DeliveryParametersContextProvider
                            initial={{
                                deliveriesServiceUrl: environment.deliveriesServiceUrl,
                                pusherCluster: environment.pusher.cluster,
                                pusherClientKey: environment.pusher.clientKey,
                            }}
                        >
                            <WebcommandePage />
                        </DeliveryParametersContextProvider>
                    </Route>
                </main>
                <Route path="/logout" component={LogoutPage} />
            </ApRootPage>
        </Router>
    );
}

export default App;
