import { UberOrderItem } from './item';

export class UberOrderModifierGroup {
    /**
     * Unique identifying string for the modifier group, provided by the store.
     */
    id: string;

    /**
     * Modifier group title.
     */
    title: string;

    /**
     * Free-form text field reserved for metadata supplied by the store.
     */
    external_data: string;

    /**
     * Array of items that were selected as options for this modifier group.
     *
     * EXAMPLE A menu might have a choose your toppings selection where lettuce, tomato, and bacon were selected as the three toppings for this order.
     * We represent that here as a “choose your toppings” modifier_group with a “lettuce” item, a “tomato” item, and a “bacon” item placed in this selected_items array.
     */
    selected_items: UberOrderItem[];

    /**
     * Array of items that were removed as options for this modifier group.
     * EXAMPLE A menu might have a choose your toppings selection where lettuce, tomato, and bacon are pre-selected by default, however,
     * the eater wishes to have no toppings and de-selects them for this order.
     * We represent that as a “choose your toppings” modifier_group with a “lettuce” item, a “tomato” item, and a “bacon” item placed in this removed_items array.
     */
    removed_items: UberOrderItem[];
}
