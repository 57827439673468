import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { ApCellData, ApCellPosition, ApColumnData } from '../../types';
import { useHoverRow, useSelect } from './hooks';
import { useDnDRef } from '../../hooks';
import { ApTableBodyElement } from './ApTableBodyElement';
import { renderRowClone } from '../../utils';
import { END_STICKY_ID } from '../../config';

import './ap-table-body.scss';

interface ApTableBodyProps extends React.ComponentPropsWithoutRef<'tbody'> {
    // Required
    columns: ApColumnData[];
    rows: Map<string, ApCellData>;
    tableId: string;

    // Optional
    noDataMessage?: string;
    isSelectable?: boolean;
    selectedRows?: string[];
    disabledRows?: string[];
    cellWitdh?: number;
    hoveredRow?: string;

    onSelectRow?: (key: string, check: boolean) => void;
    onHoverRow?: (key: string, disabledRow: boolean) => void;

    onCellDoubleClick?: (rowId: string, column: string) => void;
    onCellEdition?: (rowId: string, key: string, value: any, valueType?: string) => void;
    cellsAreEditable?: boolean;
    onSelectClose?: () => void;
    editableCell?: ApCellPosition;

    hasDnDContext?: boolean;
    rowsType?: string;
    draggingRowIndex?: number;

    isSticky?: boolean;
    hasCheck?: boolean;
}

export const ApTableBody = React.forwardRef<HTMLTableSectionElement, ApTableBodyProps>(
    (
        {
            columns,
            rows,
            tableId,
            noDataMessage,
            isSelectable,
            selectedRows,
            disabledRows,
            hoveredRow,
            onSelectRow,
            onHoverRow,
            cellsAreEditable,
            onCellDoubleClick,
            onCellEdition,
            onSelectClose,
            editableCell,

            hasDnDContext,
            rowsType,
            draggingRowIndex,

            isSticky,
            hasCheck,
        }: ApTableBodyProps,
        ref
    ) => {
        const { handleCheck, handleRow } = useSelect(onSelectRow);
        const { handleHoverRow } = useHoverRow(onHoverRow);
        const { handleDnDRef } = useDnDRef();

        if (hasDnDContext) {
            return (
                <Droppable
                    droppableId={`${tableId}-droppable-body${isSticky ? END_STICKY_ID : ''}`}
                    type={rowsType}
                    isDropDisabled={true}
                    renderClone={renderRowClone('title', rows, selectedRows)}
                >
                    {(provided, snapshot) => {
                        return (
                            <ApTableBodyElement
                                columns={columns}
                                rows={rows}
                                tableId={tableId}
                                onRow={handleRow}
                                onHoverRow={handleHoverRow}
                                bodyRef={handleDnDRef(ref, provided.innerRef)}
                                isSelectable={isSelectable}
                                noDataMessage={noDataMessage}
                                hoveredRow={hoveredRow}
                                selectedRows={selectedRows}
                                disabledRows={disabledRows}
                                hasDnDContext={hasDnDContext}
                                rowsType={rowsType}
                                onCheck={handleCheck}
                                onCellDoubleClick={onCellDoubleClick}
                                onCellEdition={onCellEdition}
                                onSelectClose={onSelectClose}
                                editableCell={editableCell}
                                isSticky={isSticky}
                                hasCheck={hasCheck}
                                draggingRowIndex={draggingRowIndex}
                                cellsAreEditable={cellsAreEditable}
                            />
                        );
                    }}
                </Droppable>
            );
        } else {
            return (
                <ApTableBodyElement
                    columns={columns}
                    rows={rows}
                    tableId={tableId}
                    onRow={handleRow}
                    onHoverRow={handleHoverRow}
                    bodyRef={ref}
                    isSelectable={isSelectable}
                    noDataMessage={noDataMessage}
                    hoveredRow={hoveredRow}
                    selectedRows={selectedRows}
                    disabledRows={disabledRows}
                    hasDnDContext={hasDnDContext}
                    rowsType={rowsType}
                    onCheck={handleCheck}
                    onCellDoubleClick={onCellDoubleClick}
                    onCellEdition={onCellEdition}
                    onSelectClose={onSelectClose}
                    editableCell={editableCell}
                    isSticky={isSticky}
                    hasCheck={hasCheck}
                    draggingRowIndex={draggingRowIndex}
                    cellsAreEditable={cellsAreEditable}
                />
            );
        }
    }
);
