import React, { CSSProperties, ReactNodeArray } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

interface Props {
    disabled?: boolean;
    isDraggable?: boolean;
    className: string;
    columns: ReactNodeArray;
    index: number;
    onRowClick?: ({ event, index, rowData, isDragging }) => void;
    onDrop?: (item: { type: string; rowData: any }, monitor: DragSourceMonitor) => void;
    endDrag?: (item: { type: string; rowData: any }, monitor: DragSourceMonitor) => void;
    rowData: object;
    style: CSSProperties;
}

export const ApTableRow = ({ disabled, isDraggable, className, columns, index, onRowClick = null, onDrop, endDrag, rowData, style }: Props) => {
    let _: any, drag: any;
    if (isDraggable) {
        [_, drag] = useDrag({
            item: {
                type: 'row',
                rowData,
            },
            end: (item, monitor) => {
                if (monitor.didDrop()) onDrop(item, monitor);
                if (endDrag) endDrag(item, monitor);
            },
            canDrag: () => !disabled,
            collect: (monitor) => {
                if (!!monitor.isDragging()) onRowClick({ event: null, index, rowData, isDragging: !!monitor.isDragging() });
                return {
                    isDragging: !!monitor.isDragging(),
                };
            },
        });
    }

    const a11yProps: any = { 'aria-rowindex': index + 1 };

    if (onRowClick) {
        a11yProps['aria-label'] = 'row';
        a11yProps.tabIndex = 0;
        a11yProps.onClick = (event) => onRowClick({ event, index, rowData, isDragging: undefined });
    }

    return (
        <div {...a11yProps} ref={drag} className={`${className} ${disabled ? 'disabled-row' : ''}`} key={index} role="row" style={style}>
            {columns}
        </div>
    );
};
