import React, { useCallback, useEffect, useState } from 'react';
import { useDeliveryParametersContext } from '@aphilia/deliveries/feature-parameters';
import { ApBanner, ApButton, ApCard, ApCol, ApInput, ApRow, ApToggle } from '@aphilia/shared-ui-core-react';
import { ApForm } from '@aphilia/shared/react';
import { Uber } from '@aphilia/deliveries/data';

export const DeliveryParametersApplicationsUberPage = () => {
    const { deliveryParameters, updateDeliveryParameters } = useDeliveryParametersContext();
    const [localUberParameters, setLocalUberParameters] = useState<Uber>();

    useEffect(() => {
        setLocalUberParameters(deliveryParameters?.applications?.uber);
    }, [deliveryParameters?.applications?.uber]);

    const addUber = useCallback(async () => {
        try {
            await updateDeliveryParameters({ applications: { uber: { enabled: false, locationId: undefined, posIntegrationEnabled: false } } });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }, []);

    const updateUber = useCallback(
        (property: string, value: any) => {
            setLocalUberParameters({
                ...localUberParameters,
                [property]: value,
            });
        },
        [localUberParameters]
    );

    const handleOnSubmit = useCallback(async () => {
        await updateDeliveryParameters({ applications: { uber: localUberParameters } });
    }, [localUberParameters]);

    return deliveryParameters?.applications?.uber?.enabled !== undefined ? (
        <ApCard headerTitle="Uber">
            <p>Vous pouvez retrouver la documentation de Uber ici: https://support.pielectronique.com/hc/fr/articles/4409677508753-Prestataires-de-livraison-Uber-Eats-Deliveroo</p>
            <ApForm
                onApSubmit={handleOnSubmit}
                errorMessage={'Une erreur est survenue'}
                successMessage={'La configuration Uber est bien modifiée'}
                cancelText={'Annuler'}
                confirmText={'Confirmer'}
            >
                <ApRow>
                    <ApCol size={4}>
                        <ApToggle checked={deliveryParameters?.applications?.uber?.enabled} label="Uber actif" onApToggle={(e) => updateUber('enabled', e.detail)} />
                    </ApCol>
                    <ApCol>
                        <ApToggle
                            checked={deliveryParameters?.applications?.uber?.posIntegrationEnabled}
                            label="Intégration des commandes en caisse"
                            onApToggle={(e) => updateUber('posIntegrationEnabled', e.detail)}
                        />
                    </ApCol>
                </ApRow>
                <ApRow>
                    <ApCol>
                        <ApInput
                            label="Location ID"
                            helpText="Code transmis par Uber"
                            value={deliveryParameters?.applications?.uber?.locationId}
                            onInputChange={(e) => updateUber('locationId', e.detail.target.value)}
                        />
                    </ApCol>
                </ApRow>
            </ApForm>
        </ApCard>
    ) : (
        <ApCard headerTitle="Uber">
            <p>Vous pouvez retrouver la documentation de Uber ici: https://support.pielectronique.com/hc/fr/articles/4409677508753-Prestataires-de-livraison-Uber-Eats-Deliveroo</p>
            <p>Le site n'a pas le prestataire Uber d'actif.</p>
            <ApBanner text="Avant d'activer Uber, veuillez bien vériifer que l'ADV est au courant." type="warning" clickable={false} />
            <ApRow>
                <ApCol>
                    <ApButton label="Activer Uber" onClick={() => addUber()} />
                </ApCol>
            </ApRow>
        </ApCard>
    );
};
