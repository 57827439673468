export enum UberMenuEntityType {
    ITEM = 'ITEM',
    MODIFIER_GROUP = 'MODIFIER_GROUP',
}

export class UberMenuEntity {
    /**
     * The unique identifying string (id) for the item or modifier group being specified
     */
    id: string;

    /**
     * The type of the entity being specified.
     */
    type: UberMenuEntityType;

    constructor(menuEntity: Partial<UberMenuEntity>) {
        Object.assign(this, menuEntity);
    }
}
