export class DeliverooNutritionalInfo {
    /**
     * Item's Calorie information.
     */
    energy_kcal?: {
        /**
         * Lower bound of Calories in the item.
         */
        low?: number;

        /**
         * Upper bound of Calories in the item.
         */
        high: number;
    };
}
