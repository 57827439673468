import mongoose from 'mongoose';
import { QueryPeriod } from './query-period';

export const fakeQueryPeriodData = (): QueryPeriod => ({
    siteId: mongoose.Types.ObjectId().toHexString(),
});

export const fakeQueryPeriod = (): QueryPeriod => new QueryPeriod(fakeQueryPeriodData());

export const fakeQueryPeriodArray = (nbMax: number = Math.ceil(Math.random() * 19)): QueryPeriod[] => {
    const period: QueryPeriod[] = [];

    for (let i = 0; i < nbMax; i++) {
        period.push(fakeQueryPeriod());
    }

    return period;
};
