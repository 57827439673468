import { Moment } from 'moment';
import { CreateCustomer } from '../../customer';

export class CreateOrderDelivery {
    /**
     * The address of the drop
     */
    dropAddress: string;

    /**
     * The date time the package will be picked up
     */
    pickupAt?: Moment;

    /**
     * The contact of delivery order
     */
    customer?: CreateCustomer;

    constructor(props: CreateOrderDelivery) {
        Object.assign(this, props);
    }
}
