import * as faker from 'faker';
import { WebPrice, WebPricePeriod } from './web-price';

const getPrice = () => {
    return {
        price: faker.random.number({ min: 500, max: 10000 }),
    };
};

const getPeriodPrice = (): WebPricePeriod => {
    return {
        enabled: faker.random.boolean(),
        delivery: getPrice(),
        atTable: getPrice(),
        clickAndCollect: getPrice(),
    };
};

export const fakeWebPriceData = (): WebPrice => {
    return {
        siteId: faker.random.number(300).toString(),
        articleId: faker.random.number(1000).toString(),
        price: {
            delivery: faker.random.number({ min: 500, max: 10000 }),
            atTable: faker.random.number({ min: 500, max: 10000 }),
            clickAndCollect: faker.random.number({ min: 500, max: 10000 }),
        },
        periods: {
            id1: getPeriodPrice(),
            id2: getPeriodPrice(),
            id3: getPeriodPrice(),
            id4: getPeriodPrice(),
            id5: getPeriodPrice(),
        },
    };
};

export const fakeWebPrice = (): WebPrice => new WebPrice(fakeWebPriceData());

export const fakeWebPriceArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebPrice[] => {
    const webPrice: WebPrice[] = [];

    for (let i = 0; i < nbMax; i++) {
        webPrice.push(fakeWebPrice());
    }

    return webPrice;
};
