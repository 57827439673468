import * as faker from 'faker';
import { PiclickV2Consumable } from './piclick-v2-consumable';

export const fakePiclickV2ConsumableData = (): PiclickV2Consumable => ({
    label: faker.random.word(),
    maxQuantity: faker.random.number(30),
});

export const fakePiclickV2Consumable = (): PiclickV2Consumable => new PiclickV2Consumable(fakePiclickV2ConsumableData());

export const fakePiclickV2ConsumableArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): PiclickV2Consumable[] => {
    const consumables: PiclickV2Consumable[] = [];

    for (let i = 0; i < nbMax; i++) {
        consumables.push(fakePiclickV2Consumable());
    }

    return consumables;
};
