import { UberMenu } from '../index';
import { UberCategory } from '../index';
import { UberItem } from '../index';
import { UberModifierGroup } from '../../modifier';
import { UberDisplayOptions } from '../index';

export class UberMenuConfiguration {
    /**
     * List of the store’s menus.
     */
    menus: UberMenu[];

    /**
     * List of the store’s menu categories.
     */
    categories: UberCategory[];

    /**
     * List of the store’s items.
     */
    items: UberItem[];

    /**
     * List of the store’s modifier groups.
     */
    modifier_groups: UberModifierGroup[];

    /**
     * Miscellaneous configuration settings for the display of all of the store’s menus.
     */
    display_options: UberDisplayOptions;

    /**
     * The type of the menu to deploy
     */
    menu_type: 'MENU_TYPE_FULFILLMENT_DELIVERY' | 'MENU_TYPE_FULFILLMENT_PICK_UP';

    constructor(props: Partial<UberMenuConfiguration>) {
        Object.assign(this, props);
    }
}
