import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function useTranslationWithBreaks() {
    const { t } = useTranslation();

    const splitTrans = useCallback(
        (transKey: string): string[] => {
            const transText = t(transKey);
            return transText.split(/\r?\n/);
        },
        [t]
    );

    const tBreak = useCallback(
        (transKey: string): ReactNode[] | string => {
            const textRows = splitTrans(transKey);
            // If there is no break in string, behavior is the same as normal t function
            if (textRows.length === 1) {
                return textRows[0];
            }
            return textRows.map(
                (textRow, rowIndex): ReactNode => {
                    return (
                        <React.Fragment key={`text-row-${rowIndex}`}>
                            {textRow}
                            {rowIndex < textRows.length - 1 ? <br /> : null}
                        </React.Fragment>
                    );
                }
            );
        },
        [splitTrans]
    );

    return { tBreak, t };
}

export { useTranslationWithBreaks };
