import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        '& .MuiFormControlLabel-root': {
            '& .MuiTypography-body1': {
                color: palette.grey[800],
            },
            '& .MuiCheckbox-root': {
                paddingLeft: 0,
                '& .MuiSvgIcon-root': {
                    width: '22px',
                    height: '22px',
                },
            },
        },
        '& .ap-checklist-header': {
            marginBottom: 30,
            '& .MuiTypography-body1': {
                fontWeight: 500,
                fontSize: theme.typography.pxToRem(20),
                lineHeight: '30px',
            },
        },
        '& .MuiCollapse-container': {
            width: '100%',
            '& .MuiGrid-item': {
                padding: 0,
                '& .MuiTypography-body1': {
                    fontSize: theme.typography.pxToRem(12),
                    lineHeight: '22px',
                },
            },
        },
    },
});
