import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs } from '@material-ui/core';

import { ApTab } from './ApTab';
import { ApTabsPanel } from './ApTabsPanel';
import { useTabs } from './ApTabs.hooks';
import style from './ApTabs.style';

interface Props {
    tabs: {
        label?: string;
        icon?: React.ReactElement;
        content?: React.ReactNode;
        url?: string;
        disabled?: boolean;
    }[];
    className?: string;
    variant?: 'scrollable' | 'standard' | 'fullWidth';
    updateCallback?: (index: number) => void;
}

const useStyles = makeStyles(style);

export const ApTabs = ({ tabs, className, updateCallback, variant = 'scrollable' }: Props) => {
    const classes = useStyles();
    const { current, handleChange } = useTabs(tabs, updateCallback);

    return (
        <div className={`${classes.root} ${className}`}>
            <AppBar position="static" color="transparent">
                {tabs && (
                    <Tabs value={current} onChange={handleChange} indicatorColor="primary" textColor="primary" variant={variant} scrollButtons="auto">
                        {tabs.map((tab, index) => (
                            <ApTab key={`tab-${index}`} label={tab.label} icon={tab.icon} disabled={!!tab.disabled} />
                        ))}
                    </Tabs>
                )}
            </AppBar>
            {tabs?.map((tab, index) => (
                <ApTabsPanel key={`tab-panel-${index}`} current={current} index={index}>
                    {tab.content}
                </ApTabsPanel>
            ))}
        </div>
    );
};
