import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { ApNavigation, Props as ApNavigationProps } from '../ApNavigation';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApNavigationPage.style';
import { Route } from 'react-router-dom';

interface Props {
    categories: ApNavigationProps;
    children?: ReactNode;
}

const useStyles = makeStyles(style);

export const ApNavigationPage = ({ categories, children }: Props) => {
    const classes = useStyles();

    return (
        <Grid container className={`ap-navigation-page ${classes.root}`}>
            <ApNavigation categories={categories.categories} />
            <Grid container className="ap-navigation-page-main-page">
                {children}
            </Grid>
        </Grid>
    );
};
