export class WebArticlePlatformConfiguration {
    /**
     * Price of the article on the platform
     */
    price: number;

    /**
     * Indicates if the article is available fro sale on the platform
     */
    enabled: boolean;

    constructor(props: WebArticlePlatformConfiguration) {
        Object.assign(this, props);
    }
}
