import { useCallback, useEffect, useState, useMemo, useRef, createContext } from 'react';
import { cloneDeep } from 'lodash';

import { ApCellData, ApCellPosition, ApColumnData, ApColumnSort, ApTableContext } from './types';
import { ApItemProps } from '@aphilia/shared-ui-core';
import { sum } from '@aphilia/shared/utils';
import { getAncestorElementByClass, getCellFromChildren } from './utils';
import { BASE_CELL_WIDTH, CHECKBOX_CELL_RATIO, navigationKeys, noEditionCellElements, noEditionCellTypes } from './config';

function useDnDRef() {
    // Set refs for forwardRef and DnD
    const handleDnDRef = useCallback(
        (fwdRef, dndRef) => (ref) => {
            if (typeof fwdRef === 'object' && fwdRef) {
                fwdRef.current = ref;
            }
            if (typeof dndRef === 'function') {
                dndRef(ref);
            }
        },
        []
    );

    return { handleDnDRef };
}

function useColumns(columns, isSelectable) {
    const [stickyColumns, setStickyColumns] = useState([]);
    const [nonStickyColumns, setNonStickyColumns] = useState([]);
    const [stickyWidth, setStickyWidth] = useState(0);

    useEffect(() => {
        const stickyColumnsList = columns.filter((col) => col.isSticky);
        const nonStickyColumnsList = columns.filter((col) => !col.isSticky);
        setStickyColumns(stickyColumnsList);
        setNonStickyColumns(nonStickyColumnsList);
        let stickyWidthValue = stickyColumnsList.reduce((acc, currentColumn) => {
            return (acc += currentColumn.cellRatio * BASE_CELL_WIDTH);
        }, 0);
        // Add checkbox column width if selectable
        if (stickyWidthValue && isSelectable) {
            stickyWidthValue += CHECKBOX_CELL_RATIO * BASE_CELL_WIDTH;
        }
        setStickyWidth(stickyWidthValue);
    }, [columns, isSelectable]);

    return { stickyColumns, nonStickyColumns, stickyWidth };
}

function useTableHoverRow() {
    const [hoveredRow, setHoveredRow] = useState(null);

    const handleTableHoverRow = useCallback((key: string, disabledRow: boolean) => {
        if (!disabledRow) {
            setHoveredRow(key);
        }
    }, []);

    return { hoveredRow, handleTableHoverRow };
}

function useSelectedRows(selectedRows, tableRows, disabledRows, onSelectRow, onSelectAllRows) {
    const [allIndeterminate, setAllIndeterminate] = useState(false);
    const [allSelected, setAllSelected] = useState(false);

    const handleSelectAllRows = useCallback(
        (evt) => {
            if (typeof onSelectAllRows === 'function') {
                onSelectAllRows(evt);
            }
        },
        [onSelectAllRows]
    );

    const handleSelectRow = useCallback(
        (key, check) => {
            if (typeof onSelectRow === 'function') {
                onSelectRow(key, check);
            }
        },
        [onSelectRow]
    );

    useEffect(() => {
        const enabledRows = tableRows ? Object.keys(tableRows).filter((row) => !disabledRows?.includes(row)) : [];

        if (0 < selectedRows?.length && selectedRows?.length < enabledRows.length) {
            setAllIndeterminate(true);
        } else {
            setAllIndeterminate(false);
        }
        if (selectedRows?.length === enabledRows?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [selectedRows, tableRows, disabledRows]);

    return { allSelected, allIndeterminate, handleSelectAllRows, handleSelectRow };
}

let debounce = null;
function useItemsSearch(onSearch) {
    const handleSearch = useCallback(
        (evt) => {
            if (typeof onSearch === 'function') {
                if (debounce) {
                    clearTimeout(debounce);
                    debounce = null;
                }
                debounce = setTimeout(() => {
                    // No need to persist event with React above v17
                    onSearch(evt.detail);
                }, 300);
            }
        },
        [onSearch]
    );

    return { handleSearch };
}

function useColumnSort(onSortBy, onSortOrder) {
    const handleSortBy = useCallback(
        (columnKey: string) => {
            if (typeof onSortBy === 'function') {
                onSortBy(columnKey);
            }
        },
        [onSortBy]
    );

    const handleSortOrder = useCallback(
        (columnOrder: ApColumnSort) => {
            if (typeof onSortOrder === 'function') {
                onSortOrder(columnOrder);
            }
        },
        [onSortOrder]
    );

    return { handleSortBy, handleSortOrder };
}

const paginationItems: ApItemProps[] = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
];

function usePagination(rows, currentPage, rowsPerPage, onCurrentPageChange, onRowsPerPageChange, searchValue) {
    const [paginateRows, setPaginateRows] = useState<Map<string, ApCellData>>(new Map([]));
    const [paginationOptions, setPaginationOptions] = useState<ApItemProps[]>([]);

    const [currentSearchValue, setCurrentSearchValue] = useState(null);

    const handleRowsPerPage = useCallback(
        (evt: CustomEvent) => {
            if (typeof onRowsPerPageChange === 'function') {
                onRowsPerPageChange(evt.detail.value);
            }
            // TODO better approach ??? external ???
            if (typeof onCurrentPageChange === 'function') {
                onCurrentPageChange(1);
            }
        },
        [onRowsPerPageChange, onCurrentPageChange]
    );

    const handleCurrentPage = useCallback(
        (page: number) => {
            if (typeof onCurrentPageChange === 'function') {
                onCurrentPageChange(page);
            }
        },
        [onCurrentPageChange]
    );

    useEffect(() => {
        const rowsArray: [string, ApCellData][] = rows.map((row) => {
            return [row.id, row];
        });
        setPaginateRows(new Map(rowsArray));
    }, [rows, rowsPerPage, currentPage]);

    useEffect(() => {
        const items = cloneDeep(paginationItems).map((item) => {
            return {
                ...item,
                isSelected: item.value === rowsPerPage,
            };
        });
        setPaginationOptions(items);
    }, [rowsPerPage]);

    useEffect(() => {
        if (currentSearchValue) {
            if (!searchValue) {
                setCurrentSearchValue(null);
            }
            if (!searchValue || currentSearchValue !== searchValue) {
                if (typeof onCurrentPageChange === 'function') {
                    onCurrentPageChange(1);
                }
            }
        } else {
            if (searchValue) {
                if (typeof onCurrentPageChange === 'function') {
                    onCurrentPageChange(1);
                }

                setCurrentSearchValue(searchValue);
            }
        }
    }, [currentSearchValue, searchValue]);

    return { paginationOptions, paginateRows, handleRowsPerPage, handleCurrentPage };
}

function useCellEdition(rows: Map<string, ApCellData>, columns: ApColumnData[], cellsAreEditable: boolean, onCellChange, onCellSave, onEnableAllDnDSensors) {
    const [editableCell, setEditableCell] = useState<ApCellPosition>(null);

    const handleCellDoubleClick = useCallback(
        (row: string, column: string) => {
            const columnIndex = columns.findIndex((col) => col.dataKey === column);
            setEditableCell({ row, column, valueType: columns[columnIndex].valueType });
        },
        [columns]
    );

    const handleClick = useCallback(
        (evt) => {
            const currentCell = getCellFromChildren(evt.target);
            const tag = evt.target?.tagName?.toLowerCase();

            if (noEditionCellElements.includes(tag) && currentCell) {
                const { rowId, columnKey, columnValuetype } = currentCell?.dataset ?? {};
                setEditableCell({ row: rowId, column: columnKey, valueType: columnValuetype });
            } else {
                if (editableCell) {
                    if (typeof onCellSave === 'function') {
                        onCellSave();
                    }

                    if (currentCell) {
                        if (currentCell.dataset?.rowId !== editableCell.row || currentCell.dataset?.columnKey !== editableCell.column) {
                            setEditableCell(null);
                        }
                    } else {
                        setEditableCell(null);
                    }
                }
            }
        },
        [editableCell, onCellSave]
    );

    const handleSelectClose = useCallback(() => {
        setEditableCell(null);
    }, []);

    const handleCellKey = useCallback(
        (evt) => {
            const keyboardKey = evt.key;

            if (editableCell) {
                const rowsKeys = Array.from(rows.keys());
                const rowIndex = rowsKeys.findIndex((currentRow) => currentRow === editableCell.row);
                const columnIndex = columns?.findIndex((currentColumn) => currentColumn.dataKey === editableCell.column);

                let newRowId;
                let newColumnKey;
                let newValueType;

                if (keyboardKey === 'Tab') {
                    evt.preventDefault();
                    if (evt.shiftKey) {
                        if (columnIndex > 0) {
                            const newColumnIndex = columnIndex - 1;
                            newRowId = editableCell?.row;
                            newColumnKey = columns[newColumnIndex].dataKey;
                            newValueType = columns[newColumnIndex].valueType;
                        } else if (columnIndex === 0) {
                            const newColumnIndex = columns?.length - 1;
                            const newRowIndex = rowIndex - 1;
                            newRowId = rowsKeys[newRowIndex];
                            newColumnKey = columns[newColumnIndex].dataKey;
                            newValueType = columns[newColumnIndex].valueType;
                        }
                    } else {
                        if (columnIndex < columns?.length - 1) {
                            const newColumnIndex = columnIndex + 1;
                            newRowId = editableCell?.row;
                            newColumnKey = columns[newColumnIndex].dataKey;
                            newValueType = columns[newColumnIndex].valueType;
                        } else if (columnIndex === columns?.length - 1) {
                            const newColumnIndex = 0;
                            const newRowIndex = rowIndex + 1;
                            newRowId = rowsKeys[newRowIndex];
                            newColumnKey = columns[newColumnIndex].dataKey;
                            newValueType = columns[newColumnIndex].valueType;
                        }
                    }
                }
                if (keyboardKey === 'Enter') {
                    if (rowIndex < rowsKeys.length - 1) {
                        const newRowIndex = rowIndex + 1;
                        newRowId = rowsKeys[newRowIndex];
                        newColumnKey = editableCell.column;
                        newValueType = columns[columnIndex].valueType;
                    }
                }

                if (navigationKeys.includes(keyboardKey) && !noEditionCellTypes.includes(editableCell.valueType) && typeof onCellSave === 'function') {
                    onCellSave();
                }

                if (newRowId && newColumnKey && newValueType) {
                    setEditableCell({ row: newRowId, column: newColumnKey, valueType: newValueType });
                }
            }
        },
        [rows, columns, editableCell, onCellSave]
    );

    const handleCellEdition = useCallback(
        (rowId, key, value, valueType, cellType?) => {
            if (typeof onCellChange === 'function') {
                onCellChange(rowId, key, value, valueType, cellType);
            }
        },
        [onCellChange]
    );

    useEffect(() => {
        if (!cellsAreEditable) {
            setEditableCell(null);
        }
    }, [cellsAreEditable]);

    // react-beutiful-dnd api doesn't work properly : dynamic change of sensors list generate errors

    // useEffect(() => {
    //     if (typeof onEnableAllDnDSensors === 'function') {
    //         if (!editableCell || noEditionCellTypes.includes(editableCell.valueType)) {
    //             onEnableAllDnDSensors(true);
    //         } else {
    //             onEnableAllDnDSensors(false);
    //         }
    //     }
    // }, [onEnableAllDnDSensors, editableCell]);

    return { editableCell, handleCellDoubleClick, handleClick, handleCellEdition, handleCellKey, handleSelectClose };
}

function useTableClick(handleCellEditionClick, cellsAreEditable) {
    const [filtersEdition, setFiltersEdition] = useState(false);

    const handleTableClick = useCallback(
        (evt) => {
            if (cellsAreEditable && typeof handleCellEditionClick === 'function') {
                handleCellEditionClick(evt);
            }
            const ancestor = getAncestorElementByClass(evt.target, 'ap-table-filters-edition');

            if (!ancestor) {
                setFiltersEdition(false);
            }
        },
        [handleCellEditionClick, cellsAreEditable]
    );

    const handleFiltersEdition = useCallback((edition: boolean) => {
        setFiltersEdition(edition);
    }, []);

    return { filtersEdition, handleTableClick, handleFiltersEdition };
}

function useTableContext(tableId, columns, columnsConfig, tableTrads, tradFunction, minSearchLength: number) {
    const tableContextData: ApTableContext = useMemo(() => {
        return {
            tableId,
            columns,
            columnsConfig,
            tableTrads,
            tradFunction,
            minSearchLength,
        };
    }, [tableId, columns, columnsConfig, tradFunction, tableTrads, minSearchLength]);

    return { tableContextData };
}

export { useDnDRef, useColumns, useItemsSearch, useColumnSort, usePagination, useTableHoverRow, useSelectedRows, useCellEdition, useTableClick, useTableContext };
// export { useDnDRef, useColumns, useItemsSearch, useColumnSort, usePagination, useTableHoverRow, useSelectedRows, useCellEdition, useTableClick };
