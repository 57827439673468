import { PriceType } from '@aphilia/shared/utils';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';
import { TerminalMedia } from '../terminal-media/terminal-media';

export class UpdateTerminal {
    /**
     * Indicates if the terminal platform is enabled or not
     */
    enabled?: boolean;

    /**
     * The price level use for the ap table platform
     */
    priceLevel?: PriceType;

    /**
     * All the time periods for the terminal platform
     */
    timePeriods?: WeekdayTimePeriods[];

    /**
     * Terminal configured videos
     */
    videos?: TerminalMedia[];

    /**
     * Terminal configured videos
     */
    images?: TerminalMedia[];

    constructor(props: UpdateTerminal) {
        Object.assign(this, props);
    }
}
