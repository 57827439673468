import React from 'react';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApRadioGroup.style';

const useStyles = makeStyles(style);

interface Props extends RadioGroupProps {
    children: React.ReactNode;
}

export const ApRadioGroup = ({ children, ...props }: Props) => {
    const classes = useStyles();

    return (
        <FormControl component="fieldset" className={`${classes.root} ap-radio-group`}>
            <RadioGroup {...props}>{children}</RadioGroup>
        </FormControl>
    );
};
