import { Theme } from '@material-ui/core/styles';

export default (theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .selected-counter': {
            borderRadius: 3.14,
            backgroundColor: theme.palette.grey[300],
            padding: 8,
            textAlign: 'center',
            lineHeight: '16px',
            width: 32,
            height: 32,
            boxSizing: 'border-box',
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
            marginRight: 18,
        },
        '& .text': {
            textDecoration: 'none',
        },
    },
});
