import moment, { Moment } from 'moment';

export class Pos {
    /**
     * Id of the pos
     */
    readonly _id: number;

    /**
     * The pos number
     */
    readonly number: number;

    /**
     * Site id of the pos
     */
    readonly siteId: string;

    /**
     * The name of the pos system
     */
    name?: string;

    /**
     * Indicates if a pos is online
     */
    isOnline: boolean;

    /**
     * Indicates if it's the main pos
     */
    isSelected: boolean;

    /**
     * The last time the pos system was seen by the proxy
     */
    lasConnectionDate: Moment;

    constructor(props: Pos) {
        Object.assign(this, props);
        this.lasConnectionDate = this.lasConnectionDate && moment(this.lasConnectionDate);
    }
}
