import { UpdateWebArticlePlatformConfiguration } from './update-web-article-platform-configuration';
import * as faker from 'faker';

export const fakeUpdateWebArticlePlatformConfigurationData = (): UpdateWebArticlePlatformConfiguration => ({
    name: faker.name.findName(),
});

export const fakeUpdateWebArticlePlatformConfiguration = (): UpdateWebArticlePlatformConfiguration =>
    new UpdateWebArticlePlatformConfiguration(fakeUpdateWebArticlePlatformConfigurationData());

export const fakeUpdateWebArticlePlatformConfigurationArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticlePlatformConfiguration[] => {
    const webArticlePlatformConfiguration: UpdateWebArticlePlatformConfiguration[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticlePlatformConfiguration.push(fakeUpdateWebArticlePlatformConfiguration());
    }

    return webArticlePlatformConfiguration;
};
