import { Period } from './period';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { fakeTimeSlotArray } from '../../time-slot';

export const fakePeriodData = (): Period => {
    let days = Array(7).fill(false);
    const categoryIds = [];
    const articleIds = [];
    days = days.map(() => faker.random.boolean());
    for (let index = 0; faker.random.number(10); index++) {
        categoryIds.push(mongoose.Types.ObjectId().toHexString());
    }
    for (let index = 0; faker.random.number(10); index++) {
        articleIds.push(mongoose.Types.ObjectId().toHexString());
    }

    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        siteId: faker.random.number().toString(),
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        days,
        timeSlots: fakeTimeSlotArray(4),
        articleIds,
        categoryIds,
    };
};

export const fakePeriod = (): Period => new Period(fakePeriodData());

export const fakePeriodArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Period[] => {
    const period: Period[] = [];

    for (let i = 0; i < nbMax; i++) {
        period.push(fakePeriod());
    }

    return period;
};
