import { QueryWebArticleDescriptiveSheet } from './query-web-article-descriptive-sheet';
import mongoose from 'mongoose';
import * as faker from 'faker';

export const fakeQueryWebArticleDescriptiveSheetData = (): QueryWebArticleDescriptiveSheet => ({
    brandId: mongoose.Types.ObjectId().toHexString(),
});

export const fakeQueryWebArticleDescriptiveSheet = (): QueryWebArticleDescriptiveSheet => new QueryWebArticleDescriptiveSheet(fakeQueryWebArticleDescriptiveSheetData());

export const fakeQueryWebArticleDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): QueryWebArticleDescriptiveSheet[] => {
    const webArticleDescriptiveSheet: QueryWebArticleDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleDescriptiveSheet.push(fakeQueryWebArticleDescriptiveSheet());
    }

    return webArticleDescriptiveSheet;
};
