import { Theme } from '@material-ui/core/styles';
import { palette, useRem } from '../../theme';
import { designTokens } from '@aphilia/shared/ui/styles';

export default (theme: Theme) => ({
    root: {
        width: 316,
        height: '100%',
        position: 'relative' as 'relative',
        '& .left': {
            position: 'relative' as 'relative',
            zIndex: 2,
            background: '#fff',
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& .main': {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: 16,
                paddingTop: 0,
                '& .MuiListItem-root': {
                    '&.has-children:hover, &.has-children.open': {
                        color: palette.primary.main,
                        background: palette.primary.light,
                        '& i, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
                            color: palette.primary.main,
                        },
                    },
                },
            },
            '& .secondary': {
                position: 'absolute' as 'absolute',
                left: '100%',
                width: '100%',
                top: 80,
                bottom: 0,
                background: '#fff',
                transition: 'all 300ms ease-in-out',
                padding: '0 16px',
                boxSizing: 'border-box',
                '&.active': {
                    left: 0,
                },
                '& .MuiButton-root': {
                    padding: '3px 5px',
                    marginLeft: 8,
                },
                '& .content > .MuiTypography-root': {
                    padding: '0 8px',
                    boxSizing: 'border-box',
                },
                '& .MuiListItem-root': {
                    padding: '12px 8px',
                    '&:hover': {
                        color: palette.primary.main,
                    },
                },
            },
        },
        '& .ApPILogo': {
            margin: '24px',
            overflow: 'inherit',
        },
        '& .sub-menu': {
            position: 'absolute' as 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 304,
            background: palette.common.white,
            transition: 'all 300ms ease-in-out',
            zIndex: 1,
            overflow: 'hidden',
            padding: 24,
            boxSizing: 'border-box',
            '&.open': {
                left: 316,
            },
            '& .MuiListItem-root': {
                cursor: 'pointer',
                '&:hover': {
                    color: palette.primary.main,
                },
            },
        },
        '& .MuiListItem-root': Object.assign(useRem(designTokens.typeStyles.navigationLinkEnable), {
            marginBottom: 4,
            borderRadius: '3.14px',
            '& .MuiListItemIcon-root, & i[class*="ap-"], .MuiTypography-body1': {
                color: 'inherit',
            },
            '&.active': Object.assign(useRem(designTokens.typeStyles.navigationLinkActive), {
                background: theme.palette.primary.light.replace('rgb', 'rgba').replace(')', ', 0.1)'),
            }),
            '&.active:hover': {
                background: theme.palette.primary.light.replace('rgb', 'rgba').replace(')', ', 0.2)'),
            },
            '&.active, &.active:hover': {
                color: palette.primary.main,
                '& i, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: palette.primary.main,
                },
            },
        }),
    },
});
