export enum UberContextType {
    MENU = 'MENU',
    ITEM = 'ITEM',
    MODIFIER_GROUP = 'MODIFIER_GROUP',
}

export enum UberDayOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
}

export enum UberPromotionType {
    BOGO = 'BOGO',
    FLATOFF = 'FLATOFF',
    PERCENTOFF = 'PERCENTOFF',
    FREEDELIVERY = 'FREEDELIVERY',
    CATEGORY_DISCOUNT = 'CATEGORY_DISCOUNT',
    FREEITEM_MINBASKET = 'FREEITEM_MINBASKET',
    MENU_ITEM_DISCOUNT = 'MENU_ITEM_DISCOUNT',
}
