import * as faker from 'faker';
import { PiClickV2DeliveryParameters } from './piclick-v2-delivery-parameters';

export const fakePiClickV2DeliveryParametersData = (): PiClickV2DeliveryParameters => ({
    posId: faker.random.uuid(),
    enabled: faker.random.boolean(),
    minAmountNoDeliveryFeed: faker.random.number(400000),
    useNoDeliveryFee: faker.random.boolean(),
    useStuartFee: faker.random.boolean(),
    maxOrderPerInterval: faker.random.number(),
    zones: [
        {
            distance: faker.random.number(100),
            fee: faker.random.number(1000),
        },
    ],
});

export const fakePiClickV2DeliveryParameters = (): PiClickV2DeliveryParameters => new PiClickV2DeliveryParameters(fakePiClickV2DeliveryParametersData());
