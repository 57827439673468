import * as React from 'react';

function SvgClose(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="close_svg__a" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </defs>
            <use fill="currentColor" xlinkHref="#close_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgClose;
