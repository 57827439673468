import * as React from 'react';

function SvgEvent(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path d="M17 13h-5v5h5v-5zM16 2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2zm3 18H5V9h14v11z" id="event_svg__a" />
            </defs>
            <use fill="currentColor" xlinkHref="#event_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgEvent;
