import axios, { AxiosInstance } from 'axios';

export const axiosInit = (environment) => {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
        async (config) => {
            config.headers.authorization = `Bearer ${(window as any).authToken.default}`;
            config.headers['Content-Type'] = config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json';
            return config;
        },
        (error) => Promise.reject(error)
    );

    return axiosInstance;
};

export interface AuthEnvironment {
    audiences: string[];
}

/**
 * Init multiple axios instances for multiple audiences.
 * It will also init the `default` axios instance with the default token defined in window.authToken.default.
 *
 * Each axios instance will be accessible with axiosInstances[audience]
 *
 * @param authEnvironment
 */
export const axiosInitWithAudience = (authEnvironment: AuthEnvironment): Record<string, AxiosInstance> => {
    const axiosInstances = {};
    for (const audience of authEnvironment.audiences) {
        axiosInstances[audience] = axios.create();
        axiosInstances[audience].interceptors.request.use(
            async (config) => {
                config.headers.authorization = `Bearer ${(window as any).authToken[audience]}`;
                config.headers['Content-Type'] = config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json';
                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    axiosInstances['default'] = axios.create();
    axiosInstances['default'].interceptors.request.use(
        async (config) => {
            config.headers.authorization = `Bearer ${(window as any).authToken['default']}`;
            config.headers['Content-Type'] = config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json';
            return config;
        },
        (error) => Promise.reject(error)
    );

    console.log(axiosInstances);

    return axiosInstances;
};
