import { ApSpinner } from '@aphilia/shared-ui-core-react';
import React, { useEffect } from 'react';
import { useAuthContext } from '../../stores';

export const LogoutPage = () => {
    const { logout } = useAuthContext();

    useEffect(() => {
        if (window.location.href.includes('apiId')) {
            logout({
                returnTo: `${window.location.origin}/${window.location.search}`,
            });
        } else {
            logout({
                returnTo: window.location.origin,
            });
        }
    }, []);

    return <ApSpinner />;
};
