import { useTranslation } from 'react-i18next';
import { ApSelect } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useMemo } from 'react';
import { useOrganizationContext } from '../../stores';

interface Props {
    onBrandSelected?: (brandId: string) => void;
}

export const ApBrandSelector = ({ onBrandSelected }: Props) => {
    const { t } = useTranslation();
    const { brandSelected, brands, setBrandSelected, setBrands } = useOrganizationContext();

    const getDefaultValue = useMemo(() => {
        return brandSelected?._id;
    }, [brandSelected?._id]);

    const handleBrandChange = useCallback(
        async (event: CustomEvent) => {
            await setBrandSelected(event.detail.value);
            if (onBrandSelected) onBrandSelected(event.detail.value);
        },
        [setBrandSelected, onBrandSelected]
    );

    return (
        <ApSelect
            id="brand"
            value={[getDefaultValue]}
            onItemSelected={handleBrandChange}
            label={t('brand.brandSelector.label')}
            onApOpen={setBrands}
            options={brands?.map((brand) => {
                return {
                    label: brand.name,
                    value: brand._id,
                };
            })}
        />
    );
};
