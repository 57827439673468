import { User } from '@aphilia/shared/organizations/types';
import { FSAction } from '@aphilia/shared/react';
import * as Actions from './constants';

///////////////////////////////////
////////////// User ///////////////
///////////////////////////////////
export function updateUser(user: User): FSAction<keyof typeof Actions, User, undefined> {
    return {
        type: Actions.UPDATE_USER,
        payload: user,
    };
}

export function updateLoadingUser(loading: boolean): FSAction<keyof typeof Actions, boolean, undefined> {
    return {
        type: Actions.UPDATE_LOADING_USER,
        payload: loading,
    };
}
