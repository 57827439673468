import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { Payment, PaymentEventName } from '@aphilia/payments/types';

interface Props {
    pusherClientKey: string;
    pusherCluster: string;
    paymentsUrl: string;
}

function usePaymentsSockets({ pusherClientKey, pusherCluster, paymentsUrl }: Props) {
    const [pusher, setPusher] = useState<Pusher>();
    const [channel, setChannel] = useState<any>();
    const [siteId, setSiteId] = useState<string>();
    const [paymentEvent, setPaymentEvent] = useState<{ event: PaymentEventName; payment: Payment }>();

    useEffect(() => {
        if (!siteId) return;
        channel?.unsubscribe();
        setPusher((oldValue) => {
            oldValue?.disconnect();
            return new Pusher(pusherClientKey, {
                cluster: pusherCluster,
                authEndpoint: `${paymentsUrl}/${siteId}/pusher`,
                auth: {
                    headers: { authorization: `Bearer ${(window as any).authToken['https://payments.aphilia.io']}` },
                },
            });
        });
    }, [siteId]);

    useEffect(() => {
        if (!pusher) return;
        setChannel(pusher.subscribe(`private-payments-${siteId}`));
    }, [pusher]);

    useEffect(() => {
        if (channel) {
            channel?.bind(PaymentEventName.FailedRefund, (payment: Payment) => {
                setPaymentEvent({ event: PaymentEventName.FailedRefund, payment: payment });
            });
            channel?.bind(PaymentEventName.Refunded, (payment: Payment) => {
                setPaymentEvent({ event: PaymentEventName.Refunded, payment: payment });
            });
            channel?.bind(PaymentEventName.Succeeded, (payment: Payment) => {
                setPaymentEvent({ event: PaymentEventName.Succeeded, payment: payment });
            });
            channel?.bind(PaymentEventName.Failed, (payment: Payment) => {
                setPaymentEvent({ event: PaymentEventName.Failed, payment: payment });
            });
            channel?.bind(PaymentEventName.Canceled, (payment: Payment) => {
                setPaymentEvent({ event: PaymentEventName.Canceled, payment: payment });
            });
        }
    }, [channel]);

    return { setSiteId, paymentEvent };
}

export { usePaymentsSockets };
