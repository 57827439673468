import { ApCard, ApCol, ApMainPage, ApRow, ApSpinner } from '@aphilia/shared-ui-core-react';
import React, { useCallback } from 'react';
import { useOrganizationContext } from '@aphilia/shared/organizations/feature-main';
import { PaymentsTable, useStripeContext } from '@aphilia/payments/feature-main';
import { IsphoraAccountInformation } from './components/information';

export const IsphoraPage = () => {
    const { siteSelected } = useOrganizationContext();
    const { isLoadingAccount, account } = useStripeContext();

    const getContent = useCallback(() => {
        if (isLoadingAccount) {
            return <ApSpinner />;
        } else if (account?.id) {
            return (
                <>
                    <ApRow>
                        <ApCol>
                            <IsphoraAccountInformation />
                        </ApCol>
                    </ApRow>
                    <ApRow>
                        <ApCol>
                            <ApCard>
                                <PaymentsTable />
                            </ApCard>
                        </ApCol>
                    </ApRow>
                </>
            );
        } else {
            return <p>Pas de compte encore pour ce site.</p>;
        }
    }, [siteSelected?._id, isLoadingAccount, account?.id]);

    return (
        <ApMainPage pageTitle={'Isphora'} pageSubtitle={'Gestion des moyens de paiements web'}>
            {getContent()}
        </ApMainPage>
    );
};
