export class UpdateAddress {
    /**
     * The main address
     */
    mainAddress?: string;

    /**
     * Complementary information on the address
     */
    complementaryInformation?: string;

    /**
     * The zip code of the address
     */
    zipCode?: string;

    /**
     * The city of the address
     */
    city?: string;

    /**
     * The country of the address
     */
    country?: string;

    constructor(props: UpdateAddress) {
        Object.assign(this, props);
    }
}
