import { ClickAndCollect } from '../click-and-collect';
import { Delivery } from '../delivery';
import { AtTable } from '../at-table';
import moment, { Moment } from 'moment';
import { Address } from '@aphilia/shared/utils';
import { DeliveryApplication } from '../delivery-applications';
import { Terminal } from '../terminal';
import { PlatformType } from '../../web-article';

export enum DeliverySiteStatus {
    Paused = 'PAUSED',
    Online = 'ONLINE',
}

export enum DeliveryPosStatus {
    Online = 'ONLINE',
    Offline = 'OFFLINE',
}

export enum DeliveryParametersEvent {
    UpdateDeliveryParameters = 'UPDATE_DELIVERY_PARAMETERS',
    ConnectToDeliveryParameters = 'CONNECT_TO_DELIVERY_PARAMETERS',
    DisconnectFromDeliveryParameters = 'DISCONNECT_FROM_DELIVERY_PARAMETERS',
}

export class DeliveryParameters {
    /**
     * The brand if of the site
     */
    readonly brandId?: string;

    /**
     * The regroupment id of a site
     */
    readonly regroupmentId?: string;

    /**
     * The name of the site
     */
    readonly name: string;

    /**
     * The code pic of the site
     */
    readonly codeSitePic?: string;

    /**
     * Site the delivery parameters are related to
     */
    readonly siteId: string;

    /**
     * Elyx code of the Site the delivery parameters are related to
     */
    readonly elyxCode?: number;

    /**
     * Indicates if the module has been activated for this site
     */
    enabled: boolean;

    /**
     * Indicates if the pilink application is enabled or not for the site.
     */
    hasPilink: boolean;

    /**
     * The address of the site for pickup
     */
    address: Address;

    /**
     * The phone number of the site
     */
    phoneNumber?: string;

    /**
     * The average time for the site to cook the food in minutes
     */
    timeForPreparation?: number;

    /**
     * Indicates the pos status of the site
     */
    posStatus: DeliveryPosStatus;

    /**
     * The last time the menu was deployed on the different platforms
     */
    lastDeployment?: Moment;

    /**
     * Indicates if the site has enabled the auto-accept.
     * If yes, the order will go directly from created to in_preparation
     */
    autoAccept: boolean;

    /**
     * Information about the click and collect integration
     */
    clickAndCollect?: ClickAndCollect;

    /**
     * Information about the delivery platform
     */
    delivery?: Delivery;

    /**
     * Information about the at table platform
     */
    atTable?: AtTable;

    /**
     * Information about the terminal platform
     */
    terminal?: Terminal;

    /**
     * The interval between each order on piclick. It will set each time an order can be placed.
     *
     * @example: 5 => 18h00, 18h05, 18h10, 18h15 .....
     */
    interval: number;

    /**
     * The maximum number of days an order can be placed ahead of now.
     *
     * @example: 3 => an order can be placed within 3 days but not 4.
     */
    orderPlanedMaxTime: number;

    /**
     * The settings for the applications
     */
    applications?: DeliveryApplication;

    /**
     * Indicates if PiClick V2 is enabled or not
     */
    isV2Enabled?: boolean;

    /**
     * The timezone of the site
     */
    timezone?: string;

    /**
     * The id of the master site if the site does not have its own pos system
     */
    masterSiteId?: string;

    /**
     * The site pic of the master site if the site does not have its own pos system
     */
    masterSitePic?: string;

    constructor(props: Partial<DeliveryParameters>) {
        Object.assign(this, props);
        this.clickAndCollect = new ClickAndCollect(this.clickAndCollect);
        if (this.delivery) {
            this.delivery = new Delivery(this.delivery);
        }

        if (this.atTable) {
            this.atTable = new AtTable(this.atTable);
        }

        if (this.lastDeployment) {
            this.lastDeployment = moment(this.lastDeployment);
        }
    }

    /**
     * Indicates if a site can receive orders or not
     */
    canReceiveOrder(platform: PlatformType) {
        return this.posStatus === DeliveryPosStatus.Online && this[platform].status === DeliverySiteStatus.Online;
    }
}
