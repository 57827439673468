import { WebArticle } from '../../web-article';
import { WebArticleCategory } from '../../web-article-category';
import { WebArticleModifier } from '../../web-article-modifier';
import moment, { Moment } from 'moment';
import { QueryJob } from '@aphilia/shared/utils';

export enum WebMenuJobName {
    DeployToSites = 'deploy_to_sites',
    DeployToPartnersPlatforms = 'deploy_to_partners_platforms',
    DeployToMongo = 'deploy_to_mongo',
}

export class WebMenuJobDataParameters {
    /**
     * If true, deliveroo cover will be considered when WebMenu is deploying
     */
    deliveroo: boolean;

    /**
     * If true, AtTable Platform will be considered when WebMenu is deploying
     */
    atTable: boolean;

    /**
     * If true, Delivery Platform will be considered when WebMenu is deploying
     */
    delivery: boolean;

    /**
     * If true, sellPeriods will be considered when WebMenu is deploying
     */
    sellPeriods: boolean;

    /**
     * If true, ClickAndCollect Platform will be considered when WebMenu is deploying
     */
    clickAndCollect: boolean;

    /**
     * If true, WebMenu will be deployed on all site applications
     */
    deployApplications: boolean;
}

export class WebMenuJobData {
    /**
     * The list of all the sites that are considered in the job
     */
    siteIds?: string[];

    /**
     * The base site that will be taken as the reference
     */
    referenceSiteId?: string;

    /**
     * The brand site that will be taken as the reference
     */
    referenceBrandId?: string;

    /**
     * The first pos id to take into account when deploying menu
     */
    firstPosId?: number;

    /**
     * The last pos id to take into account when deploying menu
     */
    lastPosId?: number;

    /**
     * The related platforms to deploy
     */
    parameters?: WebMenuJobDataParameters;

    /**
     * Indicates if we are deploying settings exclusively
     */
    onlyParameters?: boolean;

    /**
     * The failure cause, error message
     */
    failureCause?: string;
}

export class QueryWebMenuJob extends QueryJob {
    /**
     * The name of the job
     */
    name?: WebMenuJobName;

    /**
     * It will query all the jobs that has at least one of the site in the list
     */
    siteIds?: string[];

    /**
     * The base site that will be taken as the reference
     */
    referenceSiteId?: string;

    /**
     * The related platforms to deploy
     */
    parameters?: WebMenuJobDataParameters;

    /**
     * Indicates if we are deploying settings exclusively
     */
    onlyParameters?: boolean;

    /**
     * The failure cause, error message
     */
    failureCause?: string;
}

export class WebMenu {
    /**
     * Id of the menu in the database
     */
    readonly _id: string;

    /**
     * Site id to which the menu is assigned to.
     */
    readonly siteId: string;

    /**
     * List of all the articles that will be in the menu
     */
    articles?: WebArticle[];

    /**
     * List of all the categories that are in the menu
     */
    categories?: WebArticleCategory[];

    /**
     * List of all the modifiers that are in the menu
     */
    modifiers?: WebArticleModifier[];

    /**
     * The last date time the web menu was updated
     */
    updatedAt: Moment;

    /**
     * The last date time the web menu was created
     */
    createdAt: Moment;

    constructor(props: Partial<WebMenu>) {
        Object.assign(this, props);

        this.articles = this.articles?.map((article) => new WebArticle(article));
        this.categories = this.categories?.map((category) => new WebArticleCategory(category));
        this.modifiers = this.modifiers?.map((modifier) => new WebArticleModifier(modifier));
        this.createdAt = this.createdAt && moment(this.createdAt);
        this.updatedAt = this.updatedAt && moment(this.updatedAt);
    }
}
