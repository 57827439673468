enum SHADE_COEFFICIENTS {
    BLACK = -5,
    DARK_900 = -4,
    DARK_800 = -3,
    DARK_700 = -2,
    DARK_600 = -1,
    LIGHT_400 = 1,
    LIGHT_300 = 2,
    LIGHT_200 = 3,
    LIGHT_100 = 4,
    LIGHT_075 = 4.25,
    LIGHT_050 = 4.5,
    LIGHT_025 = 4.75,
    WHITE = 4.8,
}

export enum DEFAULT_COLORS {
    NEUTRAL = '#67718b',
    PRIMARY = '#1b54ea',
}

export interface RgbColor {
    red: number;
    blue: number;
    green: number;
}

interface Steps {
    red: {
        lighter: number;
        darker: number;
    };
    green: {
        lighter: number;
        darker: number;
    };
    blue: {
        lighter: number;
        darker: number;
    };
}

/**
 * Convert a giving RGB Decimal to two caracters Hexadecimal string
 *
 * @param color
 */
export const decimalToHex = (color: number): string => {
    const delimitedColorValue = color < 0 ? 0 : color > 255 ? 255 : color;
    const colorString = delimitedColorValue.toString(16);

    return colorString.length < 2 ? `0${colorString}` : colorString;
};

/**
 * Convert a Hex code color to RGB
 *
 * @param hex
 */
export const hexToRgb = (hex: string): RgbColor => {
    return {
        red: parseInt(hex.substring(1, 3), 16),
        green: parseInt(hex.substring(3, 5), 16),
        blue: parseInt(hex.substring(5, 7), 16),
    };
};

/**
 * Applies a shade (percentage) to a given RGB
 *
 * @param rgb
 * @param shade
 */
export const shadeRgb = (rgb: RgbColor, steps: Steps, shade: SHADE_COEFFICIENTS): RgbColor => {
    const { red, green, blue } = rgb;
    const lightOrDark = shade > 0 ? 'lighter' : 'darker';

    return {
        red: Math.max(0, Math.min(255, red + Math.round(steps.red[lightOrDark] * shade))),
        green: Math.max(0, Math.min(255, green + Math.round(steps.green[lightOrDark] * shade))),
        blue: Math.max(0, Math.min(255, blue + Math.round(steps.blue[lightOrDark] * shade))),
    };
};

/**
 * Applies a shade percentage to a given Hex code color
 *
 * @param color
 * @param shadePercentage
 */
export const linearShadeHexColor = (color: RgbColor, steps: Steps, shadeCoefficient: SHADE_COEFFICIENTS): string => {
    const { red, green, blue } = shadeRgb(color, steps, shadeCoefficient);

    return `#${decimalToHex(red)}${decimalToHex(green)}${decimalToHex(blue)}`;
};

/**
 * Generate complete theme color based on Piclick v2 style parameters
 *
 * @param parameters
 */
export const generateThemeColors = (parameters?: { neutralColor?: string; primaryColor?: string; darkMode?: boolean }): PiclickV2StyleValues => {
    const { neutralColor, primaryColor, darkMode } = parameters || {};
    const darkModeCoefficient = typeof darkMode !== 'undefined' ? (darkMode ? -1 : 1) : 1;
    const generatedNeutralColors = generateNeutralThemeColors(darkModeCoefficient, neutralColor);
    const generatedPrimaryColors = generatePrimaryThemeColors(darkModeCoefficient, primaryColor);

    return {
        ...generatedNeutralColors,
        ...generatedPrimaryColors,
    } as PiclickV2StyleValues;
};

/**
 * Generate the steps to go lighter and darker for all three main colors
 *
 * @param rgbColor
 * @returns Steps
 */
export const getColorStep = (rgbColor: RgbColor): Steps => {
    const darkerMult = Math.abs(SHADE_COEFFICIENTS.BLACK);
    return {
        red: {
            lighter: Math.round((255 - rgbColor.red) / SHADE_COEFFICIENTS.WHITE),
            darker: Math.round(rgbColor.red / darkerMult),
        },
        green: {
            lighter: Math.round((255 - rgbColor.green) / SHADE_COEFFICIENTS.WHITE),
            darker: Math.round(rgbColor.green / darkerMult),
        },
        blue: {
            lighter: Math.round((255 - rgbColor.blue) / SHADE_COEFFICIENTS.WHITE),
            darker: Math.round(rgbColor.blue / darkerMult),
        },
    };
};

/**
 * Generate primary theme colors
 *
 * @param dakrModeCoef equals -1 if dark mode is enabled
 * @param color
 */
const generatePrimaryThemeColors = (dakrModeCoef: 1 | -1, color: string): Partial<PiclickV2StyleValues> => {
    const primaryColor = color || DEFAULT_COLORS.PRIMARY;
    const rgbColor = hexToRgb(primaryColor);
    const steps = getColorStep(rgbColor);

    return {
        Primary_Default: primaryColor,
        Primary_Dark: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_600 * dakrModeCoef),
        Primary_Light: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_400 * dakrModeCoef),
        Primary_Transparent_8: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.08)`,
        Primary_Transparent_16: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.16)`,
        Primary_Transparent_24: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.24)`,
        Primary_900: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_900 * dakrModeCoef),
        Primary_800: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_800 * dakrModeCoef),
        Primary_700: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_700 * dakrModeCoef),
        Primary_300: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_300 * dakrModeCoef),
        Primary_200: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_200 * dakrModeCoef),
        Primary_100: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_100 * dakrModeCoef),
    };
};

/**
 * Generate neutral theme colors
 *
 * @param dakrModeCoef equals -1 if dark mode is enabled
 * @param color
 */
const generateNeutralThemeColors = (dakrModeCoef: 1 | -1, color: string): Partial<PiclickV2StyleValues> => {
    const neutralColor = color || DEFAULT_COLORS.NEUTRAL;
    const rgbColor = hexToRgb(neutralColor);
    const steps = getColorStep(rgbColor);
    const white = shadeRgb(rgbColor, steps, SHADE_COEFFICIENTS.WHITE * dakrModeCoef);

    return {
        White: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.WHITE * dakrModeCoef),
        Black: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.BLACK * dakrModeCoef),
        Neutral_800: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_800 * dakrModeCoef),
        Neutral_700: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_700 * dakrModeCoef),
        Neutral_300: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_300 * dakrModeCoef),
        Neutral_200: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_200 * dakrModeCoef),
        Neutral_100: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_100 * dakrModeCoef),
        Neutral_075: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_075 * dakrModeCoef),
        Neutral_050: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_050 * dakrModeCoef),
        Neutral_025: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_025 * dakrModeCoef),
        Neutral_Default_500: neutralColor,
        Neutral_Dark_600: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.DARK_600 * dakrModeCoef),
        Neutral_Light_400: linearShadeHexColor(rgbColor, steps, SHADE_COEFFICIENTS.LIGHT_400 * dakrModeCoef),
        Neutral_Transparent_8: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.08)`,
        Neutral_Transparent_16: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.16)`,
        Neutral_Transparent_24: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.24)`,
        Neutral_Transparent_32: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.32)`,
        Neutral_Transparent_40: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.4)`,
        Neutral_Transparent_48: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.48)`,
        White_Transparent_8: `rgba(${white.red}, ${white.green}, ${white.blue}, 0.08)`,
        White_Transparent_16: `rgba(${white.red}, ${white.green}, ${white.blue}, 0.16)`,
        White_Transparent_24: `rgba(${white.red}, ${white.green}, ${white.blue}, 0.24)`,
    };
};

export type PiclickV2StyleValues = { [key in StyleKeys]: string };

type StyleKeys =
    | 'Black'
    | 'White'
    | 'White_Transparent_8'
    | 'White_Transparent_16'
    | 'White_Transparent_24'
    | 'Primary_Default'
    | 'Primary_Dark'
    | 'Primary_Light'
    | 'Primary_900'
    | 'Primary_800'
    | 'Primary_700'
    | 'Primary_300'
    | 'Primary_200'
    | 'Primary_100'
    | 'Primary_Transparent_8'
    | 'Primary_Transparent_16'
    | 'Primary_Transparent_24'
    | 'Neutral_Default_500'
    | 'Neutral_800'
    | 'Neutral_700'
    | 'Neutral_Dark_600'
    | 'Neutral_Light_400'
    | 'Neutral_300'
    | 'Neutral_200'
    | 'Neutral_100'
    | 'Neutral_075'
    | 'Neutral_050'
    | 'Neutral_025'
    | 'Neutral_Transparent_8'
    | 'Neutral_Transparent_16'
    | 'Neutral_Transparent_24'
    | 'Neutral_Transparent_32'
    | 'Neutral_Transparent_40'
    | 'Neutral_Transparent_48';
