import axios from 'axios';

/**
 * Get the list of all the brands from the database
 */
export const isModuleAccessibleByUserId = async (organizationUrl: string, moduleId: string): Promise<boolean> => {
    try {
        return (await axios.get<boolean>(`${organizationUrl}/modules/${moduleId}/user/access`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } }))
            .data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
