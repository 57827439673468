const StorageType = {
    session: sessionStorage,
    local: localStorage,
};

class PersistantStorage {
    private storage: Storage;

    constructor(type = 'local') {
        this.storage = StorageType[type];
    }

    get length() {
        return this.storage.length;
    }

    key(itemIndex: number) {
        return this.storage.key(itemIndex);
    }

    getItem(itemKey: string): any {
        try {
            const currentItem: string = this.storage.getItem(itemKey);
            return !!currentItem ? JSON.parse(currentItem) : null;
        } catch (e) {
            console.error(e.message);
        }
    }

    setItem(itemKey: string, itemValue: any): boolean {
        try {
            this.storage.setItem(itemKey, JSON.stringify(itemValue));
            return true;
        } catch (e) {
            console.error(e.message);
        }
    }

    removeItem(itemKey: string): boolean {
        this.storage.removeItem(itemKey);
        return true;
    }

    clear(): boolean {
        this.storage.clear();
        return true;
    }

    async getItemAsync(itemKey: string): Promise<any> {
        try {
            const currentItem: string = this.storage.getItem(itemKey);
            return Promise.resolve(!!currentItem ? JSON.parse(currentItem) : null);
        } catch (e) {
            return Promise.reject(e.message);
        }
    }

    async setItemAsync(itemKey: string, itemValue: any): Promise<string | boolean> {
        try {
            this.storage.setItem(itemKey, JSON.stringify(itemValue));
            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(e.message);
        }
    }

    async removeItemAsync(itemKey: string): Promise<string | boolean> {
        this.storage.removeItem(itemKey);
        return Promise.resolve(true);
    }

    async clearAsync(): Promise<string | boolean> {
        this.storage.clear();
        return Promise.resolve(true);
    }
}

export { PersistantStorage, StorageType };
