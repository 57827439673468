import { CreateUber } from './create-uber';
import * as faker from 'faker';

export const fakeCreateUberData = (): CreateUber => ({
    enabled: faker.random.boolean(),
    locationId: faker.random.uuid(),
});

export const fakeCreateUber = (): CreateUber => new CreateUber(fakeCreateUberData());

export const fakeCreateUberArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateUber[] => {
    const uber: CreateUber[] = [];

    for (let i = 0; i < nbMax; i++) {
        uber.push(fakeCreateUber());
    }

    return uber;
};
