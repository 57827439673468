export class UberSuspension {
    /**
     * The time at which the item will return to being available for sale, specified as a Unix timestamp in seconds since Jan 1, 1970.
     * A null value, or time in the past, indicates that an item is available - otherwise it will be shown as “Sold Out” and unavailable to order.
     */
    suspend_until?: number;

    /**
     * Describes the reason for the suspension.
     */
    reason?: string;

    constructor(props: Partial<UberSuspension>) {
        Object.assign(this, props);
    }
}
