import { Address, WeekdayTimePeriods } from '@aphilia/shared/utils';
import { DeliverySiteStatus, PiclickV2StyleParameters } from '@aphilia/deliveries/data';
import { PiclickV2Consumable } from '../delivery-applications';

export class PublicDeliveryParameters {
    /**
     * The brand if of the site
     */
    readonly brandId: string;

    /**
     * The code pic of the site
     */
    readonly codeSitePic: string;

    /**
     * The code pic of the site
     */
    readonly regroupmentId: string;

    /**
     * Site the delivery parameters are related to
     */
    readonly siteId: string;

    /**
     * The name of the site
     */
    readonly name: string;

    /**
     * Indicates if the delivery module is enabled or not
     */
    readonly enabled: boolean;

    /**
     * The address of the site for pickup
     */
    readonly address: Address;

    /**
     * The phone number of the site
     */
    readonly phoneNumber?: string;

    /**
     * The current status of the site's delivery
     */
    readonly status: DeliverySiteStatus;

    /**
     * All the time periods of the click and collect system
     */
    readonly timePeriods: WeekdayTimePeriods[];

    readonly orderingPeriods: WeekdayTimePeriods[];

    /**
     * The time to prepare an order
     */
    readonly timeForPreparation: number;

    /**
     * The number of days an order can be taken in advance.
     *
     * For example if it's 5 => you can order today, tomorrow, ... until 5 days after today
     */
    readonly orderPlanedMaxTime: number;

    /**
     * The interval between each order time slot
     */
    readonly interval: number;

    /**
     * The url for the logo
     */
    readonly logoUrl?: string;

    /**
     * Use default loader
     */
    readonly useDefaultLoader?: boolean;

    /**
     * The url for the loader
     */
    readonly loaderUrl?: string;

    /**
     * The url for the delivery mobile cover image
     */
    readonly mobileCoverImageUrl?: string;

    /**
     * The url for the delivery desktop cover image
     */
    readonly desktopCoverImageUrl?: string;

    /**
     * The url for the redirection when an order is complete
     */
    readonly redirectionUrl?: string;

    /**
     * The url for the CGU page
     */
    readonly cguUrl?: string;

    /**
     * The url for the GDPR page
     */
    readonly gdprUrl?: string;

    /**
     * Indicates if PiClick V2 is enabled or not
     */
    readonly isV2Enabled?: boolean;

    /**
     * Indicates if click and collect enabled or not.
     */
    readonly isClickAndCollectEnabled?: boolean;

    /**
     * Indicates if delivery service is enabled or not.
     */
    readonly isDeliveryEnabled?: boolean;

    /**
     * Indicates whether the delivery service is performed by Stuart.
     */
    readonly hasStuart?: boolean;

    /**
     * Indicates if PiLink is enabled for or not
     */
    readonly isPiLinkEnabled?: boolean;

    /**
     * Receive style override for this site
     */
    readonly style?: PiclickV2StyleParameters;

    /**
     * Embeds consumable labels and max quantity
     */
    readonly consumables?: PiclickV2Consumable[];

    /**
     * The timezone of the site
     */
    readonly timezone?: string;

    /**
     * Use default loader for the in preparation stage
     */
    readonly useDefaultPreparationLoader?: boolean;

    /**
     * The URL for the in preparation stage
     */
    readonly inPreparationLoaderUrl?: string;

    /**
     * Indicates if alcohol prevention banner is enabled in PiClick template
     */
    readonly showAlcoholPrevention?: boolean;

    /**
     * Indicates if the client ca pay on site.
     */
    readonly prepayment?: 'MANDATORY' | 'OPTIONAL' | 'NONE';

    /**
     * Minimum price for a free delivery
     */
    readonly freeDelivery?: number;

    /**
     * Minimum amount authorized by the plateform for the order
     */
    readonly minOrderValue?: number;

    /**
     * The id of the master site if the site does not have its own pos system
     */
    readonly masterSiteId?: string;

    /**
     * The sitePic of the master site if the site does not have its own pos system
     */
    readonly masterSitePic?: string;

    constructor(props: PublicDeliveryParameters) {
        Object.assign(this, props);
    }
}
