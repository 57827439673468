import { Terminal } from './terminal';
import * as faker from 'faker';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { fakeTerminalMediaArray } from '../terminal-media/terminal.data';

export const fakeTerminalData = (): Terminal => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    videos: fakeTerminalMediaArray(5),
    images: fakeTerminalMediaArray(5),
});

export const fakeTerminal = (): Terminal => new Terminal(fakeTerminalData());

export const fakeTerminalArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Terminal[] => {
    const terminal: Terminal[] = [];

    for (let i = 0; i < nbMax; i++) {
        terminal.push(fakeTerminal());
    }

    return terminal;
};
