export class TerminalMedia {
    /**
     * Terminal media ID
     */
    _id: string;

    /**
     * Terminal media URL
     */
    url: string;

    constructor(props: TerminalMedia) {
        Object.assign(this, props);
    }
}
