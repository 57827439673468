import { AtTable } from './at-table';
import * as faker from 'faker';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '@aphilia/deliveries/data';

export const fakeAtTableData = (): AtTable => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    prepayment: 'MANDATORY',
    minOrderValue: faker.random.number(500),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
});

export const fakeAtTable = (): AtTable => new AtTable(fakeAtTableData());

export const fakeAtTableArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): AtTable[] => {
    const atTable: AtTable[] = [];

    for (let i = 0; i < nbMax; i++) {
        atTable.push(fakeAtTable());
    }

    return atTable;
};
