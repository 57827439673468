import { JobStatus } from 'bull';
import moment, { Moment } from 'moment';

export class Job<T> {
    /**
     * The id of the job in the database
     */
    readonly _id: string;

    /**
     * The id of the job in the job queue system
     */
    readonly jobId: string;

    /**
     * The job name
     */
    name: string;

    /**
     * The status of a job
     */
    status: JobStatus;

    /**
     * The percentage of progression of the job
     */
    progression?: number;

    /**
     * The date time the job was created
     */
    createdAt: Moment;

    /**
     * The date time the job started
     */
    startedAt?: Moment;

    /**
     * The date time the job was updated
     */
    updatedAt: Moment;

    /**
     * The date time the job was finished
     */
    finishedAt?: Moment;

    /**
     * The metadata associated to a job
     */
    data?: T;

    constructor(props: Job<T>) {
        Object.assign(this, props);
        this.createdAt = moment(this.createdAt);
        this.updatedAt = moment(this.updatedAt);
        this.finishedAt = this.finishedAt && moment(this.finishedAt);
        this.startedAt = this.startedAt && moment(this.startedAt);
    }
}
