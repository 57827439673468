import { UpdateWebArticleModifier } from './update-web-article-modifier';
import * as faker from 'faker';

export const fakeUpdateWebArticleModifierData = (): UpdateWebArticleModifier => ({
    siteId: faker.random.number().toString(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
});

export const fakeUpdateWebArticleModifier = (): UpdateWebArticleModifier => new UpdateWebArticleModifier(fakeUpdateWebArticleModifierData());

export const fakeUpdateWebArticleModifierArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticleModifier[] => {
    const webArticleModifier: UpdateWebArticleModifier[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifier.push(fakeUpdateWebArticleModifier());
    }

    return webArticleModifier;
};
