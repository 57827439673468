import { DeliveryParameters, DeliveryPosStatus } from './delivery';
import * as faker from 'faker';
import { fakeClickAndCollectData } from '../click-and-collect';
import { fakeDelivery } from '../delivery';
import { fakeAtTable } from '../at-table';
import moment from 'moment';
import { fakeAddress } from '@aphilia/shared/utils';
import { fakeDeliveryApplication } from '../delivery-applications';
import { fakeTerminal } from '../terminal';

export const fakeDeliveryParametersData = (): Partial<DeliveryParameters> => ({
    hasPilink: faker.random.boolean(),
    siteId: faker.random.uuid(),
    codeSitePic: faker.random.uuid(),
    regroupmentId: faker.random.uuid(),
    brandId: faker.random.uuid(),
    address: fakeAddress(),
    timeForPreparation: faker.random.number(40),
    posStatus: faker.random.objectElement<DeliveryPosStatus>(DeliveryPosStatus),
    autoAccept: faker.random.boolean(),
    clickAndCollect: fakeClickAndCollectData(),
    delivery: fakeDelivery(),
    terminal: fakeTerminal(),
    atTable: fakeAtTable(),
    lastDeployment: moment(),
    interval: faker.random.number(40),
    orderPlanedMaxTime: faker.random.number(4),
    phoneNumber: faker.phone.phoneNumber(),
    name: faker.company.companyName(),
    applications: fakeDeliveryApplication(),
    isV2Enabled: faker.random.boolean(),
});

export const fakeDeliveryParameters = (): DeliveryParameters => new DeliveryParameters(fakeDeliveryParametersData());

export const fakeDeliveryParametersArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): DeliveryParameters[] => {
    const delivery: DeliveryParameters[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeDeliveryParameters());
    }

    return delivery;
};
