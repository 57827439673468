import { BaseQueryModelPaginate } from '../..';
import { JobStatus } from 'bull';

export class QueryJob extends BaseQueryModelPaginate {
    /**
     * The name of the job
     */
    name?: string;

    /**
     * The list of all the status to query.
     * If none is provided then it will query all of the job status
     */
    status?: JobStatus[];

    constructor(props: QueryJob) {
        super(props);
        Object.assign(this, props);
    }
}
