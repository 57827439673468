import React from 'react';

import { ApButton, ApInput, ApSelect } from '@aphilia/shared-ui-core-react';
import { ApColumnData, ApColumnFilter, ApOperatorTradConfig } from '../../types';
import { useFilterEdition } from './hooks';

interface ApTableFilterRowProps {
    // Required
    columns: ApColumnData[];
    filter: ApColumnFilter;

    // Optional
    filterIndex?: number;
    filterColumnText?: string;
    filterOperatorText?: string;
    filterValueText?: string;
    filtersTrads?: ApOperatorTradConfig;
    onDeleteFilter?: (filterIndex: number) => void;
    onUpdateFilter?: (filterIndex: number, filter: ApColumnFilter) => void;
}

export function ApTableFilterRow({
    columns,
    filter,
    filterIndex,
    filterColumnText,
    filterOperatorText,
    filterValueText,
    filtersTrads,
    onUpdateFilter,
    onDeleteFilter,
}: ApTableFilterRowProps) {
    const { editedFilter, columnValue, operatorValue, columnsOptions, operatorOptions, handleColumn, handleOperator, handleValue, handleDeleteFilter } = useFilterEdition(
        columns,
        filter,
        filterIndex,
        filtersTrads,
        onUpdateFilter,
        onDeleteFilter
    );

    return (
        <li className="ap-edition-filters-item">
            <ApSelect label={filterColumnText} options={columnsOptions} value={[columnValue]} onItemSelected={handleColumn} />
            <ApSelect label={filterOperatorText} options={operatorOptions} value={[operatorValue]} onItemSelected={handleOperator} />
            <ApInput label={filterValueText} value={editedFilter?.value ?? ''} onInputChange={handleValue} />
            <ApButton iconLeft="close" type="ghost" color="neutral" onPointerUp={handleDeleteFilter} />
        </li>
    );
}
