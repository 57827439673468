import { UpdateDeliveroo, UpdatePiclick, UpdateStuart, UpdateUber } from '@aphilia/deliveries/data';

export class UpdateDeliveryApplication {
    /**
     * The piclick settings to update
     */
    piClick?: UpdatePiclick;

    /**
     * The Uber settings
     */
    uber?: UpdateUber;

    /**
     * The deliveroo settings
     */
    deliveroo?: UpdateDeliveroo;

    /**
     * The deliveroo settings
     */
    stuart?: UpdateStuart;

    constructor(props: UpdateDeliveryApplication) {
        Object.assign(this, props);
    }
}
