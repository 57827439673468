import { UberMoney } from '../payment/money';

export class UberItemPrice {
    /**
     * Price for one unit of the item, including any selected options.
     */
    unit_price: UberMoney;

    /**
     * The total cost for the specified quantity of the item, including any selected options.
     */
    total_price: UberMoney;

    /**
     * Price for one unit of the item, excluding any selected options.
     */
    base_unit_price: UberMoney;

    /**
     * The total cost for the specified quantity of the item, excluding any selected options.
     */
    base_total_price: UberMoney;
}
