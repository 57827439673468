import { CreateStuart } from './create-stuart';
import * as faker from 'faker';

export const fakeCreateStuartData = (): CreateStuart => ({
    name: faker.name.findName(),
});

export const fakeCreateStuart = (): CreateStuart => new CreateStuart(fakeCreateStuartData());

export const fakeCreateStuartArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateStuart[] => {
    const stuart: CreateStuart[] = [];

    for (let i = 0; i < nbMax; i++) {
        stuart.push(fakeCreateStuart());
    }

    return stuart;
};
