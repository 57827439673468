import { Job } from './job';
import * as faker from 'faker';
import moment from 'moment';
import { JobStatus } from 'bull';

export function fakeJobData<T>(data: T): Job<T> {
    return {
        _id: faker.random.number().toString(),
        name: faker.name.findName(),
        status: faker.random.arrayElement<JobStatus>(['completed', 'waiting', 'active', 'delayed', 'paused', 'failed']),
        progression: faker.random.number(100),
        createdAt: moment(),
        updatedAt: moment(),
        finishedAt: moment(),
        startedAt: moment(),
        jobId: faker.random.number().toString(),
        data,
    };
}

export function fakeJob<T>(data: T): Job<T> {
    return new Job(fakeJobData(data));
}

export function fakeJobArray<T>(data: T, nbMax: number = faker.random.number({ min: 1, max: 20 })): Job<T>[] {
    const job: Job<T>[] = [];

    for (let i = 0; i < nbMax; i++) {
        job.push(fakeJob<T>(data));
    }

    return job;
}
