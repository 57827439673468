import { useCallback, useState, useEffect } from 'react';
import { cloneDeep, isNil } from 'lodash';
import { SortDirection } from 'react-virtualized';

import { getLanguage } from '../../services/i18n';

function getValue(item: any | { value: string | number }) {
    const toGet = typeof item === 'object' && item.hasOwnProperty('value') ? item.value : item;
    if (!toGet && toGet !== 0) return toGet;

    return /^[0-9]+[,.]{1}[0-9]+$|^[0-9]+$/.test(toGet) ? (typeof toGet === 'string' ? parseFloat(toGet.replace(',', '.')) : toGet) : toGet.toLowerCase();
}

export function updateOrder(tableSortBy: string, tableSortDirection: string, rows: { [key: string]: object }, oldOrder: string[]) {
    if (tableSortBy === defaultSortBy) return oldOrder;

    const newOrder = cloneDeep(oldOrder).sort((k1, k2) => {
        if (!rows[k1][tableSortBy]) return (tableSortDirection === SortDirection.ASC && 1) || -1;
        if (!rows[k2][tableSortBy]) return (tableSortDirection === SortDirection.DESC && 1) || -1;

        const k1Value = getValue(rows[k1][tableSortBy]);
        const k2Value = getValue(rows[k2][tableSortBy]);
        const isNaN = Number.isNaN(k1Value) && Number.isNaN(k2Value);

        if (typeof k1Value === typeof k2Value) {
            const sortDirection = !isNaN ? k1Value > k2Value : k1Value.toLocaleCompare(k2Value, getLanguage(), { ignorePunctuation: true }) > 0;
            return sortDirection ? (tableSortDirection === SortDirection.DESC && -1) || 1 : (tableSortDirection === SortDirection.ASC && -1) || 1;
        } else {
            return typeof k1Value === 'number' ? (tableSortDirection === SortDirection.DESC && 1) || -1 : (tableSortDirection === SortDirection.ASC && 1) || -1;
        }
    });

    return newOrder;
}

function useHorizontalScroll(isSelectable, columns, colWidth, rowHeight) {
    const [horizontalScroll, setHorizontalScroll] = useState(0);

    const handleScroll = useCallback((e) => {
        setHorizontalScroll(e.currentTarget.scrollLeft);
    }, []);

    const getLeftPosition = useCallback(
        (sticky, index) => {
            if (!sticky) return null;
            return columns.slice(0, index).reduce((left, col) => {
                left += (col.colSpan || 1) * colWidth;
                return left;
            }, horizontalScroll + ((isSelectable && rowHeight) || 0));
        },
        [horizontalScroll, isSelectable, columns]
    );

    return { horizontalScroll, handleScroll, getLeftPosition };
}

function useVerticalScroll() {
    const [verticalScroll, setVerticalScroll] = useState(0);

    const resetVerticalScroll = useCallback(() => {
        setVerticalScroll(0);
    }, []);

    const handleVerticalScroll = useCallback((e) => {
        setVerticalScroll(e?.currentTarget?.scrollTop);
    }, []);

    return { verticalScroll, resetVerticalScroll, handleVerticalScroll };
}

function useSelection(order, disabledRows, rows, onSelect) {
    const [selected, setSelected] = useState<string[]>([]);

    const handleSelectAll = useCallback(() => {
        let newSelection = [];
        if (!selected.length) {
            newSelection = order;
        } else if (selected.length && order) {
            if (selectedAndDisplayedLength()) {
                newSelection = selected.filter((key) => !order.includes(key));
            } else {
                newSelection = selected.concat(order);
            }
        }
        newSelection = newSelection.filter((key) => (disabledRows ? !disabledRows.includes(key) : key));

        setSelected(newSelection);
        onSelect(newSelection.map((key) => rows[key]));
    }, [selected, order, rows, disabledRows, onSelect]);

    const resetSelection = useCallback(() => {
        setSelected([]);
        onSelect([]);
    }, [onSelect]);

    const handleSelected = useCallback((newSelection) => {
        setSelected(newSelection);
    }, []);

    const selectedAndDisplayedLength = useCallback(() => {
        return selected.filter((key) => order.includes(key)).length;
    }, [selected, order]);

    return { selected, handleSelectAll, resetSelection, selectedAndDisplayedLength, handleSelected };
}

function useOrder(display, rows, sortBy, sortDirection) {
    const [order, setOrder] = useState<string[]>(null);

    useEffect(() => {
        setOrder(updateOrder(sortBy, sortDirection, rows, display ? display : Object.keys(rows)));
    }, [display, rows, sortBy, sortDirection]);

    const handleOrder = useCallback((orderValue) => {
        setOrder(orderValue);
    }, []);

    return { order, handleOrder };
}

// Sort
export const defaultSortBy = '';
export const defaultSortDirection = null;
const sortDirections = [SortDirection.ASC, SortDirection.DESC, defaultSortDirection];

function useSort(sortByInitial: string, sortDirectionInitial: keyof typeof SortDirection, resetScroll) {
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);

    const [sortIndex, setSortIndex] = useState(0);

    const handleSort = useCallback(
        ({ sortBy: sortByValue }) => {
            setSortBy(sortByValue);

            let newSortIndex = 0;
            if (sortBy === sortByValue) {
                newSortIndex = sortIndex < sortDirections.length - 1 ? sortIndex + 1 : 0;
            }
            const newSortDirection = sortDirections[newSortIndex];
            setSortDirection(newSortDirection);
            setSortIndex(newSortIndex);

            if (typeof resetScroll === 'function') {
                resetScroll();
            }
        },
        [resetScroll, sortIndex, sortBy]
    );

    useEffect(() => {
        if (!isNil(sortByInitial)) {
            setSortBy(sortByInitial);
            /**
             * If sort by has a column name, provide a default value to sort direcion
             */
            if (sortByInitial !== '' && isNil(sortDirectionInitial)) {
                sortDirectionInitial = SortDirection.ASC;
            }
        }

        if (!isNil(sortDirectionInitial)) {
            setSortDirection(sortDirectionInitial);
            const initialIndex = sortDirections.indexOf(sortDirectionInitial);
            if (initialIndex !== -1) {
                setSortIndex(initialIndex);
            }
        }
    }, []);

    return { sortBy, sortDirection, handleSort };
}

export { useHorizontalScroll, useVerticalScroll, useSelection, useOrder, useSort };
