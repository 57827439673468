import { useTranslation } from 'react-i18next';
import { ApSelect } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrganizationContext } from '../../stores';

interface ApSiteSelectorProps {
    onSiteSelected?: (siteId: string) => void;
}

export const ApSiteSelector = ({ onSiteSelected }: ApSiteSelectorProps) => {
    const { t } = useTranslation();
    const { siteSelected, sites, setSiteSelected, setSites } = useOrganizationContext();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredSites, setFilteredSites] = useState([]);

    const getDefaultValue = useMemo(() => {
        return siteSelected?._id;
    }, [siteSelected?._id]);

    const handleSiteChange = useCallback(
        async (event: CustomEvent) => {
            await setSiteSelected(event.detail.value);
            if (onSiteSelected) onSiteSelected(event.detail.value);
        },
        [setSiteSelected, onSiteSelected]
    );

    const formatedSites = useMemo(() => {
        return sites.map((site) => {
            return {
                label: `${site.elyxCode ? `${site.elyxCode} - ` : ''}${site.name}`,
                value: site._id,
            };
        });
    }, [sites]);

    const handleSelectValueChange = useCallback(
        (event: CustomEvent<string>) => {
            setIsLoading(true);
            setFilteredSites(formatedSites?.filter((site) => site.label.toLowerCase().includes(event.detail.toLowerCase())));
            setIsLoading(false);
        },
        [formatedSites]
    );

    const handleDefault = useCallback(() => {
        setFilteredSites(formatedSites);
    }, [formatedSites]);

    useEffect(() => {
        setFilteredSites(formatedSites);
    }, [formatedSites]);

    return (
        <ApSelect
            id="site"
            value={[getDefaultValue]}
            onItemSelected={handleSiteChange}
            label={t('site.siteSelector.label')}
            onApOpen={setSites}
            compact
            borderless
            autocomplete
            isLoading={isLoading}
            onInputValueChange={handleSelectValueChange}
            emptyText={t('site.siteSelector.isEmpty')}
            options={filteredSites}
            requiredValue={true}
            onRestoreDefault={handleDefault}
        />
    );
};
