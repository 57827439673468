import { ApToast } from '@aphilia/shared-ui-core-react';
import React, { createContext, useContext, useState } from 'react';
import { ToastStateObject } from './types';

const store = createContext<ToastStateObject>({});

const { Provider } = store;

interface Props {
    children: React.ReactNode;
}

const ToastStateProvider = ({ children }: Props) => {
    const [toastType, setToastType] = useState<'success' | 'error' | 'primary' | 'warning'>('success');
    const [toastMessage, setToastMessage] = useState<string>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    /**
     * Display a toast
     *
     * @param type
     * @param message
     */
    const showToast = (type: 'success' | 'error' | 'primary' | 'warning', message: string) => {
        setToastType(type);
        setToastMessage(message);
        setIsOpen(true);
    };

    /**
     * Display a success toast with a message
     *
     * @param message
     */
    const showSuccessToast = (message: string) => {
        showToast('success', message);
    };

    /**
     * Display an toast with an error
     * @param message
     */
    const showErrorToast = (message: string) => {
        showToast('error', message);
    };

    return (
        <Provider value={{ showSuccessToast, showErrorToast }}>
            {children}
            <ApToast onDidDismiss={() => setIsOpen(false)} type={toastType} text={toastMessage} isOpen={isOpen} />
        </Provider>
    );
};

// Generate a hook to use the organization context
const useToastContext = () => useContext(store);

export { useToastContext, ToastStateProvider };
