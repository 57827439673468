import React from 'react';

export const ApPILogo: React.FC = () => {
    return (
        <svg className="ApPILogo" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                // tslint:disable-next-line: max-line-length
                d="M30.7472 1.2517V30.7483H1.25277V1.2517H30.7472ZM31.1557 0H0.844255C0.381277 0 0 0.380952 0 0.843537V31.1293C0 31.5918 0.381277 31.9728 0.844255 31.9728H31.1285C31.5915 31.9728 31.9728 31.5918 31.9728 31.1293V0.843537C32 0.380952 31.6187 0 31.1557 0Z"
                fill="#1B54EA"
            />
            <path
                // tslint:disable-next-line: max-line-length
                d="M26.063 7.89111H5.99155C5.88262 7.89111 5.77368 7.97275 5.77368 8.1088V10.3129C5.77368 10.4217 5.85538 10.5306 5.99155 10.5306H9.69538C9.80432 10.5306 9.91326 10.6122 9.91326 10.7483V25.7687C9.91326 25.8775 9.99496 25.9863 10.1311 25.9863H12.3099C12.4188 25.9863 12.5277 25.9047 12.5277 25.7687V10.6938C12.5277 10.585 12.6094 10.4761 12.7456 10.4761H19.3362C19.4452 10.4761 19.5541 10.5578 19.5541 10.6938V25.7142C19.5541 25.8231 19.6358 25.9319 19.772 25.9319H21.9507C22.0596 25.9319 22.1686 25.8503 22.1686 25.7142V10.6938C22.1686 10.585 22.2503 10.4761 22.3864 10.4761H26.1447C26.2537 10.4761 26.3626 10.3945 26.3626 10.2585V8.05438C26.2809 7.97275 26.172 7.89111 26.063 7.89111Z"
                fill="#1B54EA"
            />
        </svg>
    );
};
