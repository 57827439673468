import * as faker from 'faker';
import { Payment } from './payment';

/**
 * Return payment data generated by faker.
 *
 * @return Payment
 */
export const fakePaymentData = (): Payment => ({
    currencyCode: faker.finance.currencyCode(),
    total: parseInt(faker.finance.amount(), 10),
});

/**
 * Return an instance of Payment class with data generated by faker
 *
 * @return Payment
 */
export const fakePayment = (): Payment => new Payment(fakePaymentData());
