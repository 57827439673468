import { ApSelect, ApTextarea } from '@aphilia/shared-ui-core-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApPortalModal, useToastContext } from '@aphilia/shared/react';
import { Payment, RefundReason } from '@aphilia/payments/types';
import { createRefund } from '@aphilia/payments/data-access';
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import { useForm, Controller } from 'react-hook-form';

const schema = Joi.object({
    refundReason: Joi.string().required().valid(RefundReason.Other, RefundReason.Offer, RefundReason.AskByClient),
    otherReason: Joi.when('refundReason', {
        is: RefundReason.Other,
        then: Joi.string().required().min(5).max(200).messages({
            'any.required': 'Le champ est requis',
            'string.min': 'Le champ doit faire au minimum 5 caractères',
            'string.max': 'Le champ ne doit pas faire plus de 200 caractères',
            'string.empty': 'Le champ ne peut pas être vide',
        }),
    }),
});

interface Props {
    /**
     * The id of the payment
     */
    paymentId: string;

    /**
     * The amount to refund
     */
    amount: string;

    /**
     * Indicates if the modal is open or not
     */
    isOpen: boolean;

    /**
     * Callback trigger when the modal is hidden
     */
    onHide?: () => void;

    /**
     * Callback when the user cancel
     */
    onCancel: () => void;

    /**
     * Callback when the user confirm
     */
    onMadeRefund: (payment: Payment) => void;

    /**
     * URL of the payment service to make some refunds
     */
    paymentsServiceUrl: string;
}

export function RefundModal({ paymentId, amount, isOpen, onHide, onMadeRefund, onCancel, paymentsServiceUrl }: Props) {
    const { t } = useTranslation();
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        resolver: joiResolver(schema),
    });
    const [refundReason, setRefundReason] = useState<RefundReason>();
    const [otherReason, setOtherReason] = useState<string>();
    const [isRefunding, setIsRefunding] = useState<boolean>(false);
    const { showErrorToast } = useToastContext();

    useEffect(() => {
        if (paymentId) {
            setRefundReason(undefined);
            setOtherReason(undefined);
        }
    }, [paymentId]);

    /**
     * Create a refund
     */
    const makeRefund = useCallback(async () => {
        setIsRefunding(true);
        try {
            onMadeRefund(await createRefund(paymentsServiceUrl, paymentId, { refund_reason: refundReason, other_refund_reason: otherReason, refund_by: 'user' }));
            setRefundReason(undefined);
            setOtherReason(undefined);
            setIsRefunding(false);
        } catch (e) {
            console.error(e);
            showErrorToast(e.message);
            setIsRefunding(false);
            throw e;
        }
    }, [paymentId, refundReason, otherReason, errors]);

    return (
        <ApPortalModal
            isOpen={isOpen}
            headerTitle={t('payments.refundModal.headerTitle')}
            cancelText={t('payments.refundModal.cancelText')}
            confirmText={t('payments.refundModal.confirmText')}
            onConfirm={handleSubmit(makeRefund)}
            onHide={onHide}
            canHide={!isRefunding}
            isLoading={isRefunding}
        >
            <form>
                <p>{t('payments.refundModal.amount', { amount })}</p>
                <Controller
                    control={control}
                    render={({ field }) => (
                        <ApSelect
                            {...field}
                            label={t('payments.refundModal.form.reason.label')}
                            color={errors?.refundReason?.message ? 'error' : undefined}
                            value={refundReason && [refundReason]}
                            options={[
                                { label: t('payments.refundReason.form.reason.ask_by_client'), value: 'ask_by_client' },
                                { label: t('payments.refundReason.form.reason.offer'), value: 'offer' },
                                { label: t('payments.refundReason.form.reason.other'), value: 'other' },
                            ]}
                            onItemSelected={(e) => {
                                setRefundReason(e.detail.value);
                                setValue('refundReason', e.detail.value, { shouldValidate: true });
                            }}
                        />
                    )}
                    name="refundReason"
                />
                {refundReason === 'other' && (
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <ApTextarea
                                {...field}
                                color={errors?.otherReason?.message ? 'error' : undefined}
                                helpText={errors?.otherReason?.message}
                                label={t('payments.refundModal.form.otherReason.label')}
                                onInputBlur={(e) => {
                                    setOtherReason(e.detail.target.value);
                                    setValue('otherReason', e.detail.target.value, { shouldValidate: true });
                                }}
                            />
                        )}
                        name="otherReason"
                    />
                )}
            </form>
        </ApPortalModal>
    );
}
