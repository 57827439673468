import { ApTypography } from '@aphilia/shared-ui-core-react';
import React from 'react';

export const ForbiddenPage = () => {
    return (
        <div>
            <main>
                <ApTypography type="h4">Vous n'avez pas accès à cette partie.</ApTypography>
            </main>
        </div>
    );
};
