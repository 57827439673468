import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import style from './ApSwitch.style';
import { Typography } from '@material-ui/core';

interface Props {
    name: string;
    callBack: (event: React.ChangeEvent<{ name?: string; checked: boolean }>) => void;
    color?: any;
    checked?: boolean;
    label?: {
        text: string;
        orientation: string;
    };
    tooltip?: React.ReactNode;
    columns?: number;
}

const useStyles = makeStyles(style);

export const ApSwitch = ({ name, callBack, color = 'primary', checked = false, label, tooltip, columns = 12 }: Props) => {
    const classes = useStyles();

    return (
        <Grid container item xs={columns as boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12} direction="row" justify="space-between" className="ap-switch">
            {!label && <Switch className={classes.switch} color={color} checked={checked} name={name} onChange={callBack} />}
            {label && label.orientation === 'start' && (
                <React.Fragment>
                    <Grid item>
                        <Typography className={classes.label} variant="body1" component="span">
                            {label.text}
                        </Typography>
                        {tooltip}
                    </Grid>
                    <Grid item>
                        <Switch className={classes.switch} color={color} checked={checked} name={name} onChange={callBack} />
                    </Grid>
                </React.Fragment>
            )}
            {label && label.orientation === 'end' && (
                <React.Fragment>
                    <Grid item>
                        <Switch className={classes.switch} color={color} checked={checked} name={name} onChange={callBack} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.label} variant="body1" component="span">
                            {label.text}
                        </Typography>
                        {tooltip}
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};
