import { DeliverySiteStatus } from '@aphilia/deliveries/data';
import { WeekdayTimePeriods, PriceType } from '@aphilia/shared/utils';

export class UpdateDelivery {
    /**
     * Indicates if the delivery is enabled or not
     */
    enabled?: boolean;

    /**
     * The price level use for the delivery
     */
    priceLevel?: PriceType;

    /**
     * All the time periods for the delivery
     */
    timePeriods?: WeekdayTimePeriods[];

    /**
     * Indicates the maximum number of orders per interval.
     */
    maxOrderPerInterval?: number;

    /**
     * Minimum amount authorized for the order
     */
    minOrderValue?: number;

    /**
     * Indicates if the delivery platform is paused. Set to false to indicate that the plateform is paused
     */
    status?: DeliverySiteStatus;

    constructor(props: UpdateDelivery) {
        Object.assign(this, props);
    }
}
