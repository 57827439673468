import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import { ApButton } from '../ApButton';
import { ApSpinner } from '../ApSpinner';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApStepper.style';
import { ApInlineButton } from '../ApButton/ApInlineButton';

interface Props {
    steps: {
        label: string;
        component: React.ReactNode;
    }[];
    callBack: () => void;
    conditions?: boolean[];
    dataUpdating?: boolean;
}

const useStyles = makeStyles(style);

export const ApStepper = ({ steps, callBack, conditions, dataUpdating }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleFinish = () => {
        callBack();
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const setActive = (index) => () => {
        setActiveStep(index);
    };

    return (
        <div className={`stepper ${classes.root}`}>
            <header>
                <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}>
                    {steps.map((step, index) => (
                        <StepLabel onClick={setActive(index)} key={step.label}>
                            {step.label}
                            <i className="ap-chevron-right" />
                        </StepLabel>
                    ))}
                </Stepper>
                <div className="step-indicator">
                    <span
                        className="completion"
                        style={{
                            width: `${((activeStep + 0.5) * 100) / steps.length}%`,
                        }}
                    />
                </div>
            </header>
            <div className="steps" style={{ transform: `translateX(-${activeStep * 598}px)` }}>
                {steps.map((step) => (
                    <div key={step.label} className="step">
                        {step.component}
                    </div>
                ))}
            </div>
            {activeStep !== steps.length - 1 && (
                <footer>
                    <ApInlineButton>
                        {dataUpdating ? (
                            <ApButton disabled={true} clickCallback={handleFinish} variant="text" color="secondary">
                                <ApSpinner size={16} />
                            </ApButton>
                        ) : (
                            <ApButton clickCallback={handleFinish} variant="text" color="secondary">
                                {t('finishNow')}
                            </ApButton>
                        )}
                        <ApButton clickCallback={handleNext}>{t('nextStep')}</ApButton>
                    </ApInlineButton>
                </footer>
            )}
            {activeStep === steps.length - 1 && (
                <footer>
                    {dataUpdating ? (
                        <ApButton disabled={true} clickCallback={handleFinish}>
                            <ApSpinner size={16} />
                        </ApButton>
                    ) : (
                        <ApButton disabled={(conditions && !conditions.every((cond) => cond)) || false} clickCallback={handleFinish}>
                            {t('finish')}
                        </ApButton>
                    )}
                </footer>
            )}
        </div>
    );
};
