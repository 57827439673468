import * as faker from 'faker';
import { UpdatePayment } from './update-payment';
import { OrderPaymentStatus } from '@aphilia/deliveries/data';

/**
 * Return payment data generated by faker.
 *
 * @return UpdatePayment
 */
export const fakeUpdatePaymentData = (): UpdatePayment => ({
    currencyCode: faker.finance.currencyCode(),
    total: parseInt(faker.finance.amount(), 10),
    status: faker.random.objectElement<OrderPaymentStatus>(OrderPaymentStatus),
});

/**
 * Return an instance of Payment class with data generated by faker
 *
 * @return UpdatePayment
 */
export const fakeUpdatePayment = (): UpdatePayment => new UpdatePayment(fakeUpdatePaymentData());
