import * as React from 'react';
import { ApActionButtons } from '@aphilia/shared-ui-core-react';
import { useCallback, useEffect, useState } from 'react';
import './ap-form.scss';
import { useToastContext } from '../../stores';
import { isEqual } from 'lodash';

interface Props {
    onApSubmit: () => Promise<any>;
    children: React.ReactNode;
    forceDisabledConfirm?: boolean;
    confirmText: string;
    cancelText: string;
    successMessage?: string;
    errorMessage?: string;
}

export const ApForm = ({ onApSubmit, children, forceDisabledConfirm, cancelText, confirmText, successMessage, errorMessage }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { showSuccessToast, showErrorToast } = useToastContext();

    const handleOnConfirm = useCallback(async () => {
        try {
            setIsLoading(true);
            await onApSubmit();
            if (successMessage) {
                showSuccessToast(successMessage);
            }
        } catch (e) {
            console.error(e);
            if (errorMessage) {
                showErrorToast(errorMessage);
            }
        }
        setIsLoading(false);
    }, [onApSubmit]);

    return (
        <form className="ap-form">
            {children}
            <ApActionButtons disabled={forceDisabledConfirm} confirmText={confirmText} cancelText={cancelText} onApConfirm={() => handleOnConfirm()} isLoading={isLoading} />
        </form>
    );
};
