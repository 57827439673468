import { TimeSlot } from '../../time-slot';

export class Period {
    /**
     * id of the period
     */
    readonly _id: string;

    /**
     * The site id of the period
     */
    siteId: string;

    /**
     * Label of the period
     */
    label: Record<string, string>;

    /**
     * Days in which the period is active
     */
    days: boolean[];

    /**
     * The timeslots that make up this period
     */
    timeSlots: TimeSlot[];

    /**
     * The list of all the articles that are attached to the period
     */
    articleIds?: string[];

    /**
     * The list of all the categories that are attached to the period
     */
    categoryIds?: string[];

    constructor(props: Period) {
        Object.assign(this, props);
    }
}
