import { PromotionItem } from './promotion-item/promotion-item';

export class Promotion {
    /**
     * This is the discount value as part of the promo, represented as an integer.
     */
    value?: number;

    /**
     * This is the discount percentage as part of the promo, represented as an integer.
     * If the promo was calculatd using percentage instead of saved values, this field will be populated.
     */
    percentage?: number;

    /**
     * This is a list of cart items with a discount applied.
     */
    items?: PromotionItem[];

    constructor(props: Promotion) {
        Object.assign(this, props);
    }
}
