import { palette } from '../../theme';
import { createStyles } from '@material-ui/core';

export default () =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% + 20px)',
            '& header': {
                paddingRight: 20,
                '& .MuiStepLabel-label': {
                    cursor: 'pointer',
                    color: palette.grey[300],
                    '&.MuiStepLabel-completed': {
                        color: palette.grey[600],
                        fontWeight: 400,
                    },
                    '&.MuiStepLabel-active': {
                        fontWeight: 500,
                        color: palette.primary.main,
                    },
                },
            },
            '& .steps': {
                height: 'calc(100% - 106px)',
                display: 'flex',
                flexWrap: 'nowrap',
                transition: 'all 300ms ease-in-out',
                '& .step': {
                    width: '100%',
                    height: '100%',
                    flexShrink: 0,
                    marginRight: 12,
                    padding: '50px 20px 32px 0',
                    boxSizing: 'border-box',
                    overflow: 'auto',
                },
            },
            '& footer': {
                textAlign: 'right',
                paddingRight: 20,
                paddingTop: 16,
            },
        },
        stepper: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '14px 10px',
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: 0,
            },
            '& + .step-indicator': {
                position: 'relative',
                border: `1px solid ${palette.grey[50]}`,
                '& .completion': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    border: `1px solid ${palette.primary.main}`,
                },
            },
        },
    });
