import { CreateCustomer } from './create-customer';
import * as faker from 'faker';

export const fakeCreateCustomerData = (): CreateCustomer => ({
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    phoneNumber: faker.phone.phoneNumber(),
    email: faker.internet.email(),
    address: faker.address.streetAddress(true),
    addressComment: faker.random.words(),
    jobId: faker.random.number(),
    trackingUrl: faker.internet.url(),
    orderedConsumables: {
        cutlery: faker.random.number(10),
        example1: faker.random.number(20),
        example2: faker.random.number(5),
        example3: faker.random.number(30),
    },
});

export const fakeCreateCustomer = (): CreateCustomer => new CreateCustomer(fakeCreateCustomerData());

export const fakeCreateCustomerArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateCustomer[] => {
    const customer: CreateCustomer[] = [];

    for (let i = 0; i < nbMax; i++) {
        customer.push(fakeCreateCustomer());
    }

    return customer;
};
