import * as React from 'react';

function SvgWarning(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" id="warning_svg__a" />
            </defs>
            <use fill="currentColor" xlinkHref="#warning_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgWarning;
