import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        '& .MuiRadio-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-labelPlacementStart': {
            margin: '0 0 16px 0',
            justifyContent: 'space-between',
        },
        '& .MuiFormControlLabel-label': {
            color: palette.grey[500],
        },
    },
});
