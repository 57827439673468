export class DeliverooPrice {
    /**
     * Price in minor units of the currency, eg. pennies for GBP, or cents for EUR.
     */
    price: number;

    /**
     * Price overrides for the item in different contexts.
     */
    overrides?: {
        /**
         * Type of the context where the price is overridden. Permitted values: "MODIFIER"
         */
        type?: string;

        /**
         *  Unique identifier for the context.
         */
        id?: string;

        /**
         * Price in minor units of the currency, eg. pennies for GBP, or cents for EUR.
         */
        price?: number;
    };
}
