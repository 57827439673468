import { UberItemPrice } from './item-price';
import { UberOrderModifierGroup } from './uber-modifier-group';

export class UberOrderItem {
    /**
     * Unique identifying string for the item, provided by the store.
     */
    id: string;

    /**
     * The menu title for the item.
     */
    title: string;

    /**
     * Free-form text field reserved for metadata supplied by the store.
     */
    external_data: string;

    /**
     * Quantity of the item.
     */
    quantity: number;

    /**
     * Price information for an item.
     */
    price: UberItemPrice;

    /**
     * Any modifier groups for this item.
     */
    selected_modifier_groups: UberOrderModifierGroup[];

    /**
     * Special requests for this item.
     */
    special_requests: string;

    /**
     * Special instructions for the item
     */
    special_instructions: string;
}
