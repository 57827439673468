export enum OrderPaymentStatus {
    PendingConfirmation = 'pending_confirmation',
    Failed = 'failed',
    Refund = 'refund',
    PendingRefund = 'pending_refund',
    RefundFailed = 'refund_failed',
    Success = 'success',
    Cancelled = 'cancelled',
    OnSite = 'on_site',
}

export class Payment {
    /**
     * ISO 4217 code for the currency of the monetary amount.
     */
    currencyCode?: string;

    /**
     * The cost of the entire order, including taxes and fees.
     * The total is expressed as integer with like 17.30 => 17.30 €
     */
    total: number;

    /**
     * The status of the payment.
     */
    status?: OrderPaymentStatus;

    /**
     * The id of the pipay payment send by pipay when returning the payment information regarding the order.
     */
    pipayId?: string;

    /**
     * The URL to refund the customer
     */
    refundUrl?: string;

    /**
     * The id of the payment at table
     */
    paymentAtTableId?: string;

    /**
     * Extra lines for Resto-badge payments details
     */
    detailsExtraPayment?: any[];

    constructor(props: Payment) {
        Object.assign(this, props);
    }
}
