import { Parameters, ParametersStyles, PublicParameters } from '@aphilia/payments/types';
import axios from 'axios';

/**
 * Get parameters by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @returns
 */
export async function getParametersBySiteId(paymentsServiceUrl: string, siteId: string): Promise<Parameters> {
    try {
        const { data } = await axios.get<Parameters>(`${paymentsServiceUrl}/parameters/${siteId}`);
        return data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while GetParametersBySiteId');
    }
}

/**
 * Returns public payment parameters by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 */
export async function getPublicParametersBySiteId(paymentsServiceUrl: string, siteId: string): Promise<PublicParameters> {
    try {
        const { data } = await axios.get<PublicParameters>(`${paymentsServiceUrl}/parameters/${siteId}/public`);
        return data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while GetPublicParametersBySiteId');
    }
}

/**
 * Get styles parameters by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @returns
 */
export async function getParametersStylesBySiteId(paymentsServiceUrl: string, siteId: string): Promise<ParametersStyles> {
    try {
        const { data } = await axios.get<ParametersStyles>(`${paymentsServiceUrl}/parameters/${siteId}/styles`);
        return data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while getParametersStylesBySiteId');
    }
}

/**
 * Get styles parameters by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @returns
 */
export async function getParametersMinPaymentAmountBySiteId(paymentsServiceUrl: string, siteId: string): Promise<number> {
    try {
        const result = await axios.get<number>(`${paymentsServiceUrl}/parameters/${siteId}/min-payment-amount`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while getParametersStylesBySiteId');
    }
}

/**
 * Get styles parameters by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @returns
 */
export async function getParametersCguAndRgpd(paymentsServiceUrl: string, siteId: string): Promise<{ type: string; file: string }[]> {
    try {
        const result = await axios.get<{ type: string; file: string }[]>(`${paymentsServiceUrl}/parameters/${siteId}/cgu-rgpd`);
        return result.data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while getParametersCguAndRgpd');
    }
}

/**
 * Update parameters by site id
 *
 * @param paymentsServiceUrl
 * @param body
 * @returns
 */
export async function updateParametersBySiteId(paymentsServiceUrl: string, body: Parameters): Promise<Parameters> {
    try {
        const { data } = await axios.put<Parameters>(`${paymentsServiceUrl}/parameters/${body.siteId}`, body);
        return data;
    } catch (e) {
        console.error(e);
        throw e.response?.data?.message ? new Error(e.response?.data?.message) : new Error('An error just occured while UpdateParametersBySiteId');
    }
}

/**
 * Update parameters cover or logo image by site id
 *
 * @param paymentsServiceUrl
 * @param siteId
 * @param image
 * @param target
 */
export const updateParametersImageBySite = async (paymentsServiceUrl: string, siteId: string, image: Blob, target: 'cover' | 'logo'): Promise<Parameters> => {
    try {
        const formData = new FormData();
        formData.append('image', image);

        const { data } = await axios.put<Parameters>(`${paymentsServiceUrl}/parameters/${siteId}/${target}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
};
