import { TimeSlot } from './time-slot';
import mongoose from 'mongoose';
import * as faker from 'faker';

export const fakeTimeSlotData = (): TimeSlot => {
    const startHour = faker.random.number(22);
    const endHour = startHour + faker.random.number(23 - startHour);
    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        start: `${startHour.toString().padStart(2, '0')}:${faker.random.number(59).toString().padStart(2, '0')}`,
        end: `${endHour.toString().padStart(2, '0')}:${faker.random.number(59).toString().padStart(2, '0')}`,
    };
};

export const fakeTimeSlot = (): TimeSlot => new TimeSlot(fakeTimeSlotData());

export const fakeTimeSlotArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): TimeSlot[] => {
    const timeSlot: TimeSlot[] = [];

    for (let i = 0; i < nbMax; i++) {
        timeSlot.push(fakeTimeSlot());
    }

    return timeSlot;
};
