import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { ApCheckbox } from '@aphilia/shared-ui-core-react';
import { createClassString } from '@aphilia/shared/utils';
import { ApColumnData, ApColumnSort, ApHoveredCell } from '../../types';
import { ApTableHeaderCell } from './ApTableHeaderCell';
import { BASE_CELL_WIDTH, CHECKBOX_CELL_KEY, CHECKBOX_CELL_RATIO, END_STICKY_ID } from '../../config';
import { getHeaderCellSlotName } from '../../utils';

interface ApTableHeaderRowProps {
    // Required
    columns: ApColumnData[];

    // Optional
    isSelectable?: boolean;
    isSortBy?: string;
    hasSortOrder?: ApColumnSort;
    allSelected?: boolean;
    allIndeterminate?: boolean;
    isStickyPart?: boolean;
    onSelectAllRows?: (evt: CustomEvent) => void;
    onSortBy?: (columnKey: string) => void;
    onSortOrder?: (columnOrder: ApColumnSort) => void;

    hasDnDContext?: boolean;
    rowRef?: any;
    tableId?: string;
    droppableProps?: any;
    droppablePlaceholder?: any;
    droppableSnapshot?: any;
    hoveredHeaderCell?: ApHoveredCell;
    scrollRef?: any;

    fullWidth?: number;
    hasCheck?: boolean;
}

export function ApTableHeaderRow({
    columns,
    rowRef,
    tableId,
    isSelectable,
    allSelected,
    allIndeterminate,
    isStickyPart,
    isSortBy,
    hasSortOrder,
    fullWidth,
    hasDnDContext,
    droppableProps,
    droppablePlaceholder,
    droppableSnapshot,
    hoveredHeaderCell,
    scrollRef,
    hasCheck,
    onSortBy,
    onSortOrder,
    onSelectAllRows,
}: ApTableHeaderRowProps) {
    const tableHeadRowStyles = {
        width: `${fullWidth}px`,
    };
    const gridWidth = columns.map((col) => {
        return `[${getHeaderCellSlotName(col.dataKey)}] ${col.cellRatio * BASE_CELL_WIDTH}px`;
    });
    if (isSelectable && hasCheck) {
        gridWidth.unshift(`[${getHeaderCellSlotName(CHECKBOX_CELL_KEY)}] ${CHECKBOX_CELL_RATIO * BASE_CELL_WIDTH}px`);
    }
    tableHeadRowStyles['gridTemplateColumns'] = `${gridWidth.join(' ')} [header-cell-placeholder] 1fr`;

    const tableHeadRowClasses = createClassString(['ap-table-header-row', [droppableSnapshot?.isDraggingOver, 'ap-row-hover']]);

    return (
        <div {...droppableProps} id={`${tableId}-header-row`} style={tableHeadRowStyles} className={tableHeadRowClasses} ref={rowRef}>
            {isSelectable && hasCheck && (
                <ApTableHeaderCell
                    className="ap-checkbox-cell"
                    cellKey={CHECKBOX_CELL_KEY}
                    cellRatio={CHECKBOX_CELL_RATIO}
                    isStickyPart={isStickyPart}
                    component={<ApCheckbox onCheck={onSelectAllRows} size="medium" indeterminate={allIndeterminate} checked={allSelected} />}
                />
            )}
            {columns.map((column, columnIndex) => {
                return hasDnDContext ? (
                    <Draggable draggableId={`${tableId}-draggable-hcell-${columnIndex}${isStickyPart ? END_STICKY_ID : ''}`} index={columnIndex}>
                        {(provided, snapshot) => (
                            <ApTableHeaderCell
                                cellRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                draggableSnapshot={snapshot}
                                key={`header-cell-${columnIndex}`}
                                isSortBy={isSortBy}
                                hasSortOrder={hasSortOrder}
                                onSortBy={onSortBy}
                                onSortOrder={onSortOrder}
                                cellData={column}
                                hoveredHeaderCell={hoveredHeaderCell}
                                headerCellIndex={columnIndex}
                                scrollRef={scrollRef}
                                hasDnDContext={hasDnDContext}
                                isStickyPart={isStickyPart}
                            />
                        )}
                    </Draggable>
                ) : (
                    <ApTableHeaderCell
                        key={`header-cell-${columnIndex}`}
                        isSortBy={isSortBy}
                        hasSortOrder={hasSortOrder}
                        onSortBy={onSortBy}
                        onSortOrder={onSortOrder}
                        cellData={column}
                        isStickyPart={isStickyPart}
                    />
                );
            })}
            {hasDnDContext && droppablePlaceholder}
        </div>
    );
}
