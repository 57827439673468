import * as faker from 'faker';
import { JobStatus } from 'bull';
import { QueryJob } from './query-job';

export function fakeQueryJobData(): QueryJob {
    return {
        name: faker.name.findName(),
        status: [faker.random.arrayElement<JobStatus>(['completed', 'waiting', 'active', 'delayed', 'paused', 'failed'])],
    };
}

export function fakeQueryJob(): QueryJob {
    return new QueryJob(fakeQueryJobData());
}

export function fakeQueryJobArray(nbMax: number = faker.random.number({ min: 1, max: 20 })): QueryJob[] {
    const job: QueryJob[] = [];

    for (let i = 0; i < nbMax; i++) {
        job.push(fakeQueryJob());
    }

    return job;
}
