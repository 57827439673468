import React, { useEffect, useMemo } from 'react';
import { ApTable } from '@aphilia/shared/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useOrganizationContext } from '../../stores';

export const ApSiteTable = () => {
    const { t } = useTranslation();
    const { sites, setSites, isLoadingSites, setSiteSelected } = useOrganizationContext();

    useEffect(() => {
        setSites();
    }, []);

    /**
     * Load the sites from the server
     */
    const sitesToDisplay = useMemo(() => {
        return sites?.reduce(
            (acc, it) => ({
                ...acc,
                [it._id]: it,
            }),
            {}
        );
    }, [sites]);

    /**
     * The column to be displayed
     */
    const sitesColumn = useMemo(
        () => [
            {
                label: t('site.table.header.id'),
                colSpan: 0.5,
                dataKey: 'id',
                content: (data) => data._id,
            },
            {
                label: t('site.table.header.name'),
                colSpan: 4,
                dataKey: 'title',
                content: (data) => data.name,
            },
            {
                label: t('site.table.header.elyxCode'),
                colSpan: 1,
                dataKey: 'elyxCode',
                content: (data) => data.elyxCode,
            },
            {
                label: t('site.table.header.createdAt'),
                colSpan: 2,
                dataKey: 'createdAt',
                content: (data) => moment(data.createdAt).format('LLL'),
            },
        ],
        []
    );

    return <ApTable onRowClick={(id) => setSiteSelected(id)} columns={sitesColumn} rows={sitesToDisplay} loading={isLoadingSites} />;
};
