import { CreateTimeSlot } from './create-time-slot';
import * as faker from 'faker';

export const fakeCreateTimeSlotData = (): CreateTimeSlot => {
    const startHour = faker.random.number(22);
    const endHour = startHour + faker.random.number(23 - startHour);
    return {
        label: {
            fr: faker.random.word(),
            en: faker.random.word(),
        },
        start: `${startHour}:${faker.random.number(60)}`,
        end: `${endHour}:${faker.random.number(60)}`,
    };
};

export const fakeCreateTimeSlot = (): CreateTimeSlot => new CreateTimeSlot(fakeCreateTimeSlotData());

export const fakeCreateTimeSlotArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateTimeSlot[] => {
    const timeSlot: CreateTimeSlot[] = [];

    for (let i = 0; i < nbMax; i++) {
        timeSlot.push(fakeCreateTimeSlot());
    }

    return timeSlot;
};
