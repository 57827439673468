export class CreateItem {
    /**
     * Id of the item in the database if needed.
     */
    _id: string;

    /**
     * The id of the article on the pos system
     */
    posNumber: number;

    /**
     * Price of the article. This price is the unit price for one article only.
     * Therefore the total price of the article is the unitPrice * quantity
     */
    unitPrice: number;

    /**
     * Special instructions made by the consumer if needed.
     */
    specialInstructions?: string;

    /**
     * Title of the item
     */
    title: string;

    /**
     * Quantity asked for this item
     */
    quantity: number;

    /**
     * All the items that are selected for an item
     */
    selectedItems?: CreateItem[];

    constructor(props: CreateItem) {
        Object.assign(this, props);

        this.selectedItems = this.selectedItems?.map((selectedItem) => new CreateItem(selectedItem));
    }
}
