import React from 'react';

import './ap-table-toolbar.scss';

interface ApTableToolBarProps {
    // Optional
    selectedText?: (rowsCount: number) => string;
    selectedActions?: React.ReactNode;
    selectedRowsCount?: number;
}

export function ApTableToolBar({ selectedActions, selectedText, selectedRowsCount }: ApTableToolBarProps) {
    return (
        <div className="ap-table-toolbar elevation-8dp">
            {!!selectedText && <span className="ap-table-toolbar-text">{selectedText(selectedRowsCount)}</span>}
            {!!selectedActions && <div className="ap-table-toolbar-actions">{selectedActions}</div>}
        </div>
    );
}
