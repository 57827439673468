export class BaseQueryModelPaginate {
    /**
     * The number of docs return in one query
     */
    limit? = 10;

    /**
     * The actual page of the current query
     */
    page? = 1;

    constructor(props: BaseQueryModelPaginate) {
        Object.assign(this, props);
    }
}
