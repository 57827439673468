import { UpdateDeliveryParameters } from './update-delivery';
import * as faker from 'faker';
import { DeliverySiteStatus } from '../delivery-parameters/delivery';
import { fakeUpdateAddress } from '@aphilia/shared/utils';
import moment from 'moment';
import { fakeUpdateTerminal } from '../terminal';

export const fakeUpdateDeliveryParametersData = (): UpdateDeliveryParameters => ({
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
    hasPilink: faker.random.boolean(),
    autoAccept: faker.random.boolean(),
    timeForPreparation: faker.random.number(40),
    lastDeployment: moment(),
    address: fakeUpdateAddress(),
    interval: faker.random.number(40),
    orderPlanedMaxTime: faker.random.number(4),
    terminal: fakeUpdateTerminal(),
});

export const fakeUpdateDeliveryParameters = (): UpdateDeliveryParameters => new UpdateDeliveryParameters(fakeUpdateDeliveryParametersData());

export const fakeUpdateDeliveryParametersArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateDeliveryParameters[] => {
    const delivery: UpdateDeliveryParameters[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeUpdateDeliveryParameters());
    }

    return delivery;
};
