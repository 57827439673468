export class UpdateTimeSlot {
    /**
     * Label of the time slot
     */
    label: Record<string, string>;

    /**
     * Start time of the slot
     * @format HH:mm
     */
    start: string;

    /**
     * End time of the slot
     * @format HH:mm
     */
    end: string;

    constructor(props: UpdateTimeSlot) {
        Object.assign(this, props);
    }
}
