import React, { ReactNode, useCallback, useState, useEffect } from 'react';
import { ApButton } from '../ApButton';
import { Typography, Grid, Dialog, makeStyles } from '@material-ui/core';
import style from './ApModal.style';
import SvgClose from '../../icons/SvgClose';
import { ApInlineButton } from '../ApButton/ApInlineButton';
import { useConfirm } from './ApModal.hooks';

export type modalSizeType = false | 'xs' | 'md' | 'lg' | 'sm' | 'xl';

interface Props {
    title: string;
    titleSuffix?: ReactNode;
    subHeader?: ReactNode;
    onConfirm: () => void;
    onClose: () => void;
    confirmText: ReactNode;
    children: ReactNode;
    maxWidth?: modalSizeType;
    open: boolean;
    cancelText?: string;
    footer?: ReactNode;
    disableConfirm?: boolean;
}

const useStyles = makeStyles(style);

export const ApModal = ({ open, children, title, titleSuffix, subHeader, onClose, onConfirm, cancelText, confirmText, footer, maxWidth = 'xs', disableConfirm = false }: Props) => {
    const classes = useStyles();

    const { canConfirm, handleConfirm } = useConfirm(open, onConfirm, disableConfirm);

    return (
        <Dialog open={open} maxWidth={maxWidth} onClose={onClose} className={classes.container}>
            <header className={classes.header}>
                <Grid item xs={12} className="title">
                    <Typography variant="h5">{title}</Typography>
                    {titleSuffix}
                    <SvgClose className="close" onClick={onClose} />
                </Grid>
                {subHeader && (
                    <Grid item xs={12} className="sub-header">
                        {subHeader}
                    </Grid>
                )}
            </header>
            <Grid container className={classes.body}>
                {children}
            </Grid>
            <Grid container component="footer" className={classes.footer}>
                <Grid item xs={6}>
                    {footer}
                </Grid>
                <Grid item container spacing={2} xs={6} className="actions">
                    <ApInlineButton>
                        {cancelText && (
                            <ApButton clickCallback={onConfirm} disabled={false} color="secondary" variant="text">
                                {cancelText}
                            </ApButton>
                        )}
                        <ApButton clickCallback={handleConfirm} disabled={disableConfirm || !canConfirm}>
                            {confirmText}
                        </ApButton>
                    </ApInlineButton>
                </Grid>
            </Grid>
        </Dialog>
    );
};
