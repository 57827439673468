import { ApItemProps } from '@aphilia/shared-ui-core';

type ApValueType = 'string' | 'number' | 'date' | 'boolean' | 'select'; // ??? boolean
type ApCellType = 'icon-or-label' | 'tag' | 'toggle' | 'select' | 'button';

interface ApColumnData {
    // Required
    dataKey: string;
    valueType: ApValueType;

    // Optional
    label?: any;
    icon?: string;
    rightIcon?: string;
    isSortable?: boolean;
    isLock?: boolean;
    isLockable?: boolean;
    actions?: Record<string, (item: any) => void>;
    cellRatio?: number;
    isSticky?: boolean;
    cellType?: ApCellType;

    dndRowTitle?: boolean;

    isTruncatable?: boolean;
}

interface ApRowData extends Record<string, string | ApCellData> {
    id: string;
}

interface ApCellData {
    // Required
    value: any;

    // Optional
    icon?: string;
    rightIcon?: string;
    isSortable?: boolean;
    isSticky?: boolean;
    options?: ApItemProps[];
    content?: (item?: object) => React.ReactNode;
}

interface ApCellPosition {
    row: string;
    column: string;
    valueType: ApValueType;
}

interface ApEditedCellData {
    row: string;
    column: string;
    value: any;
    valueType: ApValueType;
    cellType?: ApCellType;
}

enum ApColumnSort {
    NONE = 'NONE',
    ASC = 'ASC',
    DESC = 'DESC',
}

type ApColumnFilterType = 'string' | 'number';
interface ApColumnFilter {
    columnKey?: string;
    operator?: string;
    value?: any;
    valueType?: ApColumnFilterType;
}

interface ApHoveredCell {
    cellIndex: number;
    isSticky: boolean;
}

interface ApOperatorConfigItem {
    labelKey?: string;
    displayKey?: string;
    tradId: string;
    function: Function;
}

type ApOperatorConfig = Record<string, Record<string, ApOperatorConfigItem>>;

type ApOperatorTradItem = Pick<ApOperatorConfigItem, 'labelKey' | 'displayKey'>;

type ApOperatorTradConfig = Record<string, ApOperatorTradItem>;

type ApTableTrads = Record<string, string>;

type ApTableContext = Record<string, any>;

export {
    ApValueType,
    ApCellType,
    ApColumnData,
    ApCellData,
    ApRowData,
    ApColumnSort,
    ApCellPosition,
    ApEditedCellData,
    ApColumnFilter,
    ApColumnFilterType,
    ApHoveredCell,
    ApOperatorConfig,
    ApOperatorConfigItem,
    ApOperatorTradItem,
    ApOperatorTradConfig,
    ApTableTrads,
    ApTableContext,
};
