export enum WebArticleModifierType {
    ArticleModifier = 'ARTICLE_MODIFIER',
    ArticleMenuModifier = 'ARTICLE_MENU_MODIFIER',
}

export class WebArticleModifier {
    /**
     * Id of the modifier in the database
     */
    readonly _id: string;

    /**
     * Id of the modifier in the pos system.
     */
    readonly posNumber: number;

    /**
     * Name of the article in the current language
     */
    title: Record<string, string>;

    /**
     * List of all the articles' id in the web modifier
     */
    articles?: string[];

    /**
     * The minimum of articles that must be selected inside the modifier
     */
    quantityMin: number;

    /**
     * The maximum number of articles that must be selected inside the modifier
     */
    quantityMax: number;

    constructor(props: WebArticleModifier) {
        Object.assign(this, props);
    }
}
