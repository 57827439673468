import { UpdateWebArticle } from './update-web-article';
import * as faker from 'faker';
import { WebArticleUnavailabilityPeriod } from '..';

export const fakeUpdateWebArticleData = (): UpdateWebArticle => ({
    articleId: faker.random.number().toString(),
    siteId: faker.random.number().toString(),
    isAvailableForSale: faker.random.boolean(),
    periodOfUnavailability: faker.random.objectElement<WebArticleUnavailabilityPeriod>(WebArticleUnavailabilityPeriod),
});

export const fakeUpdateWebArticle = (): UpdateWebArticle => new UpdateWebArticle(fakeUpdateWebArticleData());

export const fakeUpdateWebArticleArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticle[] => {
    const webArticle: UpdateWebArticle[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticle.push(fakeUpdateWebArticle());
    }

    return webArticle;
};
