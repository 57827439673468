export class UberTaxInfo {
    /**
     * The tax rate for the item, included in the price. - Must be between 0 and 100 (inclusive).
     * This tax rate only applies to the first-level items within the order.
     */
    tax_rate?: number;

    /**
     * Value-added tax rate for the item, additional to the price. - Must be between 0 and 100 (inclusive).
     */
    vat_rate_percentage?: number;

    constructor(props: Partial<UberTaxInfo>) {
        Object.assign(this, props);
    }
}
