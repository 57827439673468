import React, { ReactNode } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';
import style from '../ApTable.style';

interface Props {
    cellData?: ReactNode;
    rowHeight: number;
    sticky?: boolean;
    align?: 'left' | 'center' | 'right';
    rowIndex: number;
    selected?: boolean;
    dataKey?: string;
    isRowClickable?: boolean;
}

const useStyles = makeStyles(style);

function createTitleText(data: any): string {
    if (typeof data === 'string') {
        return data;
    }
    if (typeof data === 'number') {
        return data.toString();
    }
    return null;
}

export const ApTableCell = ({ rowIndex, sticky, cellData, dataKey, rowHeight, isRowClickable, align = 'left', selected = false }: Props) => {
    const classes = useStyles();
    const cellTitle = createTitleText(cellData);

    return (
        <TableCell
            component="div"
            className={`ap-table-cell ${classes.tableCell} ${classes.flexContainer} ${sticky && classes.sticky} ${(rowIndex % 2 && classes.greyTableCell) || ''} ${
                selected && 'selected'
            }`}
            variant="body"
            style={{
                height: rowHeight,
            }}
            align={align}
            data-key={dataKey}
        >
            {cellTitle && (
                <Tooltip title={cellTitle} placement="bottom-start">
                    <span data-key={dataKey}>{cellData}</span>
                </Tooltip>
            )}
            {!cellTitle && <span data-key={dataKey}>{cellData}</span>}
        </TableCell>
    );
};
