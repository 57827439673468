import { PublicDeliveryParameters } from './public-delivery-parameters';
import * as faker from 'faker';
import { fakeAddress, fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { DeliverySiteStatus } from '../delivery-parameters/delivery';

export const fakePublicDeliveryParametersData = (): PublicDeliveryParameters => ({
    enabled: faker.random.boolean(),
    siteId: faker.random.uuid(),
    codeSitePic: faker.random.uuid(),
    regroupmentId: faker.random.uuid(),
    brandId: faker.random.uuid(),
    address: fakeAddress(),
    status: faker.random.objectElement<DeliverySiteStatus>(DeliverySiteStatus),
    phoneNumber: faker.phone.phoneNumber(),
    name: faker.company.companyName(),
    timePeriods: fakeWeekdayTimePeriodsArray(7),
    timeForPreparation: faker.random.number(40),
    orderPlanedMaxTime: faker.random.number(30),
    interval: faker.random.number(200),
    orderingPeriods: fakeWeekdayTimePeriodsArray(7),
    style: { primaryColor: '#69f2b7' },
    logoUrl: faker.image.imageUrl(),
    loaderUrl: faker.image.imageUrl(),
    mobileCoverImageUrl: faker.image.imageUrl(),
    desktopCoverImageUrl: faker.image.imageUrl(),
    redirectionUrl: faker.internet.url(),
    cguUrl: faker.internet.url(),
    gdprUrl: faker.internet.url(),
    isClickAndCollectEnabled: faker.random.boolean(),
    isDeliveryEnabled: faker.random.boolean(),
    consumables: [
        {
            label: faker.random.word(),
            // posNumber: 123,
            maxQuantity: faker.random.number({ min: 2, max: 10 }),
        },
        {
            label: faker.random.word(),
            // posNumber: 234,
            maxQuantity: faker.random.number(5),
        },
        {
            label: faker.random.word(),
            // posNumber: 456,
            maxQuantity: 1,
        },
    ],
    showAlcoholPrevention: true,
    prepayment: 'MANDATORY',
});

export const fakePublicDeliveryParameters = (): PublicDeliveryParameters => new PublicDeliveryParameters(fakePublicDeliveryParametersData());

export const fakePublicDeliveryParametersArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): PublicDeliveryParameters[] => {
    const delivery: PublicDeliveryParameters[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakePublicDeliveryParameters());
    }

    return delivery;
};
