import { Link, useLocation } from 'react-router-dom';
import { ApNavigationDrawer, ApNavigationDrawerLink } from '@aphilia/shared-ui-core-react';
import React from 'react';

export const NavigationDrawer = () => {
    const location = useLocation();

    return (
        <ApNavigationDrawer>
            <ul>
                <Link to={`/webcommande`}>
                    <ApNavigationDrawerLink linkTitle="Web commande" icon="settings" isActive={location.pathname.indexOf(`/webcommande`) !== -1} />
                </Link>
                <Link to={`/isphora`}>
                    <ApNavigationDrawerLink linkTitle="Isphora" icon="settings" isActive={location.pathname.indexOf(`/isphora`) !== -1} />
                </Link>
            </ul>
            <ul slot="footer">
                <Link to={`/logout`}>
                    <ApNavigationDrawerLink linkTitle={'Déconnexion'} icon="ap-disconnect" />
                </Link>
            </ul>
        </ApNavigationDrawer>
    );
};
