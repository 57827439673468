import { UpdateTerminal } from './update-terminal';
import * as faker from 'faker';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { fakeTerminalMediaArray } from '../terminal-media/terminal.data';

export const fakeUpdateTerminalData = (): UpdateTerminal => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    videos: fakeTerminalMediaArray(5),
    images: fakeTerminalMediaArray(5),
});

export const fakeUpdateTerminal = (): UpdateTerminal => new UpdateTerminal(fakeUpdateTerminalData());

export const fakeUpdateTerminalArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateTerminal[] => {
    const Terminal: UpdateTerminal[] = [];

    for (let i = 0; i < nbMax; i++) {
        Terminal.push(fakeUpdateTerminal());
    }

    return Terminal;
};
