import React, { useRef } from 'react';

import { ApTableHeader, ApTableBody, ApTableFooter, ApTableToolBar } from './components';
import { useColumns, useColumnSort, useCellEdition, usePagination, useSelectedRows, useTableHoverRow, useTableClick, useTableContext } from './hooks';
import { ApSpinner } from '@aphilia/shared-ui-core-react';
import { ApTableTopBar } from './components/ApTableTopBar';
import { ApColumnData, ApColumnFilter, ApColumnSort, ApHoveredCell, ApOperatorTradConfig, ApRowData, ApTableTrads } from './types';
import { TableContext } from './config';

import './ap-table.scss';

interface ApTableProps {
    // Required
    columns: ApColumnData[];
    rows: ApRowData[];
    tableId: string;

    // Optional
    isLoading?: boolean;

    disabledRows?: string[];
    tableDisplayHeight?: string;
    noDataMessage?: string;

    isSearchable?: boolean;
    searchPlaceholder?: string;
    searchValue?: string;
    onSearch?: (search: string) => void;
    minSearchLength?: number;

    paginationText?: string;
    currentPage?: number;
    rowsPerPage?: number;
    itemsCount?: number;
    pagesCount?: number; // Temporary -> To be removed
    onCurrentPageChange?: (page: number) => void;
    onRowsPerPageChange?: (rowsCount: number) => void;

    isSortBy?: string;
    hasSortOrder?: ApColumnSort;
    onSortBy?: (column: string) => void;
    onSortOrder?: (sort: ApColumnSort) => void;

    selectedRows?: string[];
    isSelectable?: boolean;
    selectedText?: (rowsCount: number) => string;
    selectedActions?: React.ReactNode;
    onSelectRow?: (key: string, check: boolean) => void;
    onSelectAllRows?: (evt: CustomEvent) => void;

    onCellChange?: (rowId: string, key: string, value: any) => void;
    onCellSave?: () => void;
    cellsAreEditable?: boolean;

    canFilter?: boolean;
    filterColumnText?: string;
    filterOperatorText?: string;
    filterValueText?: string;
    onDataFiltering?: (filters: ApColumnFilter[]) => void;

    hasDnDContext?: boolean;
    rowsType?: string;
    hoveredHeaderCell?: ApHoveredCell;
    draggingRowIndex?: number;

    onEnableAllDnDSensors?: (allDnDSensors: boolean) => void;

    filtersTrads?: ApOperatorTradConfig;
    tradFunction?: (key: string, config?: object) => string;
    tableTrads?: ApTableTrads;

    columnsConfig?: any;
}

// TODO : Loader !!!

export function ApTableN({
    columns,
    rows,
    tableId,
    disabledRows,
    paginationText,
    noDataMessage,
    isLoading,

    selectedRows,
    isSelectable,
    selectedText,
    selectedActions,
    onSelectRow,
    onSelectAllRows,

    // Search
    isSearchable,
    searchPlaceholder,
    searchValue,
    onSearch,
    minSearchLength,

    // Sort
    isSortBy,
    hasSortOrder,
    onSortBy,
    onSortOrder,

    // Pagination
    currentPage,
    rowsPerPage,
    itemsCount,

    pagesCount, // To be removed

    onCurrentPageChange,
    onRowsPerPageChange,

    // DND
    hasDnDContext,
    rowsType,
    hoveredHeaderCell,
    draggingRowIndex,
    onEnableAllDnDSensors,

    // Cell edition
    onCellChange,
    onCellSave,
    cellsAreEditable,

    // Data filtering
    canFilter,
    filterColumnText,
    filterOperatorText,
    filterValueText,
    filtersTrads,
    tradFunction,
    tableTrads,
    onDataFiltering,

    // Columns configuration
    columnsConfig,

    tableDisplayHeight = '25rem',
}: ApTableProps) {
    const tableContentRef = useRef(null);

    const stickyHeaderRef = useRef(null);
    const nonStickyHeaderRef = useRef(null);

    const stickyBodyRef = useRef(null);
    const nonStickyBodyRef = useRef(null);

    const { stickyColumns, nonStickyColumns, stickyWidth } = useColumns(columns, isSelectable);

    const { handleSortBy, handleSortOrder } = useColumnSort(onSortBy, onSortOrder);
    const { paginationOptions, paginateRows, handleRowsPerPage, handleCurrentPage } = usePagination(
        rows,
        currentPage,
        rowsPerPage,
        onCurrentPageChange,
        onRowsPerPageChange,
        searchValue
    );

    const { hoveredRow, handleTableHoverRow } = useTableHoverRow();

    const { allSelected, allIndeterminate, handleSelectAllRows, handleSelectRow } = useSelectedRows(selectedRows, rows, disabledRows, onSelectRow, onSelectAllRows);
    const { editableCell, handleCellDoubleClick, handleClick, handleCellEdition, handleCellKey, handleSelectClose } = useCellEdition(
        paginateRows,
        columns,
        cellsAreEditable,
        onCellChange,
        onCellSave,
        onEnableAllDnDSensors
    );

    const { filtersEdition, handleTableClick, handleFiltersEdition } = useTableClick(handleClick, cellsAreEditable);
    const { tableContextData } = useTableContext(tableId, columns, columnsConfig, tableTrads, tradFunction, minSearchLength);

    const contentStyles = {
        height: tableDisplayHeight,
        gridTemplateColumns: `${stickyWidth}px 1fr`,
    };

    if (!itemsCount) {
        itemsCount = pagesCount * rowsPerPage;
    }

    return (
        <TableContext.Provider value={tableContextData}>
            <div className="ap-table-block" onClick={handleTableClick}>
                {isLoading && (
                    <div className="ap-table-loader">
                        <ApSpinner size="x-large" />
                    </div>
                )}

                {(canFilter || isSearchable) && (
                    <ApTableTopBar
                        columns={columns}
                        isSearchable={isSearchable}
                        searchPlaceholder={searchPlaceholder}
                        onSearch={onSearch}
                        searchValue={searchValue}
                        filterColumnText={filterColumnText}
                        filterOperatorText={filterOperatorText}
                        filterValueText={filterValueText}
                        onDataFiltering={onDataFiltering}
                        filtersEdition={filtersEdition}
                        onFiltersEdition={handleFiltersEdition}
                        filtersTrads={filtersTrads}
                        tradFunction={tradFunction}
                        canFilter={canFilter}
                    />
                )}
                <div className="ap-table-content" onKeyDown={handleCellKey} style={contentStyles} ref={tableContentRef}>
                    {stickyColumns?.length && (
                        <ApTableHeader
                            ref={stickyHeaderRef}
                            isSelectable={isSelectable}
                            isSortBy={isSortBy}
                            hasSortOrder={hasSortOrder}
                            onSortBy={handleSortBy}
                            onSortOrder={handleSortOrder}
                            columns={stickyColumns}
                            isSticky={true}
                            onSelectAllRows={handleSelectAllRows}
                            tableId={tableId}
                            allSelected={allSelected}
                            allIndeterminate={allIndeterminate}
                            hasDnDContext={hasDnDContext}
                            hoveredHeaderCell={hoveredHeaderCell}
                            scrollRef={tableContentRef}
                            hasCheck={true}
                        />
                    )}
                    {nonStickyColumns?.length && (
                        <ApTableHeader
                            ref={nonStickyHeaderRef}
                            isSelectable={isSelectable}
                            isSortBy={isSortBy}
                            hasSortOrder={hasSortOrder}
                            onSortBy={handleSortBy}
                            onSortOrder={handleSortOrder}
                            columns={nonStickyColumns}
                            isSticky={false}
                            onSelectAllRows={handleSelectAllRows}
                            tableId={tableId}
                            allSelected={allSelected}
                            allIndeterminate={allIndeterminate}
                            hasDnDContext={hasDnDContext}
                            hoveredHeaderCell={hoveredHeaderCell}
                            scrollRef={tableContentRef}
                            hasCheck={!stickyColumns?.length}
                        />
                    )}
                    {stickyColumns?.length && (
                        <ApTableBody
                            ref={stickyBodyRef}
                            isSelectable={isSelectable}
                            selectedRows={selectedRows}
                            disabledRows={disabledRows}
                            columns={stickyColumns}
                            isSticky={true}
                            rows={paginateRows}
                            onSelectRow={handleSelectRow}
                            hoveredRow={hoveredRow}
                            onHoverRow={handleTableHoverRow}
                            hasDnDContext={hasDnDContext}
                            rowsType={rowsType}
                            tableId={tableId}
                            noDataMessage={noDataMessage}
                            onCellDoubleClick={handleCellDoubleClick}
                            onCellEdition={handleCellEdition}
                            editableCell={editableCell}
                            hasCheck={true}
                            draggingRowIndex={draggingRowIndex}
                            onSelectClose={handleSelectClose}
                            cellsAreEditable={cellsAreEditable}
                        />
                    )}
                    {nonStickyColumns?.length && (
                        <ApTableBody
                            ref={nonStickyBodyRef}
                            isSelectable={isSelectable}
                            selectedRows={selectedRows}
                            disabledRows={disabledRows}
                            columns={nonStickyColumns}
                            isSticky={false}
                            rows={paginateRows}
                            onSelectRow={handleSelectRow}
                            hoveredRow={hoveredRow}
                            onHoverRow={handleTableHoverRow}
                            hasDnDContext={hasDnDContext}
                            rowsType={rowsType}
                            tableId={tableId}
                            noDataMessage={noDataMessage}
                            onCellDoubleClick={handleCellDoubleClick}
                            onCellEdition={handleCellEdition}
                            editableCell={editableCell}
                            hasCheck={!stickyColumns?.length}
                            draggingRowIndex={draggingRowIndex}
                            onSelectClose={handleSelectClose}
                            cellsAreEditable={cellsAreEditable}
                        />
                    )}
                </div>
                <ApTableFooter
                    paginationText={paginationText}
                    paginationOptions={paginationOptions}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    itemsCount={itemsCount}
                    onRowsPerPageChange={handleRowsPerPage}
                    onCurrentPageChange={handleCurrentPage}
                />
                {!!selectedRows?.length && <ApTableToolBar selectedRowsCount={selectedRows.length} selectedText={selectedText} selectedActions={selectedActions} />}
            </div>
        </TableContext.Provider>
    );
}
