import * as faker from 'faker';
import { Promotion } from './promotion';
import { fakePromotionItemArray } from './promotion-item/promotion-item.data';

export const fakePromotionData = (): Promotion => ({
    value: faker.random.number(10),
    percentage: faker.random.number(10),
    items: fakePromotionItemArray(),
});

export const fakePromotion = (): Promotion => new Promotion(fakePromotionData());

export const fakePromotionArray = (nbMax: number = faker.random.number({ min: 1, max: 10 })): Promotion[] => {
    return new Array(nbMax).fill(fakePromotion());
};
