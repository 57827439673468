import React, { createContext, Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js/dist/typings/global';
import { ApSpinner } from '@aphilia/shared-ui-core-react';
import { customHistory } from '@aphilia/shared/react';
import { LogoutOptions } from '@auth0/auth0-spa-js/src/global';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import * as Actions from './constants';

function createInitialState() {
    return {
        isAuthenticated: false,
        loading: true,
    };
}

export interface AuthStateObject {
    isAuthenticated?: boolean;
    loading?: boolean;
    popupOpen?: boolean;
    getIdTokenClaims?: () => void;
    loginWithRedirect?: () => void;
    getTokenSilently?: () => void;
    getTokenWithPopup?: () => void;
    logout?: (options: LogoutOptions) => void;
    isLoggingOut?: boolean;
}

const store = createContext<AuthStateObject>({});
const { Provider } = store;

const initialState = createInitialState();

interface Props {
    children: React.ReactNode;
    auth0Options: Auth0ClientOptions;
    initial?: AuthStateObject;
    audiences?: string[];
}

const AuthProvider = ({ children, initial, audiences = [], auth0Options }: Props) => {
    const [state, dispatch] = useReducer<Reducer<AuthStateObject, any>>(
        (currentState: any, action: { type: keyof typeof Actions; payload?: any }) => {
            switch (action.type) {
                case Actions.UPDATE_STATE:
                    return Object.assign({}, currentState, action.payload);
                default:
                    throw new Error();
            }
        },
        Object.assign(initialState, {
            ...initialState,
            ...initial,
        })
    );

    /**
     * Init auth0.
     *
     * It will first load the token for the organizations service.
     * There is no audience needed as auth0 will login with the default audience set in the parameters.
     * If no default audience is set in the parameters of the auth0 page, please set it.
     */
    const initAuth0 = async () => {
        // Create the auth0 client for the organizations token
        const auth0FromHook = await createAuth0Client({
            ...auth0Options,
            redirect_uri: auth0Options.redirect_uri || window.location.origin,
        });

        // If there is a code => this is the redirect of the login page so we need to intercept the code and call the redirect callback function
        if (window.location.search.includes('code=') && window.location.search.includes('state=') && !window.location.search.includes('authO=')) {
            const { appState } = await auth0FromHook.handleRedirectCallback();
            customHistory.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
        }

        // Get the status of the authentication from auth0
        const isCurrentlyAuthenticated = await auth0FromHook.isAuthenticated();

        // If we are logged in then we can proceed
        // Otherwise we need to login
        if (isCurrentlyAuthenticated) {
            // Get the token for the organizations service
            const token = await auth0FromHook.getTokenSilently();

            // Load the sites and the user with this token, then dispatch the information
            dispatch({
                type: Actions.UPDATE_STATE,
                payload: {
                    getIdTokenClaims: (...p) => auth0FromHook.getIdTokenClaims(...p),
                    loginWithRedirect: (...p) => auth0FromHook.loginWithRedirect(...p),
                    getTokenSilently: (...p) => auth0FromHook.getTokenSilently(...p),
                    getTokenWithPopup: (...p) => auth0FromHook.getTokenWithPopup(...p),
                    logout: (options: LogoutOptions = {}) => auth0FromHook.logout(options),
                },
            });

            // Set the token in the window variable to be accessed anywhere from the app
            await setWindowToken(auth0FromHook);

            // If there is some audiences, load all the token for those audiences
            for (const audience of audiences) {
                await initAuth0Audience(audience);
            }

            dispatch({
                type: Actions.UPDATE_STATE,
                payload: {
                    loading: false,
                },
            });
        } else {
            if (window.location.href.includes('apiId')) {
                await auth0FromHook.loginWithRedirect({
                    redirect_uri: `${window.location.href}${window.location.href.includes('warningEmail') ? '&warningEmail=true' : ''}`,
                });
            } else {
                await auth0FromHook.loginWithRedirect();
            }
        }
    };

    /**
     * Set the auth0 client and data for the audience provided in the props if there is one
     */
    const initAuth0Audience = async (audience: string) => {
        // Create an auth0 client for all audiences
        const auth0FromHook = await createAuth0Client({
            ...auth0Options,
            redirect_uri: auth0Options.redirect_uri || window.location.origin,
            audience: audience,
        });

        // Set the audience token in the window variable to be accessed from anywhere in the app.
        await setWindowToken(auth0FromHook, audience);
    };

    /**
     * Set the token of an audience in the window object
     *
     * @param auth0FromHook
     * @param audience
     */
    const setWindowToken = useCallback(
        async (auth0FromHook: Auth0Client, audience?: string) => {
            try {
                if (await auth0FromHook.isAuthenticated()) {
                    // Set the variables in the process.env variable to be accessible in the getTokenSilently function
                    const token = await auth0FromHook.getTokenSilently();
                    (window as any).authToken = {
                        ...((window as any).authToken || {}),
                        [audience || 'default']: token,
                    };
                } else {
                    await auth0FromHook.loginWithRedirect();
                }
            } catch (error) {
                console.error(error);
            }
        },
        [(window as any).authToken, state]
    );

    useEffect(() => {
        initAuth0();
    }, []);

    return <Provider value={state}>{state.loading ? <ApSpinner /> : children}</Provider>;
};

// Generate a hook to use the organization context
const useAuthContext = () => useContext(store);

export { useAuthContext, AuthProvider };
