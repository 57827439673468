import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    switch: {
        width: '64px',
        height: '32px',
        padding: 0,
        margin: 0,
        borderRadius: '32px',
        '& .MuiButtonBase-root': {
            padding: 0,
            color: palette.common.white,
            width: '32px',
            height: '32px',
            '&.Mui-checked': {
                transform: 'translateX(32px)',
                '& .MuiIconButton-label': {
                    color: palette.common.white,
                },
                '& + .MuiSwitch-track': {
                    backgroundColor: palette.success.main,
                    opacity: 1,
                },
            },
            '& .MuiSwitch-thumb': {
                width: '18px',
                height: '18px',
                boxShadow: 'none',
            },
        },
    },
    label: {
        color: palette.grey[500],
        lineHeight: '32px',
    },
});
