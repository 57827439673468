import { UpdateDeliveroo } from './update-deliveroo';
import * as faker from 'faker';

export const fakeUpdateDeliverooData = (): UpdateDeliveroo => ({
    enabled: faker.random.boolean(),
    locationId: faker.random.uuid(),
    brandId: faker.random.uuid(),
    coverImage: faker.random.image(),
});

export const fakeUpdateDeliveroo = (): UpdateDeliveroo => new UpdateDeliveroo(fakeUpdateDeliverooData());

export const fakeUpdateDeliverooArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateDeliveroo[] => {
    const deliveroo: UpdateDeliveroo[] = [];

    for (let i = 0; i < nbMax; i++) {
        deliveroo.push(fakeUpdateDeliveroo());
    }

    return deliveroo;
};
