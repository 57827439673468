import { UberClassifications } from '../classifications/classifications';

export class UberDishInfo {
    /**
     * Classifications of an item for supplemental information
     */
    classifications?: UberClassifications;

    constructor(props: Partial<UberDishInfo>) {
        if (props.classifications) {
            this.classifications = new UberClassifications(props.classifications);
        }
    }
}
