import { UberTimePeriod } from '../time-period/time-period';
import { UberDayOfWeek } from '../../enum';

export class UberServiceAvailability {
    /**
     * The day of the week on which these hours will be applied.
     */
    day_of_week: UberDayOfWeek;

    /**
     * The continuous time spans during which the menu is available.
     */
    time_periods: UberTimePeriod[];

    constructor(serviceAvailability: Partial<UberServiceAvailability>) {
        Object.assign(this, serviceAvailability);
        this.time_periods.forEach((period) => new UberTimePeriod(period));
    }
}
