import { ApCard, ApCol, ApRow } from '@aphilia/shared-ui-core-react';
import { DeliveryParametersAddress, DeliveryParametersPlatformConfiguration } from '@aphilia/deliveries/feature-parameters';
import React from 'react';

export const DeliveryParametersPage = () => {
    return (
        <ApRow>
            <ApCol size={6}>
                <ApCard headerTitle="Adresse" subtitle="Informe le consommateur sur la localisation du site">
                    <DeliveryParametersAddress />
                </ApCard>
            </ApCol>
            <ApCol>
                <ApCard headerTitle="Gestion des plateformes">
                    <DeliveryParametersPlatformConfiguration />
                </ApCard>
            </ApCol>
        </ApRow>
    );
};
