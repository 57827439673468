import { UpdateCustomer } from './update-customer';
import * as faker from 'faker';

export const fakeUpdateCustomerData = (): UpdateCustomer => ({
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    phoneNumber: faker.phone.phoneNumber(),
    email: faker.internet.email(),
    address: faker.address.streetAddress(true),
    addressComment: faker.random.words(),
    jobId: faker.random.number(),
    trackingUrl: faker.internet.url(),
});

export const fakeUpdateCustomer = (): UpdateCustomer => new UpdateCustomer(fakeUpdateCustomerData());

export const fakeUpdateCustomerArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateCustomer[] => {
    const customer: UpdateCustomer[] = [];

    for (let i = 0; i < nbMax; i++) {
        customer.push(fakeUpdateCustomer());
    }

    return customer;
};
