export class UpdateDeliveroo {
    /**
     * Example property of the entity
     */
    enabled?: boolean;

    /**
     * The site id in deliveroo
     */
    locationId?: string;

    /**
     * The brand id in deliveroo
     */
    brandId?: string;

    /**
     * The site cover image
     */
    coverImage?: string;

    constructor(props: UpdateDeliveroo) {
        Object.assign(this, props);
    }
}
