import { UpdateUber } from './update-uber';
import * as faker from 'faker';

export const fakeUpdateUberData = (): UpdateUber => ({
    enabled: faker.random.boolean(),
    locationId: faker.random.uuid(),
    posIntegrationEnabled: faker.random.boolean(),
});

export const fakeUpdateUber = (): UpdateUber => new UpdateUber(fakeUpdateUberData());

export const fakeUpdateUberArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateUber[] => {
    const uber: UpdateUber[] = [];

    for (let i = 0; i < nbMax; i++) {
        uber.push(fakeUpdateUber());
    }

    return uber;
};
