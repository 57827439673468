import { Customer, Item, Payment } from '../order';
import moment from 'moment';

export class TrackingStuartJob {
    /**
     * Indicates the job Id from Stuart
     */
    id: string;

    /**
     * Indicates the assignment code of the order from Stuart
     */
    assignmentCode: string;

    /**
     * Indicates the estimated delivery time by Stuart
     */
    duration?: number;

    /**
     * Indicates the url tracking for iframe representation
     */
    trackingUrl?: string;

    constructor(props: TrackingStuartJob) {
        Object.assign(this, props);
    }
}
