import { DeliverySiteStatus } from '@aphilia/deliveries/data';
import { PriceType } from '@aphilia/shared/utils';
import { WeekdayTimePeriods } from '@aphilia/shared/utils';

export class AtTable {
    /**
     * Indicates if the at table is enabled or not
     */
    enabled: boolean;

    /**
     * The price level use for the ap table platform
     */
    priceLevel: PriceType;

    /**
     * All the time periods for the at table
     */
    timePeriods: WeekdayTimePeriods[];

    /**
     * Indicates the prepayment options for the platform
     */
    prepayment?: 'MANDATORY' | 'OPTIONAL' | 'NONE';

    /**
     * Minimum amount authorized for the order
     */
    minOrderValue?: number;

    /**
     * Indicates if the at table platform is paused. Set to false to indicate that the plateform is paused
     */
    status: DeliverySiteStatus;

    constructor(props: AtTable) {
        Object.assign(this, props);
    }
}
