import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApChip.style';

interface Props extends ChipProps {
    state?: 'reverse' | 'success' | 'warning' | 'error';
    height?: number;
}

const useStyles = makeStyles(style);

export const ApChip = ({ state, className, height = 20, color = 'primary', ...props }: Props) => {
    const classes = useStyles();
    return <Chip style={{ height }} className={`${className} ${classes.root} ${(state && classes[state]) || ''}`} color={color} {...props} />;
};
