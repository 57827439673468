export class CreateDeliveroo {
    /**
     * Example property of the entity
     */
    name: string;

    constructor(props: CreateDeliveroo) {
        Object.assign(this, props);
    }
}
