import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        '&.has-value, &.Mui-focused': {
            background: 'none',
        },
        '&.has-value .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.grey[800]}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.primary.main}`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.ap-success .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.success.main}`,
        },
        '&.ap-error .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.error.main}`,
        },
        '&.ap-warning .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${palette.warning.main}`,
        },
        '& .MuiInputAdornment-positionStart svg': {
            color: palette.grey[600],
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 0',
            lineHeight: 20,
            height: '20px',
        },
    },
});
