import { useEffect, useState, useCallback } from 'react';
import { cloneDeep, isEqual, isNil } from 'lodash';

function useBlockData(blockData: any) {
    const [initialBlockData, setInitialBlockData] = useState(null);
    const [currentBlockData, setCurrentBlockData] = useState(null);

    useEffect(() => {
        if (!isNil(blockData)) {
            if (initialBlockData === null) setInitialBlockData(cloneDeep(blockData));
            setCurrentBlockData(cloneDeep(blockData));
        }
    }, [blockData]);

    const resetInitialBlockData = useCallback((blockDataValue) => {
        if (!isNil(blockDataValue)) {
            setInitialBlockData(cloneDeep(blockDataValue));
            setCurrentBlockData(cloneDeep(blockDataValue));
        }
    }, []);

    const getInitialBlockData = useCallback(() => {
        return cloneDeep(initialBlockData);
    }, [initialBlockData]);

    const isUnchanged = isEqual(initialBlockData, currentBlockData);

    return { isUnchanged, initialBlockData, resetInitialBlockData, getInitialBlockData };
}

function useBlockActions(confirmAction: () => void, cancelAction: () => void) {
    const handleConfirm = useCallback(() => {
        confirmAction();
    }, [confirmAction]);

    const handleCancel = useCallback(() => {
        cancelAction();
    }, [cancelAction]);

    return { handleConfirm, handleCancel };
}

export { useBlockData, useBlockActions };
