export abstract class BaseModelPaginate<T> {
    /**
     * The model that was query
     */
    docs: T[];

    /**
     * The total number of docs matching the query
     */
    totalDocs?: number;

    /**
     * The number of docs return in one query
     */
    limit?: number;

    /**
     * The total number of pages return by the query
     */
    totalPages?: number;

    /**
     * The actual page of the current query
     */
    page?: number;

    /**
     * Indicates if there is a previous page
     */
    hasPrevPage?: boolean;

    /**
     * Indicates if there is a next page
     */
    hasNextPage?: boolean;

    /**
     * The number of the previous page if it exists
     */
    prevPage?: number;

    /**
     * The number of the next page if it exists
     */
    nextPage?: number;
}
