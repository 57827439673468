import * as React from 'react';

function SvgBoltOutlined(props) {
    return (
        <svg width={24} height={24} {...props}>
            <path d="M14,1 L14,9 L21,9 L10,23 L10,15 L3,15 L14,1 Z" id="bolt_combined_shape" fill="currentColor" />
        </svg>
    );
}

export default SvgBoltOutlined;
