export class CreateWebMenu {
    /**
     * Site id to which the menu is assigned to.
     */
    siteId: string;

    constructor(props: CreateWebMenu) {
        Object.assign(this, props);
    }
}
