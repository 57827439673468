import { CreateDelivery } from './create-delivery';
import * as faker from 'faker';

export const fakeCreateDeliveryData = (): CreateDelivery => ({
    name: faker.name.findName(),
});

export const fakeCreateDelivery = (): CreateDelivery => new CreateDelivery(fakeCreateDeliveryData());

export const fakeCreateDeliveryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateDelivery[] => {
    const delivery: CreateDelivery[] = [];

    for (let i = 0; i < nbMax; i++) {
        delivery.push(fakeCreateDelivery());
    }

    return delivery;
};
