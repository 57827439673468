import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { ApButton, ApDateInput, ApIcon, ApInput, ApSelect, ApToggle } from '@aphilia/shared-ui-core-react';
import { ApCellData, ApColumnData } from '../../types';

// Rows

function useSelect(onSelect) {
    const handleCheck = useCallback(
        (key) => (evt) => {
            if (typeof onSelect === 'function') {
                onSelect(key, evt.detail);
            }
        },
        [onSelect]
    );

    const handleRow = useCallback(
        (key, value) => (evt) => {
            // Prevent row selection when element has 'no-selection' class
            if (typeof onSelect === 'function' && !evt.target.classList.contains('no-selection')) {
                onSelect(key, !value);
            }
        },
        [onSelect]
    );

    return { handleCheck, handleRow };
}

function useHoverRow(onHoverRow) {
    const handleHoverRow = useCallback(
        (key, disabledRow) => () => {
            if (typeof onHoverRow === 'function') {
                onHoverRow(key, disabledRow);
            }
        },
        [onHoverRow]
    );

    return { handleHoverRow };
}

// Cells

function useContent(rowId, cellData: ApCellData, column: ApColumnData, component: React.ReactNode, isEditable: boolean, onCellEdition, onSelectClose) {
    // Doesn't work with useRef
    const [inputRef, setInputRef] = useState(null);

    const updateInputRef = useCallback((ref) => {
        setInputRef(ref);
    }, []);

    const editInput = useCallback(
        (evt) => {
            if (typeof onCellEdition === 'function') {
                onCellEdition(rowId, column?.dataKey, evt.detail.target.value, column?.valueType);
            }
        },
        [onCellEdition, column?.dataKey, column?.valueType, rowId]
    );

    const editDateInput = useCallback(
        (evt) => {
            if (typeof onCellEdition === 'function') {
                onCellEdition(rowId, column?.dataKey, evt.detail, column?.valueType);
            }
        },
        [onCellEdition, column?.dataKey, column?.valueType, rowId]
    );

    const editToggle = useCallback(
        (evt) => {
            if (typeof onCellEdition === 'function') {
                onCellEdition(rowId, column?.dataKey, evt.detail, column?.valueType);
            }
        },
        [onCellEdition, column?.dataKey, column?.valueType, rowId]
    );

    const editSelect = useCallback(
        (evt) => {
            if (typeof onCellEdition === 'function') {
                onCellEdition(rowId, column?.dataKey, evt.detail.value, column?.valueType);
            }
        },
        [onCellEdition, column?.dataKey, column?.valueType, rowId]
    );

    const editButton = useCallback(
        (buttonValue) => (evt) => {
            if (typeof onCellEdition === 'function') {
                onCellEdition(rowId, column?.dataKey, buttonValue, column?.valueType, column?.cellType);
            }
        },
        [onCellEdition, column?.dataKey, column?.valueType, column?.cellType, rowId]
    );

    const renderContent = useCallback(
        (cellsAreEditable: boolean) => {
            if (cellData) {
                const { value, options } = cellData;

                if (column?.valueType === 'boolean') {
                    return <ApToggle checked={value} className="no-selection" onApToggle={editToggle} disabled={!cellsAreEditable} />;
                }
                if (column?.valueType === 'select') {
                    const currentValue = options.find((option) => option.value === value);

                    return (
                        <ApSelect
                            label=""
                            borderless={true}
                            compact={true}
                            options={options}
                            value={[currentValue?.value]}
                            openEventPropagation={true}
                            className="no-selection"
                            onItemSelected={editSelect}
                            onApClose={onSelectClose}
                            readonly={!cellsAreEditable}
                        />
                    );
                }
                if (column?.cellType === 'button') {
                    return <ApButton label={value} size="small" type="filled" onClick={editButton(value)} />;
                }
            }

            if (isEditable) {
                const { value } = cellData;

                if (column?.valueType === 'string') {
                    return <ApInput value={value} borderless={true} onInputChange={editInput} inputRef={updateInputRef} />;
                }
                if (column?.valueType === 'number') {
                    return <ApInput value={value} type="number" borderless={true} onInputChange={editInput} inputRef={updateInputRef} />;
                }
                if (column?.valueType === 'date') {
                    return <ApDateInput label="" value={value} borderless={true} compact={true} onDateChange={editDateInput} inputRef={updateInputRef} />;
                }
            } else {
                if (!!component) {
                    return component;
                } else {
                    const { value, icon, rightIcon, content } = cellData;
                    if (typeof content === 'function') {
                        return content(value);
                    }
                    return (
                        <>
                            {icon && <ApIcon className="cell-icon cell-left-icon" icon={icon} color="neutral" size="small" />}
                            <span className="ap-cell-content">{value}</span>
                            {rightIcon && <ApIcon className="cell-icon cell-right-icon" icon={rightIcon} color="neutral" size="small" />}
                        </>
                    );
                }
            }
        },
        [cellData, component, column?.valueType, isEditable, editInput, editToggle, editSelect, onSelectClose]
    );

    useEffect(() => {
        if (inputRef) {
            if (isEditable) {
                inputRef?.focus();
            } else {
                inputRef?.blur();
            }
        }
    }, [inputRef, isEditable]);

    return { renderContent };
}

function useLinkActions(rowId, column) {
    const [linkProps, setLinkPops] = useState({});

    useEffect(() => {
        const actions = column?.actions ?? {};
        const props = Object.keys(actions).reduce((acc, current) => {
            acc[current] = actions[current](rowId);

            return acc;
        }, {});

        if (!isEmpty(props)) {
            setLinkPops(props);
        }
    }, [rowId, column?.actions]);

    return { linkProps };
}

function useCellActions(rowId, column, editableCell, onCellDoubleClick) {
    const [isEditable, setIsEditable] = useState(false);

    const handleCellDoubleClick = useCallback(() => {
        if (typeof onCellDoubleClick === 'function') {
            onCellDoubleClick(rowId, column.dataKey);
        }
    }, [onCellDoubleClick, rowId, column?.dataKey]);

    useEffect(() => {
        if (rowId === editableCell?.row && column?.dataKey === editableCell?.column) {
            setIsEditable(true);
        } else {
            setIsEditable(false);
        }
    }, [rowId, column, editableCell]);

    return { isEditable, handleCellDoubleClick };
}

export { useSelect, useContent, useHoverRow, useLinkActions, useCellActions };
