import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { createClassString } from '@aphilia/shared/utils';
import { ApColumnData, ApColumnSort, ApHoveredCell } from '../../types';
import { ApTableHeaderRow } from './ApTableHeaderRow';

import './ap-table-header.scss';
import { renderHeaderCellClone } from '../../utils';
import { END_STICKY_ID } from '../../config';

interface ApTableHeaderProps extends React.ComponentPropsWithoutRef<'thead'> {
    // Required
    columns: ApColumnData[];
    tableId: string;

    // Optional
    isSelectable?: boolean;
    isSortBy?: string;
    hasSortOrder?: ApColumnSort;
    allSelected?: boolean;
    allIndeterminate?: boolean;
    onSelectAllRows?: (evt: CustomEvent) => void;
    onSortBy?: (columnKey: string) => void;
    onSortOrder?: (columnOrder: ApColumnSort) => void;

    hasDnDContext?: boolean;
    hoveredHeaderCell?: ApHoveredCell;
    scrollRef?: any;

    isSticky?: boolean;
    hasCheck?: boolean;
}

export const ApTableHeader = React.forwardRef<HTMLTableSectionElement, ApTableHeaderProps>(
    (
        {
            columns,
            tableId,
            isSelectable,
            allSelected,
            allIndeterminate,
            isSortBy,
            hasSortOrder,
            isSticky,
            hasCheck,
            onSortBy,
            onSortOrder,
            onSelectAllRows,
            hasDnDContext,
            hoveredHeaderCell,
            scrollRef,
        }: ApTableHeaderProps,
        ref
    ) => {
        const tableHeadClasses = createClassString(['ap-table-header', [isSticky, 'ap-sticky']]);

        return (
            <div className={tableHeadClasses} ref={ref}>
                {hasDnDContext ? (
                    <Droppable
                        droppableId={`${tableId}-droppable-header${isSticky ? END_STICKY_ID : ''}`}
                        type="TABLEHEADERCELL"
                        direction="horizontal"
                        renderClone={renderHeaderCellClone(columns)}
                    >
                        {(provided, snapshot) => {
                            return (
                                <ApTableHeaderRow
                                    tableId={tableId}
                                    rowRef={provided.innerRef}
                                    droppableProps={provided.droppableProps}
                                    droppablePlaceholder={provided.placeholder}
                                    droppableSnapshot={snapshot}
                                    columns={columns}
                                    isSelectable={isSelectable}
                                    allSelected={allSelected}
                                    allIndeterminate={allIndeterminate}
                                    isSortBy={isSortBy}
                                    hasSortOrder={hasSortOrder}
                                    onSortBy={onSortBy}
                                    onSortOrder={onSortOrder}
                                    onSelectAllRows={onSelectAllRows}
                                    hasDnDContext={hasDnDContext}
                                    hoveredHeaderCell={hoveredHeaderCell}
                                    scrollRef={scrollRef}
                                    hasCheck={hasCheck}
                                    isStickyPart={isSticky}
                                />
                            );
                        }}
                    </Droppable>
                ) : (
                    <ApTableHeaderRow
                        columns={columns}
                        isSelectable={isSelectable}
                        allSelected={allSelected}
                        allIndeterminate={allIndeterminate}
                        isSortBy={isSortBy}
                        hasSortOrder={hasSortOrder}
                        onSortBy={onSortBy}
                        onSortOrder={onSortOrder}
                        onSelectAllRows={onSelectAllRows}
                        hasDnDContext={hasDnDContext}
                        hasCheck={hasCheck}
                        isStickyPart={isSticky}
                    />
                )}
            </div>
        );
    }
);
