/* eslint-disable */
import { DetailedHTMLProps, HTMLAttributes, LiHTMLAttributes } from 'react';
import { applyPolyfills, defineCustomElements, JSX as LocalJSX } from '@aphilia/shared-ui-core/loader';
import { AriaAttributes, DOMAttributes } from 'react';

type StencilToReact<T> = {
    [P in keyof T]?: T[P] &
        DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
            class?: string;
        };
};

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        class?: string;
    }
}

declare global {
    export namespace JSX {
        interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {}
    }
}

applyPolyfills().then(() => {
    defineCustomElements();
});
