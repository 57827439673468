import { Brand } from '@aphilia/shared/organizations/types';
import axios from 'axios';

/**
 * Get the list of all the brands from the database
 */
export const getBrands = async (organizationUrl: string): Promise<Brand[]> => {
    try {
        return (await axios.get<Brand[]>(`${organizationUrl}/brands`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
