import { DenyOrCancelOrderReasonStuart } from '@aphilia/deliveries/data';

export class UpdateJobEvent {
    event: string;
    type: string;
    data: {
        id: number;
        status: string;
        cancellation?: {
            canceledBy?: string;
            reasonKey?: DenyOrCancelOrderReasonStuart;
            comment?: string;
        };
        comment?: string;
        pickupAt?: string;
        dropoffAt?: string;
        createdAt?: string;
        endedAt?: string;
        transportType: {
            code: string | null;
        };
        packageType?: {
            code: string;
        };
        originComment?: string;
        destinationComment?: string;
        jobReference?: string;
        currentDelivery?: {
            id: number;
            trackingUrl?: string;
            clientReference?: string;
            driver: {
                status: string;
                latitude: number;
                longitude: number;
                name: string;
                firstname: string;
                lastname: string;
                phone: string;
                picture_path_imgix?: string;
                transportType: {
                    code: string;
                };
            };
            etaToDestination: string;
            etaToOrigin: string;
            cancellation?: {
                canceledBy?: string;
                reasonKey?: DenyOrCancelOrderReasonStuart;
                comment?: string;
            };
            transportType?: {
                code: string;
            };
            status: string;
            deliveries?: {
                id?: number;
                clientReference?: string;
            };
        };
    };
}
