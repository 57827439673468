import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => {
    return createStyles({
        root: {
            boxShadow: `-1px -1px 5px 0 ${theme.palette.grey.A100}, 1px 1px 5px 0 ${theme.palette.grey.A100}`,
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: 3.1,
        },
    });
};
