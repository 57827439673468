import { UberContextType } from '../../enum';
import { UberSuspension } from '../suspension/suspension';

export class UberSuspensionOverride {
    /**
     * Type of the context in which to override.
     */
    context_type: UberContextType;

    /**
     * Identifying string (id) for the specified context.
     */
    context_value: string;

    /**
     * Suspension settings for the item in the context.
     */
    suspension: UberSuspension;

    constructor(props: Partial<UberSuspensionOverride>) {
        Object.assign(this, props);
    }
}
