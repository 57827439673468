import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) =>
    createStyles({
        container: {
            '& .MuiDialog-paper': {
                padding: 24,
            },
        },
        header: {
            flexShrink: 0,
            marginBottom: 24,
            position: 'relative',
            '& .title': {
                display: 'flex',
                alignItems: 'center',

                '& h5': {
                    marginRight: 24,
                },
            },
        },
        body: {
            padding: '0 10px',
            marginLeft: -10,
            marginRight: -10,
        },
        footer: {
            paddingTop: 40,
            flexShrink: 0,
            '& .actions, & .ap-inline-button': {
                marginBottom: 0,
                marginTop: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            '& .MuiButton-label span': {
                display: 'flex',
                '& .confirm-text.deleting': {
                    opacity: 0,
                },
                '& .spinner': {
                    marginRight: '0.5em',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                },
            },
        },
        close: {
            marginLeft: 'auto',
        },
    });
