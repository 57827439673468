import { createContext, ReactNode, Reducer, useCallback, useContext, useEffect, useReducer } from 'react';
import { DeliveryParametersState } from './types';
import * as Actions from './constant';
import React from 'react';
import { DeliveryParameters, UpdateDeliveryParameters } from '@aphilia/deliveries/data';
import { getDeliveryParametersBySiteId, updateDeliveryParametersBySiteId } from '@aphilia/deliveries/data-access';
import { updateDeliveryParameters as updateDeliveryParametersAction, updateDeliveryParametersAddress, updateIsInitializing, updateLoadingDeliveryParameters } from './action';
import { useOrganizationContext } from '@aphilia/shared/organizations/feature-main';
import { useDeliveryParametersSockets } from '../../hooks/delivery-parameters-socket';
import { UpdateAddress } from '@aphilia/shared/utils';

interface State {
    deliveryParameters?: DeliveryParameters;
    deliveriesServiceUrl: string;
    isInitializing: boolean;
    isLoadingDeliveryParameters: boolean;
    pusherCluster: string;
    pusherClientKey: string;
}

const DeliveryParametersContext = createContext<DeliveryParametersState>({} as DeliveryParametersState);

interface Props {
    children: ReactNode;
    initial: Partial<State>;
}

function reducer(state: State, action: { type: keyof typeof Actions; payload?: any }): State {
    switch (action.type) {
        case Actions.UPDATE_DELIVERY_PARAMETERS:
            return Object.assign({}, state, {
                deliveryParameters: action.payload,
            });
        case Actions.UPDATE_IS_INITIALIZING:
            return Object.assign({}, state, {
                isInitializing: action.payload,
            });
        case Actions.UPDATE_LOADING_DELIVERY_PARAMETERS:
            return Object.assign({}, state, {
                isLoadingDeliveryParameters: action.payload,
            });
        case Actions.UPDATE_ADDRESS:
            return Object.assign({}, state, {
                deliveryParameters: {
                    ...state.deliveryParameters,
                    address: action.payload,
                },
            });
    }
}

const DeliveryParametersContextProvider = ({ children, initial }: Props) => {
    const { siteSelected } = useOrganizationContext();
    const [state, dispatch] = useReducer<Reducer<State, any>>(reducer, {
        deliveriesServiceUrl: '',
        isInitializing: true,
        isLoadingDeliveryParameters: false,
        pusherClientKey: '',
        pusherCluster: '',
        ...initial,
    });
    const { deliveryParameters, setSiteId } = useDeliveryParametersSockets({
        initialDeliveryParameters: state.deliveryParameters,
        deliveriesUrl: state.deliveriesServiceUrl,
        pusherCluster: '',
        pusherClientKey: '',
    });

    /**
     * Load the delivery parameters and set them in the delivery parameters
     */
    const loadDeliveryParameters = useCallback(async () => {
        try {
            dispatch(updateLoadingDeliveryParameters(true));
            const newDeliveryParameters = await getDeliveryParametersBySiteId(state.deliveriesServiceUrl, siteSelected._id);
            dispatch(updateDeliveryParametersAction(newDeliveryParameters));
            dispatch(updateLoadingDeliveryParameters(false));
            return newDeliveryParameters;
        } catch (e) {
            console.error(e);
            dispatch(updateLoadingDeliveryParameters(false));
            throw e;
        }
    }, [state.deliveriesServiceUrl, siteSelected?._id]);

    /**
     * Update the delivery parameters that are currently stored.
     */
    const updateDeliveryParameters = useCallback(
        async (updateDeliveryParameter: UpdateDeliveryParameters) => {
            try {
                const newDeliveryParameters = await updateDeliveryParametersBySiteId(state.deliveriesServiceUrl, siteSelected._id, updateDeliveryParameter);
                dispatch(updateDeliveryParametersAction(newDeliveryParameters));
                return newDeliveryParameters;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        [state.deliveriesServiceUrl, siteSelected?._id]
    );

    /**
     * Update the delivery parameters that are currently stored.
     */
    const updateAddress = useCallback(
        async (updateAddressData: UpdateAddress) => {
            try {
                const newDeliveryParameters = await updateDeliveryParametersBySiteId(state.deliveriesServiceUrl, siteSelected._id, { address: updateAddressData });
                dispatch(updateDeliveryParametersAddress(newDeliveryParameters.address));
                return newDeliveryParameters.address;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        [state.deliveriesServiceUrl, siteSelected?._id]
    );

    useEffect(() => {
        if (siteSelected?._id) {
            dispatch(updateLoadingDeliveryParameters(true));
            loadDeliveryParameters().then(() => {
                dispatch(updateIsInitializing(false));
            });
            setSiteId(siteSelected?._id);
        }
    }, [siteSelected?._id]);

    useEffect(() => {
        if (deliveryParameters) {
            dispatch(updateDeliveryParametersAction(deliveryParameters));
        }
    }, [deliveryParameters]);

    return (
        <DeliveryParametersContext.Provider
            value={{
                ...state,
                updateDeliveryParameters,
                setDeliveryParameters: loadDeliveryParameters,
                updateAddress,
            }}
        >
            {children}
        </DeliveryParametersContext.Provider>
    );
};

const useDeliveryParametersContext = () => useContext(DeliveryParametersContext);
export { useDeliveryParametersContext, DeliveryParametersContext, DeliveryParametersContextProvider };
