export class PiClickV2DeliveryParameters {
    /**
     * The pos number where the delivery fees will go in
     */
    posId?: string;

    /**
     * Array of all the zones and the prices to cover
     */
    zones?: {
        /**
         * The distance radius of the zone to cover
         */
        distance: number;

        /**
         * The fee of the zone
         */
        fee: number;
    }[];

    /**
     * Indicates if the delivery is enabled or not
     */
    enabled?: boolean;

    /**
     * The amount of an order from which the delivery is free
     */
    minAmountNoDeliveryFeed?: number;

    /**
     * Indicates if the user wants no delivery fee for a certain amount
     */
    useNoDeliveryFee?: boolean;

    /**
     * Indicates to use stuart or custom fee for delivery
     */
    useStuartFee?: boolean;

    /**
     * Indicates the maximum number of orders per interval.
     */
    maxOrderPerInterval?: number;

    constructor(props: PiClickV2DeliveryParameters) {
        Object.assign(this, props);
    }
}
