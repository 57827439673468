import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useTabs = (tabs: any[], updateCallback) => {
    const history = useHistory();

    const [current, setCurrent] = useState<number>(0);

    const handleChange = useCallback(
        (_: React.ChangeEvent, newValue: number) => {
            setCurrent(newValue);
            const currentTab = tabs[newValue];

            if (currentTab?.url) {
                history.push(currentTab.url, {});
            }

            if (updateCallback) updateCallback(newValue);
        },
        [updateCallback, tabs, history]
    );

    useEffect(() => {
        if (tabs.every((tab) => !tab.url)) return;
        let tabIndex = tabs.findIndex((tab) => history.location.pathname === tab.url);
        if (tabIndex === -1) {
            tabIndex = 0;
        }
        setCurrent(tabIndex);
    }, [tabs, history]);

    return { current, handleChange };
};
