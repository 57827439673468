export enum UberAllergenType {
    Dairy = 'DAIRY',
    Eggs = 'EGGS',
    Fish = 'FISH',
    Shellfish = 'SHELLFISH',
    Treenuts = 'TREENUTS',
    Peanuts = 'PEANUTS',
    Gluten = 'GLUTEN',
    Soy = 'SOY',
    Other = 'OTHER',
}

export class UberAllergen {
    /**
     * The type of allergen. An allergen of type OTHER is one that is not yet supported via the API.
     * In the case of type OTHER, rely on the freeform_text field.
     */
    type: UberAllergenType;

    /**
     * This field is only populated for OTHER type allergens.
     * An allergen of type OTHER is one that is not yet supported via the API. In the case of type OTHER, rely on this field to discern the allergen.
     */
    freeform_text: string;
}
