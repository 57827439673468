import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { i18n } from '../i18n';

interface Auth {
    domain: string;
    clientId: string;
}

export const AppInit = (App: ReactNode, auth: Auth, translationPath: string) => {
    i18n.init({
        load: 'languageOnly',
        fallbackLng: ['en', 'fr'],
        backend: {
            loadPath: translationPath,
            crossDomain: true,
        },
    });
    ReactDOM.render(<>{App}</>, document.getElementById('root'));
};
