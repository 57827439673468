import { CreateTerminal } from './create-terminal';
import * as faker from 'faker';
import { PriceType } from '@aphilia/shared/utils';
import { fakeWeekdayTimePeriodsArray } from '@aphilia/shared/utils';
import { fakeTerminalMediaArray } from '../terminal-media/terminal.data';

export const fakeCreateTerminalData = (): CreateTerminal => ({
    enabled: faker.random.boolean(),
    priceLevel: faker.random.objectElement<PriceType>(PriceType),
    timePeriods: fakeWeekdayTimePeriodsArray(),
    videos: fakeTerminalMediaArray(5),
    images: fakeTerminalMediaArray(5),
});

export const fakeCreateTerminal = (): CreateTerminal => new CreateTerminal(fakeCreateTerminalData());

export const fakeCreateTerminalArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): CreateTerminal[] => {
    const createTerminal: CreateTerminal[] = [];

    for (let i = 0; i < nbMax; i++) {
        createTerminal.push(fakeCreateTerminal());
    }

    return createTerminal;
};
