import moment, { Moment } from 'moment-timezone';
import { WeekdayTimePeriods } from './weekday-time-periods/weekday-time-periods';
export * from './weekday-time-periods/weekday-time-periods';
export * from './weekday-time-periods/weekday-time-periods.data';
export * from './create-weekday-time-periods/create-weekday-time-periods';
export * from './create-weekday-time-periods/create-weekday-time-periods.data';
export * from './update-weekday-time-periods/update-weekday-time-periods';
export * from './update-weekday-time-periods/update-weekday-time-periods.data';
export * from './weekday-time-periods.permissions';

/**
 * Indicates if we are actually in a time period
 */
export function isInTimePeriods(timePeriods: WeekdayTimePeriods[]): boolean {
    return timePeriods.some((timePeriod) => {
        return timePeriod.isOpen && timePeriod.isActuallyInTimePeriod;
    });
}

/**
 * Returns the next time periods available starting from now
 */
export function nextActiveDateTime(timePeriods: WeekdayTimePeriods[]): Moment {
    const dateTime = moment();

    for (let i = dateTime.isoWeekday() - 1; i < dateTime.isoWeekday() + 7; i++) {
        const addWeek = (i % 7) + 1 < dateTime.isoWeekday() ? 1 : 0;
        const newTimePeriods = timePeriods.filter((timePeriod) => timePeriod.weekday === (i % 7) + 1 && timePeriod.isOpen);
        if (newTimePeriods.length > 0) {
            for (const timePeriod of newTimePeriods) {
                for (let j = 0; j < timePeriod.timePeriods.length; j++) {
                    if (dateTime.isBefore(moment.tz(timePeriod.timePeriods[j].startTime, 'HH:mm', timePeriod.timeZone).add(addWeek, 'w').isoWeekday(timePeriod.weekday), 'm')) {
                        return moment.tz(timePeriod.timePeriods[j].startTime, 'HH:mm', timePeriod.timeZone).isoWeekday(timePeriod.weekday).add(addWeek, 'w');
                    }
                }
            }
        }
    }

    const today = timePeriods.find((timePeriod) => timePeriod.weekday === dateTime.isoWeekday() && timePeriod.isOpen);

    return today?.timePeriods?.length > 0 ? moment.tz(today.timePeriods[0].startTime, 'HH:mm', today.timeZone).add(1, 'w') : undefined;
}

/**
 * Return true or false if we will be in a time period at a certain time
 *
 * @param dateTime
 * @param timePeriods
 */
export function isSiteActiveAt(dateTime: Moment, timePeriods: WeekdayTimePeriods[]): boolean {
    if (dateTime.isBefore(moment())) {
        return false;
    }

    return timePeriods.some(
        (timePeriod) =>
            timePeriod.weekday === dateTime.isoWeekday() &&
            timePeriod.isOpen &&
            timePeriod.timePeriods.some((timePeriodNested) => {
                const dateTimeInTimezone = dateTime.tz(timePeriod.timeZone);
                const startTimeM = moment.tz(`${dateTimeInTimezone.format('YYYY-MM-DD')} ${timePeriodNested.startTime}`, 'YYYY-MM-DD HH:mm', timePeriod.timeZone);
                const endTimeM = moment.tz(`${dateTimeInTimezone.format('YYYY-MM-DD')} ${timePeriodNested.endTime}`, 'YYYY-MM-DD HH:mm', timePeriod.timeZone);

                return dateTimeInTimezone.isBetween(startTimeM, endTimeM, 'm', '[]');
            })
    );
}
