import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => {
    return {
        root: {
            '& .MuiListItem-button': {
                color: palette.primary.main,
                '&.checked-item': {
                    background: theme.palette.primary.light.replace('rgb', 'rgba').replace(')', ', 0.1)'),
                    '&:hover': {
                        background: theme.palette.primary.light.replace('rgb', 'rgba').replace(')', ', 0.2)'),
                    },
                },
            },
        },
    };
};
