import { UberDayOfWeek } from '../../enum';
import { UberTimePeriod } from '../../menu/time-period/time-period';

export class UberHoursOfWeek {
    /**
     * The day of the week on which these hours will be applied.
     */
    day_of_week: UberDayOfWeek;

    /**
     * The continuous time spans during which the item is visible.
     */
    time_periods: UberTimePeriod[];

    constructor(props: Partial<UberHoursOfWeek>) {
        Object.assign(this, props);
    }
}
