import { UpdateArticlesWebArticleCategory } from './update-articles-web-article-category';
import * as faker from 'faker';
import { fakeWebArticleAdditionalInformationArray } from '../../web-article-additional-information';

export const fakeUpdateArticlesWebArticleCategoryData = (): UpdateArticlesWebArticleCategory => ({
    additions: fakeWebArticleAdditionalInformationArray().map((addition) => addition._id),
    siteId: faker.random.number().toString(),
});

export const fakeUpdateArticlesWebArticleCategory = (): UpdateArticlesWebArticleCategory => new UpdateArticlesWebArticleCategory(fakeUpdateArticlesWebArticleCategoryData());

export const fakeUpdateArticlesWebArticleCategoryArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateArticlesWebArticleCategory[] => {
    const webArticleCategory: UpdateArticlesWebArticleCategory[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleCategory.push(fakeUpdateArticlesWebArticleCategory());
    }

    return webArticleCategory;
};
