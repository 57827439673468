import React, { useCallback } from 'react';

import { ApIcon } from '@aphilia/shared-ui-core-react';
import { ApColumnSort } from '../../types';

const sortOrder = [ApColumnSort.ASC, ApColumnSort.DESC, ApColumnSort.NONE];

let clicksCount = 0;
let sortByValue = null;

function useColumnSort(isSortBy: string, isSortable: boolean, onSortBy, onSortOrder) {
    const handleClick = useCallback(
        (columnKey: string, isDragging: boolean) => () => {
            if (!isSortable || isDragging) return;
            // If different column, store new column name and reset clicks count
            if (sortByValue !== columnKey) {
                sortByValue = columnKey;
                clicksCount = 0;
            }
            // Get current order value from clicks count
            const currentValue = clicksCount % 3;

            if (typeof onSortBy === 'function') {
                onSortBy(columnKey);
            }
            if (typeof onSortOrder === 'function') {
                onSortOrder(sortOrder[currentValue]);
            }

            clicksCount++;
        },
        [onSortBy, onSortOrder, isSortable, isSortBy]
    );

    return { handleClick };
}

function useContent(cellData, component) {
    const renderContent = useCallback(() => {
        if (!!component) {
            return component;
        } else if (!!cellData) {
            const { label, icon, rightIcon } = cellData;

            return (
                <>
                    {icon && <ApIcon icon={icon} />}
                    {label}
                    {rightIcon && <ApIcon icon={rightIcon} />}
                </>
            );
        }
        return null;
    }, [cellData, component]);

    return { renderContent };
}

export { useColumnSort, useContent };
