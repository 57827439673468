import { DeliveryPlatform, OrderState, OrderType } from '../..';
import { Moment } from 'moment';

export class QueryOrder {
    /**
     * List of the ids of the orders
     */
    ids?: string[];

    /**
     * Array of all the states that should be return by the query.
     */
    state?: OrderState[];

    /**
     * Array of all the types that should be return by the query.
     */
    type?: OrderType[];

    /**
     * Id of the site to query the reservation
     */
    siteId?: string;

    /**
     * Field to research on the short id.
     */
    search?: string;

    /**
     * The first date time interval to look for orders
     */
    startDateTime?: Moment;

    /**
     * The last date time interval to look for orders
     */
    endDateTime?: Moment;

    /**
     * The page to request
     */
    page: number;

    /**
     * The limit number of orders to request in one page
     */
    limit: number;

    /**
     * The platform where the order comes from
     */
    deliveryPlatform?: DeliveryPlatform[];

    constructor(props: QueryOrder) {
        Object.assign(this, props);
    }
}
