import axios from 'axios';
import { UpdateUber } from '@aphilia/deliveries/data';

/**
 * Get all sites from Uber
 * @Param deliveriesService
 * @Param token
 * @Param code
 * @Param tokenUber
 */
export const getUberSites = async (
    deliveriesService: string,
    deliveriesToken: string,
    redirectUri: string,
    code?: string,
    tokenUber?: string
): Promise<{ token: string; sites: [] }> => {
    try {
        const response = await axios.get<{ token: string; sites: [] }>(`${deliveriesService}/uber/sites`, {
            headers: {
                Authorization: `Bearer ${deliveriesToken}`,
            },
            params: { redirectUri, code, tokenUber },
        });

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * Provision a uber store to a site. It will indicate that the store uber must now send information
 * to the uber application that is set in the clientId env of the application calling this function
 *
 * @Param deliveriesService
 * @para deliveriesToken
 * @Param siteId
 * @Param locationId
 * @Param uberToken
 * @param posIntegrationStatus
 */
export const provisionUberStore = async (
    deliveriesService: string,
    deliveriesToken: string,
    siteId: string,
    locationId: string,
    uberToken: string,
    posIntegrationStatus: boolean
): Promise<void> => {
    try {
        const response = await axios.post<any>(
            `${deliveriesService}/uber/sites/${siteId}`,
            {
                locationId,
                token: uberToken,
                posIntegrationStatus,
            },
            {
                headers: {
                    Authorization: `Bearer ${deliveriesToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * Update Uber parameters or create it
 * @Param deliveriesService
 * @Param siteId
 * @Param uberParameters
 * @Param tokenUber
 */
export const updateUberParameters = async (
    deliveriesService: string,
    deliveriesToken: string,
    siteId: string,
    uberParameters: UpdateUber
): Promise<{ token: string; sites: [] }> => {
    try {
        const response = await axios.post<any>(`${deliveriesService}/${siteId}/update`, uberParameters, {
            headers: {
                Authorization: `Bearer ${deliveriesToken}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
