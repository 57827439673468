import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { useDrawerPanel, transitionClass, openLayerClass } from './hooks';
import { ApDrawerPosition } from './types';

import './ap-drawer.scss';

interface ApDrawerProps {
    children?: React.ReactNode;
    isOpen: boolean;
    position?: ApDrawerPosition;
    onHide?: () => void;
    zIndex?: number;
}

export function ApDrawer({ isOpen, children, position, onHide, zIndex = 2000 }: ApDrawerProps) {
    const drawerLayer = useRef<HTMLDivElement>(null);
    const { DrawerPanel, handleHide } = useDrawerPanel(children, drawerLayer, onHide, isOpen);

    const positionClass = `ap-drawer-position-${position}`;

    if (isOpen && drawerLayer?.current?.classList?.contains(transitionClass)) {
        return ReactDOM.createPortal(DrawerPanel, drawerLayer.current);
    }

    if (isOpen) {
        if (!drawerLayer?.current) {
            drawerLayer.current = document.createElement('div');

            drawerLayer.current.addEventListener('click', handleHide);
            drawerLayer.current.setAttribute('style', `z-index: ${zIndex}`);
            document.body.appendChild(drawerLayer.current);
        }
        drawerLayer.current.setAttribute('class', ['ap-drawer-layer', openLayerClass, 'ap-closer', positionClass].join(' '));

        return ReactDOM.createPortal(DrawerPanel, drawerLayer.current);
    }

    return null;
}
