import axios from 'axios';
import { User } from '@aphilia/shared/organizations/types';
import { UpdateUser, UserDetail } from '@aphilia/shared/organizations/types';

/**
 * Get a user by its id
 */
export const getUserById = async (organizationUrl: string, userId: string): Promise<User> => {
    try {
        return (await axios.get<User>(`${organizationUrl}/users/${userId}`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return all the users matching a given query
 *
 * @param organizationUrl
 * @param siteId
 * @param search
 */
export const getUsersByEmailOrUsernameAndSiteId = async (organizationUrl: string, siteId: string, search: string): Promise<User[]> => {
    try {
        return (
            await axios.get<User[]>(`${organizationUrl}/users?siteId=${siteId}&search=${search}`, {
                headers: { authorization: `Bearer ${(window as any).authToken['default']}` },
            })
        ).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Return the current connected user object
 *
 * @param organizationsUrl
 * @param token
 */
export const getUser = async (organizationsUrl: string): Promise<UserDetail> => {
    try {
        return (await axios.get(`${organizationsUrl}/users/me`, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })).data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

/**
 * Update the user
 *
 * @param organizationsUrl
 * @param token
 * @param userId
 * @param updateObject
 */
export const updateUser = async (organizationsUrl: string, userId: string, updateObject: UpdateUser): Promise<UserDetail> => {
    try {
        return (
            await axios.patch<UserDetail>(`${organizationsUrl}/users/${userId}`, updateObject, { headers: { authorization: `Bearer ${(window as any).authToken['default']}` } })
        ).data;
    } catch (error) {
        console.error(error);
        return;
    }
};
