import { UberSuspension } from '../suspension/suspension';
import { UberSuspensionOverride } from '../suspension-override/suspension-override';

export class UberSuspensionRules {
    /**
     * Any active suspension for the item.
     */
    suspension?: UberSuspension;

    /**
     * Overrides for the suspension in different contexts.
     */
    overrides?: UberSuspensionOverride[];

    constructor(props: Partial<UberSuspensionRules>) {
        Object.assign(this, props);
        this.suspension = new UberSuspension(this.suspension);

        if (this.overrides) {
            this.overrides.forEach((override) => new UberSuspensionOverride(override));
        }
    }
}
