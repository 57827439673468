import { Stuart } from './stuart';
import * as faker from 'faker';

export const fakeStuartData = (): Stuart => ({
    enabled: faker.random.boolean(),
    clientId: faker.random.alphaNumeric(),
    clientSecret: faker.random.alphaNumeric(),
    token: faker.random.alphaNumeric(),
    tokenExpiration: faker.random.number(1000),
});

export const fakeStuart = (): Stuart => new Stuart(fakeStuartData());

export const fakeStuartArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): Stuart[] => {
    const stuart: Stuart[] = [];

    for (let i = 0; i < nbMax; i++) {
        stuart.push(fakeStuart());
    }

    return stuart;
};
