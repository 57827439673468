import { WebMenu } from './web-menu';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { fakeWebArticleArray } from '../../web-article';
import { fakeWebArticleModifierArray } from '../../web-article-modifier';
import { fakeWebArticleCategory } from '../../web-article-category';

export const fakeWebMenuData = () => {
    const articles = fakeWebArticleArray(100);
    const articlesModifier = fakeWebArticleModifierArray(
        4,
        articles.slice(0, 10).map((article) => article._id)
    );

    const categories = [];

    for (let i = 1; i < 10; i++) {
        categories.push(fakeWebArticleCategory(articles.slice(i * 10, i * 10 + 10).map((article) => article._id)));
    }

    return {
        _id: mongoose.Types.ObjectId().toHexString(),
        siteId: mongoose.Types.ObjectId().toHexString(),
        articles,
        modifiers: articlesModifier,
        categories,
        failureCause: faker.random.words(),
    };
};

export const fakeWebMenu = (): WebMenu => new WebMenu(fakeWebMenuData());

export const fakeWebMenuArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebMenu[] => {
    const webMenu: WebMenu[] = [];

    for (let i = 0; i < nbMax; i++) {
        webMenu.push(fakeWebMenu());
    }

    return webMenu;
};
