import { WebMenu } from '@aphilia/deliveries/data';
import axios from 'axios';

/**
 * Get the site menu by site id
 *
 * @param deliveriesService
 * @param siteId
 */
export const getMenuBySiteId = async (deliveriesService: string, siteId: string) => {
    try {
        return new WebMenu((await axios.get(`${deliveriesService}/menus?siteId=${siteId}`)).data);
    } catch (e) {
        console.log(e);
        throw e;
    }
};
