import { UpdateWebArticleModifierDescriptiveSheet } from './update-web-article-modifier-descriptive-sheet';
import * as faker from 'faker';
import { WebArticleModifierType } from '../../web-article-modifier';

export const fakeUpdateWebArticleModifierDescriptiveSheetData = (): UpdateWebArticleModifierDescriptiveSheet => ({
    modifierNumber: faker.random.number(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    shortDescription: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    type: faker.random.objectElement<WebArticleModifierType>(WebArticleModifierType),
    siteId: faker.random.number().toString(),
    brandId: faker.random.number().toString(),
});

export const fakeUpdateWebArticleModifierDescriptiveSheet = (): UpdateWebArticleModifierDescriptiveSheet =>
    new UpdateWebArticleModifierDescriptiveSheet(fakeUpdateWebArticleModifierDescriptiveSheetData());

export const fakeUpdateWebArticleModifierDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateWebArticleModifierDescriptiveSheet[] => {
    const webArticleModifierDescriptiveSheet: UpdateWebArticleModifierDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleModifierDescriptiveSheet.push(fakeUpdateWebArticleModifierDescriptiveSheet());
    }

    return webArticleModifierDescriptiveSheet;
};
