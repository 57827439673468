export class QueryWebArticleDescriptiveSheet {
    /**
     * The brand id the web article is related to
     */
    brandId: string;

    constructor(props: QueryWebArticleDescriptiveSheet) {
        Object.assign(this, props);
    }
}
