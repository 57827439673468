import moment from 'moment';

export const drawBankPaymentReceipt = (canvas, bankReceiptData, canvasWidth: number, canvasHeight: number) => {
    const maxWidth = canvasWidth - 20;
    const maxWidthWithPadding = canvasWidth - 50;

    // @ts-ignore
    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvasWidth, canvasHeight);

    context.fillStyle = 'black';
    context.font = '25px Arial';
    context.textAlign = 'center';
    context.fillText('CARTE BANCAIRE', canvasWidth * 0.5, 30, maxWidthWithPadding);

    context.font = '17px Arial';
    context.textAlign = 'start';
    context.fillText(moment(bankReceiptData.receiptDate).format('DD/MM/YYYY HH:mm:ss'), 10, 95, maxWidth);
    context.fillText(bankReceiptData.siteName, 10, 125, maxWidth);
    context.fillText(bankReceiptData.city, 10, 145, maxWidth);
    context.fillText(bankReceiptData.postalCode, 10, 165, maxWidth);
    context.fillText('xxxx xxxx xxxx xxxx', 10, 185, maxWidth);
    context.fillText(`Transaction: ${bankReceiptData.transaction_id}`, 10, 210, maxWidth);

    context.font = '27px Arial';
    context.fillText(`MONTANT = ${(bankReceiptData.amount / 100).toFixed(2)} EUR`, 10, 270, maxWidth);
};

export const drawBillNote = (canvas, bill, noteData, canvasWidth: number, canvasHeight: number) => {
    const xMainContentPosition = 20;
    const yStartPositionItems = 200;
    const maxWidth = canvasWidth - 20;
    const xPositionCenter = canvasWidth * 0.5;
    const maxWidthWithPadding = canvasWidth - 50;

    // const canvas = document.getElementById('ticketCanvas');
    // @ts-ignore
    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvasWidth, canvasHeight);

    context.fillStyle = 'black';
    context.font = '30px Arial';
    context.textAlign = 'center';
    context.fillText(noteData.siteName.toUpperCase(), xPositionCenter, 50, maxWidthWithPadding);

    context.font = 'lighter 15px Arial';
    context.fillText(noteData.siteAddress.toUpperCase(), xPositionCenter, 70, maxWidthWithPadding);
    context.fillText(noteData.siteAddressComp.toUpperCase(), xPositionCenter, 90, maxWidthWithPadding);
    context.font = 'lighter 10px Arial';
    context.fillText(noteData.siteSiret.toUpperCase(), xPositionCenter, 105, maxWidthWithPadding);

    context.textAlign = 'start';

    context.font = 'lighter 22px Arial';
    context.fillText(`TABLE     ${noteData.tableNumber}`, xMainContentPosition, 150, maxWidth);

    context.font = 'lighter 15px Arial';
    context.fillText(`${noteData.cutleryNumber} COUVERT   ${noteData.orderedBy}`, xMainContentPosition * 1.65, 170, maxWidth);

    let index = 0;
    bill.items.forEach((item) => {
        if (item.quantity) {
            context.textAlign = 'start';
            context.fillText(`${item.quantity}  ${item.title}`, xMainContentPosition * 2.5, yStartPositionItems + 20 * index, maxWidth - 120);
            context.textAlign = 'end';
            context.fillText(`${item.unit_price / 100} B`, xMainContentPosition * 3 + (maxWidth - 65), yStartPositionItems + 20 * index, maxWidth);
            index++;
        }
    });

    const yPositionAfterItems = yStartPositionItems + 20 * index + 30;

    context.textAlign = 'start';
    context.font = '25px Arial';
    context.fillText(`TOTAL`, canvasWidth * 0.1, yPositionAfterItems, maxWidthWithPadding);
    context.textAlign = 'end';
    context.fillText((bill.payment.total / 100).toFixed(2), canvasWidth - 45, yPositionAfterItems, maxWidthWithPadding);

    // Draw line
    context.beginPath();
    context.setLineDash([3, 2]);
    context.moveTo(20, yPositionAfterItems + 10);
    context.lineTo(canvasWidth - 20, yPositionAfterItems + 10);
    context.stroke();

    context.textAlign = 'start';
    context.font = 'lighter 15px Arial';
    context.fillText(`HT         TVA        TTC`, maxWidth - 180, yPositionAfterItems + 40, maxWidth);
    context.fillText(`B`, xMainContentPosition, yPositionAfterItems + 60, maxWidth);
    context.fillText(`TVA    ${noteData.tva}`, xMainContentPosition + 100, yPositionAfterItems + 60, maxWidth);
    context.fillText(
        `${noteData.totalHt.toFixed(2)}       ${noteData.tvaAmount}       ${(bill.payment.total / 100).toFixed(2)}`,
        maxWidth - 190,
        yPositionAfterItems + 60,
        maxWidth
    );

    context.fillText(`${noteData.totalHt.toFixed(2)}       ${noteData.tvaAmount}`, maxWidth - 190, yPositionAfterItems + 90, maxWidth);

    // Draw line
    context.beginPath();
    context.setLineDash([3, 2]);
    context.moveTo(maxWidth - 195, yPositionAfterItems + 70);
    context.lineTo(maxWidth - 145, yPositionAfterItems + 70);
    context.stroke();

    // Draw line
    context.beginPath();
    context.setLineDash([3, 2]);
    context.moveTo(maxWidth - 125, yPositionAfterItems + 70);
    context.lineTo(maxWidth - 85, yPositionAfterItems + 70);
    context.stroke();

    // Draw line
    context.beginPath();
    context.setLineDash([3, 2]);
    context.moveTo(20, yPositionAfterItems + 110);
    context.lineTo(canvasWidth - 20, yPositionAfterItems + 110);
    context.stroke();

    context.fillText(`CB WEB`, canvasWidth * 0.1, yPositionAfterItems + 140, maxWidthWithPadding);
    context.textAlign = 'end';
    context.fillText((bill.payment.total / 100).toFixed(2), canvasWidth - 45, yPositionAfterItems + 140, maxWidthWithPadding);

    context.textAlign = 'start';
    context.font = 'lighter 12px Arial';
    // TODO: Find where this data come from
    context.fillText('To:017  Doc:28  Lg:4', xMainContentPosition, yPositionAfterItems + 200, maxWidthWithPadding);
    context.textAlign = 'end';
    context.fillText(noteData.firmware, maxWidth, yPositionAfterItems + 200, maxWidthWithPadding);

    context.textAlign = 'start';
    context.fillText('LUNDI 18-10-21 16:13:38', xMainContentPosition, yPositionAfterItems + 220, maxWidthWithPadding);
    context.fillText(noteData.billDate, xMainContentPosition, yPositionAfterItems + 240, maxWidthWithPadding);

    context.textAlign = 'center';
    context.font = 'lighter 15px Arial';
    context.fillText('Prix Nets - Service Compris', xPositionCenter, yPositionAfterItems + 300, maxWidthWithPadding);
};
