export * from './web-article/web-article';
export * from './web-article/web-article.data';
export * from './create-web-article/create-web-article';
export * from './create-web-article/create-web-article.data';
export * from './update-web-article/update-web-article';
export * from './update-web-article/update-web-article.data';
export * from './query-web-article/query-web-article';
export * from './query-web-article/query-web-article.data';
export * from './web-article.permissions';
export * from './web-article-vat-information/web-article-vat-information';

/**
 * List of all the article status that must be taken in account for the delivery
 */
export const validArticleStatusCode = [
    64,
    65,
    72,
    73,
    80,
    81,
    84,
    88,
    89,
    96,
    97,
    104,
    105,
    112,
    113,
    120,
    121,
    192,
    208,
    1224,
    240,
    242,
    32880,
    2160,
    34928,
    39024,
    36976,
    4208,
    6256,
    33904,
    1136,
];
