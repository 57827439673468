import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) =>
    createStyles({
        root: {
            margin: '2em 0 0 0',
            '& .MuiButtonBase-root': {
                '& + .MuiButtonBase-root': {
                    marginLeft: '1em',
                },
            },
        },
    });
