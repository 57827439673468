import { WebArticleDescriptiveSheet } from './web-article-descriptive-sheet';
import mongoose from 'mongoose';
import * as faker from 'faker';
import { WebArticleType } from '../../web-article';

export const fakeWebArticleDescriptiveSheetData = (): WebArticleDescriptiveSheet => ({
    _id: mongoose.Types.ObjectId().toHexString(),
    brandId: mongoose.Types.ObjectId().toHexString(),
    genericArticleId: mongoose.Types.ObjectId().toHexString(),
    siteId: faker.random.number().toString(),
    title: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    shortDescription: {
        fr: faker.commerce.productName(),
        en: faker.commerce.productName(),
    },
    longDescription: {
        fr: faker.lorem.paragraph(),
        en: faker.lorem.paragraph(),
    },
    images: [faker.image.imageUrl()],
    calories: faker.random.number(),
    type: faker.random.objectElement<WebArticleType>(WebArticleType),
    menuNumber: faker.random.number(),
    allergens: [faker.commerce.productAdjective()],
    tag: faker.commerce.productAdjective(),
    nutriScore: faker.random.arrayElement(['A', 'B', 'C', 'D', 'E']),
    specialDiets: [faker.commerce.productAdjective()],
    oldPrice: parseInt(faker.commerce.price(), 10),
    labels: [faker.commerce.productAdjective()],
});

export const fakeWebArticleDescriptiveSheet = (): WebArticleDescriptiveSheet => new WebArticleDescriptiveSheet(fakeWebArticleDescriptiveSheetData());

export const fakeWebArticleDescriptiveSheetArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): WebArticleDescriptiveSheet[] => {
    const webArticleDescriptiveSheet: WebArticleDescriptiveSheet[] = [];

    for (let i = 0; i < nbMax; i++) {
        webArticleDescriptiveSheet.push(fakeWebArticleDescriptiveSheet());
    }

    return webArticleDescriptiveSheet;
};
