import { UpdateAddress } from './update-address';
import * as faker from 'faker';

export const fakeUpdateAddressData = (): UpdateAddress => ({
    mainAddress: faker.address.streetAddress(),
    complementaryInformation: faker.address.secondaryAddress(),
    zipCode: faker.address.zipCode(),
    city: faker.address.city(),
    country: faker.address.country(),
});

export const fakeUpdateAddress = (): UpdateAddress => new UpdateAddress(fakeUpdateAddressData());

export const fakeUpdateAddressArray = (nbMax: number = faker.random.number({ min: 1, max: 20 })): UpdateAddress[] => {
    const address: UpdateAddress[] = [];

    for (let i = 0; i < nbMax; i++) {
        address.push(fakeUpdateAddress());
    }

    return address;
};
