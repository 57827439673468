export enum UberEnergyDisplayType {
    SINGLE_ITEM = 'single_item',
    DOUBLE_ITEMS = 'double_items',
    ADDITIVE_ITEM = 'additive_item',
    MULTIPLE_ITEMS = 'multiple_items',
}

export class UberEnergInfo {
    /**
     * The lower range of the energy content.
     * This is used in various ways depending on the display_type.
     */
    lower_range?: number;

    /**
     * The upper range of the energy content.
     * This is used in various ways depending on the display_type.
     */
    upper_range?: number;

    /**
     * "single_item”: takes the value of lower_range as is (e.g. “10”)
     * “double_items”: takes both lower_range and upper_range (e.g. “10/20”)
     * “additive_item”: takes the value of lower_range and prepends a plus (e.g. “+10”)
     * “multiple_items”: takes the value of lower_range and upper_range as a range (e.g. “10-20)
     */
    display_type?: UberEnergyDisplayType;

    constructor(props: Partial<UberEnergInfo>) {
        Object.assign(this, props);
    }
}
