import * as React from 'react';

function SvgChevronLeft(props) {
    return (
        <svg width={24} height={24} {...props}>
            <defs>
                <path id="chevron_left_svg__a" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </defs>
            <use fill="currentColor" xlinkHref="#chevron_left_svg__a" fillRule="evenodd" />
        </svg>
    );
}

export default SvgChevronLeft;
