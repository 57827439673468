import React from 'react';
import './register-stencil-ui-components';
import App from './app/app';
import { environment } from './environments/environment';
import { AppInit, ToastStateProvider } from '@aphilia/shared/react';
import { AuthProvider } from '@aphilia/shared/organizations/feature-authentication';
import { OrganizationsStateProvider } from '@aphilia/shared/organizations/feature-main';

AppInit(
    <ToastStateProvider>
        <AuthProvider
            auth0Options={{
                domain: environment.auth.domain,
                client_id: environment.auth.clientId,
                connection: 'support',
            }}
            audiences={environment.auth.audiences}
        >
            <OrganizationsStateProvider organizationsUrl={environment.organizationsUrl} initial={{ useBrands: true }}>
                <App />
            </OrganizationsStateProvider>
        </AuthProvider>
    </ToastStateProvider>,
    null,
    `https://storage.googleapis.com/${environment.translationBucket}/isphora/locale/{{lng}}.json`
);
