import { UberHoursOfWeek } from '../hours-of-week/hours-of-week';

export class UberVisibilityHours {
    /**
     * An ISO 8601 formatted date string specifying the start of the period when an item should be visible. For example 2019-12-29.
     * Omitting this value means current day.
     */
    start_date?: string;

    /**
     * An ISO 8601 formatted date string specifying the end of the period when an item should be visible.
     * For example 2019-12-30. Omitting this value means end of time.
     */
    end_date?: string;

    /**
     * List of time of day and day of week when an item should be visible when it is between the start date and end date.
     * At least one entry is required.
     */
    hours_of_week: UberHoursOfWeek[];

    constructor(props: Partial<UberVisibilityHours>) {
        Object.assign(this, props);
        this.hours_of_week = this.hours_of_week.map((hour) => new UberHoursOfWeek(hour));
    }
}
