import React from 'react';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Typography, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import style from './ApBanner.style';

export type variantType = 'info' | 'error' | 'success' | 'warning';

interface Props {
    text: string;
    variant: variantType;
    maxWidth?: number;
    onClose?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const useStyles = makeStyles(style);

export const ApBanner = ({ text, variant, maxWidth = 418, onClose }: Props) => {
    const classes = useStyles();

    return (
        <Paper className={`ap-banner ${classes.root} ${variant}`} elevation={0} style={{ maxWidth }}>
            <Grid container justify="center" alignItems="flex-start" wrap="nowrap" spacing={2}>
                <Grid item>
                    {variant === 'success' && <CheckCircleIcon className={variant} />}
                    {(variant === 'error' || variant === 'info') && <InfoIcon className={variant} />}
                    {variant === 'warning' && <ReportProblemIcon className={variant} />}
                </Grid>
                <Grid item>
                    <Typography className={`banner-text ${variant}`} variant="body2">
                        {text}
                    </Typography>
                </Grid>
                {onClose && (
                    <Grid item>
                        <Close color="secondary" style={{ cursor: 'pointer' }} onClick={onClose} className="close" />
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};
