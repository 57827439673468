import { UberPriceRules } from '../../price/price-rules/price-rules';
import { UberQuantityConstraintRules } from '../../quantity/quantity-constraint-rules/quantity-constraint-rules';
import { UberSuspensionRules } from '../../suspension/suspension-rules/suspension-rules';
import { UberModifierGroupRules } from '../../modifier/modifier-group-rules/modifier-group-rules';
import { UberTaxInfo } from '../../tax/tax-info/tax-info';
import { UberNutritionalInfo } from '../../nutritional/nutritional-info/nutritional-info';
import { UberDishInfo } from '../../dish/dish-info/dish-info';
import { UberTaxLabelsRuleSet } from '../../tax/tax-labels-rule-set/tax-labels-rule-set';
import { UberVisibilityInfo } from '../../visibility/visibility-info/visibility-info';

export class UberItem {
    /**
     * A unique identifying string for the item, provided by the restaurant.
     */
    id: string;

    /**
     * Free-form text field reserved for the restaurant’s use, e.g. for POS integrations.
     */
    external_data?: string;

    /**
     * The name of the item (by locale).
     */
    title: {
        translations: Record<string, string>;
    };

    /**
     * Supplementary information describing the item (by locale).
     */
    description?: {
        translations: Record<string, string>;
    };

    /**
     * URL pointing to an image of the item.
     * Image requirements:
     * Hosted on a secure connection (SSL).
     * File size must be less than 10MB.
     * Image must be in JPEG, PNG or GIF format.
     * Both width and height must be between 320px and 1144px.
     */
    image_url?: string;

    /**
     * Specifies the price to charge for ordering the item. Allows overrides dependent on the ordered item’s context.
     * Price should always be integer value (never decimals) because the price is set in lowest denomination (e.g., cents for US currency, not dollars).
     * Price should always be set even if price is 0.
     */
    price_info: UberPriceRules;

    /**
     * Constrains the quantities in which the item can be ordered. Only applies to items used within a modifier group.
     * Allows overrides dependent on the ordered item’s context.
     */
    quantity_info?: UberQuantityConstraintRules;

    /**
     * Suspends the item from sale, e.g. when out of stock, until a specified time.
     * Allows overrides dependent on context.
     */
    suspension_info?: UberSuspensionRules;

    /**
     * Specifies the modifier groups to be associated with the item, allowing the user to make choices or bundle extras with their purchase.
     * Allows overrides dependent on context.
     */
    modifier_group_ids?: UberModifierGroupRules;

    /**
     * Specifies the taxes applicable to the item.
     */
    tax_info: UberTaxInfo;

    /**
     * Specifies the nutritional info for an item
     */
    nutritional_info?: UberNutritionalInfo;

    /**
     * Defines the classifications for an item
     */
    dish_info?: UberDishInfo;

    /**
     * Defines when an item is visible to the user
     */
    visibility_info: UberVisibilityInfo;

    /**
     * The labels used to generate tax values
     */
    tax_label_info?: UberTaxLabelsRuleSet;

    constructor(props: Partial<UberItem>) {
        Object.assign(this, props);
    }
}
