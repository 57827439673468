import { OrderDeliveryPrice } from '@aphilia/deliveries/data';

export class OrderDelivery {
    /**
     * The id of the job of the delivery platform
     */
    jobId?: string;

    /**
     * The assignment code for the order from delivery platform (short ID)
     */
    assignment_code?: string;

    /**
     * The duration of the delivery
     */
    duration?: number;

    /**
     * The url of the tracking map to display to the user
     */
    trackingUrl?: string;

    /**
     * The payment information about the order
     */
    payment?: OrderDeliveryPrice;

    constructor(props: OrderDelivery) {
        Object.assign(this, props);
    }
}
