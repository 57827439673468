import { PlatformType } from '../../web-article';

class CreateWebPricePeriodPrice {
    /**
     * The platform / order type price by period
     */
    price: number;
}

export class CreateWebPricePeriod {
    /**
     * Indicates if current period is enabled
     */
    enabled: boolean;

    /**
     * "delivery" PlatformType price by period
     */
    delivery?: CreateWebPricePeriodPrice;

    /**
     * "atTable" PlatformType price by period
     */
    atTable?: CreateWebPricePeriodPrice;

    /**
     * "clickAndCollect" PlatformType price by period
     */
    clickAndCollect?: CreateWebPricePeriodPrice;

    constructor(props: CreateWebPricePeriod) {
        Object.assign(this, props);
    }
}

export class CreateWebPrice {
    /**
     * The related site ID which the web price is attached to
     */
    siteId: string;

    /**
     * The related article ID which the web price is attached to
     */
    articleId: string;

    /**
     * Platform types (object keys) prices (object values)
     */
    price: Record<PlatformType, number>;

    /**
     * Platform types prices by periods (object main keys)
     */
    periods: Record<string, CreateWebPricePeriod>;

    constructor(props: CreateWebPrice) {
        Object.assign(this, props);
    }
}
