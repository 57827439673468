import React from 'react';

function SvgReorder(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 32 32" {...props}>
            <defs>
                <path id="path-1" d="M7.772 20.53h15v-2h-15v2zm2-7h15v-2h-15v2z"></path>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path d="M0 0H32V32H0z"></path>
                <path
                    fill="#232323"
                    // tslint:disable-next-line: max-line-length
                    d="M26.38 11.774c.941.186 1.842.63 2.582 1.344 2.084 2.012 2.114 5.359.067 7.479l1.414 1.365-3.738.098-.033-3.74 1.414 1.366c1.533-1.587 1.51-4.102-.05-5.609a3.89 3.89 0 00-1.644-.938zM5.84 10l.033 3.74-1.414-1.367c-1.533 1.588-1.51 4.102.05 5.61.476.459 1.044.776 1.644.938l.012 1.365a5.133 5.133 0 01-2.583-1.345C1.5 16.93 1.47 13.583 3.516 11.463l-1.414-1.366L5.84 10z"
                ></path>
                <mask fill="#fff">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use fill="#232323" xlinkHref="#path-1"></use>
            </g>
        </svg>
    );
}

export default SvgReorder;
