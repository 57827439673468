export class DayPeriod {
    /**
     * Name of the period
     */
    name: string;

    /**
     * The start time of the period. It must be in format HH:mm
     * The end time is the start time of the next period
     */
    startTime: string;

    /**
     * The colors assign to the period
     */
    color: string;

    constructor(props: DayPeriod) {
        Object.assign(this, props);
    }
}
