import React, { useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

interface Props {
    children: React.ReactNode;
}

export const ApSwitchGroup: React.FC<Props> = ({ children }) => {
    return (
        <FormControl component="fieldset" className="ap-switch-group">
            <FormGroup>{children}</FormGroup>
        </FormControl>
    );
};
