import React, { useCallback, useEffect, useState } from 'react';
import { useDeliveryParametersContext } from '@aphilia/deliveries/feature-parameters';
import { ApCard, ApCol, ApInput, ApRow, ApToggle, ApButton, ApSelect, ApBanner } from '@aphilia/shared-ui-core-react';
import { ApForm, useToastContext } from '@aphilia/shared/react';
import { Deliveroo } from '@aphilia/deliveries/data';
import { getBrandIdsByLocationId } from '@aphilia/deliveries/data-access';

export const DeliveryParametersApplicationsDeliverooPage = () => {
    const { deliveryParameters, deliveriesServiceUrl, updateDeliveryParameters } = useDeliveryParametersContext();
    const [deliverooParameters, setDeliverooParameters] = useState<Deliveroo>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [brandIds, setBrandIds] = useState<{ label: string; value: string }[]>([]);
    const { showSuccessToast, showErrorToast } = useToastContext();

    useEffect(() => {
        setDeliverooParameters(deliveryParameters?.applications?.deliveroo);
    }, [deliveryParameters?.applications?.deliveroo]);

    useEffect(() => {
        if (deliverooParameters?.locationId) {
            getBrands();
        }
    }, [deliverooParameters?.locationId]);

    const getBrands = useCallback(async () => {
        try {
            const res = await getBrandIdsByLocationId(deliveriesServiceUrl, deliverooParameters?.locationId);

            setBrandIds(
                res.map((brandId) => ({
                    value: brandId,
                    label: brandId,
                }))
            );
        } catch (e) {
            console.error(e);
        }
    }, [deliverooParameters?.locationId, deliveriesServiceUrl]);

    const updateDeliveroo = useCallback(
        (property: string, value: any) => {
            setDeliverooParameters({
                ...deliverooParameters,
                [property]: value,
            });
        },
        [deliverooParameters]
    );

    const updateDeliverooParameters = useCallback(async () => {
        await updateDeliveryParameters({ applications: { deliveroo: deliverooParameters } });
    }, [deliverooParameters]);

    const addDeliveroo = useCallback(async () => {
        try {
            setIsLoading(true);
            await updateDeliveryParameters({ applications: { deliveroo: { locationId: deliveryParameters?.siteId, enabled: true } } });
            setIsLoading(false);
            showSuccessToast('Deliveroo a bien été ajouté');
        } catch (e) {
            setIsLoading(false);
            showErrorToast('Une erreur est survenue');
        }
    }, [deliveryParameters?.siteId]);

    if (deliveryParameters?.applications?.deliveroo?.enabled !== undefined) {
        return (
            <ApCard headerTitle="Deliveroo">
                <ApForm
                    onApSubmit={updateDeliverooParameters}
                    confirmText={'Confirmer'}
                    cancelText={'Annuler'}
                    successMessage={'La configuration de Deliveroo a bien changée'}
                    errorMessage={'Une erreur est survenu'}
                >
                    <ApRow>
                        <ApCol>
                            <ApToggle
                                checked={deliveryParameters?.applications?.deliveroo?.enabled}
                                label="Deliveroo actif"
                                onApToggle={(e) => updateDeliveroo('enabled', e.detail)}
                            />
                        </ApCol>
                    </ApRow>
                    <ApRow>
                        <ApCol>
                            <ApInput
                                label="Location ID"
                                helpText={`Code à transmettre à Delivero: ${deliveryParameters?.siteId}`}
                                value={deliveryParameters?.applications?.deliveroo?.locationId}
                                placeholder={deliveryParameters?.siteId}
                                onInputBlur={(e) => updateDeliveroo('locationId', e.detail.target.value)}
                            />
                        </ApCol>
                        <ApCol>
                            <ApSelect
                                label="Brand ID"
                                options={brandIds}
                                value={[deliverooParameters?.brandId]}
                                onItemSelected={(e) => updateDeliveroo('brandId', e.detail.value)}
                            />
                        </ApCol>
                    </ApRow>
                </ApForm>
            </ApCard>
        );
    } else {
        return (
            <>
                <ApRow justifyContent="center">
                    <ApBanner
                        text="Vous pouvez retrouver la documentation de deliveroo ici: https://support.pielectronique.com/hc/fr/articles/4409677508753-Prestataires-de-livraison-Uber-Eats-Deliveroo"
                        clickable={false}
                    />
                </ApRow>
                <ApRow justifyContent="center">
                    <ApCol size={6}>
                        <ApCard headerTitle="Deliveroo" subtitle="Deliveroo n'est pas installé pour ce site. Veuillez cliquez sur le bouton ci dessous pour lancer l'intégrétion">
                            <ApButton loading={isLoading} label="Lancer l'intégration Deliveroo" size="large" onClick={addDeliveroo} />
                        </ApCard>
                    </ApCol>
                </ApRow>
            </>
        );
    }
};
