import { useState, useEffect, useCallback } from 'react';

function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = useCallback(() => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }, [isClient]);

    const [windowSize, setWindowSize] = useState(getSize);

    const handleResize = useCallback(() => {
        setWindowSize(getSize());
    }, [getSize]);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isClient, handleResize]);

    return windowSize;
}

export { useWindowSize };
