import { DeliveryPosStatus, DeliverySiteStatus } from '../delivery-parameters/delivery';
import moment, { Moment } from 'moment';
import { UpdateAddress } from '@aphilia/shared/utils';
import { UpdateClickAndCollect } from '../click-and-collect';
import { UpdateDelivery } from '../delivery';
import { UpdateAtTable } from '../at-table';
import { UpdateDeliveryApplication } from '../delivery-applications';
import { UpdateTerminal } from '../terminal';

export class UpdateDeliveryParameters {
    /**
     * Indicates the current delivery status
     */
    status?: DeliverySiteStatus;

    /**
     * Indicates if the site has enabled or not the delivery
     */
    hasPilink?: boolean;

    /**
     * Indicates the pos status of the site
     */
    posStatus?: DeliveryPosStatus;

    /**
     * Indicates if the site has auto accept enabled
     */
    autoAccept?: boolean;

    /**
     * The last time the menu has been deployed on the different plaftorms.
     */
    lastDeployment?: Moment;

    /**
     * The average time to cook a meal in minutes
     */
    timeForPreparation?: number;

    /**
     * The address of the site
     */
    address?: UpdateAddress;

    /**
     * The phone number of the site
     */
    phoneNumber?: string;

    /**
     * The interval between each order on piclick. It will set each time an order can be placed.
     *
     * @example: 5 => 18h00, 18h05, 18h10, 18h15 .....
     */
    interval?: number;

    /**
     * The maximum number of days an order can be placed ahead of now.
     *
     * @example: 3 => an order can be placed within 3 days but not 4.
     */
    orderPlanedMaxTime?: number;

    /**
     * Update the click and collect information
     */
    clickAndCollect?: UpdateClickAndCollect;

    /**
     * Update the delivery information
     */
    delivery?: UpdateDelivery;

    /**
     * Update the at table information
     */
    atTable?: UpdateAtTable;

    /**
     * Update the terminal information
     */
    terminal?: UpdateTerminal;

    /**
     * Update the applications
     */
    applications?: UpdateDeliveryApplication;

    /**
     * The id of the master site if the site does not have its own pos system
     */
    masterSiteId?: string;

    /**
     * The sitePic of the master site if the site does not have its own pos system
     */
    masterSitePic?: string;

    constructor(props: UpdateDeliveryParameters) {
        Object.assign(this, props);

        if (this.lastDeployment) {
            this.lastDeployment = moment(this.lastDeployment);
        }
    }
}
