import { useCallback } from 'react';

function usePages(onCurrentPageChange) {
    const handleCurrentPage = useCallback(
        (evt: CustomEvent<number>) => {
            if (typeof onCurrentPageChange === 'function') {
                onCurrentPageChange(evt.detail);
            }
        },
        [onCurrentPageChange]
    );

    return { handleCurrentPage };
}

export { usePages };
