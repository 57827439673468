import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export default (theme: Theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        '& .ap-navigation-page-main-page': {
            width: 'calc(100% - 316px)',
            height: '100%',
            padding: '40px',
            backgroundColor: palette.background.default,
        },
    },
});
